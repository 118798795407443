import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

	constructor(protected _santizer: DomSanitizer) { }

	public transform(value?: string, type: string = 'html'): SafeHtml | SafeResourceUrl {
		// let santizeHtml: string = '';
		if (!value) { return ''; }
		// sanitization cases
		switch (type) {
			case 'html':
				// ! TODO: code commented temporary, inline styling removed because of sanitization. Soon it will be fixed and code will be updated.
				// santizeHtml = this._santizer.sanitize(SecurityContext.HTML, value) || '';
				return this._santizer.bypassSecurityTrustHtml(value);
			case 'resourceUrl':
				return this._santizer.bypassSecurityTrustResourceUrl(value);
			default:
				throw new Error(`Unable to bypass security for invalid type: ${type}`);
		}

	}

}
