import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
// External lib
import { ToastrService} from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ } from '../../../../Services';
import { AuthServ } from '../../../../Core/_services';
// Constants
import { PASSWORD_MIN_LENGTH, IS_DEV, DEV_HOST} from '../../../../Constants';
//Custom validator
import { CustomValidators } from '../../../../Global/GlobalDefault';
@Component({
	selector: 'bk-reset-password',
	templateUrl: './ResetPassword.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy, CustomValidators]
})
export class ResetPasswordComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() queryParams: any;
	resetForm!: FormGroup; // Reset password form
	loaderId: string = 'reset-loader';
	merchantId: any;
	constructor(private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private authServ: AuthServ, public utilServ: UtilServ, private toastr: ToastrService) {
		this.merchantId = this.initServ.appAdmnStngs && this.initServ.appAdmnStngs.merchant_id;
	}
	ngOnInit(): void {
		// Forgot password form build
		this.resetForm = this.frmBldr.group({
			new_password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]],
			confirm_password: ['', [Validators.required]],
			password_reset_token: [(this.queryParams && this.queryParams.token) ? this.queryParams.token : null],
			merchant_id: [this.merchantId]
		},{
			validators: [CustomValidators.matchPassword('new_password', 'confirm_password')]
		});
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.resetForm.controls;
	}
	/**
	 * Submit the login form
	 */
	public async submitForm() {
		if(this.resetForm.valid){
			let status = await this.authServ.resetPassword(this.resetForm.value, this.loaderId, false);
			if(status){
				let loginData: any = {
					username: (this.queryParams && this.queryParams.email) ? (this.queryParams.email).toLowerCase() : '',
					password: this.resetForm.value.new_password
				};
				let loginStatus = await this.authServ.login(loginData, '', this.loaderId);
				if(loginStatus){
					this.toastr.success(this.initServ.appStr.toastr.loggedIn);
					let redirectUrl = location.pathname;
					let url = (redirectUrl.charAt(0) == '/') ? (redirectUrl.substr(1)) : redirectUrl;
					let gotoUrl = (url.charAt(0) !== '/') ? ('/'+url) : url;
					// Query params
					let bId = this.queryParams['b_id'] ? this.queryParams['b_id'] : '';
					let firstName = this.queryParams['first_name'] ? this.queryParams['first_name'] : '';
					let lastName = this.queryParams['last_name'] ? this.queryParams['last_name'] : '';
					let email = this.queryParams['email'] ? this.queryParams['email'] : '';
					let phone = this.queryParams['phone'] ? this.queryParams['phone'] : '';
					let occurrence= this.queryParams['occurrence'] ? this.queryParams['occurrence'] : '';
					let refCode = this.queryParams['ref_code'] ? this.queryParams['ref_code'] : '';
					// URL
					let redirect: string = (gotoUrl+"?b_id="+bId+"&first=true&isbooked=true&first_name="+firstName+"&last_name="+lastName+"&email="+email+"&phone="+phone+"&occurrence="+occurrence+"&ref_code="+refCode+"&u_id="+this.utilServ.userId());
					setTimeout(()=>{
						window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/' + redirect;
					},500)
				}
			}
		} else{
			this.resetForm.markAllAsTouched();
		}
	}
}