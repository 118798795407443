/* eslint-disable max-lines-per-function */
/* eslint-disable max-depth */
/* eslint-disable no-dupe-else-if */
/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Bugsnag from '@bugsnag/js';
import { FileItem, FileUploader } from 'ng2-file-upload';
declare let window: any;
declare const top: any;
// External library
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { decryptCBC, decryptGCM, encryptGCM } from 'iron-crypto-pkg';
// Environments
import { environment as env } from '../../environments/environment';
// Services
import { InitServ, RenderComponentServ, CookieServ, LoaderServ, PopupServ, SectionServ, ApiServ, APIURL } from './index'
// Constants
import { APP_PERM_SECTIONS, STRIP_HTML_REG_EXP, MIN_REVIEW_COUNT, CURRENCY_UNICODE, BK_SITE_LINK, IS_DEV, DEV_HOST, CURRENCY_UNICODE_FOR_INPUT, SUMMARY_LABELS, PRICE_MAX_LIMIT, EMAIL_REG_EXP, BILLING_ADDRESS_OBJ, SAME_BILLING_ADDRESS_OBJ, ALLOW_OLD_VERSION } from '../Constants';
import { APIRes, BillingCard } from '../Interfaces';
interface TokebVal extends APIRes {
	data?:{
		uid?: string | number
		entity_obj_id?: string
	}
}
declare interface Document {
	createEventObject(): any;
}
declare const scrlToElement: any;
interface FileUploadHeader {
	name: string;
	value: string;
}
@Injectable({
	providedIn: 'root'
})
export class UtilServ {

	public refListRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public pageSett: BehaviorSubject<any> = new BehaviorSubject<any>({ status: false, data: null });

	// private variables
	// private appData: any;
	private admnStngs: any;
	private bookingSpots: any;
	private bkngCustData: any;
	private _widgetFormData: any;
	private _multiStepForm: boolean = false;
	private _multiStepHeader: boolean = false;
	private _multiStepFooter: boolean = false;
	private _logo: any;
	private currentUser: any;
	// Private variables
	private destroy = new Subject<void>();
	embedStatus: boolean = false;
	adminPage: boolean = false;
	multiIndusStatus: boolean = false;
	id: any;
	siteData: any;
	disabledPages: any = null;
	timeFormat: string = '12';
	headerHeight: number = 0;

	// Getter functions
	// Get the multi step form
	get multiStepForm() {
		return this._multiStepForm;
	}
	// Get the multi step header status
	get multiStepHeader() {
		return this._multiStepHeader;
	}
	// Get the multi step footer status
	get multiStepFooter() {
		return this._multiStepFooter;
	}
	// Get the widget form data
	get widgetFormData(): any {
		return this._widgetFormData;
	}
	// Get the widget form data
	get siteLogo(): any {
		return this._logo;
	}
	bkngStatus: any = {
		0: {text: 'Upcoming',color: 'primary'},
		1: {text: 'Completed', color: 'success'},
		2: {text: 'Charged', color: 'success'},
		3: {text:'Cancelled', color: 'danger'},
		4: {text:'Declined', color:'danger'},
		6: {text: 'Invited', color: 'info'},
		7: {text: 'Partially assigned', color :'secondary'},
		8: {text: 'Assigned but not confirmed', color :'warning'},
		9: {text: 'Deleted', color:'danger'}
	};

	// eslint-disable-next-line max-params
	constructor(private initServ: InitServ, public rcServ: RenderComponentServ, private cookieServ: CookieServ, private loader: LoaderServ, private popupServ: PopupServ, private router: Router, private translate: TranslateService, public secServ: SectionServ, public toastr: ToastrService, private http: HttpClient, private apiServ: ApiServ, private APIURL: APIURL) {
		// this.appData = this.initServ.appData; // App data
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
		this.bookingSpots = this.initServ.appBookingSpots; // App booking spots
		this.bkngCustData = this.initServ.bkngCustData; // Booking custom data
		this.siteData = this.initServ.siteData; // App site data
		if (this.siteData && this.siteData.disabled_pages && (this.siteData.disabled_pages).length > 0) {
			this.disabledPages = this.siteData.disabled_pages;
		}
		// Current login user info from browser local storage
		this.currentUser = this.appLocalStorage();
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if (value) {
				this.currentUser = this.appLocalStorage();
			}
		});
		// Multi industry
		let multiIndus: any = this.multiIndustriesStatus();
		this.multiIndusStatus = multiIndus?.multiIndus;
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store['time_format'] && this.admnStngs.merchant_settings.store['time_format'] === '24') {
			this.timeFormat = '24';
		}
	}
	// Setter function
	/**
	 * Set the site logo
	 */
	public set setSiteLogo(data: any) {
		this._logo = data;
	}
	/**
	 * Set the multi step form
	 * @param formLayout
	 */
	public set setMultiStepForm(formLayout: string) {
		if (formLayout == 'multi_step') {
			this._multiStepForm = true;
		} else {
			this._multiStepForm = false;
		}
	}
	/**
	 * Set the multi step header & footer status
	 * @param header
	 * @param footer
	 */
	public setMultiStepHeaderFooter(header: boolean, footer: boolean): void {
		this._multiStepHeader = header;
		this._multiStepFooter = footer;
		setTimeout(() => {
			this.addClassOnBody();
		}, 500)
	}
	/**
	 * Add class on body in case of multi step header enabled and header is sticky
	 */
	private addClassOnBody() {
		let stickyHeader = document.getElementsByClassName("tjs-header--sticky");
		if (this._multiStepHeader && stickyHeader && stickyHeader.length > 0) {
			document.body.classList.add("main-header-fixed");
		} else {
			document.body.classList.remove("main-header-fixed");
		}
	}
	/**
	 * Set the booking form widgets
	 * @param data
	 */
	set setWidgetFormData(data: any) {
		this._widgetFormData = data;
	}
	/**
	 * Reset the booking form widgets
	 */
	public resetWidgetFormData(): void {
		this._widgetFormData = null;
	}

	/**
	 * Screen type mobile/desktop
	 * @returns
	 */
	public secreenType() {
		let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			return 'mobile'
		} else {
			return 'desktop';
		}
	}
	/**
	 * Get the userId;
	 * @returns
	 */
	public userId(): any {
		if (this.id) {
			return this.id;
		}
		this.id = this.appLocalStorage() && this.appLocalStorage().id;
		return this.id;
	}
	/**
	 * Get the localStorage data
	 * Default current user info
	 * @param key : currentUser
	 * @returns data | null
	 */
	public appLocalStorage(key: string = 'currentUser', isLocalStorage: boolean = false): any {
		let isEmbed = false;
		if (!isLocalStorage) {
			isEmbed = this.embedStatus;
		}
		if (!isEmbed) {
			let localItem: any;
			try {
				localItem = localStorage.getItem(key);
			} catch (err) { /* empty */ }
			let storage: any = localItem !== null ? this.getJson(localItem) : null;
			if (key == 'currentUser' && storage) {
				this.id = storage.id;
			}
			return storage;
		} else {
			return null;
		}
	}
	/**
	 * Convert string into JSON format
	 * @param data Data which we want to convert into JSON format. It might be already in JSON format, so in that case, return the data as it is.
	 * @returns Data in JSON format.
	 */
	private getJson(data: any): any {
		let json: any;
		try {
			json = JSON.parse(data);
		} catch (err) {
			return data;
		}
		return json;
	}
	/**
	 * Remove local storage
	 * @param key : currentUser
	 */
	public removeStorage(key: string = 'currentUser'): void {
		try {
			localStorage.removeItem(key);
		} catch (err) { /* empty */ }
	}
	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy, Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param index
	 * @returns index
	 */
	public trackByFnIndex(index: number): number {
		return index;
	}
	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy, Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param _index
	 * @param data
	 * @returns data id
	 */
	public trackById(_index: any, data: any) {
		return data?.id;
	}
	/**
	 * Check the app particular section permission
	 * @param name: Rating, Coupons, GiftCards etc
	 * @returns boolean value
	 */
	public appPermission(name: string): boolean {
		let secName: string = APP_PERM_SECTIONS[name];
		if (this.initServ.appData?.permissions && this.initServ.appData.permissions[secName] != '0') {
			return true;
		}
		return false;
	}
	/**
	 * Function for iframe
	 * @param embedStatus
	 * @returns
	 */
	public inIframe(embedStatus: any = this.embedStatus): any {
		if (!embedStatus) {
			return false;
		}
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}
	/**
	 * Get the powered by link
	 * @returns link
	 */
	public poweredByLink(): string {
		if (this.admnStngs?.merchant_settings?.store?.domain_name) {
			return `${BK_SITE_LINK}/?bkref=${this.admnStngs.merchant_settings.store.domain_name}`;
		}
		return '';
	}
	/**
	 * Return the last initial name depend on settings.
	 * @param sett: admin settings
	 * @param name: user name
	 * @returns name
	 */
	public lastInitial(sett: any, name?: string): string {
		if (sett && name && sett == 'first_name_last_initial') {
			return name.charAt(0);
		}
		return name ?? '';
	}
	/**
	 * Remove extras HtmlRegExp
	 * @param html
	 * @returns false & html
	 */
	private strip(html: any): any {
		if ((html === null) || (html === '')) {
			return false;
		} else {
			return (html.toString()).replace(STRIP_HTML_REG_EXP, '');
		}
	}
	/**
	 * Get the review description, if char is long add '...'
	 * @param html
	 * @returns
	 */
	public reviewDesc(html: any): any {
		let text = html;
		if (typeof (html) !== 'undefined') {
			text = this.strip(html);
			if (this.reviewCount(text)) {
				let str = text.substr(0, MIN_REVIEW_COUNT) + '...';
				return str.trim();
			}
		}
		return text;
	}
	/**
	 * Retrun the description min length status.
	 * @param html
	 * @returns
	 */
	public reviewCount(html: any): boolean {
		let text = this.strip(html);
		return (text && text.length > MIN_REVIEW_COUNT) ? true : false;
	}
	/**
	 * Check the http exist in url
	 * @param url
	 * @returns url
	 */
	public checkHttpExist(url: string): string {
		if (!(/^http:\/\//.test(url)) && !(/^https:\/\//.test(url))) {
			return "https://" + url;
		}
		return url;
	}
	/**
	 * Get the image url
	 * @param url: url
	 * @returns
	 */
	public getImgUrl(url: any): any {
		if (url) {
			let newUrl = this.initServ.imgBase + (url.startsWith("/") ? url : '/' + url);
			return newUrl;
		}
		return url;
	}
	/**
	 * Build the link
	 * @param type
	 * @param linkUrl
	 * @returns
	 */
	public buildLink(type: string, linkUrl: string | null): string {
		let link: string = '';
		if (linkUrl && type) {
			let slug = linkUrl.replace(/\//g, "");
			let pageUrl = this.initServ.appDynamicRoutes[slug];
			if (slug == 'booknow' && type == 'popup') {
				type = 'page';
			}
			switch (type) {
				case 'page':
					// let url = (linkUrl.charAt(0) == '/') ? (linkUrl.substr(1)) : linkUrl;
					// let gotoUrl = (url.charAt(0) !== '/') ? ('/'+url) : url;
					// link = (pageUrl && (pageUrl.charAt(0) !== '/')) ? ('/'+pageUrl) : gotoUrl;
					if (pageUrl) {
						link = (pageUrl.charAt(0) !== '/') ? '/' + pageUrl : pageUrl;
					} else {
						link = (linkUrl.charAt(0) !== '/') ? '/' + linkUrl : linkUrl;
					}
					break;
				case 'web':
					if (linkUrl == '/') {
						return "";
					} else {
						link = this.checkHttpExist(linkUrl);
					}
					break;
				case 'email':
					link = 'mailto:' + linkUrl;
					break;
				case 'phone':
					link = 'tel:' + linkUrl;
					break;
				default:
					link = linkUrl;
					break;
			}
		}
		return link;
	}
	/**
	 * Generate the time:: 00:00 AM to 11:00 PM
	 * @returns array of object
	 */
	public generateTime(): any {
		let times: any = [];
		for (let i = 0; i <= 23; i++) {
			let hours: any;
			let meridian: any;
			if (i < 12) {
				hours = (i < 10) ? ("0" + i) : i;
				meridian = "AM";
			} else {
				hours = i - 12;
				if (hours == 0) { hours = 12 }
				if (hours < 10) { hours = "0" + hours; }
				meridian = "PM";
			}
			times.push(hours + ':00' + ' ' + meridian);
		}
		return times;
	}
	/**
	 * Yesterday date, used to disable past dates on calendar.
	 * @returns
	 */
	public yesterdayDate(): any {
		let yesterday = dayjs().subtract(1, "days");
		let date = { year: +(dayjs(yesterday).format('YYYY')), month: +(dayjs(yesterday).format('M')), day: +(dayjs(yesterday).format('D')) };
		return date;
	}
	/**
	 * Unmask the phone number
	 * @param value
	 * @returns
	 */
	public phoneUnmask(value: any): any {
		if (value) {
			return value.replace(/\D+/g, '');
		}
		return value
	}
	/**
	 * Multiple industries status
	 * @returns true/false
	 */
	public multiIndustriesStatus(): boolean {
		let multi: boolean = false;
		let industries: any = [];
		if (this.initServ.appData && this.initServ.appData.industries && (this.initServ.appData.industries).length > 0) {
			let numberOfIndustries = 0;
			for (let industry of this.initServ.appData.industries) {
				if (industry.status == 1 && this.checkIndustryStatusForCust(industry)) {
					numberOfIndustries = numberOfIndustries + 1;
					(industries).push(industry);
				}
			}
			multi = (numberOfIndustries > 1) ? true : false;
		}
		let obj: any = {
			industries: industries,
			multiIndus: multi
		}
		return obj;
	}
	/**
	 * Multi industries
	 * @returns Object
	 */
	public multiIndustries(): any {
		let industries: any;
		if (this.initServ.appData && this.initServ.appData.industries && (this.initServ.appData.industries).length > 0) {
			let industryObj: any = { state: 'booknow', name: 'Add Booking', type: 'sub', children: [] };
			for (let industry of this.initServ.appData.industries) {
				if (industry && industry.status == 1 && this.checkIndustryStatusForCust(industry)) {
					let subIndustryObj: any = {
						id: industry.id,
						state: industry.industry_slug,
						name: industry.custom_industry_name ? industry.custom_industry_name : industry.industry_name,
						type: 'link',
						photo_url: industry.photo_url,

					};
					(industryObj.children).push(subIndustryObj);
				}
			}
			industries = industryObj;
		}
		return industries;
	}
	/**
	 * Check the industry status for customer
	 * @param industry
	 * @returns
	 */
	public checkIndustryStatusForCust(industry: any): boolean {
		if (industry.customer_status && industry.customer_status == 2) {
			return false;
		}
		return true;
	}
	/**
	 * Function to show date in the top bar of multistep form.
	 */
	showDateForMultiStep(selectedDate: any): any {
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		let newdatearray = selectedDate.split("-");
		let newdate = newdatearray[1] + "/" + newdatearray[2] + "/" + newdatearray[0];
		const objDate = new Date(newdate);
		newdate = this.translate.instant(months[objDate.getMonth()]) + ' ' + newdatearray[2] + ', ' + newdatearray[0];
		return newdate;
	}
	/**
	 * Slot to string, use for booking date time
	 * @param locationId
	 * @param day
	 * @param key
	 * @param spotType
	 * @param arrivalWindow
	 * @returns
	 */
	// eslint-disable-next-line max-params
	public slotToString(locationId: any, day: string, key: any, spotType: string = 'automatic', arrivalWindow: any = 0): string {
		if (this.bookingSpots && typeof (this.bookingSpots[locationId]) !== 'undefined' && day && typeof (this.bookingSpots[locationId][day.toLowerCase()]) !== 'undefined' && typeof (this.bookingSpots[locationId][day.toLowerCase()][+key]) !== 'undefined' && spotType != 'manual') {
			return this.convertTime24HFormat(this.bookingSpots[locationId][day.toLowerCase()][+key].name);
		} else {
			let startTime = this.getSpotString(key)
			let endTime: any;
			if (arrivalWindow && +arrivalWindow > 0 && spotType == 'manual') {
				let startMin = key % 100
				arrivalWindow = +arrivalWindow + startMin
				let hoursFromRange = (Math.floor(+arrivalWindow / 60) * 100) + (+arrivalWindow % 60);
				key = (key - startMin) + hoursFromRange;
				endTime = this.getSpotString(key)
				return startTime + ' - ' + endTime
			}
			return startTime;
		}
	}
	/**
	 * Convert time to string
	 * @param time
	 * @returns
	 */
	private getSpotString(time: any): string {
		let hours = Math.floor(time / 100);
		let minutes = time % 100;
		let Meridian: any;
		let Hours: any;
		let Minutes: any;
		if (hours >= 12) {
			Meridian = "PM";
			if (hours > 12) { Hours = hours - 12; } else { Hours = hours }
			Minutes = minutes;
		} else {
			Meridian = "AM";
			if (hours > 0) { Hours = hours; } else { Hours = 12; }
			Minutes = minutes;
		}
		if (Hours < 10) { Hours = '0' + Hours; } else { Hours = Hours; }
		if (minutes < 10) { Minutes = '0' + Minutes; } else { Minutes = Minutes; }
		return this.convertTime24HFormat(Hours + ':' + Minutes + ' ' + Meridian);
	}
	/**
	 * Week day name based on date timestamp
	 * @param dateTM
	 * @returns
	 */
	public weekDayName(dateTM: any): string {
		let weekDayName = dayjs(dateTM * 1000).format('dddd');
		return weekDayName;
	}
	/**
	 * Check the user name settings
	 * @returns
	 */
	public userNameVisible(): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.customers && this.admnStngs.merchant_settings.customers.enable_provider_display_to_customer && this.admnStngs.merchant_settings.customers.enable_provider_display_to_customer == 'yes' && this.admnStngs.merchant_settings.customers.details_to_show && (this.admnStngs.merchant_settings.customers.details_to_show).length > 0 &&
			(this.userNameSett('firstname') || this.userNameSett('lastname') || this.userNameSett('last_initial'))) {
			return true;
		}
		return false;
	}
	/**
	 * Check user name settings
	 * @param setting firstname,lastName & last_initial
	 * @returns
	 */
	public userNameSett(setting: any): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.customers && this.admnStngs.merchant_settings.customers.details_to_show && (this.admnStngs.merchant_settings.customers.details_to_show).length > 0) {
			if ((this.admnStngs.merchant_settings.customers.details_to_show).includes(setting)) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Get the name last initial
	 * @param name
	 * @returns
	 */
	public nameLastInitial(name: string): string | null {
		if (name) {
			return name.charAt(0);
		}
		return null;
	}
	/**
	 * Check and get customization of booking summary variable(s).
	 * @param indFormId industry_id_form_id
	 * @param labelName
	 * @param defVal
	 * @returns
	 */
	public bkngCustomLabel(indFormId: any, labelName: string, defVal: string): string | boolean {
		this.bkngCustData = this.initServ.bkngCustData; // Booking custom data
		// Check customization data exist or not
		if (this.bkngCustData && indFormId && this.bkngCustData.hasOwnProperty(indFormId) && this.bkngCustData[indFormId].hasOwnProperty(labelName)) {
			if (this.bkngCustData[indFormId][labelName]) {
				if (this.bkngCustData[indFormId][labelName] == 'ele_hide') {
					return false;
				} else {
					// Return customized value
					return this.bkngCustData[indFormId][labelName];
				}
			}
		}
		return this.translate.instant(defVal);
	}
	/**
	 * Replace underscore with space
	 * @param name
	 * @returns
	 */
	public replaceUnderscore(name: any): string {
		return (name).replace(/_/g, " ");
	}
	public getParamCat(setting: any): string {
		if (setting) {
			return setting.name
		}
		return '';
	}
	/**
	 * Get the form parameter name
	 * @param setting
	 * @returns
	 */
	public getFormParamName(setting: any): string {
		if (setting) {
			if (setting.name_different_customer_end) {
				return setting.customer_end_name;
			} else {
				if (setting.name) {
					return this.replaceUnderscore(setting.name);
				}
			}
		}
		return '';
	}
	/**
	 * Get the frequency name
	 * @param frequency
	 * @returns string
	 */
	public getFrequencyName(frequency: any): string {
		if (frequency.form_frequency_data.name_different_customer_end) {
			return frequency.form_frequency_data.customer_end_name;
		} else {
			if (frequency.name) {
				return this.replaceUnderscore(frequency.name);
			}
		}
		return '';
	}
	/**
	 * Get the pricing parameters value name
	 * @param catName: Pricing param category name
	 * @param paramId: value id
	 * @param pricingParams: pricing params settings (Based on selected industry and form)
	 * @returns string
	 */
	public getPricingParamName(catName: string, paramId: any, pricingParams: any) {
		if (pricingParams && (Object.keys(pricingParams)).length > 0) {
			if (pricingParams[catName] && pricingParams[catName][paramId]) {
				return this.getFormParamName(pricingParams[catName][paramId]);
			}
		}
		return '';
	}
	/**
	 * Service name
	 * @param id service id
	 * @returns
	 */
	public serviceName(id: string | number): string {
		// TODO: Remove appload data usage, check again (Lakhvir)
		if (this.initServ.allServCats && (this.initServ.allServCats).length > 0) {
			for (let service of this.initServ.allServCats) {
				if (service.id == id) {
					return this.getFormParamName(service);
				}
			}
		}
		return '';
	}
	/**
	 * Frequency name
	 * @param id frequency id
	 * @returns
	 */
	public frequencyName(id: string | number): string {
		// TODO: Remove appload data usage, check again (Lakhvir)
		if (this.initServ.allFreqs && (this.initServ.allFreqs).length > 0) {
			for (let frequency of this.initServ.allFreqs) {
				if (frequency.id == id) {
					return this.getFormParamName(frequency);
				}
			}
		}
		return '';
	}
	/**
	 * Merchant location
	 * @param id location id
	 * @returns
	 */
	public merchantLocation(id: string | number): string {
		if (id && this.initServ.appData && this.initServ.appData.locations && (this.initServ.appData.locations).length > 0) {
			for (let location of this.initServ.appData.locations) {
				if (location._id == id) {
					return location.location.merchant_location_address;
				}
			}
		}
		return '';
	}
	/**
	 * Image load error, show default image
	 * @param event Event
	 * @param src image src
	 */
	public handleImgError(event: Event, src: string): void {
		let source: any = event.srcElement;
		source.src = src;
	}
	/**
	 * Decimal 2
	 * @param number
	 * @returns
	 */
	public roundToTwo(number: number) {
		if(!number && number != 0){ return 0; }
		if(number){
			let val: any = Math.round(number * 100) / 100;
			if(val < 0){ return 0; }
			return (val && (Math.abs(val) < PRICE_MAX_LIMIT)) ? val : 0;
		}
		return number;
	}

	/**
	 * Convert date timestamp
	 * @param date
	 * @returns timestamp
	 */
	public convertToTM(date: any): any {
		return Date.parse(date) / 1000;
	}
	/**
	 * Split date strings and generate new date;
	 * @param datestr
	 * @param hours
	 * @param minutes
	 * @returns
	 */
	public dateObj(datestr: any, hours: number = 0, minutes: number = 0, isLocal: boolean = true): any {
		let dateObj = dayjs(datestr)
		if (!isLocal) {
			dayjs.extend(utc);
			dateObj = dateObj.utc()
		}
		let newDate = dateObj.add(hours, 'hours').add(minutes, 'minutes').add(0, 'seconds');
		return newDate.toDate();
	}
	/**
	 * Check the booking pre charged
	 * @param bkng
	 * @returns
	 */
	public bkngPreCharged(bkng: any): boolean {
		let checkOnStatus = [0, 1, 6, 7, 8];
		let isBkngPreCharged: boolean = false;
		if (bkng && bkng.payment_log && (bkng.payment_log).length > 0 && checkOnStatus.includes(+bkng.status)) {
			for (let log of bkng.payment_log) {
				if (log.payment_type == 'booking' && log.status == 'paid') {
					isBkngPreCharged = true;
					if (log.is_refunded && log.refund_type && log.refund_type == 'full') {
						isBkngPreCharged = false;
					}
					break;
				}
			}
		}
		return isBkngPreCharged;
	}
	/**
	 * Reschedule fees is charged
	 * @param bkng
	 * @param oldBkng
	 * @param serviceCat
	 * @param isPostpone
	 * @returns boolean
	 */
	public isChargeRescheduleFee(bkng: any, oldBkng: any = null, serviceCat: any, isPostpone: boolean = false) {
		let canChargeRescFee: boolean = this.canChargeRescheduleFee(bkng, oldBkng);
		if (isPostpone) {
			canChargeRescFee = true;
		}
		let checkIsFeeChargeOnServ: boolean = this.checkIsFeeChargeOnServ(serviceCat)
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.reschedule && this.admnStngs.merchant_settings.reschedule.override_service_reschedule_fee) {
			checkIsFeeChargeOnServ = true
		}
		if (checkIsFeeChargeOnServ && canChargeRescFee && this.bkngUnderChargeTimeFrame(oldBkng) && this.rescFeeAlreadyCharged(oldBkng) && this.rescheduleFee(oldBkng, serviceCat).amount > 0) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Check "charge_reschedule_fee_for" store option
	 * @param bkng
	 * @param oldBkng
	 * @returns return boolean according to settings.
	 */
	public canChargeRescheduleFee(bkng: any, oldBkng: any): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.reschedule && this.admnStngs.merchant_settings.reschedule.charge_reschedule_fees && this.admnStngs.merchant_settings.reschedule.charge_reschedule_fees == 'yes') {
			let chargeRescheduleFeeFor: any = this.admnStngs.merchant_settings.reschedule.charge_reschedule_fee_for;
			if (chargeRescheduleFeeFor && chargeRescheduleFeeFor.length > 0) {
				if (chargeRescheduleFeeFor.includes('any')) {
					return true;
				} else if (chargeRescheduleFeeFor.includes('time')) {
					if (oldBkng && (oldBkng.booking_date != bkng.booking_date) || (oldBkng.arrival_time != bkng.arrival_time)) {
						return true;
					}
				} else if (chargeRescheduleFeeFor.includes('date')) {
					if (oldBkng && oldBkng.booking_date != bkng.booking_date) {
						return true;
					}
				}
			}
		}
		return false;
	}
	/**
	 * Check reschedule fee is enabled under selected service category.
	 * @param category
	 * @returns boolean
	 */
	public checkIsFeeChargeOnServ(category: any): boolean {
		if (category && category.enable_reschedule_fees && category.enable_reschedule_fees == 'no') {
			return false;
		}
		return true;
	}
	/**
	 * Check booking under change time frame
	 * @param oldBkng
	 * @returns boolean
	 */
	public bkngUnderChargeTimeFrame(oldBkng: any): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && oldBkng) {
			if (oldBkng.same_day_booking && this.admnStngs.merchant_settings.reschedule.exclude_same_day_reschedule_fees) {
				return false;
			} else {
				if (this.admnStngs.merchant_settings.reschedule.charge_reschedule_fees == 'yes') {
					let time = Math.floor(Date.now() / 1000);
					let rescheduleTime: any;
					let hours: any = Math.floor(+oldBkng.arrival_time / 100);
					let minutes: any = +oldBkng.arrival_time % 100;
					if (this.admnStngs.merchant_settings.reschedule.reschedule_time_type == 'day_before') {
						let dayBeforeRescHours: number = 0;
						let dayBeforeRescMinutes: number = 0;
						if (this.admnStngs.merchant_settings.reschedule.reschedule_max_time > 0) {
							dayBeforeRescHours = Math.floor(+this.admnStngs.merchant_settings.reschedule.reschedule_max_time / 100);
							dayBeforeRescMinutes = +this.admnStngs.merchant_settings.reschedule.reschedule_max_time % 100;
						}
						let serviceDate: any = this.dateObj(oldBkng.booking_date, 0, 0);
						serviceDate.setDate(serviceDate.getDate() - 1);
						rescheduleTime = this.convertToTM(dayjs(serviceDate).format('MM/DD/YYYY') + ' ' + dayBeforeRescHours + ':' + dayBeforeRescMinutes + ':' + 0);
					} else {
						let bookingDate: any = this.convertToTM(this.dateObj(oldBkng.booking_date, hours, minutes));
						let hoursBeforeRescTime = (+this.admnStngs.merchant_settings.reschedule.reschedule_max_time) * 3600;
						rescheduleTime = bookingDate - hoursBeforeRescTime;
					}
					if (rescheduleTime < time) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			}
		}
		return false;
	}
	/**
	 * Reschedule already charged
	 * Check "charge_multiple_fee" value and compair from previous logs
	 * @param oldBkng
	 * @returns boolean
	 */
	public rescFeeAlreadyCharged(oldBkng: any): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.reschedule) {
			let chargeMultipleFee: any = this.admnStngs.merchant_settings.reschedule.charge_multiple_fee;
			if (chargeMultipleFee) {
				return true;
			} else {
				if (oldBkng && oldBkng.payment_log && (oldBkng.payment_log).length > 0) {
					let latesLogTime: number = 0;
					let isRescLog: number = 0
					for (let log of oldBkng.payment_log) {
						if (log.payment_type == 'reschedule') {
							if (log.charged_on > latesLogTime) {
								latesLogTime = log.charged_on;
								isRescLog++
							}
						}
					}
					if (isRescLog == 0) {
						return true
					}
					let logNxtDate: number = 0;
					if (latesLogTime && latesLogTime > 0) {
						let d = dayjs(latesLogTime * 1000).toDate();
						let d1 = dayjs(latesLogTime * 1000).toDate();
						logNxtDate = Math.floor((d1.setDate(d.getDate() + 1)) / 1000);
					}
					let currTime = Math.floor((dayjs().valueOf()) / 1000);
					if (currTime > logNxtDate) {
						return true;
					} else {
						return false;
					}
				}
			}
		}
		return true;
	}
	/**
	 * Reschedule fees
	 * @param oldBkng
	 * @param category
	 * @returns object
	 */
	public rescheduleFee(oldBkng: any, category: any) {
		let chargeAmount: number = 0;
		let chargeUnit: string = '';
		let amount: number | string;
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.reschedule) {
			if (this.admnStngs.merchant_settings.reschedule.charge_reschedule_fees == 'yes') {
				chargeAmount = +this.admnStngs.merchant_settings.reschedule.reschedule_fees;
				chargeUnit = this.admnStngs.merchant_settings.reschedule.reschedule_fees_unit;
			}
			if (category && category.enable_reschedule_fees != 'no' && category.reschedule_charge_type && !this.admnStngs.merchant_settings.reschedule.override_service_reschedule_fee) {
				chargeAmount = +category.reschedule_charge_value;
				chargeUnit = category.reschedule_charge_type;
			}
		}
		if (chargeAmount) {
			if (chargeUnit && chargeUnit == 'percentage') {
				amount = Math.round((((+oldBkng.total) * (chargeAmount)) / 100) * 100) / 100;
			} else {
				amount = chargeAmount;
			}
		} else {
			amount = 0;
		}
		let obj: any = {
			chargeAmount: chargeAmount,
			chargeUnit: chargeUnit,
			amount: amount
		}
		return obj;
	}
	/**
	 *
	 * @param setting
	 * @param rescFee
	 * @returns
	 */
	public convertDayBeforeTime(setting: any, rescFee: boolean = false): string {
		let amount = this.admnStngs?.merchant_settings?.reschedule?.reschedule_max_time;
		let time: any = rescFee ? (+amount) : (+setting.cancellation_time);
		return this.getSpotString(time);
	}

	/**
	 * Create a short address
	 * @param address
	 * @returns
	 */
	public createShortAddr(address: any): string {
		let componentArray: any = ['street_number', 'route', 'neighborhood', 'sublocality'];
		let addressArray: any = [];
		if (componentArray && componentArray.length > 0) {
			for (let component of componentArray) {
				/** To get the street number from address. **/
				let addressComp = this.getComponentByType(address, component);
				if (addressComp && addressComp.long_name) {
					addressArray.push((addressComp.long_name).toString());
				}
			}
		}
		let shortAddress = '';
		let i = 0;
		if (addressArray && addressArray.length > 0) {
			for (let addressPart of addressArray) {
				shortAddress += addressPart;
				if (i != (addressArray.length - 1)) {
					shortAddress += ', ';
				}
				i++;
			}
		}
		return shortAddress;
	}
	/**
	 * Get the address component by using type
	 * @param address
	 * @param type
	 * @returns
	 */
	public getComponentByType(address: any, type: string): any {
		if (!type)
			return null;
		if (!address || !address.address_components || address.address_components.length == 0)
			return null;
		type = type.toLowerCase();
		for (let comp of address.address_components) {
			if (!comp.types || comp.types.length == 0)
				continue;
			if (comp.types.findIndex((x: any) => x.toLowerCase() == type) > -1)
				return comp;
		}
		return null;
	}
	/**
	 * Booking invoice window
	 * @param id booking id
	 */
	public async bkngInvoiceWindow(id: string | number): Promise<void> {
		let x = screen.width / 2 - 700 / 2;
		let y = screen.height / 2 - 450 / 2;
		let bkngId: string = await this.dataEncryptByGCM(id.toString());
		window.open(`${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}/booking-invoice/${bkngId}`, 'popupWindow', 'dialog=yes, close=0, width=600, height=400,left=' + x + ',top=' + y);
	}
	/**
	 * Note is available
	 * @param note
	 * @returns boolean
	 */
	public noteAvailable(note: any): boolean {
		if (note) {
			if ((note != null) && (note != '')) {
				let string = note.replace(/<\/?[^>]+(>|$)/g, "");
				string = string.trim();
				if (string.length > 0) {
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Convert dynamic text(like token) into valid/readable string
	 * @param content actual content
	 * @param token token
	 * @param replaceWith replace value
	 * @param str multiple token on one string pass string
	 * @returns Dynamic text string
	 */
	public generateDynamicStr(content: string, token: string, replaceWith: string, str: string = ''): string {
		let text: string = '';
		if (str && str.length > 0) {
			text = str;
		} else {
			if (content) {
				text = content;
			}
		}
		if (text) {
			if (text.includes(token)) {
				let newStr = text.replace(new RegExp(token, 'g'), replaceWith);
				return newStr;
			} else {
				return text;
			}
		}
		return '';
	}
	/**
	 * Amount with currency
	 * Use in under the typescript functions
	 * @param amount
	 * @returns string
	 */
	public amountWithCurrency(amount: string | number, AdditionText: string = '', spacingClass: string = ''): string {
		let currency: string = this.admnStngs?.merchant_settings?.store?.store_currency;
		let unicode = this.generateCode(currency);
		if (unicode) {
			let html: any = `<span class="dir-ltr` + spacingClass + `"><i class="fa">` + unicode + `</i>` + (+amount).toFixed(2) + `</span>`;
			if (AdditionText) {
				html = `<span class="dir-ltr">` + AdditionText + ` <i class="fa">` + unicode + `</i>` + (+amount).toFixed(2) + `</span>`;
			}
			return html;
		}
		return '';
	}
	/**
	 * Generate the currency icon based on unicode
	 * @returns icon
	 */
	private generateCode(currency: string){
		let unicode = CURRENCY_UNICODE[(currency)];
		if(currency == unicode) {
			return unicode
		}
		return  "&#x"+ unicode +';';
	}
	/**
	 * Function to get the currency code for input field
	 */
	public currencyCodeForInput() {
		let currency = 'USD';
		if (this.admnStngs && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.store_currency) {
			currency = this.admnStngs.merchant_settings.store.store_currency;
		}
		return CURRENCY_UNICODE_FOR_INPUT[currency];
	}
	/**
	 * Copy the invite link
	 */
	public copyToClipboard(id: string, type: string = ''): void {
		let copyText: any = document.getElementById(id) as HTMLInputElement;
		if (copyText) {
			copyText.select();
			document.execCommand("copy");
			let toastText: string = '';
			if (type == 'review') {
				toastText = 'Review copied successfully.';
			} else {
				toastText = 'Copied url - ' + copyText.value
			}
			this.toastr.success(toastText);
		}
	}
	/**
	 * Set the particular page data
	 * @param pageId
	 * @param sett
	 */
	public setPageSett(pageId: any, sett: any): void {
		let obj: any = {
			id: pageId,
			header: true,
			headerMenu: true,
			headerType: 'none',
			footer: true,
			footerMenu: true,
			footerType: 'none'
		}
		if (sett) {
			// Header
			if (sett.hide_header && sett.hide_header == 'yes') {
				obj.header = false;
			} else {
				if (sett.hide_header_menu && (sett.hide_header_menu == 'yes' || sett.hide_header_menu == 'complete')) {
					obj.headerMenu = false;
				} else {
					obj.headerMenu = true;
					if (sett.hide_header_menu == 'specific') {
						obj.headerType = 'specific';
					}
				}
				obj.header = true;
			}
			// Footer
			if (sett.hide_footer && sett.hide_footer == 'yes') {
				obj.footer = false;
			} else {
				if (sett.hide_footer_menu && (sett.hide_footer_menu == 'yes' || sett.hide_footer_menu == 'complete')) {
					obj.footerMenu = false;
				} else {
					obj.footerMenu = true;
					if (sett.hide_footer_menu == 'specific') {
						obj.footerType = 'specific';
					}
				}
				obj.footer = true;
			}
		}
		//apply page background color
		document.body.style.backgroundColor = (sett && sett.background_color) ? sett.background_color : '';
		this.pageSett.next({ status: true, data: obj });
	}
	/**
	 * Redirect broken url
	 */
	public redirectBrokenUrl(pageSlug: string = ''): void {
		// Redirect to next page only 2 times.
		let count: any = +this.rcServ.pageApiHitCount;
		if (this.rcServ.pageApiHitCount < 2) {
			count++
			this.rcServ.setPageApiHitCount = count;
			if (pageSlug == 'home' || this.initServ.appDynamicRoutes['booknow'] == pageSlug) {
				this.gotoNextRouteHomeBookNow();
			} else {
				if (this.siteData && this.siteData.theme_settings && this.siteData.theme_settings.settings && this.siteData.theme_settings.settings.broken_url && this.initServ.appDynamicRoutes && this.initServ.appDynamicRoutes[this.siteData.theme_settings.settings.broken_url]) {
					let pageUrl = this.initServ.appDynamicRoutes[this.siteData.theme_settings.settings.broken_url];
					this.router.navigate([pageUrl]);
				} else {
					this.gotoNextRouteHomeBookNow();
				}
			}
		}
	}
	/**
	 * Goto next booknow/home page
	 * If both disabled 404 page
	 * If 404 page not found, goto next enabled page
	 */
	private gotoNextRouteHomeBookNow() {
		let url = this.gotoNextRoute();
		let slug = url.replace(/\//g, "");
		let pageUrl = this.initServ.appDynamicRoutes[slug];
		if (url) {
			let currentUrl = pageUrl ? pageUrl : url;
			let newurl = (currentUrl.charAt(0) !== '/') ? ('/' + currentUrl) : currentUrl;
			this.router.navigate([newurl]);
		}
	}
	/**
	 * Goto next route if broken url is not there
	 * @returns url
	 */
	private gotoNextRoute(): string {
		if (this.siteData && this.siteData.disabled_pages) {
			let disabledPages = this.siteData.disabled_pages;
			if ((disabledPages && disabledPages.length > 0) && disabledPages.includes('home') && !disabledPages.includes('booknow')) {
				return 'booknow';
			} else if ((disabledPages && disabledPages.length > 0) && disabledPages.includes('booknow') && !disabledPages.includes('home')) {
				return 'home';
			} else {
				if (this.siteData && this.siteData.theme_settings && this.siteData.theme_settings.settings && this.siteData.theme_settings.settings.broken_url && this.initServ.appDynamicRoutes && this.initServ.appDynamicRoutes[this.siteData.theme_settings.settings.broken_url]) {
					return this.initServ.appDynamicRoutes[this.siteData.theme_settings.settings.broken_url];;
				} else {
					for (let slug in this.initServ.appDynamicRoutes) {
						if (disabledPages && !disabledPages.includes(slug)) {
							return slug;
						}
					}
				}
			}
		}
		return '/';
	}
	/**
	 * Redirect url
	 * @param pageSett
	 */
	public redirectPageURL(pageSett: any): void {
		if (pageSett.redirect_link_to == 'page') {
			let pageUrl = this.initServ.appDynamicRoutes[pageSett.redirect_link_url];
			let link = (pageUrl && (pageUrl.charAt(0) !== '/')) ? ('/' + pageUrl) : pageUrl;
			this.router.navigate([link]);
		} else if (pageSett.redirect_link_to == 'web') {
			top.window.location.href = this.checkHttpExist(pageSett.redirect_link_url);
		}
	}
	/**
	 * Check the menu conditions
	 * @param menu menu object
	 * @param pageId current page id
	 * @returns boolean
	 */
	public menuChecker(menu: any, pageId: number | string): boolean {
		// Hide menu
		if (menu.hasOwnProperty('hide_item_on')) {
			if (menu?.hide_item_on == 'all') {
				return false;
			} else {
				if (menu?.hide_item_on == 'specific') {
					if (menu?.disable_item_on && (menu.disable_item_on).includes(pageId)) {
						return false;
					}
				}
			}
		}
		// Menu link
		if (menu?.link_url) {
			switch (menu.link_url) {
				case "/booknow":
				case "booknow":
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes('booknow')) {
						return false;
					} else {
						return this.bookingOrLeadFormStatus('booking_form');
					}
				case "/dashboard":
					if (this.currentUser && !this.initServ.theme) {
						return true;
					}
					return false;
				case "/gift-cards/send":
				case "gift-cards":
				case "gift-card":
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes('gift-card')) {
						return false;
					} else {
						if (this.appPermission('giftCards')) {
							return true;
						}
						return false;
					}
				case "/referrals":
				case "referrals":
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes('referrals')) {
						return false;
					} else {
						if (this.currentUser && this.appPermission('referrals') && (!this.initServ.theme)) {
							return true;
						}
						return false;
					}
				case "/contact-us":
				case "contact-us":
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes('contact-us')) {
						return false;
					} else {
						if (this.appPermission('leadForm')) {
							return this.bookingOrLeadFormStatus('lead_form');
						}
						return false;
					}
				case "/login":
				case "login":
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes('login')) {
						return false;
					} else {
						if (!this.currentUser || this.initServ.theme) {
							return true;
						}
						return false;
					}
				case "/signup":
				case "signup":
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes('signup')) {
						return false;
					} else {
						if (!this.currentUser || this.initServ.theme) {
							return true;
						}
						return false;
					}
				default:
					let url = (menu.link_url.charAt(0) == '/') ? (menu.link_url.substr(1)) : menu.link_url;
					if (menu.link_to == 'page' && this.disabledPages && this.disabledPages.includes(url)) {
						return false;
					}
					return true;
			}
		} else {
			// link_to and link_url variable not there consider the none
			if (menu.hasOwnProperty('link_to') && menu.link_to == 'none') {
				return true;
			} else if (!menu.hasOwnProperty('link_to') && !menu.hasOwnProperty('link_url')) {
				return true;
			}
			return false;
		}
	}

	/**
	 * Book now and lead form status
	 * @param type booking_form & lead_form
	 * @returns
	 */
	public bookingOrLeadFormStatus(type: string): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.offer_form && (this.admnStngs.merchant_settings.store.offer_form).length > 0) {
			if ((this.admnStngs.merchant_settings.store.offer_form).includes(type)) {
				return true;
			} else {
				return false;
			}
		} else {
			if (type == 'lead_form') {
				return false;
			} else {
				return true;
			}
		}
	}
	/**
	 * Build menu data in nestable format
	 * @param items Array menu items
	 * @returns Nestable menu
	 */
	public processFilteredMenu(menus: any, items: any, pageSett: any): any {
		if (items && items.length > 0 && ((menus).length > items.length)) {
			let i = 0;
			for (let item of items) {
				let subMenuItems: Array<any> = this.processFilteredMenu(menus, this.getChildren(menus, item.id, pageSett), pageSett);
				if (subMenuItems && subMenuItems.length > 0) {
					items[i]['children'] = subMenuItems;
				}
				i++;
			}
		}
		return items;
	}
	/**
	 * Get children menu items
	 * @param parentId Id of menu item for which we are looking children.
	 * @returns Array of children menu items
	 */
	public getChildren(menus: any, parentId: any = null, pageSett: any): any {
		let arr: any = (menus).filter((obj: any) => {
			if (this.checkMenu(obj, 'footer', pageSett)) {
				if (parentId) {
					return obj.parent_id == parentId;
				} else {
					return !obj.parent_id;
				}
			}
			return null;

		});
		return arr;
	}
	/**
	 * Check menu settings
	 * @param menu menu object
	 * @returns boolean
	 */
	public checkMenu(menu: any, type: string, pageSett: any) {
		let layoutType = type == 'footer' ? (pageSett.footerType) : pageSett.headerType;
		if (menu) {
			// Disabled menu
			if (layoutType && layoutType == 'specific' && menu.hasOwnProperty('disable_on')) {
				if ((menu.disable_on).includes(pageSett.id)) {
					return false;
				}
			}
			// Menu checker
			return this.menuChecker(menu, pageSett.id);
		}
		return false;
	}
	/**
	 * Get the cookies and referrer url
	 * @returns object
	 */
	public getCookieAndRefUrl(): any {
		let obj: any = {
			cookiesInfo: {
				ses_id: null,
				campaign_id: null,
				contact_id: null,
				track_from: null,
				sequence_id: null
			},
			referrerSource: null
		}
		// Track booking from campaign
		obj.cookiesInfo.ses_id = this.cookieServ.getCookie('ses_id');
		obj.cookiesInfo.campaign_id = this.cookieServ.getCookie('campaign_id');
		obj.cookiesInfo.contact_id = this.cookieServ.getCookie('contact_id');
		obj.cookiesInfo.track_from = this.cookieServ.getCookie('track_from');
		obj.cookiesInfo.sequence_id = this.cookieServ.getCookie('sequence_id');
		// Referrer source from refferer url
		let refURL: any = this.appLocalStorage('referrer_url', true);
		if (!refURL && !this.widgetFormData) {
			let parentUrl = document.referrer;
			if (parentUrl) {
				// Store the referrer url
				try {
					localStorage.setItem("referrer_url", parentUrl);
				} catch (err) { /* empty */ }
			}
			refURL = parentUrl;
		}
		if (refURL) {
			let refSplitUrl: any = refURL.split("/");
			if (refSplitUrl[2]) {
				let arr2 = refSplitUrl[2].split(".");
				obj.referrerSource = arr2[0];
			}
		}
		return obj;
	}
	/**
	 * List of hours
	 * @returns array
	 */
	public listHours(count: number = 24): any {
		let hours: any = []
		for (let i = 1; i <= count; i++) {
			if (i < 10) {
				(hours).push('0' + i);
			} else {
				(hours).push(i);
			}
		}
		return hours;
	}
	/**
	 * List of minutes
	 * @returns array
	 */
	public listMinutes(): any {
		let minutes: any = []
		for (let i = 0; i < 60; i++) {
			if (i < 10) {
				(minutes).push('0' + i);
			} else {
				(minutes).push(i);
			}
		}
		return minutes;
	}
	/**
	 * Only numbers are allowed in input
	 * @param event: input event
	 */
	public onlyNumbers(event: any, isNegative: boolean = false): void {
		let pattern: any = /[0-9.]/;
		if(isNegative){
			pattern = /[0-9.-]/;
		}
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}
	/**
	 * Check the amount values is there, else return 0
	 * @param amount: amount
	 * @returns
	 */
	public calValidAmount(amount: any) {
		if (amount) {
			return amount;
		} else {
			return 0;
		}
	}
	/**
	 * Check the payment method visibility
	 * @param type: cash/check/card
	 * @returns boolean
	 */
	public visiblePaymentMethod(type: string): boolean {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.payment_method && (this.admnStngs.merchant_settings.store.payment_method).length > 0 && (this.admnStngs.merchant_settings.store.payment_method).includes(type)) {
			return true;
		}
		return false;
	}
	/**
	 * Popup position
	 * @param varId: var id
	 * @param pageSett: section settings
	 * @param dialogRef: popup ref
	 */
	public popupPosition(varId: any, pageSett: any, dialogRef: any): void {
		if (dialogRef) {
			const overlayEl = dialogRef['_overlayRef'].overlayElement;
			if (pageSett && pageSett.is_extendable) {
				overlayEl.parentElement.className += ' bk_blank_popup_V1';
			} else {
				overlayEl.parentElement.className += ' ' + varId;
			}
			if (pageSett && pageSett.position) {
				overlayEl.parentElement.className += ' ' + this.popupPositionClass(pageSett.position);
			}
			// This customer-interior check is added for 3-level popup of cancel popup because when 3rd level popup back-drop is clicked then 2nd level popup is closed then 3rd level popup is closed. Therefore to solve this problem additional check is added.
			if (overlayEl && overlayEl.parentElement && overlayEl.parentElement.previousElementSibling && pageSett?.category !== 'customer_interior') {
				overlayEl.parentElement.insertBefore(overlayEl.parentElement.previousElementSibling, overlayEl);
			}
		}
	}
	/**
	 * Get the popup position class
	 * @param position: popup settings
	 * @returns
	 */
	private popupPositionClass(position: any): any {
		switch (position) {
			case "top_left":
				return 'tjs-popup--top_left';
			case "top_center":
				return 'tjs-popup--top_center';
			case "top_right":
				return 'tjs-popup--top_right';
			case "center":
				return 'tjs-popup--center_center';
			case "bottom_left":
				return 'tjs-popup--bottom_left';
			case "bottom_center":
				return 'tjs-popup--bottom_center';
			case "bottom_right":
				return 'tjs-popup--bottom_right';
			case "left":
				return 'tjs-popup--left';
			case "right":
				return 'tjs-popup--right';
			default:
				// code...
				break;
		}
	}
	/**
	* Generate the redirection url according to app running status.
	* If app is running on local system then generate the url with localhost,
	* Otherwise generate the url with current url.
	*/
	public generateLink(url: any) {
		if (IS_DEV) {
			return window.location.protocol + "//" + DEV_HOST + url;
		} else {
			return window.location.protocol + "//" + window.location.hostname + url;
		}
	}
	/**
	 * Get the after success redirection url
	 * Used in gift card and lead form
	 * @param redirectUrl: url
	 * @param loaderId: loader id
	 * @returns url
	 */
	public getAfterSuccessRedirection(redirectUrl: any, loaderId: string = 'main'): any {
		let gotoLinkUrl: any = null
		let slug = redirectUrl.replace(/\//g, "");
		switch (redirectUrl) {
			case "/booknow":
			case "booknow":
				if (this.multiIndusStatus) {
					gotoLinkUrl = null;
					this.loader.hide(loaderId);
					this.popupServ.industriesPopup();
				} else {
					gotoLinkUrl = '/' + this.initServ.appDynamicRoutes['booknow'];
				}
				break;
			case "/gift-cards/send":
			case "/gift-cards":
			case "gift-card":
				let gcUrl;
				if (this.currentUser && !this.initServ.theme) {
					gcUrl = '/gift-cards';
				} else {
					gcUrl = '/' + this.initServ.appDynamicRoutes['gift-card'];
				}
				gotoLinkUrl = gcUrl;
				break;
			case "/referrals":
			case "referrals":
				let refUrl = '/';
				if (!this.initServ.theme) {
					refUrl = '/' + this.initServ.appDynamicRoutes['referrals'];
				}
				gotoLinkUrl = refUrl;
				break;
			default:
				let url = (slug.charAt(0) == '/') ? (slug.substr(1)) : slug;
				const gotoUrl = (url.charAt(0) !== '/') ? ('/' + url) : url;
				gotoLinkUrl = gotoUrl;
				break;
		}
		return gotoLinkUrl;
	}

	/**
	 * Scroll to specific element
	 * @param elementId
	 */
	public scrollToSpecificEle(elementId: string) {
		let headerHeight = 0;
		let headerElRef: any;
		try {
			headerElRef = document.querySelector('header');
		} catch (err) { /* empty */ }
		if (headerElRef) {
			let headerElRec = headerElRef.getBoundingClientRect();
			if (headerElRec) {
				headerHeight = headerElRec.height;
			}
		}
		let multiStepHeaderHeight = 0;
		let multiStepHeaderElRef: any;
		try {
			multiStepHeaderElRef = document.querySelector('#multistep-header-id');
		} catch (err) { /* empty */ }
		if (multiStepHeaderElRef) {
			let multiStepHeaderElRec = multiStepHeaderElRef.getBoundingClientRect();
			if (multiStepHeaderElRec) {
				multiStepHeaderHeight = multiStepHeaderElRec.height;
			}
		}
		if (headerHeight) {
			multiStepHeaderHeight = multiStepHeaderHeight + headerHeight;
		}
		let el: any = document.getElementById(elementId);
		if (elementId && el) {
			let elRec = el.getBoundingClientRect();
			if (this.inIframe(this.embedStatus)) {
				el.scrollIntoView();
				if (typeof (scrlToElement) !== 'undefined') { scrlToElement(elRec.top) }
			} else {
				let top = (elRec.top + window.scrollY) - (multiStepHeaderHeight ? multiStepHeaderHeight : headerHeight);
				window.scrollTo({ top: top, behavior: 'smooth' });
			}
		}
	}

	/**
	 * Build the summary for side bar and multi step header
	 * @param summaryId: summary id
	 * @param elements: elements
	 * @returns
	 */
	public summaryBuild(summaryId: any, elements: any, secSett: any, pageSett: any): any {
		if (elements && (Object.keys(elements)).length > 0 && secSett) {
			let obj: any = {
				id: summaryId
			}
			for (let key in elements) {
				if (elements[key]) {
					let keyId = key + '_id';
					obj[keyId] = elements[key];
					if (key == 'parameters' || key == 'short_summary_value') {
						obj[key] = this.secServ.checkEleStatus(pageSett, elements[key]);
					} else {
						if (this.secServ.checkEleStatus(pageSett, elements[key])) {
							if (this.secServ.getContent(elements[key])) {
								obj[key] = this.secServ.getContent(elements[key]);
							} else {
								let replacedText = elements[key].replace(summaryId + '_', '');
								obj[key] = SUMMARY_LABELS[replacedText];
							}
						} else {
							obj[key] = false;
						}
						if (key == 'extras' || key == 'excludes') {
							obj[key + '_design_id'] = this.getDesignId(elements[key], pageSett);
						}
					}
				}
			}
			return obj;
		}
		return null
	}
	public getDesignId(id: string, pageSett: any): string | null {
		if (id && this.pageSett && pageSett[id] && pageSett[id].design_id) {
			return pageSett[id].design_id;
		}
		return '';
	}
	/**
	 * Load the stripe/square/paypal gateway script
	 */
	public loadPaymentGatewayScript(type: string): void {
		// In case of paypal load more 2 cdn for threeDSecure
		if (type == 'paypal') {
			this.loadPaymentGatewayScript('paypalThreeDSecure');
			this.loadPaymentGatewayScript('paypalHostedFields');
			this.loadPaymentGatewayScript('paypalDeviceData');
		}
		let link: any = env[type];
		if (!window.document.getElementById(type + '-script') && link) {
			const scr: any = window.document.createElement("script");
			scr.id = type + "-script";
			scr.type = "text/javascript";
			scr.src = link;
			scr.async = true;
			scr.defer = true;
			if(type == 'stripe'){
				// let head = window.document.getElementsByTagName("head")[0];
				// head.insertBefore(scr, head.firstChild);
				window.document.head.appendChild(scr);
			} else {
				window.document.body.appendChild(scr);
			}
		}
	}
	/**
	 * Load the google place api script
	 */
	public loadGoogleApiScript(): void {
		let type: any = 'googleApi';
		let link: any = env[type];
		if (!window.document.getElementById(type + '-script') && link) {
			const scr: any = window.document.createElement("script");
			scr.id = type + "-script";
			scr.type = "text/javascript";
			scr.src = link;
			scr.async = true;
			scr.defer = true;
			window.document.head.appendChild(scr);
		}
	}
	/**
	 * Load the google platform script
	 */
	public loadGooglePlatformScript(): void {
		let type: any = 'googlePlatform';
		let link: any = env[type];
		if (!window.document.getElementById(type + '-script') && link) {
			const scr: any = window.document.createElement("script");
			scr.id = type + "-script";
			scr.type = "text/javascript";
			scr.src = link;
			scr.async = true;
			scr.defer = true;
			window.document.head.appendChild(scr);
		}
	}
	/**Function to toggle modal
	 * status : true/false
	 * elId : Element ID
	 */
	public togglePopup(elId?: string, status: boolean = true): void {
		if (elId) {
			let elem: any = document.getElementById(elId);
			if (elem) {
				if (status) {
					let modalEl = new window.bootstrap.Modal(elem);
					if (modalEl) {
						modalEl.show();
					}
					this.showPopup(elId);
				} else {
					let modalEl = window.bootstrap.Modal.getInstance(elem);
					if (modalEl) {
						modalEl.hide();
					}
				}
			}
		}
	}

	/**Function to toggle modal
	 * status : true/false
	 * elId : Element ID
	 */
	public toggleCanvas(elId?: string, status: boolean = true): void {
		if (elId) {
			const elem: any = document.getElementById(elId);
			if (elem) {
				if (status) {
					const canvasEl: any = new window.bootstrap.Offcanvas(elem);
					if (canvasEl) {
						canvasEl.show();
					}
					this.showCanvas(elId);
				} else {
					const canvasEl: any = window.bootstrap.Offcanvas.getInstance(elem);
					if (canvasEl) {
						canvasEl.hide();
					}
				}
			}
		}
	}

	public showCanvas(eleId: any) {
		const el: any = document.getElementById(eleId);
		if (el) {
			document.body.append(el);
			if (this.embedStatus) {
				let canvasContent: any;
				try {
					canvasContent = el.querySelector('.modal-dialog');
				} catch (err) { /* empty */ }
				if (canvasContent) {
					canvasContent.classList.add('tjs-embed-center');
					el.addEventListener('hidden.bs.modal', this.removeEmbedClass)
				}
			}
		}
	}


	/**Function to trigger event
	 * element
	 * event : change/click
	*/
	public triggerEvnt(element?: any, event?: any): any {
		if (element) {
			let evt: any = document.createEvent("HTMLEvents");
			evt.initEvent(event, true, true); // event type,bubbling,cancelable
			return !element.dispatchEvent(evt);
		}
	}

	/**Function to perform slider autoplay */
	public autoPlay(slider: any = null, duration: any = 3000): void {
		if (slider) {
			let timeout: any;
			// eslint-disable-next-line no-inner-declarations
			function nextTimeout() {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					slider.next()
				}, duration)
			}
			slider.on("created", nextTimeout)
			slider.on("animationEnded", nextTimeout)
			slider.on("updated", nextTimeout)
			slider.on("slideChanged", () => this.adaptiveHeight(slider))
		}
	}

	public adaptiveHeight(slider: any = null): void {
		if (slider && slider.slides && slider.track && slider.track.details && slider.slides[slider.track.details.rel]) {
			let el = slider.slides[slider.track.details.rel].children;
			if (el && el.length > 0) {
				slider.container.style.height = el[0].offsetHeight + 10 + "px"
			} else {
				slider.container.style.height = slider.slides[slider.track.details.rel].offsetHeight + "px"
			}
		}
	}

	/**Function to parse URL and get url without fragment*/
	public getUrlWithoutFragment(url: string): string {
		if (url && url !== '') {
			return url.split(location.hash || /[#]/)[0];
		}
		return '';
	}

	public showPopup(eleId: any) {
		let el: any = document.getElementById(eleId);
		if (el) {
			document.body.append(el);
			if (this.embedStatus) {
				let modalContent: any;
				try {
					modalContent = el.querySelector('.modal-dialog');
				} catch (err) { /* empty */ }
				if (modalContent) {
					modalContent.classList.add('tjs-embed-center');
					el.addEventListener('hidden.bs.modal', this.removeEmbedClass)
				}
			}
		}
	}

	public removeEmbedClass(event: any) {
		let modalContent: any;
		try {
			modalContent = event.target.querySelector('.modal-dialog');
		} catch (err) { /* empty */ }
		if (modalContent) {
			modalContent.classList.remove('tjs-embed-center');
		}
		event.target.removeEventListener('hidden.bs.modal', this.removeEmbedClass)
	}

	/* function to load google analytic if it is not loaded yet */
	public loadGtag() {
		// console.log('initServ',this.initServ)
		if (this.initServ._siteData && this.initServ._siteData.theme_settings && this.initServ._siteData.theme_settings.settings) {
			let themSettings = this.initServ._siteData.theme_settings.settings
			if (themSettings.google_analytics_tracking_id && themSettings.google_analytics_tracking_id != '') {
				let script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = "https://www.googletagmanager.com/gtag/js?id=" + themSettings.google_analytics_tracking_id;
				script.async = true;
				script.onload = () => {
					const dataLayerScript = document.createElement('script');
					dataLayerScript.innerHTML = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${themSettings.google_analytics_tracking_id}' , {'send_page_view': false});`;
					document.head.appendChild(dataLayerScript);
				};
				document.getElementsByTagName('head')[0].appendChild(script);
			}

			if (themSettings.google_adword_tracking_id && themSettings.google_adword_tracking_id != '') {
				let script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = "https://www.googletagmanager.com/gtag/js?id=" + themSettings.google_adword_tracking_id;
				script.async = true;
				script.onload = () => {
					const dataLayerScript = document.createElement('script');
					dataLayerScript.innerHTML = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${themSettings.google_adword_tracking_id}' , {'send_page_view': false});`;
					document.head.appendChild(dataLayerScript);
				};
				document.getElementsByTagName('head')[0].appendChild(script);
			}
		}
	}
	/**
	 * Return the booking and gift card invoice status
	 * Depend on admin store option
	 */
	public getInvoiceStatus(): any {
		let invStatus = true;
		let giftCardStatus = true;
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.customers && this.admnStngs.merchant_settings.customers.offer_invoice) {
			if ((this.admnStngs.merchant_settings.customers.offer_invoice).length > 0) {
				if (!(this.admnStngs.merchant_settings.customers.offer_invoice).includes('booking') && !(this.admnStngs.merchant_settings.customers.offer_invoice).includes('invoice')) {
					invStatus = false;
				}
				if (!(this.admnStngs.merchant_settings.customers.offer_invoice).includes('gift_card')) {
					giftCardStatus = false;
				}
			} else {
				invStatus = false;
				giftCardStatus = false;
			}
		}
		return {
			invStatus: invStatus,
			giftCardStatus: giftCardStatus
		}
	}
	public checkIsBkngStarted(bkngDate: any, bkngTime: any): boolean {
		let date: any = new Date();
		let dateStr: string = this.getLocalDateYMD(date);
		let hours: any = date.getHours();
		let min: any = date.getMinutes();
		let meridian: string = hours < 12 ? 'AM' : 'PM';
		let time: any = this.calculateTime(hours, min, meridian);
		if (dateStr == bkngDate && time > bkngTime) {
			return true;
		}
		return false;
	}
	// private getDateYMD(dateObj: any): any {
	// 	let tempDate = dateObj.getUTCFullYear() + '-' + ('0' + (dateObj.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getUTCDate()).slice(-2);
	// 	return tempDate;
	// }
	private getLocalDateYMD(dateObj: any): any {
		let tempDate = dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2);
		return tempDate;
	}
	/**
	 * Function for calculate time.
	 */
	private calculateTime(hours: any, minutes: any, meridiem: string) {
		let time: any;
		let hour: any = hours;
		let minute: any = +minutes;
		if (hour < 10) { hour = +hour; }
		if (meridiem == "AM") {
			if (hour == 12) { hour = '0'; }
		}
		time = hour + (('0' + (minute.toString())).slice(-2));
		return +time;
	}

	/**
	 * Function to convert Time to 24 hour Format.
	 */
	public convertTime24HFormat(time: any, format: string = 'HH:mm') {
		if (this.timeFormat === '24' && time) {
			let momentTime: any = dayjs(`${dayjs().format('MM/DD/YYYY')} ${time}`);
			if (momentTime.isValid()) {
				time = momentTime.format(format)
			}
		}
		return time;
	}
	public isValExist(val: any): boolean {
		return (val != undefined) ? true : false;
	}
	/**
	 * Check if page url was updated and page accessing on different url then redirect to the actual url of page
	 * @param pageData Data pf page
	 */
	public redirectToUrlIfNotSame(pageData: any): void {
		let existingPageSlug: Array<string> = ['home', 'gift-card', 'referrals', 'login', 'signup', 'forgot-password', 'booknow', 'contact-us', 'reset-password', 'dashboard', 'gift-cards', 'my-drive', 'info-and-billing', 'edit-personal-details', 'notifications'];
		let currUrl: string = (this.router.url.split('?')[0]).slice(1);
		if (!existingPageSlug.includes(pageData?.slug) && currUrl && pageData?.settings?.url) {
			let pageUrl: any = pageData?.settings?.url;
			if (pageUrl != currUrl) {
				this.router.navigate([pageUrl]);
			}
		}
	}
	public getImgHeight(sett: any, id: string): any {
		let img = document.createElement('img');
		img.id = 'img_' + id;
		img.src = this.initServ.imgBase + sett[id].urls[0];
		document.body.appendChild(img);
		let height: any = JSON.parse(JSON.stringify(img.naturalHeight));
		setTimeout(() => {
			img.remove();
		}, 100);
		return height;
	}
	/**
	 * Decrypt the value using AES algorithm
	 * with the specified key and initialization vector.
	 * If the decrypted value is not valid, the function will return the passed value.
	 * @param {any} val - encrypted string value, it should not already contain the '-org' substring to decrypt the value
	 * @returns {string} decrypted string value after performing AES decryption operation
	 */
	public async aesDecryption(val: any) {
		// Check if encrypted string value is valid and already not contains -org
		if(val && !val.endsWith("-org")){
			// Combination of `env.arjun+env.bhishma+env.chanakya+env.drona` build encryptionKey & `env.astonmartin+env.bentley` build encryptionIv
			return await decryptCBC(val, env.arjun+env.bhishma+env.chanakya+env.drona, env.astonmartin+env.bentley);
		}
		// Check if the string ends with the suffix "-org". If it does, it returns a substring of the input value that excludes the last 4 characters. Otherwise, it returns the value unchanged.
		return (val && val.endsWith("-org")) ? val.slice(0, -4) : val;
	}
	/**
	 * Check array length
	 * @param arr Array
	 * @returns Boolean (true/false)
	 */
	public checkArrLength(arr: Array<any> | undefined): boolean {
		return (arr && arr.length > 0) ? true : false;
	}


	/**
	 * Method to build the popup sections with the help of section object & popup data passed in it.
	 * @param popupData popup data
	 * @param section section data like title, desc, media, list & button
	 * @param dialogRef popup reference
	 * @returns section object
	 */
	public buildPopupSection(popupData: any, section: any, dialogRef: any): any {
		let pageSett: any = popupData?.section_settings;
		this.popupPosition(pageSett[popupData?.added_sections?.[0]]?.variation_id, popupData?.settings, dialogRef);
		this.secServ.setServData(pageSett, popupData?.content);
		return this.secServ.buildSectionFields(popupData?.added_sections?.[0], section, popupData);
	}
	/**
	 * Method "changeToResObj" takes in a parameter called "popupData" and returns an converted obj into response object.
	 * @param {any} popupData - data associatd with popup : style and content
	 * @returns response obj
	 */
	public changeToResObj(popupData: any): any {
		return { 'data': popupData, 'api_status': 1 };
	}
	/**
	 * split underscore
	 * @param value: value of string to split
	 * @returns array of strings
	 */
	public splitUnderscore(value :any){
		let valStr = value.toString();
		let res = valStr.split("_");
		return res;
	}
	/**
	* Change the browser url to desired url
	*/
	public redirect(link: any) {
		try {
			top.window.location.href = link;
		} catch (err) {
			// this.bookingCnfrmPopup(data);
		}
	}

	/**Function to filter booking users */
	public filterUsers(bkngUsers: any): any {
		const allUsers: any = {};
		bkngUsers.map((user: any) => (allUsers[user.id] = { "name": `${user?.first_name} ${user.last_name}`, "photo_url": user.photo_url, "role": user.role }));
		return allUsers;
	}

	/**Function to get ejabberd recipients */
	public getRecipients(users: any, type?: string): any {
		const recipients = [];
		for (const userId in users) {
			if (+userId !== this.currentUser?.id) {
				if (type === 'note') {
					const user = users[userId];
					if (user?.role !== 'provider') {
						const userName = `${this.admnStngs?.merchant_id}_${userId}@${this.APIURL['ejabDomain']}`;
						recipients.push(userName);
					}
				} else {
					const userName = `${this.admnStngs?.merchant_id}_${userId}@${this.APIURL['ejabDomain']}`;
					recipients.push(userName);
				}
			}
		}
		return recipients;
	}

	/**Function to check value is empty */
	public isEmpty = (value: any): boolean => {
		if (!value && value !== 0 && typeof value !== 'boolean') return true;
		if (Array.isArray(value)) {
			if (!value.length) return true
			return value.every(this.isEmpty)
		}
		if (typeof value === 'object') {
			return Object.values(value)?.every(this.isEmpty)
		}
		return false
	}

	/**Function to get object copy */
	public getObjCopy(obj: any): object {
		if (obj) {
			return JSON.parse(JSON.stringify(obj));
		}
		return obj;
	}
	/**
	 * Function to get file name from url
	 * @param url
	 * @returns
	 */
	public getFileName(url: any) {
		if (url) {
			url = url.substring(1 + url.lastIndexOf('/'));
			url = url.split('?')[0];
			url = url.split('#')[0];
			if (url?.includes('_')) {
				// Remove timestamp and get file name
				const fileName = url.split('_');
				if (fileName?.length > 0) {
					return url.replace(`${fileName[0]}_`, "");
				}
			}
		}
		// Now we have only the file name
		return url;
	}

	/**
	 * To build tags file
	 * @param urls
	 */
	public buildFileTags(urls: any[], tags:any): any{
		let tagIds: any[] = [];
		if (!this.isEmpty(urls)) {
			for (let url of urls) {
				if (!this.isEmpty(url?.tag_ids)) {
					for (let tagId of url.tag_ids) {
						let isIncludedInCKTags:boolean = !this.isEmpty(tags) && Object.prototype.hasOwnProperty.call(tags, tagId);
						if (!tagIds.includes(tagId) && isIncludedInCKTags) {
							tagIds.push(tagId);
						}
					}
				}
			}
		}
		return tagIds;
	}
	/**
	 * Function to download media files
	 * @param file
	 */
	public downloadMedia(file: any): void {
		let fileName = file?.url.substring(file?.url.lastIndexOf("/") + 1);
		let fileURL = this.initServ.fileCdn + file?.url;
		this.downloadFiles(fileURL, fileName, true, true);
	}
	/**
	 * Dowload the file/media
	 */
	public downloadFiles(url: any, name = '', isURL: boolean = false, isMedia: boolean = false) {
		let downloadName = url;
		const fileUrl = isURL ? url : (this.initServ.fileCdn + url);
		if (name) {
			downloadName = name;
		}
		this.downloadFileUrl(fileUrl).pipe(takeUntil(this.destroy)).subscribe((res: any) => { saveAs(res, downloadName) }, (err: any) => console.log(err), () => {
			if (isMedia) {
				this.toastr.success(this.initServ.appStr.toastr.mediaDownload);
			} else {
				this.toastr.success(this.initServ.appStr.toastr.fileDownload);
			}
		});
	}
	/**
	 * Download file
	 * @param url download url
	 * @returns
	 */
	public downloadFileUrl(url: string): any {
		let header = new HttpHeaders({ 'auth': 'false', 'Content-Type': 'application/json' });
		return this.http.get(url, { headers: header, responseType: "blob" }).pipe(map((res: any) => { return new Blob([(res)]) }));
	}
	/**
	 * Function to merge checklist files and job pictures files
	 * @param data
	 */
	public mergeJobMedia(data: any): void {
		const files: any = [];
		if (data?.job_pictures?.length > 0) {
			for (const jobPicture of data.job_pictures) {
				if (!this.isEmpty(jobPicture)) {
					let url: any = jobPicture.url;
					if (jobPicture.photo_url) {
						url = jobPicture.photo_url;
						jobPicture.type = "image";
					}
					jobPicture.url = url;
					jobPicture.name = this.getFileName(jobPicture.url);
					files.push(jobPicture);
				}
			}
		}
		let urls: any = (data?.urls?.length > 0) ? data?.urls : data?.ck_media;
		if (urls?.length > 0) {
			for (const media of urls) {
				if (!this.isEmpty(media)) {
					media.name = this.getFileName(media.url);
					files.push(media);
				}
			}
		}
		return files;
	}
	/**
	 * Build the media based on tags, use this function on checklist
	 * @param files: media files
	 * @param ckData: checklist data
	 * @returns media data
	 */
	public buildMediaBasedOnTags(files:any, ckData: any): any {
		if (files?.length > 0 && ckData?.cust_file_tags_type != 'all') {
			let urls: any = files.filter((url:any) => {
				const tagIds = url.tag_ids?.filter((tagId:any) => (ckData?.file_tags?.includes(tagId)));
				url.tag_ids = tagIds;
				return tagIds?.length > 0;
			});
			return urls;
		}
		return files;
	}
	/**
	 * Can customer see the checklist filter
	 * @param ckData: checklist data
	 * @returns array
	 */
	public custSeeChecklist(ckData: any, oldchecklistId: any = null): any {
		let allCk: any = ckData?.filter((ck: any) => (ck?.can_cust_see === 'yes' && (ck?.when_cust_see === 'all_time' || (ck?.when_cust_see === 'after_completed' && (ck?.completion_status === 1 || (ck?.migrated_from && ck?.migrated_from == oldchecklistId))))));
		return allCk;
	}
	/**
	 * Get days between two dates
	 * @param {*} startDate // unix timestamp
	 * @param {*} endDate // unix timestamp
	 */
	public getDaysDiff(startDate: number, endDate: number): any {
		const _startDate = dayjs(startDate * 1000);
		const _endDate = dayjs(endDate * 1000);
		const days = _endDate.diff(_startDate, 'day', true);
		return Math.floor(days);
	}
	/**
	 * Feature trial
	 * Api call only onces, if trialFeatures value not exist
	 */
	public getFeatureTrial(): void {
		this.initServ.trialFeatures.pipe(takeUntil(this.destroy)).subscribe((value: any) => {
			if (!value) {
				this.apiServ.callApi("GET", "FeaturesTrial").pipe(takeUntil(this.destroy)).subscribe((res: any) => this.checkFeaturesTrial(res));
			}
		});
	}
	/**
	 * Check the feature trial check
	 */
	private checkFeaturesTrial(res: any): void {
		if (this.apiServ.checkAPIRes(res) && res.data && (res.data).length > 0) {
			let trialFeatures: any = null;
			for (let feature of res.data) {
				if (!trialFeatures) {
					trialFeatures = {};
				}
				trialFeatures[feature?.name] = {
					...feature,
					days: this.getDaysDiff(dayjs().unix(), feature?.ended_on)
				}
			}
			this.initServ.trialFeatures.next(trialFeatures);
		}
	}
	/**
	 * Function for is note available.
	 */
	public isNoteAvailable(note: any): boolean {
		if (note) {
			if ((note != null) && (note != '')) {
				let demoString = note.replace(/<\/?[^>]+(>|$)/g, "");
				demoString = demoString.replace('&nbsp;', "").replace(';', "");
				demoString = demoString.trim();
				if (demoString.length > 0) {
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Unique app locations
	 * Stripe ids
	 * Stripe base ids
	 * Location name base on id
	 */
	public uniqueLocations(): Promise<any> {
		const locObj:any = {
			locations: [],
			stripeIds: [],
			stripeBaseId: [],
			squareIds : [],
			locationsNames: []
		};
		return new Promise<any>((resolve) => {
			if (this.initServ.appData?.locations && (this.initServ.appData.locations).length > 0) {
				for (let loc of this.initServ.appData.locations) {
					let status: boolean = false;
					if (locObj.locations.length > 0) {
						status = locObj.locations.some((baseLoc: any) => loc.location_id == baseLoc.location_id);
					}
					if (!status) {
						(locObj.locations).push(loc);
						// For stripe
						if (this.initServ.paymentGateway == 'stripe') {
							if (loc?.live_stripe_publish_key && !(locObj.stripeIds).includes(loc.live_stripe_publish_key)) {
								locObj.stripeIds.push(loc.live_stripe_publish_key);
							}
							locObj.stripeBaseId.push(loc.location_id);
						}
						// For square
						else if (this.initServ.paymentGateway == 'square' && loc.gateway_keys && loc.gateway_keys.square_location_id) {
							if (!(locObj.squareIds).includes(loc.gateway_keys.square_location_id)) {
								locObj.squareIds.push(loc.gateway_keys.square_location_id);
							}
						}
					}
					locObj.locationsNames[loc.location_id] = loc.location.location_name;
				}
			}
			resolve(locObj);
		});
	}
	/**
	 * Function to check if member is team lead.
	 */
	public checkIfTeamLead(member: any, provider: any): boolean{
		if(provider && member){
			let teamId = +provider.id;
			let teamMember = this.isValExist(member.team.team_info)  ? member.team.team_info : null;
			if(teamId && teamMember && teamMember[teamId] && teamMember[teamId].team_lead){
				return true;
			}
		}
		return false;
	}
	public objHasProp(obj: any, val: string): boolean {
		return Object.prototype.hasOwnProperty.call(obj, val);
	}
	/**
	 * Function to add prefix zero before number.
	 */
	public addPrefixZeroBeforeNum(val: any): string {
		if (val >= 10) {
			return val.toString();
		} else {
			return ('0' + val).toString();
		}
	}
	/**
	 * Function to get time in 24 format.
	 */
	public getTimeIn24HFormat(hours: any, meridian: any) {
		let h: any;
		if (meridian == 'AM') {
			h = (+hours) * 100;
		} else {
			h = this.calHours(hours);
		}
		h = Math.round(h);
		if (h == 2400) {
			h = 1200;
		} else if (h == 1200) {
			h = 0;
		}
		return h;
	}
	private calHours(hours: any){
		if (+hours > 12) {
			return ((+hours)) * 100;
		} else {
			return Math.round(((+hours) + 12) * 100);
		}
	}
	/**
	 * Function to get time string from 24 hours format.
	 */
	public getTimeStringFrom24HFormat(time: any, fieldName: any): any {
		if(time > 0){
			time = time / 100;
			return this.calcTime(time, fieldName);
		}else{
			return this.returnMeridianOrTime(fieldName, '12', 'AM');
		}
	}
	/**
	 *
	 * @param time
	 * @param fieldName
	 * @returns
	 */
	private calcTime(time: any, fieldName: any): any {
		if(time > 12){
			return this.returnMeridianOrTime(fieldName, time-12, 'PM');
		} else {
			if (time == 12) {
				return this.returnMeridianOrTime(fieldName, 12, 'PM');
			} else {
				return this.returnMeridianOrTime(fieldName, time, 'AM');
			}
		}
	}
	/**
	 *
	 * @param fieldName
	 * @param time
	 * @param meridian
	 * @returns
	 */
	private returnMeridianOrTime(fieldName: any, time: any, meridian: string): any {
		if(fieldName == 'meridian'){
			return meridian;
		}else{
			if(+time == 0){ time = 12 }
			return this.addPrefixZeroBeforeNum(time);
		}
	}
	/**
	 * Get the address long name
	 * @param code: address
	 * @returns string
	 */
	public getLongAddr(code: any, fieldName: string = 'long_name'): any {
		let name: any = '';
		if(code && code?.[fieldName]){
			name = code[fieldName];
		}
		return name;
	}

	/**
	 * Navigate to the 'invoice' page with query parameter 'token' set to this.invId
	 * @param type invoice url type
	 * @param id invoice id
	 * @param isPrint
	 */
	public navigateToInvPage(type: string, id?: string | null, isPrint: boolean = false): any{
		let queryParam: any = type == 'short_url' ? {} : {token: id};
		if(isPrint){
			queryParam['is_print'] = true;
		}
		let navLink: string = type == 'short_url' ? `/invoice/${id}` : '/invoice/';
		this.router.navigate([navLink], { queryParams: queryParam });
	}

	/**
	 * Print
	 */
	public printDiv(): void {
		let printElem = document.getElementById('invoice-block');
		let existingStyleSheet : any = document.getElementById('theme-css');
		let fontAwesomeStyleSheet : any = document.getElementById('font-awesome');
		if(printElem && existingStyleSheet){
			let printContents = printElem?.innerHTML;
			let winPrint: any = window.open('','','');
			winPrint.document.open();
			winPrint.document.write('<html><head><link rel="stylesheet" href='+fontAwesomeStyleSheet?.href+' media="print" onload="all"><link rel="stylesheet" type="text/css" href='+existingStyleSheet?.href+'></head><body>');
			winPrint.document.write(printContents);
			winPrint.document.write('</body></html>');
			winPrint.focus();
			setTimeout(() => {
				winPrint.print();
				winPrint.close();
			}, 100);
		}
	}

	/**
	 * Method updates the list of items and determines whether to show a "Load More" button based on the limit.
	 * @param {any} items - response data from the api you want to update or add to the existing list.
	 * @param {any[]} prevItems - an array that contains the previously loaded items.
	 * @param {number} [limit=10] - limit to the number of items to be loaded if items exceeds the limit then show a "Load More else not
	 * @returns `showLoadMore` flag and `items` ie. updated list of items in the form of object.
	 */
	public updateLoadMoreItems(items: any, prevItems: any[], limit: number = 10): any {
		let showLoadMore: boolean = false;
		let previousItems: any[] = (prevItems?.length > 0) ? prevItems : [];

		if (items?.length > 0) {
			// items arr is exceeding the limit then update showLoadMore flag to true and remove the last item.
			showLoadMore = items?.length > limit;
			if (showLoadMore) {
				items?.splice(-1);
			}
			return { showLoadMore, 'items': [...previousItems, ...items] };
		}
		return { showLoadMore, 'items': [...previousItems] };
	}
	public getDefaultCardId(cards: BillingCard[]): BillingCard {
		let billingCard: BillingCard = {card_id: '', card_last4_digits: ''};
		if (this.checkArrLength(cards)) {
			let defaultCard: any = cards.find((card: any) => card?.is_default);
			if (defaultCard) {
				billingCard.card_id =  defaultCard.id;
				billingCard.card_last4_digits = defaultCard?.last4;
			} else {
				billingCard.card_id =  cards[0]?.id ?? '';
				billingCard.card_last4_digits = cards[0]?.last4 ?? '';
			}
		}
		return billingCard;
	}

	public loadAddrAndPaymentScript(): void {
		// Load the google api script
		this.loadGoogleApiScript();
		// Load the payment gateway script
		if(this.initServ.paymentGateway){
			this.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
	}

	/**
	 * Get the customer add card booking options
	 */
	public addCardBkngOptions(): object {
		let option = this.admnStngs?.merchant_settings?.customers?.card_apply_to;
		let sett: {bkng:object | null, options:{is_upcoming_bookings: boolean, is_bookings_with_cash: boolean, is_give_options: boolean}} = {
			bkng: null,
			options: {
				is_upcoming_bookings: false,
				is_bookings_with_cash: false,
				is_give_options: false
			}
		}
		switch (option) {
			case "options":
				sett.options.is_give_options = true;
				break;
			case "booking_incl_cash":
				sett.bkng = {apply_to: 'upcoming_with_pending', booking_with_cash: 'yes' };
				break;
			case "booking_exc_cash":
				sett.bkng = {apply_to: 'upcoming_with_pending', booking_with_cash: 'no' };
				break;
			default:
				sett.bkng = {apply_to: 'upcoming_with_pending', booking_with_cash: 'yes' };
				break;
		}
		return sett;
	}
	/**
	 * Set the embed form height
	 * @param timeOut: time out time
	 */
	public setHeight(timeOut: number, id: string): void{
		setTimeout(() => {
			this.autoResize(id);
		}, timeOut);
	}
	/**
	 * Auto resize
	 */
	// eslint-disable-next-line complexity
	public autoResize(id: string): void {
		let iframe = document.getElementById(id) as HTMLIFrameElement;
		if (!iframe?.contentWindow?.document?.body) {
			return;
		}
		let iframeHeight = iframe?.contentWindow?.document.body.scrollHeight;
		if(iframeHeight){
			iframe.height = `${iframeHeight}px`;
		}
		if(iframe.contentWindow.document.documentElement.scrollHeight > 0){
			iframeHeight = iframe.contentWindow.document.documentElement.scrollHeight;
			if(iframeHeight){
				iframe.height = `${iframeHeight}px`;
			}
		}
	}

	public convertTimeIn24HoursFormat(time: any): string{
		let hours = Math.floor(time / 100);
		let minutes = time % 100;
		return (hours ? this.addPrefixZeroBeforeNum(hours) : '00')+':'+(minutes ? this.addPrefixZeroBeforeNum(minutes) : '00');
	}

	/**
	 * Image error load the default icon
	 * @param event: Image event
	 */
	public onImgError(event: any): void {
		event.target.src = this.initServ.imgBase + '/assets/images/icons/deepcleaning-icon.png';
	}
	/**
	 * Validate an email address using a regular expression.
	 * @param {string} email - The email address to validate.
	 * @returns {string} - The validated email address, or an empty string if invalid.
	 */
	public getValidEmail(email: string):  string {
		if(email && EMAIL_REG_EXP.test(email)){
			return email;
		}
		return '';
	}

	public areSameObj(objOne: any, objTwo: any): boolean {
		return (JSON.stringify(objOne) == JSON.stringify(objTwo)) ? true : false;
	}








	// TODO: Anupam remove this code below after 2months of live
	/**
	 * Get default element JSON object based on the provided element name.
	 * @param {string} elemName - represents the name of an element to retrieve the default JSON object.
	 * @returns the default element from the `defaultElem` object based on the `elemName`
	 */
	private getDefaultElemJson(elemName:string): { [key: string]: string | object }{
		let defaultElem:{[key:string]: { [key: string]: string | object }} = {
			address : BILLING_ADDRESS_OBJ,
			same_address : SAME_BILLING_ADDRESS_OBJ
		}
		return JSON.parse(JSON.stringify(defaultElem[elemName] ?? {}));
	}

	/**
	 * Migration of Billing Address
	 * Loads default element JSON data for the provided `elementName` and updates section data.
	 * @param {string} elemName - represents the name of an element to retrieve the default JSON object.
	 */
	public loadDefaultElemJson(elemName:string): { [key: string]: string | object } {
		let defaultElemData:{ [key: string]: string | object } = this.getDefaultElemJson(elemName);
		return defaultElemData;
	}

	/*
	 * This function is used to convert date timestamp.
	 */
	public convertToTimestamp(date: any) {
		return Date.parse(date) / 1000;
	}
	/**
	 * Function to create date object according to local timestamp.
	 */
	createDateObjLocal(datestr: any, hours: number = 0, minutes: number = 0) {
		let newdatearray = datestr.split("-");
		let newdate = new Date(newdatearray[0], (Math.floor(newdatearray[1]) - 1), Math.floor(newdatearray[2]), hours, minutes, 0)
		return newdate;
	}

	/**
	 * Validate price input fields that it should not be greater than 10,00,000.
	 * @param control
	 * @returns
	 */
	public maxPriceValidation(control: FormControl){
		return (control.value) <= PRICE_MAX_LIMIT ?  null : {maxPriceLimit: true}
	}

	/**
	 * Validates a phantom field by checking if the value is empty.
	 * If the value is empty, it returns true.
	 * Otherwise, it notifies Bugsnag about the phantom alert with the field name with domain name and returns false.
	 * @param {string} value - The value of the field to be validated.
	 * @param {string} fieldName - The name of the field being validated.
	 * @returns {boolean} - True if the value is empty, false otherwise.
	 */
	public validatePhantomField(value: string, fieldName: string): boolean {
		// Check if the value is empty
		if(value == ''){
			return true;
		}
		// Notify Bugsnag about the phantom alert with the field name
		Bugsnag.notify(new Error(`Phantom alert::${fieldName}, storeName: ${this.admnStngs?.merchant_settings?.store?.domain_name}`));
		return false;
	}

	/**
	 * Validates the user's access token by making a secure API call.
	 * If the access token is valid, it processes the response and handles it accordingly.
	 * If no access token is provided, it returns null.
	 * @param {string | null} accessToken - The access token to be validated.
	 * @returns {Promise<string | number | null>} - The result of the access token validation or null if no access token is provided.
	 */
	public validateUserAccessTokenApi(accessToken: string | null): string | number | null {
		if(accessToken){
			return this.apiServ.callApiWithPathVariables('GET', 'SecureLink', [accessToken]).pipe(takeUntil(this.destroy)).toPromise().then((res: TokebVal)=> {return this.handleValidUserAccessTokenApiResp(res)});
		}
		return null;
	}

	/**
	 * Handles the response from the user access token validation API call.
	 * Checks the validity of the response and extracts the user ID (uid) or object ID (entity_obj_id) if present.
	 * @param {TokebVal} resp - The response from the API call.
	 * @returns {string | number | null} - The extracted user ID or object ID if present, otherwise null.
	 */
	private handleValidUserAccessTokenApiResp(resp: TokebVal): string | number | null {
		if(this.apiServ.checkAPIRes(resp) && resp?.data){
			if(resp.data?.uid){
				return resp.data.uid;
			} else if(resp.data?.entity_obj_id){
				return resp.data.entity_obj_id
			}
		}
		return null;
	}

	/**
	 * Creates a new instance of FileUploader with specified options.
	 * @param allowedFileType - An array of allowed file types. Defaults to ['image'].
	 * @param maxFileSize - The maximum file size allowed for upload.
	 * @returns A new FileUploader instance configured with the provided options.
	 */
	public fileUploadOptions(allowedFileType: string[] | undefined, maxFileSize: number | undefined): FileUploader{
		return new FileUploader({
			url: `${this.APIURL.apiUrl}upload`,
			method: 'POST',
			allowedFileType: allowedFileType,
			maxFileSize : maxFileSize,
			autoUpload: false
		});
	}

	/**
	 * Generates headers for the file upload request.
	 * @returns A promise that resolves to an array of headers required for file upload.
	 */
	public async fileUploadHeaders(): Promise<FileUploadHeader[]> {
		let headers: any = await this.initServ.getSessionHeaders();
		const currUserObj = this.appLocalStorage();
		let fileUploadHeaders: FileUploadHeader[] = [
			{ name: 'Authorization', value: `Bearer ${currUserObj?.token}`},
			{ name:'Ip', value: currUserObj?.Ip },
			{ name: 'Auth-Session', value: headers?.['Auth-Session']}
		];
		if(ALLOW_OLD_VERSION){
			fileUploadHeaders.push({name: 'Period',value: headers?.['Period']});
			fileUploadHeaders.push({name: 'Session-Token',value: headers?.['Session-Token']});
		}
		return fileUploadHeaders;
	}

	public async fileUploaderApi(uploader: FileUploader, index:number = 0): Promise<void> {
		let fileUploadHeaders = await this.fileUploadHeaders();
		const firstFileItem: FileItem = uploader.queue[index];
		// Set headers dynamically before uploading this file
		firstFileItem.headers = fileUploadHeaders;
		// Upload the file
		firstFileItem.upload();
	}

	public async dataEncryptByGCM(dataToEncrypt: string): Promise<string> {
		return await encryptGCM(dataToEncrypt, env.satya+env.treta+env.dvaeara+env.kali)
	}

	public async dataDecryptByGCM(encryptedData: string): Promise<string> {
		return await decryptGCM(encryptedData, env.satya+env.treta+env.dvaeara+env.kali)
	}
}
