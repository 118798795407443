import { Component, OnInit, Input, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, ApiServ, InitServ, LoaderServ, UtilServ, LeadsServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { EMAIL_REG_EXP, PASSWORD_MIN_LENGTH, TEXT_REG_EXP } from '../../Constants';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';
@Component({
	selector: 'bk-referral-signup',
	templateUrl: './ReferralSignup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy, CustomValidators]
})
export class ReferralSignupComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		social: null,
		form: null
	}
	refSignupForm!: FormGroup; // Referral signup form
	admnStngs: any;
	loaderInfoId: string = 'ref-signup-info';
	loaderId: string = 'referral-signup-loader';
	referralData: any;
	referralToken: string = '';
	emailDisabled: boolean = false;
	// eslint-disable-next-line max-params
	constructor(private actRoute: ActivatedRoute, private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private customValidators: CustomValidators, private authServ: AuthServ, private loader: LoaderServ, public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, public toastr: ToastrService, private cDRef: ChangeDetectorRef, private leadsServ: LeadsServ){
		this.admnStngs = this.initServ.appAdmnStngs; // Admin settings
		// Referral token
		this.referralToken = this.actRoute.snapshot.params['rid'] ? this.actRoute.snapshot.params['rid'] : '';
		if(this.referralToken){
			// Call referral api for get the user info
			this.referralApi();
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		// Signup form build
		this.refSignupForm = this.frmBldr.group({
			first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			email_id: [
			null,{
				validators: [Validators.required, Validators.pattern(EMAIL_REG_EXP)],
				asyncValidators: [this.customValidators.existingEmail()],
				updateOn: 'blur'
			}],
			phone_number: [null],
			password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]],
			confirm_password: ['', [Validators.required]],
			ip: [this.initServ.ipAddress],
			user_agent: [navigator.userAgent],
			customer: this.frmBldr.group({ refferar_uid: [null] })
		},{
			validators: [CustomValidators.matchPassword('password', 'confirm_password')]
		});
		// Get the email from queryparams, disable the email input and prefilled the form value
		let email = this.actRoute.snapshot.queryParamMap.get('email');
		if(email){
			this.emailDisabled = true;
			this.refSignupForm.controls['email_id'].setValue(email);
		}
		// Get the phone from queryparams and prefilled the form value
		let phone = this.actRoute.snapshot.queryParamMap.get('phone');
		if(phone){
			this.refSignupForm.controls['phone_number'].setValue(phone);
		}
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.refSignupForm.controls;
	}
	/**
	 * Get the referral info based on referral token
	 */
	private referralApi(): void{
		this.apiServ.callApiWithPathVariables('GET', 'ReferralToken', [this.referralToken]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}

	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.referralData = res.data;
			if(this.referralData){
				(<FormGroup>this.refSignupForm.controls['customer']).controls['refferar_uid'].setValue(this.referralData.user_profile._id);
			}
		}
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderInfoId);
	}
	/**
	 * Submit the signup form
	 */
	public submitForm(): any {
		if(this.refSignupForm.valid){
			this.refSignupForm.controls['email_id'].setValue((this.refSignupForm.controls['email_id'].value).toLowerCase());
			let authKey: string = '';
			// Auth key domain name + email id
			if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.domain_name){
				authKey = this.admnStngs.merchant_settings.store.domain_name + this.refSignupForm.controls['email_id'].value;
			}
			this.detailsFocusOut(1);
			this.authServ.signup(this.refSignupForm.value,authKey, this.loaderId);
		} else{
			for(let i in this.refSignupForm.controls){
				this.refSignupForm.controls[i].markAsTouched();
			}
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * Handles the focus out event for the details section.
	 * This function checks the validation status of the email_id form control.
	 * If the email is valid and meets all the specified criteria, it adds contact information to a lead.
	 */
	public detailsFocusOut(interval: number | null = null): void {
		if(!this.f['email_id']?.errors?.required && !this.f['email_id']?.errors?.pattern && !this.f['email_id']?.errors?.minlength && !this.f['email_id']?.errors?.matching){
			this.leadsServ.addContactToLead(this.refSignupForm.value, {type: 'sign_up', slug: 'sign-up'}, interval)
		}
	}
}