/* eslint-disable max-depth */
import { Component, Input, OnInit, ViewEncapsulation, Self, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// Services
import { NgOnDestroy, InitServ, UtilServ, ApiServ, PopupServ, LoaderServ } from '../../../../Services';
import { UserPopupServ } from '../../UserPopup.service';

@Component({
	selector: 'bk-cancel-booking',
	template: `
	<ng-container *ngIf="!isBkngStarted && admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.cancellation && (admnStngs?.merchant_settings?.cancellation?.customer_postpone_service == 'yes' || admnStngs?.merchant_settings?.cancellation?.customer_cancel_service == 'yes' || admnStngs?.merchant_settings?.cancellation?.customer_cancel_recurring_service?.enable)">
		<bk-navigate *ngIf="booking" [secId]="secId" [designId]="designId" innerHTML="{{ textContent }}" [customClass]="customClass" [essentialClass]="essentialClass" (callback)="cancel()" ></bk-navigate>
	</ng-container>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CancelBookingComponent implements OnInit {
	@Input() booking: any;
	@Input() secId:string = '';
	@Input() customClass: string = 'btn btn-danger btn-lg fs-19 fw-bold';
	@Input() essentialClass: string = 'w-100';
	@Input() designId: any;
	@Input() isModifyAllowed: boolean = false;
	@Input() innerText: any;
	@Output() refreshComp: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	bkngPreCharged: boolean = false;
	postponeOnPrecharge: boolean = false;
	postponeDates: any;
	isBkngStarted: boolean = false;
	textContent: string = '';
	// eslint-disable-next-line max-params
	constructor(@Self() private destroy: NgOnDestroy, private initServ: InitServ, private utilServ: UtilServ, private apiServ: ApiServ, private popupServ: PopupServ, private userPopupServ: UserPopupServ, private loader: LoaderServ, private translate: TranslateService) {
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.cancellation && this.admnStngs.merchant_settings.cancellation.customer_postpone_pre_charge_service && this.admnStngs.merchant_settings.cancellation.customer_postpone_pre_charge_service == 'yes'){
			this.postponeOnPrecharge = true;
		}
	}
	ngOnInit(): void {
		this.bkngPreCharged = this.utilServ.bkngPreCharged(this.booking);
		if (this.booking) {
			this.isBkngStarted = this.utilServ.checkIsBkngStarted(this.booking.booking_date, this.booking.arrival_time);
		}
		// to set the text content of the Cancel btn
		this.textContent = (this.isModifyAllowed) ? this.innerText : this.translate.instant('Cancel Booking');
	}
	/**
	 * Cancel booking
	 */
	public cancel(): void {
		this.loader.show()
		if (this.booking && this.booking.payment_log && (this.booking.payment_log).length > 0) {
			this.cancelPopup();
		} else {
			this.calledApi('PaymentLogs');
		}
	}
	/**
	 * Cancel popup
	 */
	// eslint-disable-next-line complexity
	private cancelPopup() {
		if(this.admnStngs && this.admnStngs.merchant_settings.cancellation.customer_cancel_service == 'yes'){
			if(this.booking && this.booking.occurrence == "recurring" && (this.admnStngs && this.admnStngs.merchant_settings.cancellation.customer_postpone_service == 'yes')){
				if([6, 7, 8].includes(this.booking.status) && this.admnStngs.merchant_settings?.bookings?.show_all_unassigned_bookings && this.admnStngs.merchant_settings?.bookings?.show_all_unassigned_bookings == 'no'){
					this.cancelBkngPopup();
				}else{
					this.calledApi('PostponeDates');
				}
			}else{
				this.cancelBkngPopup();
			}
		}else{
			this.cancelBkngPopup();
		}
	}
	/**
	 * Call the api
	 * @param apiName PaymentLogs/PostponeDates
	 */
	private calledApi(apiName: string): void {
		this.apiServ.callApiWithPathVariables('GET', apiName, [this.booking._id]).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, apiName));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * @param type: PaymentLogs/PostponeDates
	 * API response handler
	 */
	private onResultCallback(res: any, type: string): void {
		if (type == 'PaymentLogs') {
			if (this.apiServ.checkAPIRes(res, false) && res.data && (res.data).length > 0) {
				this.booking['payment_log'] = res.data;
			}
			this.bkngPreCharged = this.utilServ.bkngPreCharged(this.booking);
			this.cancelPopup();
		} else {
			if(this.apiServ.checkAPIRes(res, false) && res.data && res?.data?.bookings && this.utilServ.checkArrLength(res?.data?.bookings)){
				this.postponeDates = res?.data?.bookings;
			}
			this.cancelBkngPopup();
		}
		this.loader.hide()
	}
	/**
	 * Check the booking prechared and postpone pre charge
	 * @returns
	 */
	private postponeAllowdOnPreCharge(): boolean {
		if (this.bkngPreCharged && !this.postponeOnPrecharge) {
			return false;
		}
		return true;
	}
	/**
	 * Check the customer cancel service category
	 * @returns boolean
	 */
	private cancelServiceCat(): boolean {
		let industryId = this.booking.industry_id;
		let serviceId = this.booking.service_category;
		if (this.admnStngs && this.admnStngs.merchant_settings.cancellation.customer_cancel_service_categories && (this.admnStngs.merchant_settings.cancellation.customer_cancel_service_categories).length > 0) {
			for (let industry of this.admnStngs.merchant_settings.cancellation.customer_cancel_service_categories) {
				if (industry.id == industryId) {
					if ((industry.services).includes(serviceId)) {
						return true;
					}
				}
			}
		}
		return false;
	}
	/**
	 * Cancel booking popup's
	 */
	// eslint-disable-next-line complexity
	private cancelBkngPopup(): void {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.cancellation && this.admnStngs.merchant_settings.cancellation.customer_cancel_service == 'yes') {
			if (this.booking.occurrence == "recurring" && (this.admnStngs && this.admnStngs.merchant_settings.cancellation.customer_postpone_service == 'yes') && (this.postponeDates && this.postponeDates.length > 0) && this.postponeAllowdOnPreCharge()) {
				if (this.admnStngs.merchant_settings.cancellation.recurring_customer_cancel_service && this.cancelServiceCat()) {
					// Postpone or cancel
					this.userPopupServ.postponePopup(this.booking, this.postponeDates).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				} else {
					// Postpone
					this.userPopupServ.postponePopup(this.booking, this.postponeDates, 'Postpone').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				}
			} else {
				// Cancel popup
				if (this.cancelServiceCat()) {
					if (this.booking.occurrence == "recurring") {
						if (this.admnStngs.merchant_settings.cancellation.recurring_customer_cancel_service) {
							if([6, 7, 8].includes(this.booking.status) && (this.admnStngs.merchant_settings?.bookings?.show_all_unassigned_bookings && this.admnStngs.merchant_settings?.bookings?.show_all_unassigned_bookings == 'no')){
								// Cancel all
								this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
							}else{
								this.openCancelPopupBasedOnAdminSett();
								// Cancel this or all
								// this.userPopupServ.cancelServicePopup(this.booking).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
							}
						} else {
							this.popupServ.newMsgPopup(this.admnStngs.merchant_settings.cancellation.cancel_recurring_booking_message);
						}
					} else {
						if (this.admnStngs.merchant_settings.cancellation.one_time_customer_cancel_service) {
							// Cancel this
							this.userPopupServ.cancelServicePopup(this.booking, 'single').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
						} else {
							this.popupServ.newMsgPopup(this.admnStngs.merchant_settings.cancellation.cancel_one_time_booking_message);
						}
					}
				} else {
					this.popupServ.newMsgPopup(this.admnStngs.merchant_settings.cancellation.booking_cancel_popup_message);
				}
			}
		} else {
			if (this.booking.occurrence == "recurring" && (this.admnStngs && this.admnStngs.merchant_settings.cancellation.customer_postpone_service == 'yes') && this.postponeAllowdOnPreCharge()) {
				this.userPopupServ.postponePopup(this.booking, this.postponeDates, 'Postpone').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			} else {
				this.popupServ.newMsgPopup(this.admnStngs.merchant_settings.cancellation.booking_cancel_popup_message);
			}
		}
		this.loader.hide()
	}
	/**
	 * On popup result callback method
	 * @param res: popup res
	 * Popup response handler
	 */
	private popupCallback(res: any): void {
		if (res) {
			this.refreshComp.emit(true);
		}
	}
	// TODO: Common code in "CancelBookingComponent" & "PostponeComponent" (Lakhvir)
	/**
	 * Opens the cancel service popup based on admin settings and booking options.
	 * Determines the cancellation options available based on admin settings
	 * and the type of booking (single or recurring). It then opens the cancel service popup accordingly.
	 */
	private openCancelPopupBasedOnAdminSett(): void {
		// Get the cancellation options available for the current booking
		let custRecBkngCancelOpt: string[] = this.getCustRecBkngCancelOpt();
		// Check if multiple cancellation options are available
		if(custRecBkngCancelOpt.length > 1){
			// If there are multiple options, handle accordingly
			if(custRecBkngCancelOpt.length == 2 && custRecBkngCancelOpt.includes('upcoming') && custRecBkngCancelOpt.includes('all') && this.booking.is_first){
				// Open the cancel service popup for recurring bookings with 'upcoming' and 'all' options
				this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			}else{
				// Open the cancel service popup for 'this', 'upcoming', and 'all' options
				this.userPopupServ.cancelServicePopup(this.booking).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			}
		}else{
			// If only one cancellation option is available, proceed with that option
			this.oneCancelRecBkngOptAction(custRecBkngCancelOpt[0]);
		}
	}
	/**
	 * Handles the action when there is only one cancellation option available.
	 * Opens the cancel service popup based on the specified option.
	 * @param {string} option - The cancellation option to handle ('single', 'upcoming', or 'all')
	 */
	private oneCancelRecBkngOptAction(option: string): void {
		// Handle the action based on the specified cancellation option
		switch (option) {
			case 'single':
				// Open the cancel service popup for a single booking
				this.userPopupServ.cancelServicePopup(this.booking, 'single').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				break;
			case 'upcoming':
				// Open the cancel service popup for upcoming occurrences of a recurring booking
				this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				break;
			case 'all':
				// If it's the first occurrence of a recurring booking, open the cancel service popup for all occurrences
				if(this.booking.is_first){
					this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				}else{
					// If it's not the first occurrence, fetch booking data for the first occurrence from the API
					this.loader.show();
					// Fetch booking data for the first occurrence from the API
					this.apiServ.callApiWithPathVariables('GET', 'RecurringScheduleFirstBkng', [this.booking._id]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.recurringScheduleFirstBkngRes(res));
				}
				break;
		}
	}
	/**
	 * Retrieves the cancellation options available for customer recurring bookings from admin settings.
	 * If the options are not defined in admin settings, default options ('single' and 'upcoming') are returned.
	 * @returns {string[]} - An array of cancellation options available for customer recurring bookings
	 */
	private getCustRecBkngCancelOpt(): string[] {
		// Check if cancellation options are defined in admin settings
		if(this.initServ.appAdmnStngs?.merchant_settings?.cancellation?.customer_recurring_booking_cancellation_options){
			// Return the defined cancellation options
			return this.initServ.appAdmnStngs?.merchant_settings?.cancellation?.customer_recurring_booking_cancellation_options;
		}
		// If cancellation options are not defined, return default options
		return ['single', 'upcoming'];
	}
	/**
	 * Handles the response for fetching booking data for the first occurrence in a recurring schedule.
	 * @param {any} res - The response object from the 'BookingData' API for the first booking
	 */
	private recurringScheduleFirstBkngRes(res: any): void {
		this.loader.hide();
		if(this.apiServ.checkAPIRes(res)){
			// Extract the first booking data from the response
			let firstBkng: any = res.data;
			// Check if payment logs are available for the first booking
			if(this.utilServ.checkArrLength(firstBkng?.payment_log)){
				this.userPopupServ.cancelServicePopup(firstBkng, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			}else{
				// If payment logs are not available, fetch payment logs for the first booking
				this.loader.show();
				this.apiServ.callApiWithPathVariables('GET', 'PaymentLogs', [firstBkng._id]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.firstBkngPaymentLogApiRes(res, firstBkng));
			}
		}
	}
	/**
	 * Handles the response for fetching payment logs for the first booking in a recurring schedule.
	 * @param {any} res - The response object from the 'PaymentLogs' API for the first booking
	 * @param {any} firstBkng - The first booking object for which payment logs are fetched
	 */
	private firstBkngPaymentLogApiRes(res: any, firstBkng: any): void {
		// Check the API response for validity and the availability of payment logs
		if(this.apiServ.checkAPIRes(res, false) && this.utilServ.checkArrLength(res?.data)){
			// If payment logs are available, update the first booking object with the payment logs
			firstBkng['payment_log'] = res.data;
		}
		this.userPopupServ.cancelServicePopup(firstBkng, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
		this.loader.hide();
	}
}