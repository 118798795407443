<div class="multistep__header--elements d-none d-lg-flex">
	<div class="multistep__responsive d-lg-none p-15">
		<div class="d-flex justify-content-between w-100 h-50px align-items-center">
			<div class="multistep__overlay--title">
				<h5 class="fw-bold mb-0 bk-title">{{initServ?.appStr?.text?.bookingSummary | translate}}</h5>
			</div>
			<div class="multistep__overlay--icon d-flex justify-content-center align-items-center tjs-pointer bk-arrow" (click)="addBkngServ.respSummary()">
				<span class="tjsicon-up-arrow tjs-pointer"></span>
			</div>
		</div>
	</div>
	<div class="multistep__header--elements-wrap">
		<!-- Responsive > 991 -->
		<div [id]="parentSection?.multi_step_header?.logo" *ngIf="parentSection?.multi_step_header?.logo && utilServ?.siteLogo" class="multistep__header--ele multistep__header--logo multistep__header--ele-fixed justify-content-center align-items-center tjs-pointer step-completed bk-form-multi-header-ele">
			<ng-container *ngTemplateOutlet="logoTemp"></ng-container>
		</div>
		<!-- Industry name -->
		<div class="multistep__header--ele multistep__header--industry justify-content-center align-items-center tjs-pointer bk-form-multi-header-ele step-completed" (click)="moveToStep.emit('first')" *ngIf="isMultiInd.multiIndus">
			<h5 class="fw-bold mb-0 bk-form-multi-header-label" [id]="section?.industry_id"> {{industryName |translate}}
			</h5>
		</div>
		<!-- Service and frequency name -->
		<div *ngIf="(isStepOneAvail || formId == 5) && (this.bkngFormServ.isFrequencyAvailable(this.settings.frequencies,this.selectedServiceType,this.selectedLocation,this.prefilledData, this.settings, this.bookingType) || ((this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType)) || this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType, true)))" [ngClass]="{'step-completed': activeStep > 0 }" class="multistep__header--ele bk-form-multi-header-ele" (click)="moveToStep.emit('step_one')">
			<h5 class="fw-bold mb-5 bk-form-multi-header-label" *ngIf="(this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType) || this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType, true))">{{serviceName |translate}}</h5>
			<p class="mb-0 fs-14 text-muted bk-form-multi-header-desc" *ngIf="this.bkngFormServ.isFrequencyAvailable(this.settings.frequencies,this.selectedServiceType,this.selectedLocation,this.prefilledData, this.settings, this.bookingType)">{{frequencyName |translate}}</p>
		</div>
		<!-- Form parameters -->
		<ng-container [ngSwitch]="formId">
			<ng-container *ngSwitchCase="1">
				<div [ngClass]="{'step-completed': (activeStep > 1 || ((!isStepOneAvail && formId != 5) && activeStep > 0)) }" class="multistep-dropdown-toggle multistep__header--ele tjs-transition--base flex-columm bk-form-multi-header-ele" *ngIf="(activeStep > 0 || (((!isStepOneAvail && formId != 5) && activeStep >= 0))) && pricingParams && (pricingParams).length > 0">
					<div class="d-none d-lg-flex h-100 w-100 align-items-center justify-content-center">
						<div class="multistep__header--ele_inner" *ngIf="pricingParams[0] && pricingParams[0]?.quantity" (click)="moveToStep.emit('step_two')">
							<h5 class="fw-bold mb-5 bk-form-multi-header-label">
								{{pricingParams[0]?.name | translate}}
							</h5>
							<p class="mb-0 fs-14 mt-5 text-muted text-capitalize w-100 bk-form-multi-header-desc" >
								{{pricingParams[0]?.cat_name | translate}}
							</p>
							<div class="fs-13 text-muted mt-5 d-none d-lg-block bk-form-multi-header-desc" *ngIf="((pricingParams).length > 1)" translate>View All</div>
						</div>
					</div>
					<ul class="ps-0 mb-0 list-unstyled multistep-dropdown-menu dropdown-menu  shadow-sm tjs-dropdown__menu  border-0 fs-16 text-center position-absolute tjs-popup__content--height tjs-scrollbar tjs-overflow-scroll" (click)="moveToStep.emit('step_two')">
						<ng-container *ngFor="let pricingParam of pricingParams; let i = index;trackBy:utilServ.trackByFnIndex;">
							<li *ngIf="pricingParam?.quantity" class="dropdown-item">
								<div class="d-flex flex-column align-items-center w-100 text-center text-capitalize">
									<p class="mb-0 opacity-75">
										{{pricingParam?.cat_name | translate}}
									</p>
									<p class="fw-bold mb-0">
										{{pricingParam?.name | translate}}
									</p>
								</div>
							</li>
						</ng-container>
					</ul>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="2">
				<div class="multistep-dropdown-toggle multistep__header--ele bk-form-multi-header-ele" *ngIf="(activeStep > 0 || ((!isStepOneAvail && formId != 5) && activeStep >= 0)) && items && items.length > 0 && (items[0].packages).length > 0" [ngClass]="{'step-completed': (activeStep > 1 || ((!isStepOneAvail && formId != 5) && activeStep > 0))}">
					<div class="d-none d-lg-flex flex-column" *ngIf="items[0]" (click)="moveToStep.emit('step_two')">
						<h5 class="fw-bold mb-5 w-100 bk-form-multi-header-label">
							<ng-container *ngIf="hideItems else itemsName">
								<ng-container *ngIf="items[0]?.packages && (items[0]?.packages).length > 0">
									{{'Packages' | translate}}
								</ng-container>
							</ng-container>
							<ng-template #itemsName>
								{{bkngFormServ.getElementName(items[0], 'items')  | translate}}
								<!-- ({{item?.quantity | translate}}) -->
							</ng-template>
						</h5>
						<ng-container *ngIf="items[0].packages && (items[0].packages).length > 0">
							<ng-container *ngFor="let package of items[0].packages;let j = index;trackBy:utilServ.trackByFnIndex;">
								<div class="w-100" *ngIf="j == 0">
									<p class="mb-0 bk-form-multi-header-desc">
										{{bkngFormServ.getElementName(package, 'package') | translate}} ({{package.quantity | translate}})
									</p>
									<div *ngIf="package.package_addons && (package.package_addons).length > 0">
										<ng-container *ngFor="let pckgAddon of package.package_addons; let i = index; trackBy: utilServ.trackByFnIndex">
											<p class="mb-0 fs-13 bk-form-multi-header-desc">
												<ng-container *ngIf="settingsParams?.package_addons && settingsParams?.package_addons[pckgAddon?.id]">
													{{utilServ.getFormParamName(settingsParams?.package_addons[pckgAddon?.id]) | translate}} ({{pckgAddon.quantity | translate}})
												</ng-container>
											</p>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</ng-container>
						<div class="fs-13 text-muted mt-5 d-none d-lg-block bk-form-multi-header-desc" *ngIf="(items).length > 1"  translate>View All</div>
						<div class="fs-13 text-muted mt-5 d-none d-lg-block bk-form-multi-header-desc" *ngIf="items && items[0].id  == 0 && (items[0].packages).length > 1" translate>View All</div>
					</div>
					<ul class="ps-0 mb-0 list-unstyled multistep-dropdown-menu dropdown-menu  shadow-sm tjs-dropdown__menu  border-0 fs-16 text-center" (click)="moveToStep.emit('step_two')">
						<ng-container *ngIf="items && items.length > 0">
							<ng-container *ngFor="let item of items;let i = index; trackBy: utilServ.trackByFnIndex;">
								<li class="dropdown-item text-capitalize">
									<p class="mb-0 opacity-75">
										<ng-container *ngIf="hideItems else itemsName">
											<ng-container *ngIf="item && item?.packages && (item?.packages).length > 0">
												{{'Packages' | translate}}
											</ng-container>
										</ng-container>
										<ng-template #itemsName>
											{{bkngFormServ.getElementName(item, 'items')  | translate}}
											<!-- ({{item?.quantity | translate}}) -->
										</ng-template>
									</p>
									<ng-container *ngFor="let package of item.packages;trackBy:utilServ.trackByFnIndex;;">
										<div class="fw-bold">
											<p class="mb-0">
												{{bkngFormServ.getElementName(package, 'package') | translate}} ({{package.quantity | translate}})
											</p>
											<div *ngIf="package.package_addons && (package.package_addons).length > 0">
												<ng-container *ngFor="let pckgAddon of package.package_addons; let i = index; trackBy: utilServ.trackByFnIndex">
													<p class="mb-0 fs-14">
														<ng-container *ngIf="settingsParams?.package_addons && settingsParams?.package_addons[pckgAddon?.id]">
															{{utilServ.getFormParamName(settingsParams?.package_addons[pckgAddon?.id]) | translate}} ({{pckgAddon.quantity | translate}})
														</ng-container>
													</p>
												</ng-container>
											</div>
										</div>
									</ng-container>
								</li>
							</ng-container>
						</ng-container>
					</ul>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="3">
				<ng-container *ngIf="(activeStep > 0 || ((!isStepOneAvail && formId != 5) && activeStep >= 0)) && items && (items).length > 0">
					<div class="multistep-dropdown-toggle multistep__header--ele bk-form-multi-header-ele" [ngClass]="{'step-completed': (activeStep > 1 || ((!isStepOneAvail && formId != 5) && activeStep > 0))}">
						<div class="d-none d-lg-flex flex-column w-100 disable-pack" *ngIf="items[0]" [ngClass]="{'step-completed': activeStep > 1 }" (click)="moveToStep.emit('step_two')">
							<h5 class="fw-bold mb-5 bk-form-multi-header-label">
								{{items[0]?.name  | translate}} ({{items[0]?.quantity | translate}})
							</h5>
							<p class="mb-0 fs-14 text-muted text-capitalize bk-form-multi-header-desc" *ngIf="items[0]?.addons">
								{{items[0]?.addons}}
							</p>
							<div class="fs-13 text-muted mt-5 d-none d-lg-block" *ngIf="items && (items).length > 1" translate>View All</div>
						</div>
						<ul class="ps-0 mb-0 list-unstyled multistep-dropdown-menu dropdown-menu  shadow-sm tjs-dropdown__menu  border-0 fs-16 text-center" (click)="moveToStep.emit('step_two')">
							<ng-container *ngIf="items && items.length > 0">
								<ng-container *ngFor="let item of items;let i = index; trackBy: utilServ.trackByFnIndex">
									<li class="dropdown-item">
										<p class="mb-0 opacity-75">
											{{item?.name  | translate}} ({{item?.quantity | translate}})
										</p>
										<p class="fw-bold mb-0" *ngIf="item?.addons">
											{{item?.addons}}
										</p>
									</li>
								</ng-container>
							</ng-container>
						</ul>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase="4">
				<div [ngClass]="{'step-completed': (activeStep > 1 || ((!isStepOneAvail && formId != 5) && activeStep > 0))}" class="multistep__header--ele tjs-transition--base flex-column bk-form-multi-header-ele" *ngIf="(activeStep > 0 || ((!isStepOneAvail && formId != 5) && activeStep >= 0)) && BKFrm?.value?.area_parameter?.quantity && BKFrm?.value?.area_parameter?.id" (click)="moveToStep.emit('step_two')">
					<h5 class="fw-bold mb-5 bk-form-multi-header-label">
						{{areaParams?.quantity | translate}}
					</h5>
					<p class="mb-0 fs-14 mt-5 text-muted text-capitalize bk-form-multi-header-desc">
						{{areaParams?.name | translate}}
					</p>
				</div>
			</ng-container>
		</ng-container>
		<div [ngClass]="{'step-completed': (BKFrm?.value?.form_id != 5 && activeStep > 2) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep > 2) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep > 1) || (BKFrm?.value?.form_id != 5 && !isStepOneAvail && activeStep > 1)}" class="multistep__header--ele  tjs-transition--base flex-column bk-form-multi-header-ele" *ngIf="((BKFrm?.value?.form_id != 5 && activeStep > 1) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep > 1) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep > 0) || (BKFrm?.value?.form_id != 5 && !isStepOneAvail && activeStep > 0)) && (BKFrm?.value?.arrival_date || BKFrm?.value?.arrival_time) && BKFrm?.value?.booking_date" (click)="moveToStep.emit('step_three')">
			<h5 class="fw-bold mb-5 bk-form-multi-header-label">
				{{utilServ.showDateForMultiStep(BKFrm?.value?.booking_date) | translate}}
			</h5>
			<p class="mb-0 fs-14 mt-5 text-muted text-capitalize bk-form-multi-header-desc dir-ltr" *ngIf="BKFrm?.value?.arrival_time">
				{{utilServ.slotToString(BKFrm?.value?.base_location_id, BKFrm?.value?.day, BKFrm?.value?.arrival_time,BKFrm?.value?.spot_type,BKFrm?.value?.arrival_window)}}
			</p>
			<p class="mb-0 fs-14 mt-5 text-muted text-capitalize bk-form-multi-header-desc" *ngIf="displaySpotDiscount">{{'Spot Discount' | translate}} :
				<span>
					<bk-amount-display [amount]="displaySpotDiscount" [isZero]="true"></bk-amount-display>
				</span>
			</p>
		</div>
		<div [ngClass]="{'step-completed': (BKFrm?.value?.form_id != 5 && activeStep > 3) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep > 3) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep > 2) || (BKFrm?.value?.form_id != 5 && !isStepOneAvail && activeStep > 2)}" class="multistep-dropdown-toggle multistep__header--ele  tjs-transition--base flex-column  multistep__header--address bk-form-multi-header-ele" *ngIf="((BKFrm?.value?.form_id != 5 && activeStep > 2) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep > 2) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep > 1) || (BKFrm?.value?.form_id != 5 && !isStepOneAvail && activeStep > 1)) && BKFrm?.value['address'].address">
			<h5 class="fw-bold mb-5 bk-form-multi-header-label">{{BKFrm?.value['address'].address | translate}}
				<span *ngIf="BKFrm?.value?.customer?.apt">, {{'Apt' | translate}}- {{BKFrm?.value?.customer?.apt}}</span>
			</h5>
			<p class="mb-0 fs-14 mt-5 text-muted text-capitalize bk-form-multi-header-desc"  translate>Address</p>
			<ul class="ps-0 mb-0 list-unstyled multistep-dropdown-menu dropdown-menu shadow-sm tjs-dropdown__menu  border-0 fs-16 text-center position-absolute tjs-popup__content--height tjs-scrollbar tjs-overflow-scroll">
				<li class="dropdown-item">
					<div class="d-flex flex-column align-items-center w-100 text-center text-capitalize">
						<p class="mb-0 opacity-75 text-wrap">
							{{BKFrm?.value['address'].address | translate}}
						</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div [id]="parentSection?.header_summary?.id" class="multistep__responsive--summary justify-content-center flex-column align-items-center px-25 d-lg-none w-100 bk-form-multi-header-summary d-flex" *ngIf="parentSection && parentSection?.header_summary">
		<div class="d-flex justify-content-between w-100 mb-5" [style.order]="multiPaymentOrder.total_before_tax" *ngIf="parentSection?.header_summary?.total_before_tax">
			<h6 class="fw-bold mb-0 text-muted w-60 text-truncate text-start bk-form-multi-header-summary-label">{{parentSection?.header_summary?.total_before_tax}}</h6>
			<h6 class="fw-bold d-flex before-tax-total mb-0 bk-form-multi-header-summary-label">
				<bk-amount-display [amount]="totalWithoutTax" [isZero]="true"></bk-amount-display>
			</h6>
		</div>
		<div class="d-flex justify-content-between w-100 align-items-center" [style.order]="multiPaymentOrder.total" *ngIf="parentSection?.header_summary?.total">
			<h5 class="mb-0 fw-bold text-muted w-60 text-truncate text-start bk-form-multi-header-summary-label">{{parentSection?.header_summary?.total}}</h5>
			<h4 class="fw-bold d-flex after-tax-total mb-0 text-secondary bk-form-multi-header-summary-label">
				<span class="dir-ltr">
					<bk-amount-display [amount]="displayFinalAmount" [isZero]="true"></bk-amount-display>
				</span>
			</h4>
		</div>
		<ng-container *ngIf="selectedFrequency?.form_frequency_data?.occurence_time && selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring'">
			<div class="justify-content-between d-flex align-items-center mb-0 w-100 fs-13" [style.order]="multiPaymentOrder.next_n_apt_total" *ngIf="parentSection?.header_summary?.next_n_apt_total">
				<span class="text-muted w-60 text-truncate text-start bk-form-multi-header-summary-label" >
					<span>{{multiNextNAptTotal}}</span> :
					<span class="dir-ltr d-inline-block">
						<bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
					</span>
				</span>
			</div>
			<div class="justify-content-between d-flex align-items-center mb-0 w-100 fs-13" [style.order]="multiPaymentOrder.rec_total_after_n_apt" *ngIf="parentSection?.header_summary?.rec_total_after_n_apt">
				<span class="fw-bolder text-dark fs-15 bk-form-multi-header-summary-label">
					<span>{{multiRecTotalAfterNApt}}</span> : <span class="dir-ltr d-inline-block">
						<bk-amount-display [amount]="afterNthAptFinalAmount" [isZero]="true"></bk-amount-display>
					</span>
				</span>
			</div>
		</ng-container>
		<ng-container *ngIf="!(selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring') && (selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && (selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking') || (selectedFrequency?.form_frequency_data?.shorter_job_length && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length)))">
			<div class="justify-content-between d-flex align-items-center mb-0 w-100 fs-13" [style.order]="multiPaymentOrder.first_apt_total" *ngIf="parentSection?.header_summary?.first_apt_total" >
				<span class="text-muted w-60 text-truncate text-start bk-form-multi-header-summary-label">{{parentSection?.header_summary?.first_apt_total}}</span>
				<span class="fw-bolder text-dark fs-15 bk-form-multi-header-summary-label"><bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
				</span>
			</div>
		</ng-container>
		<!-- Booking payment summary -->
		<bk-bkng-summary
			class="multi-bkng-summary"
			[style.order]="multiPaymentOrder.learn_more"
			summaryType="only-tooltip"
			subId="multione"
			[isMultiStepHeader]="true"
			[isMultiStepForm]="true"
			[bookingType]="bookingType"
			[isStepOneValid]="isStepOneValid"
			[prefilledData]="prefilledData"
			[BKFrm]="BKFrm"
			[smryScrlStatus]="smryScrlStatus"
			[smryMobileScrlStatus]="smryMobileScrlStatus"
			[parentSection]="parentSection"
			[section]="section"
			[pageSett]="pageSett"
			[couponData]="couponData"
			[isMobile]="isMobile"
			[settings]="settings"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[selectedPricingParams]="selectedPricingParams"
			[selectedItems]="selectedItems"
			[selectedAreaParams]="selectedAreaParams"
			[selectedExtras]="selectedExtras"
			[selectedExcludes]="selectedExcludes"
			[adjustedTimeHours]="adjustedTimeHours"
			[adjustedTimeMin]="adjustedTimeMin"
			[bookingTimeHours]="bookingTimeHours"
			[bookingTimeMin]="bookingTimeMin"
			[selectedAddr]="selectedAddr"
			[isServiceFeeTaxable]="isServiceFeeTaxable"
			[displayServicePrice]="displayServicePrice"
			[excludeExpeditedAmountValue]="excludeExpeditedAmountValue"
			[displayFrequencyDiscount]="displayFrequencyDiscount"
			[displayCouponDiscount]="displayCouponDiscount"
			[displayDiscountedAmount]="displayDiscountedAmount"
			[bookingTaxRate]="bookingTaxRate"
			[exemptSalesTaxValue]="exemptSalesTaxValue"
			[bookingTaxType]="bookingTaxType"
			[displaySaleTax]="displaySaleTax"
			[displayTipsAmount]="displayTipsAmount"
			[excludeServiceFeeValue]="excludeServiceFeeValue"
			[displayServiceFee]="displayServiceFee"
			[displayTotal]="displayTotal"
			[firstRecAptFinalAmount]="firstRecAptFinalAmount"
			[afterNthAptFinalAmount]="afterNthAptFinalAmount"
			[displaySpotDiscount] = "displaySpotDiscount"
			[displayGiftCardAmount]="displayGiftCardAmount"
			[displayReferralDiscount]="displayReferralDiscount"
			[displayFinalAmount]="displayFinalAmount"
			[formMetaData]="formMetaData"
			[settingsObj]="settingsObj"
			>
		</bk-bkng-summary>
	</div>
</div>
<div [id]="parentSection?.header_summary?.id" class="d-inline-flex multistep__header--summary bg-white bk-form-multi-header-summary lh-normal" *ngIf="parentSection && parentSection?.header_summary">
	<div class="d-flex justify-content-center flex-column align-items-center position-relative w-100">
		<div class="d-flex justify-content-between w-100 bk-form-sum-tbt" [style.order]="multiPaymentOrder.total_before_tax" *ngIf="parentSection?.header_summary?.total_before_tax">
			<h6 class="fw-bold mb-0 text-muted w-60 text-truncate bk-form-sum-tbt-label">{{parentSection?.header_summary?.total_before_tax}}</h6>
			<h6 class="fw-bold d-flex before-tax-total mb-0 bk-form-sum-tbt-value">
				<span>
					<bk-amount-display [amount]="totalWithoutTax" [isZero]="true"></bk-amount-display>
				</span>
			</h6>
		</div>
		<div class="d-flex justify-content-between w-100 align-items-center bk-form-sum-total" [style.order]="multiPaymentOrder.total" *ngIf="headerSummTotalDisplayStatus">
			<h5 class="mb-0 fw-bold text-muted w-50 text-truncate bk-form-sum-total-label">{{(parentSection?.header_summary?.total) ? parentSection?.header_summary?.total : headerSummTotalLable}}</h5>
			<h4 class="fw-bold d-flex after-tax-total w-50 mb-0 text-secondary bk-form-sum-total-value justify-content-end">
				<span class="dir-ltr">
					<bk-amount-display [amount]="displayFinalAmount" [isZero]="true"></bk-amount-display>
				</span>
			</h4>
		</div>
		<ng-container *ngIf="selectedFrequency?.form_frequency_data?.occurence_time && selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring'">
			<div *ngIf="parentSection?.header_summary?.next_n_apt_total" [style.order]="multiPaymentOrder.next_n_apt_total" class="align-items-center w-100 mb-0 justify-content-between d-flex bk-form-sum-nxt-apt">
				<span class="text-muted w-60 text-truncate fs-13 bk-form-sum-nxt-apt-label fw-bold">{{multiNextNAptTotal}}</span>
				<span class="fw-bolder text-dark fs-15 bk-form-sum-nxt-apt-value">
					<bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
				</span>
			</div>
			<div *ngIf="parentSection?.header_summary?.rec_total_after_n_apt" [style.order]="multiPaymentOrder.rec_total_after_n_apt" class="justify-content-between d-flex align-items-center mb-0 w-100 bk-form-sum-rec-apt">
				<span class="text-muted fs-13 w-60 text-truncate bk-form-sum-rec-apt-label fw-bold">{{multiRecTotalAfterNApt}}</span>
				<span class="fw-bolder text-dark fs-15 bk-form-sum-rec-apt-value">
					<bk-amount-display [amount]="afterNthAptFinalAmount" [isZero]="true"></bk-amount-display>
				</span>
			</div>
		</ng-container>
		<ng-container *ngIf="!(selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring') && (selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && (selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking') || (selectedFrequency?.form_frequency_data?.shorter_job_length && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) ))">
			<div class="justify-content-between d-flex align-items-center mb-0 w-100 fs-13 bk-form-sum-fst-apt" [style.order]="multiPaymentOrder.first_apt_total" *ngIf="parentSection?.header_summary?.first_apt_total">
				<h6 class="text-muted fw-bold w-60 text-truncate bk-form-sum-fst-apt-label mb-0 fs-15">{{parentSection?.header_summary?.first_apt_total}}</h6>
				<h6 class="fw-bolder text-dark fs-15 bk-form-sum-fst-apt-value mb-0"><bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
				</h6>
			</div>
		</ng-container>
		<!-- Booking summary learn more -->
		<bk-bkng-summary
			class="multi-bkng-summary"
			[style.order]="multiPaymentOrder.learn_more"
			summaryType="only-tooltip"
			subId="multitwo"
			[isMultiStepHeader]="true"
			[isMultiStepForm]="true"
			[bookingType]="bookingType"
			[isStepOneValid]="isStepOneValid"
			[prefilledData]="prefilledData"
			[BKFrm]="BKFrm"
			[smryScrlStatus]="smryScrlStatus"
			[smryMobileScrlStatus]="smryMobileScrlStatus"
			[parentSection]="parentSection"
			[section]="section"
			[pageSett]="pageSett"
			[couponData]="couponData"
			[isMobile]="isMobile"
			[settings]="settings"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[selectedPricingParams]="selectedPricingParams"
			[selectedItems]="selectedItems"
			[selectedAreaParams]="selectedAreaParams"
			[selectedExtras]="selectedExtras"
			[selectedExcludes]="selectedExcludes"
			[adjustedTimeHours]="adjustedTimeHours"
			[adjustedTimeMin]="adjustedTimeMin"
			[bookingTimeHours]="bookingTimeHours"
			[bookingTimeMin]="bookingTimeMin"
			[selectedAddr]="selectedAddr"
			[isServiceFeeTaxable]="isServiceFeeTaxable"
			[displayServicePrice]="displayServicePrice"
			[excludeExpeditedAmountValue]="excludeExpeditedAmountValue"
			[displayFrequencyDiscount]="displayFrequencyDiscount"
			[displayCouponDiscount]="displayCouponDiscount"
			[displayDiscountedAmount]="displayDiscountedAmount"
			[bookingTaxRate]="bookingTaxRate"
			[exemptSalesTaxValue]="exemptSalesTaxValue"
			[bookingTaxType]="bookingTaxType"
			[displaySaleTax]="displaySaleTax"
			[displayTipsAmount]="displayTipsAmount"
			[excludeServiceFeeValue]="excludeServiceFeeValue"
			[displayServiceFee]="displayServiceFee"
			[displayTotal]="displayTotal"
			[firstRecAptFinalAmount]="firstRecAptFinalAmount"
			[afterNthAptFinalAmount]="afterNthAptFinalAmount"
			[displaySpotDiscount] = "displaySpotDiscount"
			[displayGiftCardAmount]="displayGiftCardAmount"
			[displayReferralDiscount]="displayReferralDiscount"
			[displayFinalAmount]="displayFinalAmount"
			[formMetaData]="formMetaData"
			[settingsObj]="settingsObj">
		</bk-bkng-summary>
	</div>
</div>
<!-- Site logo -->
<ng-template #logoTemp>
	<ng-container *ngIf="(utilServ?.siteLogo?.media && (utilServ?.siteLogo?.media).length > 0); else defaultLogo">
		<bk-navigate essentialClass="bk-logo p-0 text-capitalize" [secSett]="utilServ?.siteLogo?.link" innerHTML="<img loading='lazy'  id='{{utilServ?.siteLogo?.media_id}}' width='' height='' class='multistep__responsive--logo-image img-fluid' src='{{utilServ?.siteLogo?.media[0]}}' alt='Your Logo' />"></bk-navigate>
	</ng-container>
	<ng-template #defaultLogo>
		<bk-navigate essentialClass="multistep-header_logo-text fw-bold bk-logo p-0 text-capitalize" [secSett]="utilServ?.siteLogo?.link" innerHTML="{{initServ?.appStr?.text?.yourLogo | translate}}" [isText]="true"></bk-navigate>
	</ng-template>
</ng-template>