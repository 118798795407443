import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
// Serviecs
import { NgOnDestroy, InitServ, ApiServ, LoaderServ, UtilServ, PaymentGatewayServ, CacheService } from '../../../../../../Services';
// Custom validator
import { CustomValidators } from '../../../../../GlobalDefault';

@Component({
	selector: 'bk-cancel-popup',
	templateUrl: './CancelPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CancelPopupComponent implements OnInit {
	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	booking: any;
	type: string = 'both'; // single/recurring
	cancelForm!: FormGroup; // cancel form
	loaderId: string = 'cancel-loader';
	settings: any;
	reasons: any = [];
	reasonSelected: boolean = true;
	reasonVisible: boolean = false;
	cancelFee: number = 0;
	reasonTextarea: boolean = false;
	cancelBefore: any = 2;
	reasonFieldType: string = '';
	isBkngStarted: boolean = false;
	cancellationFeeObj: any = {};
	cancellationDates: Array<any> = [];
	cancelFeeObj: any = {};
	//
	secId: any;
	slug: string = '';
	section: any = {};

	custRecBkngCancelOpt: string[] = this.getCustRecBkngCancelOpt();

	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<CancelPopupComponent>, public matDialog: MatDialog, private cDRef: ChangeDetectorRef, private toastr: ToastrService, public initServ: InitServ, private frmBldr: FormBuilder, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, private translate: TranslateService, private paymentServ: PaymentGatewayServ, private cacheServ: CacheService) { }

	ngOnInit() {
		if (this.type) {
			this.cancel(this.type);
		}
		if (this.initServ.paymentGateway) {
			this.utilServ.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
	}
	/**
	 * Method builds a dynamic section for canceling appointments or bookings and applies styling to
	 * it.
	 */
	private buildDynamicSec(): void {
		// fields for cancel appointments & cancel booking sections
		this.section = {};
		let cancelAppointSec: any = { title: null, this_only_btn: null, all_upcoming_btn: null, whole_schedule_btn: null };
		let cancelBookingSec: any = { title: null, form: null, cancel_btn: null, keep_btn: null, cancel_all_btn: null, cancel_upcoming_btn: null};
		// set slug value
		this.slug = (this.type == 'both') ? 'cancel_appointment' : 'cancel_booking';
		// set section object.
		this.section = (this.type == 'both') ? { ...cancelAppointSec } : { ...cancelBookingSec };
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect the changes
			this.cDRef.detectChanges();
		});
	}
	/**
	 * Cancel service
	 * @param type
	 */
	public cancel(type: string): void {
		this.type = type;
		// build the dynamic section based on the type.
		this.buildDynamicSec();
		if (type == 'single') {
			this.cancelForm = this.frmBldr.group({
				booking_id: [],
				cancellation_reason: this.frmBldr.array([]),
				reason: [null, [Validators.required]],
				charge_cancellation_fee: [],
				booking_type: [],
				cancellation_fee: [],
				exclude_all_cancellation_fee: [false],
				late_cancellation: [false],
				provider_cancellation_pay: [],
				void_provider_pay: [false]
			});
		} else {
			this.cancelForm = this.frmBldr.group({
				booking_id: [],
				previous_booking_id: [0],
				cancellation_reason: this.frmBldr.array([]),
				reason: [null, [Validators.required]],
				charge_cancellation_fee: [0],
				booking_type: [],
				cancellation_fee: [],
				update_booking: [false],
				discount: [0],
				charge_full_onetime_fee: [],
				booking_tax_rate: [],
				booking_tax_type: [],
				tax_amount: [],
				exclude_all_cancellation_fee: [false],
				late_cancellation: [false],
				onetime_cancel_type: [],
				provider_cancellation_pay: [],
				void_provider_pay: [false]
			});
		}
		this.getSettings();
		if (this.booking) {
			this.isBkngStarted = this.utilServ.checkIsBkngStarted(this.booking.booking_date, this.booking.arrival_time);
		}
	}
	/**
	 * Get the cancellation settings
	 */
	private getSettings(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId, this.dialogRef);
		let queryParams: any = this.type == 'single' ? { type: 'one' } : null;
		let apiName: string = this.type == 'single' ? 'CancelSettings' : 'CancelRecSettings';
		this.apiServ.callApiWithPathQueryVars('GET', apiName, [this.booking._id], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'settings'));
	}
	/**
	 * Filled comman single/recurring cancel form values
	 */
	private filledCancelFormValue(type: string = 'onetime'): void {
		this.cancelForm.patchValue({
			booking_id: this.booking._id,
			booking_type: type
			// cancellation_fee : this.cancellationFee()
		});
		this.cancelFee = this.canChargeCancellationFee(); // Can cancellation fee
		this.reasonTextarea = this.isReasonAvailableWithService(); // Textarea
		this.reasonVisible = this.isReasonVisible(); // Reasons visibility
		// Reason textarea required validation
		if ((this.settings && this.settings.visible_comment_box == 'yes' && this.checkValueIsInAarray(this.settings.comment_box_booking_types)) && (this.settings.allow_cancellation_without_comment == 'no' || (this.settings.allow_cancellation_without_comment == 'yes' && !this.checkValueIsInAarray(this.settings.allow_cancellation_without_comment_booking_types))) && this.reasonTextarea) {
			CustomValidators.requiredValidator(true, this.cancelForm.controls['reason']);
		} else {
			CustomValidators.requiredValidator(false, this.cancelForm.controls['reason']);
		}
		if (type == 'recurring') {
			this.filledRecForm();
		}
		// Charge cancellation fee
		if (this.booking && this.booking.exclude_cancellation_fee) {
			this.cancelForm.controls['charge_cancellation_fee'].setValue(0);
		} else {
			this.cancelForm.controls['charge_cancellation_fee'].setValue(this.cancelFee);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Cancellation fees
	 * @returns
	 */
	private cancellationFee(sett: any): any {
		if (sett.cancellation_fees_unit && sett.cancellation_fees_unit == 'percentage') {
			return Math.round((((+this.booking.final_amount) * (sett.cancellation_fees)) / 100) * 100) / 100;
		} else {
			return sett.cancellation_fees;
		}
	}
	/**
	 * Can charge cancellation fees
	 * @returns 1/0
	 */
	private canChargeCancellationFee(): any {
		if (this.booking.exclude_cancellation_fee || (this.settings.charge_cancellation_fee && this.settings.charge_cancellation_fee == 'no')) {
			return 0;
		}
		if (this.admnStngs && this.admnStngs.merchant_settings && this.booking && this.settings) {
			if (this.booking.same_day_booking && this.admnStngs.merchant_settings.cancellation.exclude_same_day_cancellation_fees) {
				return 0;
			} else {
				if (this.cancelFeeObj && this.cancelFeeObj.cancellation_fee) {
					return this.excludeCancelFee();
				} else {
					return 0;
				}
			}
		}
		return 0;
	}
	/**
	 * Check the exclude cancel fee
	 * @returns
	 */
	private excludeCancelFee(): number {
		if (this.reasons && (this.reasons).length > 0 && this.settings.cancellation_reason && (this.settings.cancellation_reason).length > 0) {
			for (let reason of this.settings.cancellation_reason) {
				if ((this.reasons).includes(reason.id)) {
					if (this.type == 'recurring' && reason.exclude_cancellation_after_1st_appointment_fee) {
						this.cancelForm.controls['charge_full_onetime_fee'].setValue(0);
					}
					if (reason.exclude_cancellation_fee) {
						this.cancelForm.controls['exclude_all_cancellation_fee'].setValue(true);
						return 0;
					}
				}
			}
		}
		return 1;
	}
	/**
	 * Reason is available with service
	 * @returns boolean
	 */
	private isReasonAvailableWithService(): boolean {
		let industryId = this.booking.industry_id;
		let serviceId = this.booking.service_category;
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.cancellation && this.admnStngs.merchant_settings.cancellation.show_cancellation_popup_to == 'both' && this.admnStngs.merchant_settings.cancellation.enable_industry_cancellation_popup && (this.admnStngs.merchant_settings.cancellation.enable_industry_cancellation_popup).length > 0) {
			for (let industry of this.admnStngs.merchant_settings.cancellation.enable_industry_cancellation_popup) {
				if (industry.id == industryId) {
					if ((industry.services).includes(serviceId)) {
						return true;
					}
				}
			}
		}
		return false;
	}
	/**
	 * Check the reason is visible
	 * @returns
	 */
	public isReasonVisible(): boolean {
		let count = 0;
		// App permission
		if (this.utilServ.appPermission('cancellation') && this.settings && this.settings.enable_cancellation_reason == 'yes' && this.settings.cancellation_reason && (this.settings.cancellation_reason).length > 0 && this.reasonTextarea) {
			for (let reason of this.settings.cancellation_reason) {
				if (this.checkReasonfrequency(reason) && (this.booking.occurrence == 'onetime' || ((this.booking.occurrence == 'recurring' && this.checkReasonfrequency(reason)) && reason[this.reasonFieldType]))) {
					count++;
					break

				}
			}
		}
		if (count > 0) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Check value in array
	 * @param arrayName
	 * @param value
	 * @returns boolean
	 */
	public checkValueIsInAarray(arrayName: any): boolean {
		if ((arrayName).includes(this.booking.occurrence)) {
			return true;
		}
		return false;
	}
	/**
	 * Check the reason in frequency
	 * @param reason
	 * @returns
	 */
	public checkReasonfrequency(reason: any): boolean {
		let frequency: number = 1;
		if (this.booking.occurrence == 'recurring') {
			frequency = 2;
		}
		if ((reason.frequencies).includes(frequency)) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Change reason checkbox
	 * @param event Checkbox
	 * @param reason
	 */
	public reasonChange(event: Event, reason: any): void {
		const isChecked = (<HTMLInputElement>event.target).checked;
		let cancelReasons = <FormArray>this.cancelForm.controls['cancellation_reason'];
		if (isChecked) {
			cancelReasons.push(new FormControl(reason.title));
			(this.reasons).push(reason.id);
		} else {
			(cancelReasons.value).forEach((selected: any, index: number) => {
				if (selected == reason.title) {
					cancelReasons.removeAt(index);
				}
			});
			let id = (this.reasons).indexOf(reason.id);
			if (id > -1) {
				(this.reasons).splice(id, 1);
			}
		}
		this.reasonValidation();
		this.cancelFee = this.canChargeCancellationFee(); // Can cancellation fee
	}
	/**
	 * Check the reason checkbox validation
	 */
	private reasonValidation(): void {
		if (this.settings && this.settings.allow_cancellation_without_reason && this.settings.allow_cancellation_without_reason == 'yes') {
			this.reasonSelected = true;
		} else {
			if ((this.cancelForm.controls['cancellation_reason'].value).length > 0) {
				this.reasonSelected = true;
			} else {
				if (this.reasonVisible) {
					this.reasonSelected = false;
				} else {
					this.reasonSelected = true;
				}
			}
		}
	}
	/**
	 * Late cancellation
	 */
	private lateCancellation(): void {
		const time: any = Math.floor(Date.now() / 1000);
		let tempArrivalDate: any = this.utilServ.dateObj(this.booking.booking_date);
		let bookingTM: number = tempArrivalDate.getTime() / 1000;
		const timediff: number = bookingTM - time;
		const fivepmoffset: number = 7 * 3600;
		if (this.booking.status == 1 || this.booking.status == 2 || this.booking.status == 3 || this.booking.status == 4) {
			this.cancelForm.controls['late_cancellation'].setValue(false);
		} else {
			if (timediff > fivepmoffset) {
				this.cancelForm.controls['late_cancellation'].setValue(false);
			} else {
				this.cancelForm.controls['late_cancellation'].setValue(true);
			}
		}
	}

	// Recurring booking functions

	/**
	 * Filled recurring booking form
	 */
	// eslint-disable-next-line complexity
	private filledRecForm(): void {
		// Booking tax
		if (this.settings.onetime_cancel_type != 'coupon' && this.settings.previous_booking_record && (this.settings.previous_booking_record).length > 0) {
			this.cancelForm.controls['booking_tax_rate'].setValue(this.settings.previous_booking_record[0].booking_tax_rate);
			this.cancelForm.controls['booking_tax_type'].setValue(this.settings.previous_booking_record[0].booking_tax_type);
		}
		// Discount: coupon/tax/Frequency
		if (this.settings.onetime_cancel_type == 'coupon') {
			if (this.settings.previous_booking_record && (this.settings.previous_booking_record).length > 0) {
				let descTotal: number = 0;
				let taxTotal: number = 0;
				let i = 1;
				for (let bkng of this.settings.previous_booking_record) {
					if (bkng._id && bkng._id > 0 && (this.settings.previous_booking_record).length == i) {
						this.cancelForm.controls['previous_booking_id'].setValue(bkng._id);
					}
					switch (bkng.status) {
						case 1:
						case 2:
							descTotal = descTotal + this.calCouponDiscount(bkng);
							taxTotal = taxTotal + this.calTaxTotal(bkng);
							break;
						default:
							this.cancelForm.controls['update_booking'].setValue(false);
							this.cancelForm.controls['discount'].setValue(0);
							break;
					}
					i++;
				}
				// Discount and tax
				this.cancelForm.controls['discount'].setValue(+this.toFixedToTwo(descTotal, 2));
				this.cancelForm.controls["tax_amount"].setValue(+this.toFixedToTwo(taxTotal, 2));
			}
		} else {
			if (this.settings.previous_booking_record && (this.settings.previous_booking_record).length > 0 && this.settings.previous_booking_record[0]) {
				if (this.settings.previous_booking_record[0]._id && this.settings.previous_booking_record[0]._id > 0) {
					this.cancelForm.controls['previous_booking_id'].setValue(this.settings.previous_booking_record[0]._id);
				}
				switch (this.settings.previous_booking_record[0].status) {
					case 1:
					case 2:
						this.calFrequencyDiscount();
						break;
					default:
						this.cancelForm.controls['update_booking'].setValue(false);
						this.cancelForm.controls['discount'].setValue(0);
						break;
				}
			}
		}
		// Cancellation after appointment
		if (this.settings.previous_booking_record && (this.settings.previous_booking_record).length > 0 && this.settings.previous_booking_record[0] && this.settings.previous_booking_record[0].coupon && this.settings.previous_booking_record[0].coupon.cancellation_after_appt && this.settings.previous_booking_record[0].coupon.cancellation_after_appt > 0) {
			this.cancelBefore = this.settings.previous_booking_record[0].coupon.cancellation_after_appt;
		}
		// Charge full onetime fee
		if (this.booking.exclude_cancellation_fee_after_first_booking) {
			this.cancelForm.controls['charge_full_onetime_fee'].setValue(0);
		} else {
			if (this.settings.charge_full_onetime_fee && (this.settings.onetime_cancel_type == 'coupon' && !this.bkngsVoided()) || (this.settings.charge_full_onetime_fee && !this.settings.previous_booking_record[0].is_voided)) {
				this.cancelForm.controls['charge_full_onetime_fee'].setValue(1);
			} else {
				this.cancelForm.controls['charge_full_onetime_fee'].setValue(0);
			}
		}
		// Prefill
		if(!this.booking.is_first){
			this.prefillDontAddNextBkngVal();
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Prefills the cancellation form control for 'dont_add_next_booking_for_canceled_sch'.
	 * This method adds the form control to the cancellation form and sets its initial value.
	 * If the value is available in the application admin settings, it sets the value from there.
	 */
	private prefillDontAddNextBkngVal(): void {
		// Add the form control for 'dont_add_next_booking_for_canceled_sch' to the cancellation form
		this.cancelForm.addControl('dont_add_next_booking_for_canceled_sch', new FormControl(true));
		// Check if the value is available in the application admin settings
		if(this.utilServ.objHasProp(this.initServ.appAdmnStngs?.merchant_settings?.cancellation, 'dont_add_next_booking_for_canceled_sch')){
			// Set the initial value from the application admin settings if available
			this.cancelForm.controls['dont_add_next_booking_for_canceled_sch'].setValue((this.initServ.appAdmnStngs.merchant_settings.cancellation.dont_add_next_booking_for_canceled_sch == 'yes') ? true : false);
		}
	}

	/**
	 * Check the previous booking record
	 * @returns
	 */
	private bkngsVoided(): boolean {
		if (this.settings && this.settings.previous_booking_record && (this.settings.previous_booking_record).length > 0) {
			for (let bkng of this.settings.previous_booking_record) {
				if (!bkng.is_voided) {
					return false;
				}
			}
		}
		return true;
	}
	/**
	 * Cut of a number to two decimal places without roundoff
	 * @param num
	 * @param fixed
	 * @returns
	 */
	private toFixedToTwo(num: any, fixed: number) {
		let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
		return num.toString().match(re)[0];
	}
	/**
	 * Calcualte the coupon discount
	 * @param bkng
	 * @returns
	 */
	private calCouponDiscount(bkng: any): any {
		if (bkng.total_before_coupon_discount && bkng.coupon && bkng.coupon.discount) {
			if (bkng.coupon && bkng.coupon.discount_type && bkng.coupon.discount_type == 'percentage') {
				return +this.toFixedToTwo((+bkng.total_before_coupon_discount * +bkng.coupon.discount) / 100, 2);
			} else {
				return +this.toFixedToTwo(+bkng.total_before_coupon_discount, 2);
			}
		}
		return 0;
	}
	/**
	 * Calculate the tax total
	 * @param bkng
	 * @returns
	 */
	private calTaxTotal(bkng: any): any {
		if (!bkng.exempt_sales_tax && bkng.booking_tax_type == "percentage") {
			let taxAmount = (bkng.frequency_discount_amount) * (bkng.booking_tax_rate / 100);
			return this.toFixedToTwo(taxAmount, 2);
		}
		return 0;
	}
	/**
	 * Calculate the frequency discount
	 */
	private calFrequencyDiscount(): void {
		if (this.settings.previous_booking_record && (this.settings.previous_booking_record).length > 0) {
			this.cancelForm.controls['discount'].setValue(this.settings.previous_booking_record[0].frequency_discount_amount);
			if (this.settings.previous_booking_record[0].booking_tax_type == "percentage") {
				let taxAmount = (this.settings.previous_booking_record[0].frequency_discount_amount) * (this.settings.previous_booking_record[0].booking_tax_rate / 100);
				this.cancelForm.controls["tax_amount"].setValue(this.utilServ.roundToTwo(+taxAmount));
			}
		}
	}
	private isProvPayVoided(): boolean {
		let unassignedBkngStatus: Array<number> = [6, 7, 8];
		if (this.booking && (unassignedBkngStatus.includes(this.booking.status) || !this.cancelForm.controls['charge_cancellation_fee'].value || !(this.cancelForm.controls['cancellation_fee'].value && this.cancelForm.controls['cancellation_fee'].value > 0))) {
			return true;
		}
		return false;
	}
	/**
	 * Submit cancellation form
	 */
	async submitSingleForm() {
		await this.reasonValidation();
		if (this.cancelForm.valid && this.reasonSelected) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.cancelForm.controls['charge_cancellation_fee'].setValue(this.cancelFee);
			this.cancelForm.controls['exclude_all_cancellation_fee'].setValue(false);
			await this.excludeCancelFee();
			await this.lateCancellation();
			if (this.cancelFeeObj && this.cancelFeeObj.provider_cancellation_pay) {
				this.cancelForm.controls['provider_cancellation_pay'].setValue(+this.cancelFeeObj.provider_cancellation_pay);
			}
			this.cancelForm.controls['void_provider_pay'].setValue(this.isProvPayVoided());
			// For cancellation payment in case of credit card and stripe/paypal
			if ((this.initServ.threeDSecure && this.initServ.paymentGateway != 'authorizedotnet') && this.booking.payment_method == 'existing_credit_card') {
				this.totalAmtPayment();
			} else {
				this.sendCancelBkngReq(this.cancelForm.value);
			}
		} else {
			this.cancelForm.controls['reason'].markAsTouched();
			this.reasonSelected == false;
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * Calcellation total amount
	 */
	private totalAmtPayment(): void {
		let cancelFrm: any = this.cancelForm.value;
		let totalCancelAmt = 0;
		if (cancelFrm.charge_cancellation_fee == 1) {
			totalCancelAmt = cancelFrm.cancellation_fee;
		}
		if (cancelFrm.charge_full_onetime_fee == 1 && cancelFrm.update_booking == false) {
			totalCancelAmt += cancelFrm.discount + cancelFrm.tax_amount;
		}
		let sendData: any = this.cancelForm.value;
		if (this.canCustCanclThisBkng() && this.settings && totalCancelAmt > 0) {
			this.createPayment(sendData, totalCancelAmt);
		} else {
			this.sendCancelBkngReq(sendData);
		}
	}
	/**
	 * Function to create payment token for verify the payment in case of stripe,square and paypal
	 * @param totalCancelAmt: total cancellation amount
	 */
	private async createPayment(sendData: any, totalCancelAmt: any) {
		let payInfo: any = {
			amount: totalCancelAmt,
			booking_id: sendData.booking_id,
			base_location_id: this.booking.base_location_id,
			uid: this.booking.uid,
			pay_with_cc: this.booking.pay_with_cc
		}
		let chargeData: any;
		if (this.initServ.paymentGateway == 'stripe') {
			chargeData = await this.paymentServ?.stripePaymentIntent(payInfo);
			if (chargeData) {
				sendData['token'] = chargeData?.payment_method;
				sendData['charge_id'] = chargeData?.id;
			}
		} else if (this.initServ.paymentGateway == 'square' || this.initServ.paymentGateway == 'paypal') {
			payInfo['location'] = this.booking.location_id;
			if (this.initServ.paymentGateway == 'paypal') {
				chargeData = await this.paymentServ.paypalPaymentNonce(payInfo);
			} else {
				chargeData = await this.paymentServ.squarePaymentVerify(this.booking.pay_with_cc, payInfo);
			}
			if (chargeData) {
				sendData['pay_with_cc'] = chargeData?.card_id;
				// sendData['token'] = chargeData?.token;
				sendData['charge_id'] = chargeData?.charge_id;
			}
		}
		if (chargeData) {
			this.sendCancelBkngReq(sendData);
		} else {
			this.loader.hide(this.loaderId, this.dialogRef);
		}
	}
	/**
	 * Function to send cancel booking request
	 */
	private sendCancelBkngReq(sendData: any): void {
		sendData['system_time'] = dayjs().valueOf();
		sendData = this.setAddNextBkngForCanceledSchVal(sendData);
		this.apiServ.callApiWithPathVariables('POST', 'CancelBooking', [sendData.booking_id], sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'cancel'));
	}

	/**
	 * Sets the value for 'dont_add_next_booking_for_canceled_sch' in the sendData object.
	 * @param {any} sendData - The data object containing booking details.
	 * @returns {any} - Updated sendData object with 'dont_add_next_booking_for_canceled_sch' property.
	 */
	private setAddNextBkngForCanceledSchVal(sendData: any): any {
		// Check if the booking type is 'recurring' and if the property 'dont_add_next_booking_for_canceled_sch' does not exist in sendData.
		if(sendData?.booking_type == 'recurring' && !this.utilServ.objHasProp(sendData, 'dont_add_next_booking_for_canceled_sch')){
			// Initialize a variable to determine if the next booking should be added
			let val: boolean = true;
			// Check if the 'dont_add_next_booking_for_canceled_sch' property exists in the merchant settings under cancellation.
			if(this.utilServ.objHasProp(this.initServ.appAdmnStngs?.merchant_settings?.cancellation, 'dont_add_next_booking_for_canceled_sch')){
				// Set the value based on the merchant setting. If the value is 'yes', set val to true, otherwise set it to false.
				val = (this.initServ.appAdmnStngs.merchant_settings.cancellation.dont_add_next_booking_for_canceled_sch == 'yes') ? true : false;
			}
			// Add the determined value to sendData
			sendData['dont_add_next_booking_for_canceled_sch'] = val;
		}
		// Return the modified sendData
		return sendData;
	}

	/**
	 * Function to check whether customer can cancel this booking or not
	 */
	canCustCanclThisBkng() {
		let admncanclStngs = this.admnStngs?.merchant_settings?.cancellation;
		if (this.utilServ.isValExist(admncanclStngs)) {
			if (admncanclStngs?.confirm_cancellation_by_admin == 'no') {
				return true;
			} else if (admncanclStngs?.confirm_cancellation_by_admin == 'yes' && admncanclStngs?.confirm_cancellation_by_admin_booking_type != 'both' && admncanclStngs?.confirm_cancellation_by_admin_booking_type != this.booking?.occurrence) {
				return true;
			}
		}
		return false;
	}

	/**
	 * On result callback method
	 * @param res API res
	 * @param type PostponeDates/postpone
	 * API response handler
	 */
	private onResultCallback(res: any, type: string = ''): void {
		if (type == 'settings') {
			if (this.apiServ.checkAPIRes(res)) {
				this.settings = res.data;
				if (this.settings) {
					this.getCancellationFeeOut();
					if (this.type == 'single') {
						this.reasonFieldType = 'cancel_onetime';
						this.filledCancelFormValue();
						// eslint-disable-next-line max-depth
						if(this.settings?.single_booking_of_schedule){
							this.prefillDontAddNextBkngVal();
						}
					} else {
						this.reasonFieldType = 'cancel_recurring';
						this.filledCancelFormValue('recurring');
					}
				}
			}
		} else {
			if (this.apiServ.checkAPIRes(res)) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
			} else {
				if (res && res.message) {
					this.toastr.error(res.message);
				}
			}
		}
		this.loader.hide(this.loaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
	getCancellationFeeOut(): void {
		if (!(this.settings && this.settings.cancellation_fee_type && this.settings.cancellation_fee_type == 'multiple')) {
			let obj: any = {
				cancellation_fees: this.settings.cancellation_fee_amount,
				cancellation_fees_unit: this.settings.cancellation_fee_unit,
				cancellation_time_type: this.settings.cancellation_time_type,
				max_days: 1,
				max_time: (this.settings.cancellation_time_type && this.settings.cancellation_time_type == 'hour_before') ? (this.settings.cancellation_time) * 60 : this.settings.cancellation_time,
				pay_cancellation_fee_to_provider: this.settings.pay_cancellation_fee_to_provider,
				provider_cancellation_fees: this.settings.provider_cancellation_fees,
				provider_cancellation_fees_unit: this.settings.provider_cancellation_fees_unit
			};
			this.settings.multiple_cancellation_fee = [];
			this.settings.multiple_cancellation_fee.push(obj);
		}
		this.calCancellationFee();
	}
	calCancellationFee(): void {
		let cancArr: any = this.settings.multiple_cancellation_fee;
		if (cancArr && cancArr.length > 0) {
			for (let sett of cancArr) {
				let cancellationTs: any = this.generateCancellationTime(sett);
				let cancellationFee: any = this.cancellationFee(sett);
				let cancellationVar: any = this.cancellationTimeVar(sett);
				if (!this.cancellationDates.includes(cancellationTs)) {
					this.cancellationDates.push(cancellationTs);
				}
				this.cancellationFeeObj[cancellationTs] = {
					cancellation_fee: cancellationFee,
					cancellation_fee_unit: sett.cancellation_fees_unit,
					cancellation_val: sett.cancellation_fees,
					provider_cancellation_pay_status: (sett.pay_cancellation_fee_to_provider && sett.pay_cancellation_fee_to_provider == 'yes') ? true : false,
					provider_cancellation_pay: this.provCancellationPay(sett, cancellationFee),
					provider_pay_val: sett.provider_cancellation_fees,
					provider_pay_unit: sett.provider_cancellation_fees_unit,
					type: cancellationVar.type,
					days: cancellationVar.days,
					time: cancellationVar.time,
				}
			}
		}
		this.cancellationDates.sort().reverse();
		this.cancelFeeObj = this.getCancellationFeeBasedOnTime();
		if (this.cancelFeeObj) {
			this.cancelForm.controls['cancellation_fee'].setValue(this.cancelFeeObj.cancellation_fee);
		}
		this.cDRef.detectChanges();
	}
	generateCancellationTime(sett: any): any {
		let hours: any = Math.floor(+this.booking.start_time / 100);
		let minutes: any = +this.booking.start_time % 100;
		let cancellationTime: any;
		if (sett.cancellation_time_type == 'day_before') {
			let dayBeforeHours: number = 0;
			let dayBeforeMinutes: number = 0;
			if (sett.max_time > 0) {
				dayBeforeHours = Math.floor(+sett.max_time / 100);
				dayBeforeMinutes = +sett.max_time % 100;
			}
			let serviceDate: any = dayjs(this.booking.booking_date).subtract(sett.max_days, 'd').startOf('day');
			cancellationTime = serviceDate.add(dayBeforeHours, 'hours').add(dayBeforeMinutes, 'minutes').add(0, 'seconds').unix();
		} else {
			let bookingDate: any = this.utilServ.convertToTimestamp(this.utilServ.createDateObjLocal(this.booking.booking_date, hours, minutes));
			let hoursBeforeTime = (+sett.max_time) * 60;
			cancellationTime = bookingDate - hoursBeforeTime;
		}
		return cancellationTime;
	}
	cancellationTimeVar(sett: any): any {
		let obj: any = {
			type: sett.cancellation_time_type,
			days: sett.max_days
		};
		if (sett.cancellation_time_type == 'day_before') {
			obj['time'] = this.utilServ.convertTime24HFormat(this.getTimeStringFrom24HFormat(sett.max_time, 'hours') + ':00 ' + this.getTimeStringFrom24HFormat(sett.max_time, 'meridian'));
		} else {
			let hrs: any = 0;
			let min: any = 0;
			if (sett.max_time / 60 > 0) {
				hrs = Math.trunc(sett.max_time / 60);
			}
			if (sett.max_time % 60 > 0) {
				min = Math.round(sett.max_time % 60);
			}
			if (hrs && min) {
				obj['time'] = hrs + ' ' + this.translate.instant('Hours') + ' ' + min + ' ' + this.translate.instant('Minutes');
			} else {
				obj['time'] = hrs ? hrs + ' ' + this.translate.instant('Hours') : min + ' ' + this.translate.instant('Minutes');
			}
		}
		return obj;
	}
	provCancellationPay(sett: any, cancellationFee: any): any {
		if (sett && sett.pay_cancellation_fee_to_provider && sett.pay_cancellation_fee_to_provider == 'yes') {
			if (sett.provider_cancellation_fees_unit && sett.provider_cancellation_fees_unit == 'percentage') {
				if (cancellationFee && cancellationFee > 0) {
					return Math.round((((+cancellationFee) * (sett.provider_cancellation_fees)) / 100) * 100) / 100;
				}
			} else {
				return sett.provider_cancellation_fees;
			}
		}
		return 0;
	}
	getCancellationFeeBasedOnTime(): any {
		let currTime = Math.floor(Date.now() / 1000);
		if (this.cancellationDates && (this.cancellationDates).length > 0) {
			for (let ts of this.cancellationDates) {
				if (ts < currTime) {
					return this.cancellationFeeObj[ts];
				}
			}
		}
	}
	/**
	 * Function to get time string from 24 hours format.
	 */
	public getTimeStringFrom24HFormat(time: any, fieldName: any) {
		if (time > 0) {
			time = time / 100;
			if (time > 12) {
				if (fieldName == 'meridian') {
					return 'PM';
				}
				time = time - 12;
				return this.addPrefixZeroBeforeNum(time);
			} else {
				if (time == 12) {
					if (fieldName == 'meridian') {
						return 'PM';
					}
					return time.toString();
				} else {
					if (fieldName == 'meridian') {
						return 'AM';
					}
					if (time == 0) { time = 12 }
					return this.addPrefixZeroBeforeNum(time);
				}
			}
		} else {
			if (fieldName == 'meridian') {
				return 'AM';
			}
			return '12';
		}
	}
	/**
	 * Function to add prefix zero before number.
	 */
	public addPrefixZeroBeforeNum(val: any) {
		if (val >= 10) {
			return val.toString();
		} else {
			return ('0' + val).toString();
		}
	}
	private getCustRecBkngCancelOpt(): string[] {
		if(this.initServ.appAdmnStngs?.merchant_settings?.cancellation?.customer_recurring_booking_cancellation_options){
			return this.initServ.appAdmnStngs?.merchant_settings?.cancellation?.customer_recurring_booking_cancellation_options;
		}
		return ['single', 'upcoming'];
	}
	public cancelFromFirst(): void {
		this.loader.show();
		// Fetch booking data for the first occurrence from the API
		this.apiServ.callApiWithPathVariables('GET', 'RecurringScheduleFirstBkng', [this.booking._id]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.recurringScheduleFirstBkngRes(res));
	}
	/**
	 * Handles the response for fetching booking data for the first occurrence in a recurring schedule.
	 * @param {any} res - The response object from the 'BookingData' API for the first booking
	 */
	private recurringScheduleFirstBkngRes(res: any): void {
		this.loader.hide();
		if(this.apiServ.checkAPIRes(res)){
			// Extract the first booking data from the response
			let firstBkng: any = res.data;
			// Check if payment logs are available for the first booking
			if(this.utilServ.checkArrLength(firstBkng?.payment_log)){
				this.booking = firstBkng;
				this.cancel('recurring');
			}else{
				// If payment logs are not available, fetch payment logs for the first booking
				this.loader.show();
				this.apiServ.callApiWithPathVariables('GET', 'PaymentLogs', [firstBkng._id]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.firstBkngPaymentLogApiRes(res, firstBkng));
			}
		}
	}
	/**
	 * Handles the response for fetching payment logs for the first booking in a recurring schedule.
	 * @param {any} res - The response object from the 'PaymentLogs' API for the first booking
	 * @param {any} firstBkng - The first booking object for which payment logs are fetched
	 */
	private firstBkngPaymentLogApiRes(res: any, firstBkng: any): void {
		// Check the API response for validity and the availability of payment logs
		if(this.apiServ.checkAPIRes(res, false) && this.utilServ.checkArrLength(res?.data)){
			// If payment logs are available, update the first booking object with the payment logs
			firstBkng['payment_log'] = res.data;
		}
		this.booking = firstBkng;
		this.cancel('recurring');
		this.loader.hide();
	}
}
