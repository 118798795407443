<div [id]="secId" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" class="tjs-session-page py-100 tjs-bg-style position-relative" [class.embeded-container]="utilServ?.embedStatus" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
	<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="tjs-session__wrapper">
		<div class="container">
			<div class="tjs-section__title text-center mb-50" *ngIf="section?.title?.content || section?.sub_title?.content">
				<!-- Title -->
				<div class="tjs-section__heading mb-15" *ngIf="section?.title?.content">
					<h4 [id]="section?.title?.id" class="fw-bold display-6 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h4>
				</div>
				<!-- Sub title -->
				<div class="tjs-section__description" *ngIf="section?.sub_title?.content">
					<p [id]="section?.sub_title?.id" class="bk-sub-title" [innerHTML]="section?.sub_title?.content | safeHtml" ></p>
				</div>
			</div>
			<div class="row justify-content-center">
				<!-- Social -->
				<ng-container *ngIf="(socialKeys?.Facebook || socialKeys?.Google) && section?.social">
					<div class="col-12 col-sm-12 col-md-5">
						<div class="d-flex justify-content-center justify-content-md-end text-center text-md-start">
							<bk-session-social [data]="section?.social" openFrom="signup"></bk-session-social>
						</div>
					</div>
					<div class="col-12 py-25 py-md-0 col-md-1">
						<p class="tjs-session__border-style d-flex align-items-center justify-content-center h-100 w-100 position-relative mb-0">
							<!-- <span class="position-relative bg-white fw-bold fs-14 rounded-circle d-flex justify-content-center align-items-center">{{initServ?.appStr?.text?.or | translate}}</span> -->
						</p>
					</div>
				</ng-container>
				<!-- signup form -->
				<div class="col-12 col-md-6 col-lg-5" *ngIf="section?.form">
					<form [id]="section?.form?.id" class="tjs-session__form bk-form" [formGroup]="signupForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()" >
						<!-- Title -->
						<span [id]="section?.form?.title_id" class="d-inline-block mb-20" *ngIf="section?.form?.title" [innerHTML]="section?.form?.title | safeHtml"></span>
						<!-- First name -->
						<div class="mb-30" *ngIf="section?.form?.first_name">
							<input [id]="section?.form?.first_name_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['first_name']?.touched && f['first_name']?.errors}" [placeholder]="section?.form?.first_name?.placeholder" formControlName="first_name" type="text" (focusout)="detailsFocusOut()">
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'first_name'}"></ng-container>
						</div>
						<!-- Last name -->
						<div class="mb-30" *ngIf="section?.form?.last_name">
							<input [id]="section?.form?.last_name_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['last_name']?.touched && f['last_name']?.errors}" [placeholder]="section?.form?.last_name?.placeholder" formControlName="last_name" type="text" (focusout)="detailsFocusOut()">
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'last_name'}"></ng-container>
						</div>
						<!-- Email -->
						<div class="mb-30" *ngIf="section?.form?.email">
							<div class="tjs-form-icon">
								<input [id]="section?.form?.email_id" class="form-control bk-input ps-50" [ngClass]="{'is-invalid': f['email_id']?.touched && f['email_id']?.errors}" [placeholder]="section?.form?.email?.placeholder" formControlName="email_id" type="text" (focusout)="detailsFocusOut()">
								<span class="tjsicon-email tjs-icon"></span>
							</div>
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'email_id'}"></ng-container>
						</div>
						<!-- Password -->
						<div class="mb-30" *ngIf="section?.form?.password">
							<div class="tjs-form-icon">
								<input [id]="section?.form?.password_id" class="form-control bk-input ps-50" type="password" [ngClass]="{'is-invalid': f['password']?.touched && f['password']?.errors}" [placeholder]="section?.form?.password?.placeholder" formControlName="password">
								<span class="tjsicon-key tjs-icon"></span>
							</div>
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'password'}"></ng-container>
						</div>
						<!-- Confirm password -->
						<div class="mb-30" *ngIf="section?.form?.confirm_password">
							<div class="tjs-form-icon">
								<input [id]="section?.form?.confirm_password_id" class="form-control bk-input ps-50" type="password" [ngClass]="{'is-invalid': f['confirm_password']?.touched && f['confirm_password']?.errors}" [placeholder]="section?.form?.confirm_password?.placeholder" formControlName="confirm_password">
								<span class="tjsicon-key tjs-icon"></span>
							</div>
							<!-- Error template -->
							<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'confirm_password'}"></ng-container>
						</div>
						<!-- Hidden input -->
						<div class="d-none"><input type="text" class="form-control bk-input" formControlName="customer_name" /></div>
						<!-- Submit button -->
						<bk-navigate *ngIf="section?.form?.button" [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [innerHTML]="section?.form?.button?.content" essentialClass="mb-25 bk-form-btn" customClass="btn btn-primary border-transparent" [isSubmit]="true"></bk-navigate>
						<!-- Login link -->
						<div>
							<span *ngIf="section?.form?.login_link" [id]="section?.form?.login_link_id" class="me-5" [innerHTML]="section?.form?.login_link | safeHtml"></span>
							<a class="link--orange" *ngIf="section?.form?.login_redirect_link" [id]="section?.form?.login_redirect_link_id" [routerLink]="['/'+initServ.appDynamicRoutes['login']]" [queryParams]="utilServ?.embedStatus ? { embed: 'true'} : {}" [innerHTML]="section?.form?.login_redirect_link | translate | safeHtml"></a>
						</div>
						<!-- Cookies -->
						<div class="tjs-privacy-text mt-10 mb-20" *ngIf="section?.form?.cookie_content">
							<span class="bk-cookie fs-14" [id]="section?.form?.cookie_content_id" [innerHTML]="section?.form?.cookie_content | safeHtml"></span>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Form error template-->
<ng-template #errorTemp let-controlName="controlName">
	<ng-container *ngIf="f[controlName]?.touched && (f[controlName]?.errors?.required || f[controlName]?.errors?.pattern || f[controlName]?.errors?.minlength || f[controlName]?.errors?.matching || f[controlName]?.errors?.emailExists)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="f[controlName]?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.pattern"> {{(controlName == 'email_id') ? (initServ?.appStr?.errorMsg?.validEmail) : (initServ?.appStr?.errorMsg?.specialChars) | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.minlength"> {{initServ?.appStr?.errorMsg?.passwordLength | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.matching"> {{initServ?.appStr?.errorMsg?.passwordNotMatch | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.emailExists"> {{initServ?.appStr?.errorMsg?.signInEmailExists | translate}} </ng-container>
		</div>
	</ng-container>
</ng-template>
