import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, UtilServ } from 'src/app/Services';
@Component({
	selector: 'bk-time-picker',
	template: `
		<div class="tjs-date__picker position-relative">
			<input [format]="+utilServ.timeFormat" [min]="'01:01 am'" [ngxMatTimepicker]="timepicker" hidden>
			<input type="text" class="form-control tjs-pointer bk-input pe-50" placeholder="{{placeholder}}" [(ngModel)]="scheduleTime" [ngModelOptions]="{standalone: true}" (click)="timepicker.open()" readonly [attr.disabled]="isDisabled">
			<div class="tjs-group-icon h-100 position-absolute">
				<button *ngIf="resetBtnStatus && defaultTime && !isDisabled" type="button" id="basic-addon1" class="input-group-text bg-transparent border-0 position-absolute end-0 p-5-imp text-danger lh-1  fs-14 me-30 pe-auto" (click)="timeChanged.emit(null)"><i class="tjsicon-x-mark"></i></button>
				<button type="button" id="basic-addon1" class="input-group-text bg-transparent border-0 position-absolute end-0 p-0 pe-none fs-14"><i class="tjsicon-alarm-clock"></i></button>
			</div>
			<ngx-mat-timepicker #timepicker (timeSet)="setScheduleTime($event)" [defaultTime]="scheduleTime"  ></ngx-mat-timepicker>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class TimePickerComponent implements OnInit, OnChanges {
		// Variables receive data from the parent
	@Input() defaultTime: any;
	@Input() resetBtnStatus: boolean = false;
	@Input() isDisabled: boolean = false;
	@Output() timeChanged : EventEmitter<any> = new EventEmitter();
	// Variables
	placeholder: string = 'HH:MM AM';
	scheduleTime: any;
	changeByPicker: boolean = false;
	constructor(public utilServ: UtilServ) { }

	ngOnInit(): void {
		this.buildPicker();
	}
	/**
	 * Call on changes
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			// Loop on changes
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev && !this.changeByPicker){
						this.buildPicker();
					}
				}
			}
		}
	}
	/**
	 * Build the picker values
	 */
	private buildPicker(): void {
		if(this.utilServ.timeFormat == '24'){
			this.placeholder = 'HH:MM';
		}
		if(this.defaultTime){
			let timeArray = this.defaultTime.split(' ');
			let timeString = timeArray[0].split(':');
			let startHour = this.getHours(timeString[0],timeArray[1]);
			let startMin = timeString[1];
			let startMeridian = timeArray[1];
			if(this.utilServ.timeFormat == '24'){
				this.scheduleTime = startHour+':'+startMin;
			} else {
				this.scheduleTime = startHour+':'+startMin+' '+startMeridian;
			}
		}
	}
	/**
	 * Get the hours value
	 * @param hours
	 * @param meridian
	 * @returns
	 */
	private getHours(hours: any, meridian: any): any {
		let h: any;
		if (meridian == 'AM') {
			h = hours;
		} else {
			if(this.utilServ.timeFormat == '24'){
				h = (+hours)+12;
			} else {
				h = hours;
			}
		}
		return this.utilServ.addPrefixZeroBeforeNum(h);
	}
	/**
	 * Set the schedule time
	 */
	public setScheduleTime(event: string): void {
		let onlyTimeString = event.replace(/\u202f/g, " ");
		this.scheduleTime = onlyTimeString;
		this.changeByPicker = true;
		this.timeChanged.emit(this.scheduleTime);
	}
}
