<div class="position-relative" *ngIf="customFields && (customFields).length > 0 && secStatus">
	<ng-container *ngFor="let customField of customFields">
		<div id="custom-section-{{customField?._id}}" [ngClass]="{'pt-30 px-md-30 border-bottom bk-card-border':!isMultiStepForm}" *ngIf="stepType == 'all' || customField?.display_on == stepType || (!customField.display_on && stepType == 'step_two')">
			<!-- Heading -->
			<div class="mb-25" *ngIf="(!customField.is_individual || (customField.is_individual && customField.name_status)) && customField?.name">
				<h4 class="mb-0 bk-form-title">{{customField?.name | translate}}</h4>
			</div>
			<div class="d-flex flex-column cust-field-wrapper" [formGroup]="customFieldsGroup" *ngIf="customField && customField?.custom_fields && (customField?.custom_fields).length > 0">
				<ng-container *ngFor="let field of customField?.custom_fields">
					<ng-container *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]">
						<ng-container *ngIf="(!field.is_dependent || (field.is_dependent && (bkngCustSecServ.depFieldDisWhen).hasOwnProperty(customField?._id+'_'+field?._id) && ((bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].isCheckbox && checkDependentFieldStatusForCheckbox(customField, field)) || (!bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].isCheckbox && (bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].val).includes(customFieldsGroup.controls[bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].ctrl].value))))) && ((field.field_type == 'Priceable Input' && !field?.summary_only) || field.field_type != 'Priceable Input')">
							<ng-container [ngSwitch]="field?.field_type">
								<div id="custom-field-{{field?._id}}" class="mb-30" [style.order]="field.display_order">
									<!-- Text -->
									<ng-container *ngSwitchCase="'Text'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<input type="text" class="form-control bk-input" attr.placeholder="{{field?.placeholder | translate}}" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" [ngClass]="{'is-invalid': customFieldsGroup.controls[customField._id+'_'+field._id]?.touched && customFieldsGroup.controls[customField._id+'_'+field._id]?.errors}" [attr.disabled]="isDisabled">
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
									</ng-container>
									<!-- Text area -->
									<ng-container *ngSwitchCase="'Text Area'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<textarea class="form-control bk-form-textarea" rows="5" attr.placeholder="{{field?.placeholder | translate}}" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" [ngClass]="{'is-invalid': customFieldsGroup.controls[customField._id+'_'+field._id]?.touched && customFieldsGroup.controls[customField._id+'_'+field._id]?.errors}" [attr.disabled]="isDisabled">
										</textarea>
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
									</ng-container>
									<!-- Radio buttons -->
									<ng-container *ngSwitchCase="'Radio'">
										<ng-container *ngIf="field?.options && (field?.options).length > 0">
											<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
											<div class="d-flex flex-wrap row-gap-15">
												<div class="form-check form-check-inline" *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex; let i = index">
													<input id="{{customField?._id}}_{{field?._id}}_{{option}}" class="form-check-input" name="{{customField?._id}}_{{field?._id}}" formControlName="{{customField?._id}}_{{field?._id}}" type="radio" value="{{option}}" (change)="onHierarchicalFieldChange(customField?._id+'_'+field._id, option, field); changeValueForPriceable(customField?._id+'_'+field._id, option, field); onValChange(customField?._id+'_'+field._id, option)" [attr.disabled]="isDisabled">
													<label class="form-check-label bk-form-label" for="{{customField?._id}}_{{field?._id}}_{{option}}" translate>{{option}}</label>
												</div>
											</div>
											<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
											<!-- Message display -->
											<ng-container *ngIf="customFieldsGroup.controls[customField?._id+'_'+field._id] && customFieldsGroup.controls[customField?._id+'_'+field._id].value && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value].status && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value].type != 'popup'">
												<ng-container *ngTemplateOutlet="selMsg; context:{optData:bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value]}"></ng-container>
											</ng-container>
										</ng-container>
									</ng-container>
									<!-- Checkbox -->
									<ng-container *ngSwitchCase="'Checkbox'">
										<ng-container *ngIf="field?.options && (field?.options).length > 0">
											<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
											<ng-container formGroupName="{{customField?._id}}_{{field?._id}}">
												<div class="d-flex flex-wrap row-gap-15">
												<div class="form-check form-check-inline" *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex">
													<input id="{{customField?._id}}_{{field?._id}}_{{option}}" type="checkbox" formControlName="{{option | RmSpclCharAndSpace}}" class="form-check-input" (change)="bkngCustSecServ.custCheckBoxChange($event, customField?._id+'_'+field?._id, option, field); onHierarchicalCheckboxFieldChange($event, field, option); onValChange(customField?._id+'_'+field._id, option, $event);changeValueForPriceable(customField?._id+'_'+field._id, $event, field)" [attr.disabled]="isDisabled">
													<label class="form-check-label bk-form-label" for="{{customField?._id}}_{{field?._id}}_{{option}}" translate>{{option}}</label>
												</div>
											</div>
												<!-- Error message -->
												<div class="tjs-error bk-error" *ngIf="cfCheckboxGroup.controls[customField?._id+'_'+field?._id].touched && cfCheckboxGroup.controls[customField?._id+'_'+field?._id].errors">
													<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.required | translate}}
												</div>
												<!-- Checkbox message -->
												<ng-container *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex">
													<ng-container *ngIf="cfCheckboxGroup.controls[customField?._id+'_'+field._id] && cfCheckboxGroup.controls[customField?._id+'_'+field._id].value  && (cfCheckboxGroup.controls[customField?._id+'_'+field._id].value).includes(option | RmSpclCharAndSpace) && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][option] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][option].status && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][option].type != 'popup'">
													<ng-container *ngTemplateOutlet="selMsg; context:{optData:bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][option], optName: option}"></ng-container>
													</ng-container>
												</ng-container>
											</ng-container>
										</ng-container>
									</ng-container>
									<!-- Dropdown -->
									<ng-container *ngSwitchCase="'Dropdown'">
										<ng-container *ngIf="field?.options && (field?.options).length > 0">
											<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
											<select class="form-select" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" (change)="onHierarchicalFieldChange(customField?._id+'_'+field._id, $event, field); changeValueForPriceable(customField?._id+'_'+field._id, $event, field); onValChange(customField?._id+'_'+field._id, $event, null, true)" [attr.disabled]="(isDisabled || field.display_on == 'admin') ? true : null">
												<option value='' translate>Select Option</option>
												<ng-container *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex">
													<option value="{{option}}" translate>{{option}}</option>
												</ng-container>
											</select>
											<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
											<!-- Message display -->
											<ng-container *ngIf="customFieldsGroup.controls[customField?._id+'_'+field._id] && customFieldsGroup.controls[customField?._id+'_'+field._id].value && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value].status && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value].type != 'popup'">
												<ng-container *ngTemplateOutlet="selMsg; context:{optData:bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][customFieldsGroup.controls[customField?._id+'_'+field._id].value]}"></ng-container>
											</ng-container>
										</ng-container>
									</ng-container>
									<!-- Note -->
									<ng-container *ngSwitchCase="'Note'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field, hideTooltip:true}"></ng-container>
										<span class="bk-note bk-form-desc" [innerHTML]="field?.note_text | translate | safeHtml"></span>
									</ng-container>
									<!-- Price able Input -->
									<ng-container *ngSwitchCase="'Priceable Input'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<div class="input-group">
											<span bk-currency class="input-group-text"></span>
											<input formControlName="{{customField?._id}}_{{field?._id}}" type="text" class="form-control" placeholder="{{field?.placeholder | translate}}" [ngClass]="{'is-invalid': customFieldsGroup.controls[customField._id+'_'+field._id]?.touched && customFieldsGroup.controls[customField._id+'_'+field._id]?.errors}" (keypress)="utilServ.onlyNumbers($event, true)" (blur)="validateAmount(customField?._id+'_'+field?._id)" (focusout)="changeValueForPriceable(customField?._id+'_'+field._id, $event, field)" [readonly]="!field?.can_customer_edit" [attr.disabled]="isDisabled">
										</div>
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
									</ng-container>
									<!-- Datepicker -->
									<ng-container *ngSwitchCase="'Datepicker'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<bk-date-picker [secId]="customField?._id+'_'+field?._id" [isDisableFutureDates]="bkngCustSecServ.custFieldsObj[customField?._id+'_'+field?._id].disableFuture" [isDisabledPastDates]="bkngCustSecServ.custFieldsObj[customField?._id+'_'+field?._id].disablePast" [prefillDate]="customFieldsGroup.controls[customField?._id+'_'+field?._id].value" [isDisabled]="isDisabled" (dateChange)="onDateChange($event, customField?._id+'_'+field?._id)"></bk-date-picker>
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
									</ng-container>
									<!-- Timepicker -->
									<ng-container *ngSwitchCase="'Timepicker'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<bk-time-picker *ngIf="refreshTimepicker" [resetBtnStatus]="true" [defaultTime]="getTime(customFieldsGroup.controls[customField?._id+'_'+field?._id].value)" [isDisabled]="isDisabled" (timeChanged)="onTimeChange($event, customField?._id+'_'+field?._id)"></bk-time-picker>
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
									</ng-container>
									<!-- Quantity based -->
									<ng-container *ngSwitchCase="'Quantity Based'">
										<div class="tjs-extra mx-n10 flex-wrap d-flex">
											<div class="tjs-extra__list text-center px-10">
												<div class="border rounded-1 position-relative mb-5 bk-form-extra" [ngClass]="{'selected-extra': +customFieldsGroup.controls[customField?._id+'_'+field?._id].value && +customFieldsGroup.controls[customField?._id+'_'+field?._id].value > 0}">
													<label class="d-flex cursor-pointer justify-content-center align-items-center h-100 w-100 mb-0" for="{{customField?._id}}_{{field?._id}}">
														<!-- Checkbox -->
														<input id="{{customField?._id}}_{{field?._id}}" class="form-check-input bk-form-extra-input" type="checkbox" [attr.checked]="(+customFieldsGroup.controls[customField?._id+'_'+field?._id].value && +customFieldsGroup.controls[customField?._id+'_'+field?._id].value > 0) ? 'checked' : null" (change)="quantityBasedSelect($event, customField?._id+'_'+field?._id, field)" [attr.disabled]="isDisabled">
														<!-- Image -->
														<span class="tjs-extra__img m-auto">
															<img loading="auto" width="21" height="20" [src]="(field?.icon_url) ? initServ.imgBase+field?.icon_url : initServ?.img?.KoalaIcon" alt="extras" class="img-fluid" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
														</span>
														<!-- Count down -->
														<span class="card-img-overlay tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk-form-extra-overlay">
															<ng-container *ngIf="field?.quantity_details?.enable_quantity_based == 'yes'">
																<!-- Decrement -->
																<button class="tjs-extra__counter p-0 tjs-extra__decrement d-flex justify-content-center align-items-center border-0 text-white h-100 bk-form-extra-dec" type="button" (click)="countChange(customField?._id+'_'+field?._id, field, 'decrement')">-</button>
																<!-- Value -->
																<input class="tjs-extra__number text-center border-0 text-white bg-transparent bk-form-extra-desc" type="text" [value]="+customFieldsGroup.controls[customField?._id+'_'+field?._id].value" readonly>
																<!-- Increment -->
																<button class="tjs-extra__counter p-0 tjs-extra__increment bk-form-extra-inc d-flex justify-content-center align-items-center border-0 text-white h-100" type="button" (click)="countChange(customField?._id+'_'+field?._id, field, 'increment')">+</button>
															</ng-container>
														</span>
													</label>
												</div>
												<p class="fs-14 mb-0 bk-form-sub-label">
													<span translate>{{field?.name}}</span>
													<span *ngIf="bookingType == 'reschedule' && field?.status == 8" class="badge bg-light text-dark">{{'Archived' | translate}}</span>
													<!-- Tooltip -->
													<bk-tooltip *ngIf="field?.help_text" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="field?.help_text"></bk-tooltip>
												</p>
											</div>
										</div>
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
										<!-- Message display -->
										<ng-container *ngIf="customFieldsGroup.controls[customField?._id+'_'+field._id] && customFieldsGroup.controls[customField?._id+'_'+field._id].value && customFieldsGroup.controls[customField?._id+'_'+field._id].value > 0 && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][1] && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][1].status && bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][1].type != 'popup'">
											<ng-container *ngTemplateOutlet="selMsg; context:{optData:bkngCustSecServ.optSelDisplayMsg[customField?._id+'_'+field._id][1]}"></ng-container>
										</ng-container>
									</ng-container>
									<!-- Image -->
									<ng-container *ngSwitchCase="'Image'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<div>
											<img loading="auto" [src]="(field?.icon_url) ? initServ.imgBase+field?.icon_url : initServ?.img?.DeepCleaning" [alt]="field?.name" class="img-fluid-disabled bk-cust-img" (error)="utilServ.handleImgError($event, initServ?.img?.DeepCleaning)" [width]="field.media_width" [height]="field.media_height" />
										</div>
										<!-- img-fluid -->
									</ng-container>
									<!-- Video -->
									<ng-container *ngSwitchCase="'Video'">
										<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
										<div class="mt-20 bk-cust-video-wrap w-100" [ngSwitch]="field?.video_details?.video_type">
											<ng-container *ngSwitchCase="'youtube'">
												<iframe class="bk-video-player" [width]="field?.media_width" [height]="videosHeight[field?._id]" [src]="'https://www.youtube.com/embed/'+bkngCustSecServ.getYoutubeVideoId(field?.video_details?.video_url)+'?si=gNA-5XSzcu8XEJDd&amp' | safeHtml: 'resourceUrl'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
												<script [src]="embedJsUrl" defer></script></ng-container>
											<ng-container *ngSwitchCase="'vimeo'">
												<iframe class="bk-video-player" [src]="'https://player.vimeo.com/video/'+bkngCustSecServ.getVimeoVideoId(field?.video_details?.video_url)+'?h=a9a924c301' | safeHtml: 'resourceUrl'" [width]="field?.media_width" [height]="videosHeight[field?._id]" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
												<script src="https://player.vimeo.com/api/player.js"></script>
											</ng-container>
											<ng-container *ngSwitchCase="'custom'">
												<video class="bk-video-player" id="preview" [width]="field?.media_width" [height]="videosHeight[field?._id]" controls>
													<source [src]="initServ.imgBase+field?.video_details?.video_url" type="video/mp4">
												</video>
											</ng-container>
										</div>
									</ng-container>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>
	<!-- Loader -->
	<bk-app-loader [loaderId]="bkngCustSecServ.loaderId"></bk-app-loader>
</div>
<!-- Custom template -->
<ng-template #labelTemp let-field="field" let-hideTooltip="hideTooltip" >
	<label class="form-label d-block bk-form-label-clr">
		{{field.name | translate}}
		<span *ngIf="bookingType == 'reschedule' && field?.status == 8" class="badge bg-light text-dark">{{'Archived' | translate}}</span>
		<!-- Help text -->
		<ng-container *ngIf="field?.help_text && !hideTooltip">
			<bk-tooltip *ngIf="field?.display_help_text_as != 'description'" customClass="bk-tooltip" content="{{field?.help_text | translate}}"></bk-tooltip>
		</ng-container>
	</label>
	<!-- Help text -->
	<div class="mb-15" *ngIf="field?.help_text && !hideTooltip && field?.display_help_text_as && field?.display_help_text_as == 'description'">
		<small class="bk-form-desc">{{field?.help_text | translate}}</small>
	</div>
</ng-template>
<!-- Form error template -->
<ng-template #errorTemp let-customField="customField" let-field="field">
	<ng-container *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]?.touched">
		<!-- Required -->
		<div class="tjs-error bk-error" *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]?.errors?.required">
			<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.required | translate}}
		</div>
		<!-- Pattern -->
		<div class="tjs-error bk-error" *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]?.errors?.pattern">
			<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.specialChars | translate}}
		</div>
		<!-- Short text -->
		<div class="tjs-error bk-error" *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]?.errors?.shortTextValidationCheck">
			<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.shortMaxLength | translate}}
		</div>
		<!-- Long text -->
		<div class="tjs-error bk-error" *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]?.errors?.longTextValidationCheck">
			<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.longMaxLength | translate}}
		</div>
		<!-- Min quantity -->
		<div class="tjs-error bk-error" *ngIf="customFieldsGroup.controls[customField?._id+'_'+field?._id]?.errors?.minValueValidate">
			<i class="tjsicon-attention"></i>{{field?.quantity_details?.['min_quantity_notice_text'] | translate}}
		</div>
	</ng-container>
</ng-template>
<!-- Display message on option selection -->
<ng-template #selMsg let-optData="optData" let-optName="optName">
	<ng-container *ngIf="optData">
		<div class="mt-10 mb-0 {{optData.type != 'text' ? 'alert' : ''}}" [ngClass]="{'alert-info': optData.type == 'info', 'alert-warning': optData.type == 'warning', 'alert-danger': optData.type == 'error'}">
			<strong *ngIf="optName">{{optName | translate}} : </strong>
			<span [innerHTML]="optData.msg | translate | safeHtml"></span>
		</div>
	</ng-container>
</ng-template>
