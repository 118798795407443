<div class="position-relative">
	<div class="min-height--100" [ngClass]="isMultiStepForm ? 'my-20 bk-custom-card-bg' : 'pt-30 pb-15 px-md-30'" [id]="section?.id" [formGroup]="paymentForm" *ngIf="section">
		<!-- Title and description -->
		<bk-section-heading [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<!-- Radio buttons -->
		<div *ngIf="isCreditCard || isCash">
			<div class="form-check form-check-inline mb-15" *ngIf="(isCreditCard && customerGroup.controls['customer_type'].value == 'existing customer' && cards && cards.length > 0) || (bookingType != 'add' && prefilledData?.payment_method == 'existing_credit_card')">
				<input id="existing-card" class="form-check-input existing_credit_card" type="radio" value="existing_credit_card" formControlName="payment_method" (change)="cardTypeChange('existing_credit_card')" [attr.disabled]="isDisabled">
				<label class="form-check-label bk-form-check-label" for="existing-card" [id]="section?.existing_card_id" [innerHTML]="section?.existing_card | safeHtml"></label>
			</div>
			<div class="form-check form-check-inline mb-15" *ngIf="isCreditCard">
				<input id="new-card" class="form-check-input new_credit_card" type="radio" value="new_credit_card" formControlName="payment_method" (change)="cardTypeChange('new_credit_card')" [attr.disabled]="isDisabled">
				<label class="form-check-label bk-form-check-label" for="new-card" [id]="section?.new_card_id" [innerHTML]="section?.new_card | safeHtml"></label>
			</div>
			<div class="form-check form-check-inline mb-15" *ngIf="(bookingType == 'add' && isCash) || ((isCash && cashVisibleOnService) || (prefilledData?.payment_method == 'cash')) ">
				<input id="cash" class="form-check-input cash" type="radio" value="cash" formControlName="payment_method" (change)="cardTypeChange('cash')" [attr.disabled]="isDisabled">
				<label class="form-check-label bk-form-check-label" for="cash" [id]="section?.cash_id" [innerHTML]="section?.cash | safeHtml"></label>
			</div>
		</div>
		<ng-container [ngSwitch]="paymentForm.value['payment_method']">
			<ng-container *ngSwitchCase="'existing_credit_card'">
				<div class="mt-10 mb-15" *ngIf="cards && cards.length > 0">
					<label class="form-label bk-form-label text-capitalize" *ngIf="section?.saved_card" [id]="section?.saved_card_id" [innerHTML]="section?.saved_card | translate | safeHtml"></label>
					<select class="form-select" formControlName="pay_with_cc" [attr.disabled]="isDisabled" (change)="onCardChange($event)">
						<ng-container *ngFor="let card of cards;trackBy: utilServ.trackByFnIndex">
							<option *ngIf="card?.is_default; else notDefault" [value]="card?.id" selected>XXXX XXXX XXXX {{card?.last4}}</option>
							<ng-template #notDefault>
								<option [value]="card?.id">XXXX XXXX XXXX {{card?.last4}}</option>
							</ng-template>
						</ng-container>
					</select>
				</div>
			</ng-container>
			<div *ngSwitchCase="'new_credit_card'" class="mb-20">
				<div id="same_as_booking_address" class="mt-20 form-check" *ngIf="initServ.allowBillingAddr && this.addressGroup.controls['address'].value && this.paymentForm.controls['location_type'].value == 'SA'">
					<div class="custom-control tjs-checkbox custom-checkbox">
						<input id="billing_same_address" type="checkbox" [(ngModel)]="isSameAsBookingAddress" [ngModelOptions]=" {standalone: true}"  class="form-check-input" (change)="isSameAddressChange(isSameAsBookingAddress)">
						<label for="billing_same_address" class="form-check-label bk-form-check-label" translate [innerHTML]="section?.same_address?.label | safeHtml"></label>
						<bk-tooltip customClass="bk-form-check-label" content="{{section?.same_address?.tooltip | translate}}"></bk-tooltip>
					</div>
				</div>
				<bk-payment-gateway *ngIf="buildPaymentForm" id="payment_gateway" [locationId]="paymentForm.controls['base_location_id'].value" [baseLoc]="true" [section]="section" [isDynamic]="true" [billingAddr]="true" [billingAddrForm]="creditCardGroup"></bk-payment-gateway>
			</div>
		</ng-container>
		<!-- Update button(If card is declined) -->
		<bk-navigate [isDebounce]="true" *ngIf="bookingType != 'add' && (prefilledData?.status == 1 || prefilledData?.status == 4)" innerHTML="{{section?.update_btn?.content}}" customClass="btn btn-primary border-transparent" [secSett]="section?.update_btn" essentialClass="mt-10 bk-btn" (callback)="updatePayment()"></bk-navigate>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
