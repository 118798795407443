/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectionStrategy, Self, SimpleChanges, ChangeDetectorRef,ElementRef,ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs';
import KeenSlider from "keen-slider";
// Services
import { BkngFormServ, InitServ, LoaderServ, NgOnDestroy, UtilServ, PopupServ, BuildCustomSectionService } from '../../../Services';

@Component({
	selector: 'bk-items-with-addons',
	templateUrl: './ItemsWithAddons.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ItemsWithAddonsComponent implements OnInit {
	// Variables
	@ViewChild("itemSlide") itemSlideRef : ElementRef<HTMLElement> | undefined;
	@ViewChild("addonSlide") addonSlideRef : ElementRef<HTMLElement> | undefined;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() prefilledItems: any;
	@Input() itemsForm!: FormGroup;
	@Input() settings: any;
	@Input() availableSett: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Output() itemChange: EventEmitter<any> = new EventEmitter();
	@Output() itemParamChange: EventEmitter<any> = new EventEmitter();
	@Output() addonChange: EventEmitter<any> = new EventEmitter();
	@Output() itemAddonStatus: EventEmitter<boolean> = new EventEmitter();

	loaderId: string = 'bkng-items-with-addons';
	items: any;
	isDisabled: any;
	selectedItem: any;
	addons: any = {};
	selectedAddonIds : any = [];
	addonsName: any = {};
	itemsValues: any;
	isChange: boolean = false;
	addonSlider: any = null;
	itemSlider: any = null;

	public slideConfig = {
		breakpoints: {
			"(min-width: 451px)": {
				slides: { perView: 2 },
			},
			"(min-width: 768px)": {
				slides: { perView: 3 },
			},
		},
		slides: { perView: 1 },
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	}

	// eslint-disable-next-line max-params
	constructor(private loader: LoaderServ, public bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, private frmBldr: FormBuilder, private popupServ: PopupServ, @Self() private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, private buildCustSecServ: BuildCustomSectionService) {}

	ngOnInit(): void {
		if(!this.prefilledData || this.bookingType != 'add'){
			this.buildItems();
		}
		this.itemsValues = this.itemsForm.value['items'];
	}

	/**
	 * on changes only with if dependencies are change like location, service, frequency
	 * @param changes : auto detect
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.itemsValues = this.itemsForm.value['items'];
						this.buildItems();
						break;
					}
				}
			}
		}
	}
	/**
	 * ngOnChanges is not detect the changes, then ngDoCheck works
	 * Param change by list(user selection), build function is not working
	 * Like prefilled items take time etc
	 */
	ngDoCheck(): void {
		if(!this.isChange){
			let cur: any = JSON.stringify(this.itemsForm.value['items']);
			let prev: any = JSON.stringify(this.itemsValues);
			if(cur != prev){
				this.itemsValues = this.itemsForm.value['items'];
				if(this.prefilledData){
					this.buildItems();
				}
			}
		}
	}
	/**
	 * Build the items
	 */
	private buildItems(): void {
		this.loader.show(this.loaderId);
		this.items = [];
		this.addons = {};
		this.buildCustSecServ.availableElems['items'] = [];
		this.selectedItem = null;
		this.selectedAddonIds = [];
		if(this.settings && this.settings.items && (this.settings.items).length > 0){
			(this.settings.items).forEach((item: any, index: any) => {
				if(this.bkngFormServ.itemVisible(item, index, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledItems, this.availableSett)){
					item['display_name'] = this.bkngFormServ.getElementName(item, 'items');
					this.items.push(item);
					this.buildCustSecServ.availableElems['items'].push(item.id);
				}
			});
		}
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, false, 'items', this.isCustomerAllowedRes);
		let status: boolean = this.items.length > 0 ? true : false;
		this.itemAddonStatus.emit(status);
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
		setTimeout(()=>{
			if(this.itemSlideRef){
				if (this.itemSlider) {this.itemSlider.destroy();}
				this.itemSlider = new KeenSlider(this.itemSlideRef.nativeElement,this.slideConfig)
			}
			this.cDRef.detectChanges();
		})
	}
	/**
	 * Select the item and build the addons
	 * @param item: selected item
	 */
	public itemSelect(item: any): void {
		this.selectedItem = null;
		this.selectedAddonIds = [];
		if(this.bkngFormServ.itemsCanCombine(this.itemsForm.value)){
			if((item.combined_for_same_spot != 'no' || (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.bookings.multiple_appointments_per_spot != 'yes')) || (this.itemsForm.controls['items'].value).length == 0){
				this.selectedItem = item;
				// Selected parameter popup
				this.bkngFormServ.formParamMsgPopup(item);
				this.itemChange.emit(item.id);
				// Build addons
				this.buildItemAddons(this.selectedItem.id);
			} else{
				this.uncheckedItem(item);
				// this.bkngFormServ.msgPopup(this.initServ.appStr.msg.itemNotCombined);
				this.bkngFormServ.newMsgPopup('', true, 'item_not_combined');
			}
		} else {
			this.uncheckedItem(item);
			// this.bkngFormServ.msgPopup(this.initServ.appStr.msg.previousSelectedItemNotCombined);
			this.bkngFormServ.newMsgPopup('', true, 'previous_selected_item_not_combined');
		}
		//uncheck all addons
		let inputElms = document.querySelectorAll('.addon-checkbox');
		if(inputElms && inputElms.length > 0){
			inputElms.forEach((element:any) => element.checked = false);
		}
	}
	/**
	 * Unchecked the item field is case of else part
	 * @param item: selected item
	 */
	private uncheckedItem(item: any): void {
		var identifier = "item-"+item.id;
		const elem: any = document.getElementById(identifier) as HTMLInputElement;
		if(elem){
			elem.checked = false;
		}
	}
	/**
	 * Build the item addons
	 * @param itemId: item id
	 */
	private buildItemAddons(itemId: any): void {
		this.addons[itemId] = [];
		this.buildCustSecServ.availableElems['addons'] = [];
		if(this.settings && this.settings.addons && (this.settings.addons).length > 0){
			// let prefilledAddons: any = this.prefilledItems && this.prefilledItems[itemId];
			let prefilledAddons: any = this.getPrefilledAddon(itemId);
			for(let itemAddon of this.settings.addons){
				if(this.bkngFormServ.itemAddonVisible(itemAddon, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, itemId, prefilledAddons, this.availableSett)){
					this.addonsName[itemAddon.id] = {
						name:this.utilServ.replaceUnderscore(itemAddon.name)
					};
					(this.addons[itemId]).push(itemAddon);
					this.buildCustSecServ.availableElems['addons'].push(itemAddon.id);
				}
			}
		}
		let status: boolean = (this.addons[itemId]).length > 0 ? true : false;
		this.itemAddonStatus.emit(status);
		setTimeout(()=>{
			if(this.addonSlideRef){
				if (this.addonSlider) {this.addonSlider.destroy();}
				this.addonSlider = new KeenSlider(this.addonSlideRef.nativeElement,this.slideConfig)
			}
		},500);
		this.cDRef.detectChanges();
	}
	/**
	 * Get the item index in case of reschedule
	 * @param item: item
	 */
	private getPrefilledAddon(itemId: any): any {
		if(this.prefilledItems && (Object.keys(this.prefilledItems)).length > 0){
			for(let i in this.prefilledItems){
				if(this.prefilledItems[i] && this.prefilledItems[i][itemId]){
					return this.prefilledItems[i][itemId];
				}
			}
		}
	}
	/**
	 * Select the addons
	 * @param event: input event
	 * @param addon: selected addon
	 */
	public selectAddon(event: any,addon: any): void {
		if(event.target.checked){
			if(!(this.selectedAddonIds).includes(+addon.id) ){
				(this.selectedAddonIds).push(+addon.id);
			}
			// Selected parameter popup
			this.bkngFormServ.formParamMsgPopup(addon);
		} else {
			if((this.selectedAddonIds).includes(addon.id)){
				let index = (this.selectedAddonIds).indexOf(addon.id);
				if (index > -1) { (this.selectedAddonIds).splice(index, 1);}
			}
		}
	}
	/**
	 * Add item on click add button
	 */
	public addItem(): void {
		this.isChange = true;
		let itemsArray = <FormArray>this.itemsForm.controls['items'];
		let sameAddons: boolean = false;
		if(itemsArray.value && (itemsArray.value).length > 0){
			let oldAddonArray: any = [];
			let newAddonArray: any = this.selectedAddonIds;
			newAddonArray = newAddonArray.sort();
			(itemsArray.value).forEach((item: any, index: any) => {
				oldAddonArray = [];
				if(this.selectedItem && this.selectedItem.id == item.id){
					if(item.addons && (item.addons).length > 0){
						for(let addon of item.addons){
							oldAddonArray.push(addon.id);
						}
					}
					oldAddonArray = oldAddonArray.sort();
					let alreadyExist: any = (oldAddonArray.length == newAddonArray.length) && oldAddonArray.every(function(element: any, index: string | number) {return element === newAddonArray[index]; });
					if(alreadyExist){
						sameAddons = true;
						// Update quantity
						let addonGroup: any = (<FormGroup>(itemsArray).controls[index]);
						let itemEnableQuantity = addonGroup.controls['enable_quantity_based'].value;
						if(itemEnableQuantity == 'yes'){
							let addonControl: any = addonGroup.controls['quantity'];
							let itemMaxQuantity = addonGroup.controls['quantity_based'].value;
							if(addonControl.value < itemMaxQuantity ){
								(<FormGroup>(itemsArray).controls[index]).controls['quantity'].setValue(addonControl.value + 1);
							}
						}
					}
				}
			});
		}
		// Not same addon, then add a new control of item and addon
		if(!sameAddons){
			this.addItemControl();
		}
		this.selectedItem = null;
		this.selectedAddonIds = []
		this.itemParamChange.emit();
		// Unchecked the radio buttons
		let inputElms = document.querySelectorAll(`input[name="item"]`);
		if(inputElms && inputElms.length > 0){
			inputElms.forEach((element:any) => element.checked = false);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Add item control and addons array
	 */
	private addItemControl(): void {
		let itemsArray = <FormArray>this.itemsForm.controls['items'];
		if(this.selectedItem){
			let itemObj: any = {
				id: +this.selectedItem.id,
				name: this.selectedItem.name && this.utilServ.replaceUnderscore(this.selectedItem.name),
				quantity: 1,
				enable_quantity_based:  this.selectedItem.enable_quantity_based,
				quantity_based: this.selectedItem.quantity_based,
				can_combine: this.selectedItem.combined_for_same_spot,
				count_multiple_spots: this.selectedItem.qty_based_multiple_spots,
				addons: this.frmBldr.array([])
			};
			itemsArray.push(this.frmBldr.group(itemObj));
			(itemsArray.value).forEach((item: { id: any; }, index: any)=>{
				if(this.selectedItem && this.selectedItem.id == item.id && ((index+1) == (itemsArray.value).length)){
					let addonArray: any = <FormArray>(<FormGroup>itemsArray.controls[index]).controls['addons'];
					if(this.selectedAddonIds && this.selectedAddonIds.length > 0){
						for(let addonId of this.selectedAddonIds){
							let addonObject = this.frmBldr.group({
								id: addonId,
								name: this.addonsName[addonId]['name']
							});
							addonArray.push(addonObject);
						}
					}
				}
			});
		}
	}
	/**
	 * Update the item quantity
	 * @param index: item index
	 * @param item: selected item
	 * @param type: decrement/increment
	 */
	public itemCountChange(index: any, item: any, type: any): void {
		this.isChange = true;
		let itemsArray: any = <FormArray>this.itemsForm.controls['items'];
		let itemControl: any = <FormGroup>itemsArray.controls[index];
		let itemValue: any = itemControl.value;
		if(type == 'decrement'){
			let updatedQty: number = +(itemValue?.quantity) - 1;
			// if updatedQty is 0 then uncheck the selected item/addon combination
			if(updatedQty == 0){
				this.confirmPopup(index);
			}else{
				this.decQuantity(itemValue, itemControl);
			}
		} else {
			this.incQuantity(itemValue, item, itemControl);
		}
	}
	/**
	 * Increase quantity
	 * @param itemValue: item value
	 * @param item: selected item
	 * @param itemControl: item control
	 */
	private incQuantity(itemValue: any, item: any, itemControl: any): void{
		if(+(itemValue.quantity) < item?.quantity_based){
			itemControl.controls['quantity'].setValue(+(itemValue?.quantity) + 1);
			this.itemParamChange.emit();
		}
	}
	/**
	 * Decrease quantity
	 * @param itemValue: item value
	 * @param itemControl: item control
	 */
	private decQuantity(itemValue: any, itemControl: any): void {
		if(+(itemValue?.quantity) > 1){
			itemControl.controls['quantity'].setValue(+(itemValue?.quantity) - 1);
			this.itemParamChange.emit();
		}
	}
	/**
	 * Confirm popup for delete the selected item
	 * @param index
	 */
	public confirmPopup(index: any): void {
		let extraData: any = { index:index }
		this.popupServ.confirmPopup('delete_item').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res,extraData));
	}
	/**
	 * On popup result callback method
	 * @param res: popup res
	 * @param data: extra data
	 * Popup response handler
	 */
	private popupCallback(res:any,data: any = null): void {
		if(res){
			// Remove the item control from form
			let itemsArray: any = <FormArray>this.itemsForm.controls['items'];
			itemsArray.removeAt(data.index);
			this.itemParamChange.emit();
		}
	}

	ngOnDestroy():void {
		if (this.itemSlider) {this.itemSlider.destroy()}
		if (this.addonSlider){ this.addonSlider.destroy()}
	}
}
