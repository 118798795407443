import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from "rxjs/operators";
// Services
import { NgOnDestroy, ApiServ, LoaderServ, UtilServ, InitServ, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-gift-card-listing',
	templateUrl: './GiftCardListing.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class GiftCardListingComponent implements OnInit {
	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	pagePerm: boolean = true;
	isEdit: boolean = false;
	receivedLoaderId: string = 'received-gc-loader';
	sentLoaderId: string = 'sent-gc-loader';
	received: any = {
		data: null,
		limit: 20,
		page: 1,
		totalRecords: 0,
		headers: ['Sender name', 'Sender email', 'Code', 'Amount']
	};
	sent: any = {
		data: null,
		limit: 20,
		page: 1,
		totalRecords: 0,
		headers: ['Recipient name', 'Recipient email', 'Sent', 'Gift card amount']
	};
	//
	secId: any;
	section: any = { profile: null, sent_list: null, received_list: null };

	constructor(private router: Router, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, public initServ: InitServ, public rcServ: RenderComponentServ, public secServ: SectionServ) {
		this.pagePerm = this.utilServ.appPermission('giftCards'); // Page permission
		if (this.pagePerm) {
			this.giftCards('received'); // Received
			this.giftCards('sent'); // Sent
			// Edit gift card settings
			if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.customers && this.admnStngs.merchant_settings.customers.cust_edit_giftcard && this.admnStngs.merchant_settings.customers.cust_edit_giftcard == 'yes') {
				this.isEdit = true;
				this.sent.headers.push('Actions');
			}
		} else {
			// No page permission goto dashboard
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
		}
	}

	ngOnInit(): void {
		// building the section
		if (this.secId && this.rcServ.pageData) {
			let pageSett:any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
			// adding the table headers for sent gift cards section & receive gift card section.
			this.received.headers = [this.section?.received_list?.table?.name, this.section?.received_list?.table?.gc_email, this.section?.received_list?.table?.gc_code, this.section?.received_list?.table?.amount];
			this.sent.headers = (this.isEdit) ? [this.section?.sent_list?.table?.name, this.section?.sent_list?.table?.gc_email, this.section?.sent_list?.table?.date, this.section?.sent_list?.table?.amount, this.section?.sent_list?.table?.action] : [this.section?.sent_list?.table?.name, this.section?.sent_list?.table?.gc_email, this.section?.sent_list?.table?.date, this.section?.sent_list?.table?.amount];
		}
	}
	/**
	 * Get the gift cards
	 * @param type sent/received
	 */
	private giftCards(type: string): void {
		let loaderId = type == 'sent' ? this.sentLoaderId : this.receivedLoaderId;
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);
		let vm: any = this;
		let queryParams: any = { type: type, limit: vm[type].limit, page: vm[type].page };
		this.apiServ.callApiWithPathQueryVars('GET', 'UserGiftCards', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, type));
	}
	/**
	 * On result callback method
	 * @param res
	 * @param type
	 * API response handler
	 */
	private onResultCallback(res: any, type: string): void {
		let vm: any = this;
		let loaderId = type == 'sent' ? this.sentLoaderId : this.receivedLoaderId;
		if(this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)){
			vm[type].totalRecords = res?.data?.length;
			vm[type].data = [];
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, vm[type].data, vm[type].limit);
			vm[type].data = loaMoreData.items;
		}
		this.loader.hide(loaderId);
	}
	/**
	 * Get the page number, get the blogs based on page number
	 */
	public pageChange(event: any, type: string = 'received'): void {
		let vm: any = this;
		vm[type].page = event;
		this.giftCards(type);
	}
}
