<div class="position-relative">
	<div class="" [ngClass]="isMultiStepForm ? 'mb-10' : 'py-30 px-md-30 border-bottom bk-card-border'" [formGroup]="areaParamForm" [id]="section?.id" *ngIf="settings && section && areaParams && areaParams.length > 0">
		<!-- Title and description -->
		<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<div [formGroup]="areaParamGroup">
			<!-- Label -->
			<label [id]="section?.area_label_id" *ngIf="section?.area_label" class="form-label d-block bk-label" [innerHTML]="section?.area_label | safeHtml"></label>
			<div class="row">
				<div class="col-12 col-md-6 mb-20 mb-md-0">
					<input class="form-control" type="text" attr.placeholder="{{section?.area_placeholder}}" formControlName="quantity" (keypress)="utilServ.onlyNumbers($event)" (focusout)="areaValueChange()" [attr.disabled]="isDisabled" [ngClass]="{'is-invalid': areaParamGroup.controls['quantity'].hasError('required') && areaParamGroup.controls['quantity'].touched, 'form-control-lg': isMultiStepForm}">
					<!-- Error -->
					<div class="tjs-error bk-error" *ngIf="areaParamGroup.controls['quantity'].hasError('required') && areaParamGroup.controls['quantity'].touched">
						<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.areaSize | translate}}
					</div>
				</div>
				<div class="col-12 col-md-6">
					<ng-container *ngFor="let areaParam of areaParams;trackBy: utilServ.trackByFnIndex">
						<div class="d-flex align-items-center">
							<select class="form-select" [ngClass]="{'form-select-lg': isMultiStepForm}" [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}" (change)="changeAreaUnit($event, areaParam)" [attr.disabled]="isDisabled">
								<ng-container *ngIf="areaParam.value && (areaParam.value).length > 0">
									<ng-container *ngFor="let option of areaParam.value; let i=index;trackBy: utilServ.trackByFnIndex">
										<option [value]="option?.id" [selected]="option?.id == areaParam?.selected_value">
											{{utilServ.getFormParamName(option) | translate}}
											<span *ngIf="bookingType != 'add' && option.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
										</option>
									</ng-container>
								</ng-container>
							</select>
							<!-- Tooltip -->
							<bk-tooltip *ngIf="selectedParam && selectedParam?.show_tooltip_icon" customClass="bk-tooltip" [content]="selectedParam?.tooltip_text | translate"></bk-tooltip>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
