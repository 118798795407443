<div class="position-relative">
	<div class="tjs-package-selection" [ngClass]="isMultiStepForm ? 'mb-10' :'pt-30 pb-10 px-md-30 border-bottom pb-0 bk-card-border'" [formGroup]="itemsForm" [id]="section?.id" *ngIf="section && ((items && items.length > 0) || (packages && packages.length > 0))">
		<!-- Title and description -->
		<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<!-- Items -->
		<div id="item-section-id" *ngIf="items && items.length > 0; else onlyPackages">
			<!-- Label -->
			<label [id]="section?.sel_item_id" *ngIf="section?.sel_item" class="form-label bk-form-label" [innerHTML]="section?.sel_item | safeHtml"></label>
			<!-- Items -->
			<div id="vehicle_selection_area" class="tjs-extra mx-n10 flex-wrap d-flex" [ngClass]="isMultiStepForm ? 'tjs-extra--lg' :''">
				<div class="tjs-extra__list text-center mb-20 px-10" *ngFor="let item of items;let i=index;trackBy: utilServ.trackByFnIndex">
					<div class="border rounded-2 position-relative mb-5 bk-form-extra selected-extra--item"  [ngClass]="{'selected-extra': item?.id == selectedItem?.id}">
						<label for="item-{{item?.id}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
							<!-- Radio button -->
							<input id="item-{{item?.id}}" class="form-check-input bk-form-extra-input" type="radio" name="item" value="{{item?.id}}" (change)="itemSelect(item, i)" [attr.disabled]="isDisabled">
							<!-- Image -->
							<span class="tjs-extra__img m-auto">
								<img loading="auto" width="21" height="20" [src]="utilServ.getImgUrl(item?.icon_url)" class="img-fluid" alt="item" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
							</span>
							<!-- <span class="d-flex align-items-center justify-content-center vehicle-select-label-image position-absolute end-0 start-0 bottom-0 top-0" [ngClass]="{'select-label': item?.id == selectedItem?.id}">
							</span> -->
							<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk-form-extra-overlay">
							</span>
						</label>
					</div>
					<p class="fs-14 mb-0 bk-form-sub-label">
						<span class="text-capitalize">{{item?.display_name | translate}}</span>
						<span *ngIf="bookingType != 'add' && item?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
						<!-- Tooltip -->
						<bk-tooltip *ngIf="item?.show_tooltip_icon && item?.tooltip_text" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="item?.tooltip_text | translate"></bk-tooltip>
					</p>
				</div>
			</div>
			<!-- Packages based on selected item -->
			<div id="package-section-id" class="mt-10" *ngIf="selectedItem">
				<ng-container *ngTemplateOutlet="pckgSliderTemp; context:{type:'item'}"></ng-container>
			</div>
		</div>
		<!-- Only packages -->
		<ng-template #onlyPackages>
			<ng-container *ngTemplateOutlet="pckgSliderTemp; context:{type:'package'}"></ng-container>
		</ng-template>
	</div>
	<!-- Selected item/package section -->
	<div id="selected-items-section-scroll" *ngIf="itemsForm.value['items'] && (itemsForm.value['items']).length > 0">
		<div [ngClass]="isMultiStepForm ? 'mt-10' : 'pt-30 pb-10 px-md-30 border-bottom'" *ngIf="items && items.length > 0; else elsePackages">
			<!-- Selected item/package label -->
			<ng-container *ngTemplateOutlet="selectedPckgLabel"></ng-container>
			<div class="d-flex flex-column-reverse">
				<!-- Items -->
				<div class="card tjs-d-border mb-30 tjs-mb-first-0 bg-transparent overflow-hidden rounded bk-card" [ngClass]="{'border':isMultiStepForm}" *ngFor="let item of itemsForm.value['items']; let index = index;trackBy: utilServ.trackByFnIndex">
					<div class="card-body p-0 selected-item-wrap">
						<div class="px-20 pt-20 pb-15 tjs-bg-gray rounded-top bk-form-selected-package">
							<div class="d-flex justify-content-between">
								<h5 class="fw-bold mb-0 bk-form-selected-package-label text-capitalize">
									{{bkngFormServ.getElementName(item, 'items')  | translate}}
									<span *ngIf="bookingType != 'add' && bkngFormServ.statusOfSelectedItem(item?.id, availableSett)">{{initServ?.appStr?.text?.archived | translate}}</span>
								</h5>
								<button type="button" class="btn  p-0 tjs-btn-close bg-white text-danger tjs-transition--base rounded-circle fs-12" title="Delete" (click)="confirmPopup(index, 'item')" [attr.disabled]="isDisabled">
									<i class="tjsicon-close"></i>
								</button>
							</div>
						</div>
					</div>
					<!-- Packages & package addons-->
					<ng-container *ngIf="item.packages && (item.packages).length > 0">
						<div *ngFor="let package of item.packages; let pckgIndex = index;trackBy: utilServ.trackByFnIndex">
							<div class="d-sm-flex align-items-center justify-content-between px-20 pb-20 tjs-bg-gray bk-form-selected-package" [ngClass]="{'tjs-d-border__bottom':pckgAddons && pckgAddons[item?.id] && pckgAddons[item?.id][package?.id] && (pckgAddons[item?.id][package?.id]).length > 0}">
								<div class="mb-20 mb-sm-0 bk-form-selected-package-label">
									<span class="text-capitalize">{{bkngFormServ.getElementName(package, 'package') | translate}}</span>
									<span *ngIf="bookingType != 'add' && bkngFormServ.statusOfSelectedPackage(package?.id, availableSett)">{{initServ?.appStr?.text?.archived | translate}}</span>
								</div>
								<div class="tjs-pcounter d-flex rounded text-center" *ngIf="package?.enable_quantity_based == 'yes'">
									<button class="tjs-pcounter__btn border-0 p-0 rounded-left tjs-pcounter__btn-dec bk-form-extra-dec text-white fs-24" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'decrement')" [attr.disabled]="isDisabled">-</button>
									<input type="text" class="tjs-pcounter__number border-0 text-center" [value]="package?.quantity" readonly>
									<button class="tjs-pcounter__btn text-white fs-24 border-0 p-0 rounded-right tjs-pcounter__btn-inc bk-form-extra-inc" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'increment')" [attr.disabled]="isDisabled">+</button>
								</div>
							</div>
							<!-- Package addons -->
							<ng-container *ngTemplateOutlet="pckgAddonTemp; context:{item:item, index:index, package:package, pckgIndex:pckgIndex }"></ng-container>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="my-20">
				<bk-navigate [secId]="section?.add_another_item_btn?.id" [designId]="section?.add_another_item_btn?.design_id" innerHTML="<i class='tjsicon-plus me-10'></i>{{section?.add_another_item_btn?.content}}" customClass="btn btn-lg btn-primary border-transparent" essentialClass="w-100 d-flex align-items-center justify-content-center bk-btn" (callback)="utilServ.scrollToSpecificEle('item-section-id')"></bk-navigate>
			</div>
		</div>
		<!-- Only packages(No item case) -->
		<ng-template #elsePackages>
			<ng-container *ngIf="itemsForm.value['items'] && (itemsForm.value['items']).length > 0">
				<ng-container *ngFor="let item of itemsForm.value['items']; let index = index;trackBy: utilServ.trackByFnIndex">
					<div [ngClass]="isMultiStepForm ? 'mt-10' : 'py-30 px-md-30 border-bottom'" *ngIf="item?.packages && (item?.packages).length > 0">
						<!-- Selected item/package label -->
						<ng-container *ngTemplateOutlet="selectedPckgLabel"></ng-container>
						<div class="d-flex flex-column-reverse" *ngIf="item.packages && (item.packages).length > 0">
							<div class="card tjs-d-border mb-30 tjs-mb-first-0 bg-transparent overflow-hidden rounded" [ngClass]="{'border bk-custom-border':isMultiStepForm}" *ngFor="let package of item.packages; let pckgIndex = index;trackBy: utilServ.trackByFnIndex">
								<div class="card-body p-0 selected-item-wrap">
									<div class="px-20 pt-20 pb-15 tjs-bg-gray rounded-top bk-form-selected-package" [ngClass]="{'tjs-d-border__bottom':pckgAddons && pckgAddons[item?.id] && pckgAddons[item?.id][package?.id] && (pckgAddons[item?.id][package?.id]).length > 0}">
										<div class="d-flex justify-content-between">
											<!-- Package name -->
											<div>
												<h5 class="fw-bold mb-0 bk-form-selected-package-label"> {{bkngFormServ.getElementName(package, 'package') | translate}}</h5>
												<span *ngIf="bookingType != 'add' && bkngFormServ.statusOfSelectedPackage(package?.id, availableSett)">{{initServ?.appStr?.text?.archived | translate}}</span>
											</div>
											<!-- Delete and inc/dec count -->
											<div class="text-end">
												<button type="button" class="btn p-0 tjs-btn-close bg-white text-danger tjs-transition--base rounded-circle fs-12" data-placement="top" data-toggle="tooltip" title="Delete" (click)="confirmPopup(index, 'package', pckgIndex, item?.id, package?.id)" [attr.disabled]="isDisabled">
													<i class="tjsicon-close"></i>
												</button>
												<div class="tjs-pcounter d-flex rounded text-center overflow-hidden mt-15" *ngIf="package?.enable_quantity_based == 'yes'">
													<button class="tjs-pcounter__btn border-0 p-0 rounded-left tjs-pcounter__btn-dec bk-form-extra-dec" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'decrement')" [attr.disabled]="isDisabled">-</button>
													<input type="text" class="tjs-pcounter__number border-0 text-center" [value]="package?.quantity" readonly>
													<button class="tjs-pcounter__btn border-0 p-0 rounded-right tjs-pcounter__btn-inc  bk-form-extra-inc" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'increment')" [attr.disabled]="isDisabled">+</button>
												</div>
											</div>
										</div>
									</div>
									<!-- Package addons -->
									<ng-container *ngTemplateOutlet="pckgAddonTemp; context:{item:item, index:index, package:package, pckgIndex:pckgIndex }"></ng-container>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</ng-template>
	</div>
<!-- Loader -->
<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>

<!-- Common templates -->
<!-- Packages slider -->
<ng-template #pckgSliderTemp let-type="type">
	<!-- Label -->
	<div class="d-flex align-items-center justify-content-between mb-15">
		<div class="d-flex w-80 flex-wrap" *ngIf="section?.choose_pkg || (viewAllStatus && section?.choose_pkg_view_all)">
			<label [id]="section?.choose_pkg_id" *ngIf="section?.choose_pkg" class="mb-5 me-5 form-label bk-form-label" [innerHTML]="section?.choose_pkg | safeHtml"></label>
			<!-- View all button -->
			<div class="d-flex" *ngIf="viewAllStatus && section?.choose_pkg_view_all && isDisabled != 'disabled'">
				(<bk-navigate innerHTML="{{section?.choose_pkg_view_all}}" customClass="btn btn-link" essentialClass="tjs-secondary-active position-relative fw-bold p-0 bk-link" (callback)="packagesPopup()" [secId]="section?.choose_pkg_view_all_id" ></bk-navigate>)
			</div>
		</div>
		<div class="d-flex align-items-center pe-xl-50" *ngIf="enableArrow">
			<span (click)="slider.prev()" class="bk-form-slick-arrow fs-18 tjs-pointer text-dark me-10 slider-arrow"><i class="tjsicon-left-arrow"></i></span>
			<span (click)="slider.next()" class="bk-form-slick-arrow fs-18 tjs-pointer text-dark slider-arrow"><i class="tjsicon-right-arrow"></i></span>
		</div>
	</div>
	<div class="packages-select slider mb-20 position-relative">
		<ng-container *ngIf="packages && packages.length > 0; else noPackageForItem">
			<!-- Packages slider -->
			<div class="keen-slider packages-slide-wrapper bk-slider {{(isMultiStepForm && (packages.length <= 2)) ? 'justify-content-center':''}}" #pckgSlide [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'">
				<div class="keen-slider__slide packages-slide" *ngFor="let pckg of packages;trackBy: utilServ.trackByFnIndex">
					<div class="package-hover card border fw-normal bk-form-package bk-form-package-border">
						<div class="p-20 border-bottom bk-form-package-border">
							<div class="d-flex justify-content-center">
								<h5 class="text-capitalize fw-bold mb-0 text-center bk-form-package-label">
									<span>{{pckg?.display_name | translate}}</span>
									<span *ngIf="bookingType != 'add' && pckg?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
								</h5>
								<!-- Tooltip -->
								<bk-tooltip *ngIf="pckg?.show_tooltip_icon && pckg?.tooltip_text" customClass="bk-tooltip" [content]="pckg?.tooltip_text | translate"></bk-tooltip>
							</div>
						</div>
						<div class="card-body d-flex flex-column justify-content-between">
							<div class="mb-20">
								<!-- Image -->
								<div class="text-center mb-20">
									<img loading="auto" width="" height="" [src]="utilServ.getImgUrl(pckg?.icon_url)" alt="package" class="img-fluid m-auto bk-img" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
								</div>
								<div *ngIf="pckg?.description" class="tjs-html-content fs-14 bk-form-package-label" [innerHTML]="pckg?.description | translate | safeHtml"></div>
							</div>
							<!-- Add button -->
							<div *ngIf="section?.add_pkg_btn">
								<bk-navigate [secId]="section?.add_pkg_btn?.id" [designId]="section?.add_pkg_btn?.design_id" [innerHTML]="section?.add_pkg_btn?.content" essentialClass="bk-form-package-btn w-100" customClass="btn btn-outline-primary btn-lg" (callback)="packageSelect(pckg,type)"></bk-navigate>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #noPackageForItem>
			<h6 class="bk-form-label">{{initServ?.appStr?.text?.noPackageAvailable}}</h6>
		</ng-template>
		<!-- Loader -->
		<bk-app-loader [loaderId]="pckgLoaderId"></bk-app-loader>
	</div>
</ng-template>
<!-- Added packages label -->
<ng-template #selectedPckgLabel>
	<div class="mb-25" *ngIf="section?.added_pkg">
		<h4 [id]="section?.added_pkg_id" class="mb-0 bk-form-title" [innerHTML]="section?.added_pkg | safeHtml"></h4>
	</div>
</ng-template>
<!-- Package addons -->
<ng-template #pckgAddonTemp let-item="item" let-index="index" let-package="package" let-pckgIndex="pckgIndex">
	<div class="px-20 pt-20 tjs-d-border__top" *ngIf="pckgAddons && pckgAddons[index] && pckgAddons[index][item?.id] && pckgAddons[index][item?.id][package?.id] && (pckgAddons[index][item?.id][package?.id]).length > 0">
		<label [id]="section?.pkg_addons_id" *ngIf="section?.pkg_addons" class="mb-20 bk-form-label form-label" [innerHTML]="section?.pkg_addons | safeHtml"></label>
		<div class="tjs-extra mx-n10 flex-wrap d-flex">
			<div class="tjs-extra__list text-center mb-20 px-10" *ngFor="let pckgAddon of (pckgAddons[index][item?.id][package?.id]);trackBy: utilServ.trackByFnIndex">
				<div class="border rounded-2 position-relative mb-5 bk-form-extra" [ngClass]="{'selected-extra': prefilledItems && prefilledItems[index] && prefilledItems[index][item?.id] && prefilledItems[index][item?.id][package?.id] && prefilledItems[index][item?.id][package?.id][pckgAddon?.id]}">
					<label for="addon-{{item?.id}}-{{index}}-{{package?.id}}-{{pckgAddon?.id}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
						<!-- Checkbox -->
						<input id="addon-{{item?.id}}-{{index}}-{{package?.id}}-{{pckgAddon?.id}}" class="form-check-input bk-form-extra-input" type="checkbox" (change)="selectPckgAddon($event,index, item?.id, pckgIndex, package?.id, pckgAddon)" [attr.disabled]="isDisabled" [attr.checked]="(prefilledItems && prefilledItems[index] && prefilledItems[index][item?.id] && prefilledItems[index][item?.id][package?.id] && prefilledItems[index][item?.id][package?.id][pckgAddon?.id]) ? 'checked' : null">
						<!-- Image -->
						<span class="tjs-extra__img m-auto">
							<img loading="auto" width="21" height="20" [src]="initServ?.imgBase+pckgAddon?.icon_url" alt="package addon" class="img-fluid bk-img" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
						</span>
						<!-- Count down -->
						<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk-form-extra-overlay">
							<ng-container *ngIf="pckgAddon?.enable_quantity_based == 'yes' && (prefilledItems && prefilledItems[index] && prefilledItems[index][item?.id] && prefilledItems[index][item?.id][package?.id] && prefilledItems[index][item?.id][package?.id][pckgAddon?.id])">
								<!-- Decrement -->
								<button class="tjs-extra__counter tjs-transition--base p-0 tjs-extra__decrement bk-form-extra-dec d-flex justify-content-center align-items-center border-0 text-white h-100" type="button" (click)="pckgAddonCountChange(index,item?.id, pckgIndex, package?.id, pckgAddon, 'decrement')" [attr.disabled]="isDisabled">-</button>
								<!-- Value -->
								<input class="tjs-extra__number text-center border-0 tjs-transition--base text-white bg-transparent bk-form-extra-desc" type="text" [value]="(prefilledItems && prefilledItems[index] && prefilledItems[index][item?.id] && prefilledItems[index][item?.id][package?.id]) && (prefilledItems[index][item?.id][package?.id][pckgAddon?.id]?.quantity)" readonly [attr.disabled]="isDisabled">
								<!-- Increment -->
								<button class="tjs-extra__counter tjs-transition--base p-0 tjs-extra__increment bk-form-extra-inc d-flex justify-content-center align-items-center border-0 text-white h-100" type="button" (click)="pckgAddonCountChange(index,item?.id, pckgIndex, package?.id, pckgAddon, 'increment')" [attr.disabled]="isDisabled">+</button>
							</ng-container>
						</span>
					</label>
				</div>
				<p class="fs-14 mb-0 bk-form-sub-label">
					<span class="text-capitalize">{{utilServ.getFormParamName(pckgAddon) | translate}}</span>
					<span *ngIf="bookingType != 'add' && pckgAddon?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
					<!-- Tooltip -->
					<bk-tooltip *ngIf="pckgAddon?.show_tooltip_icon && pckgAddon?.tooltip_text" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="pckgAddon?.tooltip_text | translate"></bk-tooltip>
				</p>
			</div>
		</div>
	</div>
</ng-template>