import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, Self, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
// Services
import { ApiServ, InitServ, LoaderServ, NgOnDestroy, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-address',
	templateUrl: './Address.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AddressComponent implements OnInit {

	// Variables
	@Input() currentUser: any;
	@Input() isQuote : any;
	@Input() settings: any;
	@Input() bookingType: string = '';
	@Input() addressForm!: FormGroup
	@Input() prefilledData: any;
	@Input() locationLayout: any;
	@Input() section: any;
	@Input() custSection: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() locationType: any;
	@Input() isCustomerDetails: boolean = false;
	@Input() selectedLocation: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Input() zipcode: any;
	@Output() zipCodeChange: EventEmitter<any> = new EventEmitter();

	splitAddress: boolean = false;
	addressType: any;
	addresses: any;
	address: any;
	selectedAddress: any;
	isAddrDisabled: any;
	isRadioDisabled: any;
	isAddrReadonly: boolean = false;
	isShowZipcode: boolean = false;
	loaderId: string = 'bkng-address';

	// convenience getter for easy access to form fields
	get customerGroup(): any{
		return <FormGroup>this.addressForm.controls['customer'];
	}

	constructor(private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, public utilServ: UtilServ, public initServ: InitServ, private loader: LoaderServ) {
	}

	ngOnInit(): void {
		this.buildAddr();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildAddr();
						break;
					}
				}
			}
		}
	}
	/**
	 * Build the address component data
	 */
	private buildAddr(): void {
		this.loader.show(this.loaderId);
		// Address Details split parts
		if(this.custSection && (this.custSection.design_id == 'bk_customer_v2' || this.custSection.design_id == 'bk_customer_v4')){
			this.splitAddress = true;
		}
		if(this.bookingType != 'add'){
			if(this.locationType == 'SA'){
				this.addressType = "existing_address";
				this.userAddresses();
			}
		} else {
			// Existing customer
			if(this.customerGroup.controls['customer_type'].value == 'existing customer'){
				this.addressType = "existing_address";
				this.userAddresses();
			} else {
				this.addressType = "new_address";
				let formGroup: any = <FormGroup>this.addressForm.controls['address'];
				if(formGroup && formGroup.value['address']){
					this.address = formGroup.value['address'];
				}
			}
		}
		this.addressEditable();
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
	}

	/**
	 * Get the user addresses
	 */
	private userAddresses(): void {
		this.addresses = null;
		let userId: any = this.addressForm.controls['uid'].value;
		let queryParams: any={};
		let zipcode = this.addressForm.controls['zipcode'].value;
		if(this.bookingType == 'add' && zipcode){
			queryParams['zipcode'] = zipcode;
		}
		if(userId){
			this.apiServ.callApiWithPathQueryVars('GET', 'UserAddress', [userId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		}else{
			if(this.bookingType == 'add'){
				this.addressType = "new_address";
				this.resetAddress();
			}
		}
	}

	/**
	 * Get the address by 'Address' lib
	 * @param place
	 */
	 public getAddress(place: any): void {
		// Empty the latitude and longitude
		this.setLatlng();
		this.address = place.target.value;
		let formGroup: any = <FormGroup>this.addressForm.controls['address'];
		formGroup.controls['address'].setValue(this.address);
		if(!this.address){
			this.customerGroup.controls['address'].markAsTouched();
		}
		this.customerGroup.controls['address'].setValue(this.address);
		if(this.customerGroup.controls['apt'].value){
			formGroup.controls['apt'].setValue(this.customerGroup.controls['apt'].value);
		}
	}
	/**
	 * Create short address
	 * @param addr: Address
	 */
	private createShortAddr(addr: any): void {
		let shortAddr: any = this.utilServ.createShortAddr(addr);
		if(this.splitAddress){
			this.address = shortAddr;
		}
		this.customerGroup.controls['short_address'].setValue(shortAddr);
		let addrFormGroup: any = <FormGroup>this.addressForm.controls['address'];
		addrFormGroup.controls['short_address'].setValue(shortAddr);
	}
	/**
	 * Set the address: zipcode/city/state
	 * @param addr: Address
	 * @param type: zipcode/city/state
	 */
	 public setAddress(addr: any, type: string){
		let code: any;
		let addrFormGroup: any = <FormGroup>this.addressForm.controls['address'];
		switch(type){
			case 'zipcode':
				// Set the latitude and longitude
				this.setLatlng(addr);
				this.createShortAddr(addr);
				code = this.utilServ.getComponentByType(addr, 'postal_code');
				let zipcode: any = this.getLongAddr(code);
				addrFormGroup.controls['zipcode'].setValue(zipcode);
				this.customerGroup.controls['customer_zipcode'].setValue(zipcode);
				if(!zipcode && this.locationLayout && this.locationLayout != 'zipcode_based') {
					this.isShowZipcode = true;
					this.customerGroup.controls['customer_zipcode'].markAsUntouched();
					this.customerGroup.controls['customer_zipcode'].setValidators([Validators.required]);
				} else {
					this.isShowZipcode = false;
					this.customerGroup.controls['customer_zipcode'].markAsUntouched();
					this.customerGroup.controls['customer_zipcode'].clearValidators();
				}
				this.customerGroup.controls['customer_zipcode'].updateValueAndValidity();
				this.zipCodeChange.emit(zipcode);
			break;
			case 'city':
				code = this.utilServ.getComponentByType(addr, 'locality');
				let selectedCity: any = this.getLongAddr(code);
				addrFormGroup.controls['city'].setValue(selectedCity);
				this.customerGroup.controls['city'].setValue(selectedCity);
			break;
			case 'state':
				code = this.utilServ.getComponentByType(addr, 'administrative_area_level_1');
				let selectdState: any = this.getLongAddr(code)
				addrFormGroup.controls['state'].setValue(selectdState);
				this.customerGroup.controls['state'].setValue(selectdState);
			break;
		}
	}
	/**
	 * Get the address long name
	 * @param code: address
	 * @returns string
	 */
	private getLongAddr(code: any): any {
		let name: any = '';
		if(code && code.long_name){
			name = code.long_name;
		}
		return name;
	}
	/**
	 * Focus out address fields
	 * @param type: zipcode/city/state
	 */
	public focusOutAddr(type: string): void {
		let addrFormGroup: any = <FormGroup>this.addressForm.controls['address'];
		switch(type){
			case 'zipcode':
				let zipcode: any = this.customerGroup.controls['customer_zipcode'].value;
				addrFormGroup.controls['zipcode'].setValue(zipcode);
				this.zipCodeChange.emit(zipcode);
			break;
			case 'city':
				let city: any = this.customerGroup.controls['city'].value;
				addrFormGroup.controls['city'].setValue(city);
			break;
			case 'state':
				let state = this.customerGroup.controls['state'].value;
				addrFormGroup.controls['state'].setValue(state);
			break;
		}
	}
	/**
	 * Address editable
	 */
	private addressEditable(): void {
		this.isAddrDisabled = this.addressDisable();
		this.isRadioDisabled = this.addrRadioDisable();
	}
	/**
	 * Check the address disabled
	 * @returns any
	 */
	private addressDisable(): any{
		if(this.addressType != 'new_address' &&  this.prefilledData && ((this.prefilledData.status == 1 || this.prefilledData.status == 2 || this.prefilledData.status == 4) || (!this.addressForm.controls['zipcode'].value) && this.isCustomerAllowedRes == false)){
			this.isAddrReadonly = true;
			return 'disabled';
		} else if(this.addressType == "existing_address"){
			this.isAddrReadonly = true;
			return 'disabled';
		}
		this.isAddrReadonly = false;
		return null;
	}
	/**
	 * Disabled radio buttons and existing address select list
	 * @returns any
	 */
	private addrRadioDisable(): any {
		/** Executes for the reschedule booking. **/
		if(this.bookingType == 'reschedule' && this.prefilledData && (this.prefilledData.status == 1 || this.prefilledData.status == 2 || this.prefilledData.status == 4)){
			return 'disabled';
		}
		return null;
	}
	/**
	 * Set the selected address
	 * @param address: selected address
	 */
	public setSelectedAddr(address: any): void {
		if(address){
			this.customerGroup.patchValue({
				address_id: address.id,
				address: address.address,
				customer_zipcode: address.zipcode,
				state: address.state,
				city: address.city,
				apt: address.apt
			})
			let addressGroup: any = <FormGroup>this.addressForm.controls['address'];
			if(address.short_address){
				addressGroup.controls['short_address'].setValue(address.short_address);
				this.customerGroup.controls['short_address'].setValue(address.short_address);
			} else{
				addressGroup.controls['short_address'].setValue(address.address);
				this.customerGroup.controls['short_address'].setValue(address.address);
			}
			if(this.splitAddress && address.short_address){
				this.address = address.short_address;
			} else {
				this.address = address.address;
			}
			this.addressForm.controls['address_id'].setValue(address.id)
			addressGroup.patchValue({
				address: address.address,
				zipcode: address.zipcode,
				state: address.state,
				city: address.city,
				apt: address.apt
			});
			// Prefilled the latitude and longitude
			if(address.latlng){
				addressGroup.controls['latlng'].patchValue({
					lat: address.latlng.lat ? address.latlng.lat : 0,
					lng: address.latlng.lng ? address.latlng.lng : 0
				});
			}
			this.zipCodeChange.emit(address.zipcode);
		}
	}
	/**
	 * Reset the address all fields
	 */
	private resetAddress(): void{
		this.customerGroup.patchValue({
			address_id : null,
			address : null,
			customer_zipcode : null,
			state : null,
			city : null,
			apt : null,
			short_address : null
		});
		this.addressForm.controls['address_id'].setValue('');
		let addressGroup: any = <FormGroup>this.addressForm.controls['address'];
		addressGroup.patchValue({
			address: '',
			zipcode: '',
			state: '',
			city: '',
			apt: '',
			short_address: ''
		});
		// empty the latitude and longitude
		this.setLatlng();
		this.customerGroup.controls['address'].markAsUntouched();
		this.address = null;
		this.addressEditable();
	}
	/**
	 * Prefilled the address
	 */
	private prefilledAddr(): void {
		if(this.bookingType != 'add'){
			if(this.addresses && (this.addresses).length > 0){
				for(let address of this.addresses){
					if(address.id == this.prefilledData.address.id){
						this.selectedAddress = address;
						this.setSelectedAddr(this.selectedAddress);
						break;
					}
				}
			}
		} else {
			if(this.prefilledData && this.prefilledData.address && this.prefilledData.address.address){
				if(!this.prefilledData.address_id){
					this.addressType = "new_address";
					this.addressEditable();
					if(this.addresses && (this.addresses).length > 0){
						this.selectedAddress = this.addresses[0];
					}
				} else {
					if(this.addresses && (this.addresses).length > 0){
						for(let address of this.addresses){
							if((this.prefilledData.address_id && address.id == this.prefilledData.address_id) || (!this.prefilledData.address_id && address.address == this.prefilledData.address.address)){
								this.selectedAddress = address;
								this.setSelectedAddr(this.selectedAddress);
								break;
							}
						}
					}
				}
			} else {
				this.selectedAddress = this.addresses[0];
				this.setSelectedAddr(this.selectedAddress);
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Address type change
	 * @param type existing/new
	 */
	public addressTypeChange(type: string): void {
		this.isShowZipcode = false;
		this.customerGroup.controls['customer_zipcode'].markAsUntouched();
		this.customerGroup.controls['customer_zipcode'].clearValidators();
		this.customerGroup.controls['customer_zipcode'].updateValueAndValidity();
		if(type == 'existing'){
			this.setSelectedAddr(this.selectedAddress);
		} else{
			this.resetAddress();
		}
		this.addressEditable();
		this.cDRef.detectChanges();
	}

	/**
	 * On result callback method
	 * @param res API res
	 * @param type contacts/address
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res, false)) {
			if(res.data){
				this.addresses = res.data;
				this.prefilledAddr();
			} else {
				if(this.bookingType == 'add'){
					this.addressType = "new_address";
					this.resetAddress();
				}
			}
		}
		this.setQuoteAddress();
		this.cDRef.detectChanges();
	}
	/**
	 * Set the latitude and longitude
	 * @param address
	 */
	private setLatlng(address: any = null): void{
		let latitude = (address && address?.geometry && address?.geometry?.location) ? (address?.geometry?.location.lat()) : 0;
		let longitude = (address && address?.geometry && address?.geometry?.location) ? (address?.geometry?.location.lng()) : 0;
		let addressGroup = <FormGroup>this.addressForm.controls['address'];
		addressGroup.controls['latlng'].patchValue({
			lat: Number(latitude.toFixed(6)),
			lng: Number(longitude.toFixed(6))
		});
	}
	/**
	 * Set address in case of quote
	 */
	private setQuoteAddress(): void {
		setTimeout(()=>{
			if(this.isQuote && this.prefilledData && this.prefilledData?.address && this.prefilledData?.address){
				if(!this.prefilledData?.address_id || (this.prefilledData?.customer && this.prefilledData?.address_id && (this.prefilledData?.address_id != this.prefilledData?.customer?.address_id))){
					this.addressType = "new_address";
					this.addressTypeChange('new');
					this.setSelectedAddr(this.prefilledData?.address);
				}
			}
		}, 100)
	}
}
