/* eslint-disable complexity */
import { Component, OnInit, Input, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Router} from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// External lib
import * as sha512 from 'js-sha512';
import { ToastrService} from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, ApiServ, UtilServ, LoaderServ, SocialServ } from '../../Services';
// Constants
import { IS_DEV, DEV_HOST, AUTH_KEY } from '../../Constants';
//Referral popup
import { ReferralPopupComponent } from './Components/ReferralPopup/ReferralPopup.component';

@Component({
	selector: 'bk-thank-you',
	templateUrl: './ThankYou.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ThankYouComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	varId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		thank_you: null,
		form: null
	}
	variationClass: any = {
		bk_thank_you_V2 : "tjs-thankyou--v2",
		bk_thank_you_V3 : "tjs-thankyou--v3"
	}
	currentUser: any;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	userInfo: any = this.initServ.userInfo; // LoggedIn user info
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	firstName: string | undefined;
	email: string | undefined;
	phone: any;
	creditsForReferrar: any;
	defReferralAmt: any;
	overrideDesc: string = '';
	resetPass: boolean = false;
	referralUrl: string = '';
	fbAppId: string = '';
	showMsg: boolean = false;
	msgStatus: boolean = false;
	thankYouSett: any;
	loaderId: string = 'thank-you-loader';
	queryParams: any = null;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private actRoute: ActivatedRoute, public utilServ: UtilServ, private toastr: ToastrService, private router: Router, private loader: LoaderServ,private cDRef: ChangeDetectorRef,public socialServ: SocialServ, private dialog: MatDialog) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// Store query parameters
		if(this.actRoute.snapshot.queryParamMap){
			let queryParamMap: any = this.actRoute.snapshot.queryParamMap;
			if(queryParamMap.params && (Object.keys(queryParamMap.params)).length > 0){
				this.queryParams = queryParamMap.params
			}
		}
		if(this.queryParams){
			this.firstName = this.queryParams.first_name ? this.queryParams.first_name : '';
			this.email = this.queryParams.email ? this.queryParams.email : '';
			this.phone = this.queryParams.phone ? this.queryParams.phone : '';
		}
		if(this.admnStngs && this.admnStngs.merchant_settings){
			// Referral credits for referrar
			if(this.admnStngs.merchant_settings.admin) {
				if(this.admnStngs.merchant_settings.admin.referral_credits_for_referrar){
					this.creditsForReferrar = this.admnStngs.merchant_settings.admin.referral_credits_for_referrar;
				} else if(this.admnStngs.merchant_settings.admin.def_referral_amount){
					this.creditsForReferrar = this.admnStngs.merchant_settings.admin.def_referral_amount;
				}
				if(this.admnStngs.merchant_settings.admin.def_referral_amount){
					this.defReferralAmt = this.admnStngs.merchant_settings.admin.def_referral_amount;
				}
			}
			// Override description
			if(this.admnStngs.merchant_settings.bookings) {
				if (this.admnStngs.merchant_settings.bookings && this.admnStngs.merchant_settings.bookings.facebook_coupon_share_popup) {
					if (this.admnStngs.merchant_settings.bookings.referral_share_desc) {
						this.overrideDesc = this.admnStngs.merchant_settings.bookings.referral_share_desc;
					}
				}
			}
		}
		// Token is there and user not logged, reset password block true.
		if(this.queryParams && this.queryParams.token && this.queryParams.token != '' && !(this.currentUser && this.currentUser.id)){
			this.resetPass = true;
		}
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.varId = this.pageSett[this.secId].variation_id;
		this.secServ.setServData(this.pageSett, secData.content, [], [], 'thank_you');
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		// Code for variation 3
		if(this.varId == 'bk_thank_you_V3'){
			// App keys
			this.appSocialKeys();
			// Referral url
			if(this.queryParams && this.queryParams.ref_code){
				this.referralUrl = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/referral-signup/' + this.queryParams.ref_code;
			} else {
				// User logged in get the user info
				this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
					if(value){
						this.userInfo = this.initServ.userInfo;
						if(this.userInfo && this.userInfo.customer && this.userInfo.customer.refferal_code){
							this.referralUrl = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/referral-signup/' + this.userInfo.customer.refferal_code;
						}
					}
				});
			}
		}
		// Check show message status according to settings and occurrence on booking for varation 2 & 3
		if(this.varId == 'bk_thank_you_V2' || this.varId == 'bk_thank_you_V3' && (this.section && this.section.thank_you && this.section.thank_you.form)){
			this.thankYouSett = this.pageSett[this.section.thank_you.form_id];
			if(this.queryParams && this.queryParams.token){
				if(this.thankYouSett.show_msg_to_new){
					if(this.queryParams && this.queryParams.occurrence && this.queryParams.occurrence == 'onetime'){
						this.showMsg = (this.thankYouSett.show_msg_to_new_ot) ? true : false;
					} else {
						this.showMsg = (this.thankYouSett.show_msg_to_new_rec) ? true : false;
					}
				} else {
					this.showMsg = false;
				}
			} else {
				if(this.thankYouSett.show_msg_to_existing){
					if(this.queryParams && this.queryParams.occurrence && this.queryParams.occurrence == 'onetime'){
						this.showMsg = (this.thankYouSett.show_msg_to_existing_ot) ? true : false;
					} else {
						this.showMsg = (this.thankYouSett.show_msg_to_existing_rec) ? true : false;
					}
				} else {
					this.showMsg = false;
				}
			}
			// If its variation 2 and audience_id is null then load the default block, because this variation has dependency on campaign.
			if(this.varId == 'bk_thank_you_V2' && !this.thankYouSett.audience_id){
				this.showMsg = false;
			}
		}
		// If its variation 3 and user is not logged then load the default block, because this variation has dependency on user id.
		if(this.varId == 'bk_thank_you_V3' && !(this.currentUser && this.currentUser.id)){
			this.showMsg = false;
		}
		this.cDRef.detectChanges();
	}
	/**
	 * App social key, like Google, FB & Yahoo
	 */
	private appSocialKeys(): void {
		this.apiServ.callApi('GET', 'Addons').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'keys'));
	}
	/**
	 * Add contact to campaign
	 */
	public claim(): void {
		let sendData: any = {
			list_id: this.thankYouSett.audience_id,
			form_type: "thankyou",
			user_info: {}
		};
		if(this.thankYouSett.elements && (Object.keys(this.thankYouSett.elements)).length > 0){
			if(this.thankYouSett.elements.name && this.pageSett[this.thankYouSett.elements.name]){
				let lastName: string = (this.queryParams && this.queryParams.last_name) ? this.queryParams.last_name : '';
				sendData.user_info['first_name'] = this.firstName
				sendData.user_info['last_name'] =  lastName
				sendData.user_info['full_name'] = this.firstName+' '+lastName;
			}
			if(this.thankYouSett.elements.email && this.pageSett[this.thankYouSett.elements.email]){
				sendData.user_info['email_id'] = this.email;
			}
			if(this.thankYouSett.elements.phone && this.pageSett[this.thankYouSett.elements.phone]){
				sendData.user_info['phone_number'] = this.phone;
			}

			// Campaign contact
			let date =  Math.floor((new Date().getTime())/1000);
			let dateStr: string = date.toString();
			let businessName: string = '';
			if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.domain_name){
				businessName =  this.admnStngs.merchant_settings.store.domain_name;
			}
			let token: any = businessName + AUTH_KEY + dateStr;
			let tokenString: string = (sha512.sha512(token)+'_'+sha512.sha512(businessName)).toString();
			let header: any = { 'Content-Type': 'application/json','Auth-token' : tokenString, 'Timestamp' : dateStr};
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId);
			this.apiServ.callApi('POST', 'SystemFormContact', sendData, header).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'claim'));
		}
	}
	/**
	 * Open share via email/phone popup
	 */
	public shareRefPopup(): void {
		let ref: MatDialogRef<ReferralPopupComponent>;
		ref = this.dialog.open(ReferralPopupComponent);
		// ref.componentInstance.section = this.section;
		ref.componentInstance.radioCtrlSection = this.section?.thank_you?.form;
		ref.componentInstance.defReferralAmt = this.defReferralAmt;
		ref.componentInstance.thankYouSett = this.thankYouSett;
		ref.afterClosed();
	}
	/**
	 * Convert token into valid/readable string
	 * @param content actual content
	 * @returns Dynamic text string
	 */
	public tokenReplace(content: string): any {
		if(content){
			let str = this.utilServ.generateDynamicStr(content, '{{.ReferralAmountForReferrar}}', this.utilServ.amountWithCurrency(this.creditsForReferrar));
			return this.utilServ.generateDynamicStr(content, '{{.ReferralAmount}}', this.utilServ.amountWithCurrency(this.defReferralAmt), str);
		}
		return '';
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		switch(type) {
			case 'keys':
				if(this.apiServ.checkAPIRes(res)){
					let appKeys = res.data;
					if(appKeys && appKeys.length > 0){
						for(let keys of appKeys) {
							if(keys){
								if (keys.addon_type == 'facebook') {
									if ((keys.status && keys.status == 1) && (keys.details && keys.details.app_id)) {
										this.fbAppId = keys.details.app_id;
									}
								}
							}
						}
					}
					// Build FB script
					if(this.fbAppId){
						this.socialServ.fbInit(this.fbAppId);
					}
				}
			break;
			case 'claim':
				if(this.apiServ.checkAPIRes(res)){
					this.toastr.success(res.message);
					if(this.thankYouSett && this.thankYouSett.after_success_event){
						switch(this.thankYouSett.after_success_event) {
							case 'dashboard':
								if(this.currentUser){
									this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
								} else {
									if(this.queryParams && this.queryParams.token && this.queryParams.token != '' && this.queryParams.token != 'undefined'){
										this.router.navigate(['/'+this.initServ.appDynamicRoutes['reset-password']]);
									}
								}
							break;
							case 'custom_url':
								if(this.thankYouSett.redirect_url){
									window.location = this.thankYouSett.redirect_url;
								}
							break;
							case 'message':
								this.msgStatus = true;
							break;
						}
					}
				} else {
					if(res && res.message){
						this.toastr.error(res.message);
					}
				}
				this.loader.hide(this.loaderId);
			break;
		}
		this.cDRef.detectChanges();
	}
}
