import { PAYMENT_PROCESSOR_LINK } from '../Constants';

// Static strings used in "App"
export const STR: any = {
	tTip: {
		pwd: 'If this email is associated with you then enter the password to proceed otherwise please change the email id.',
		yourReferral: 'Here you can track every sent invite or successful referral.',
		totalInvites: 'The number of invitations sent but not yet accepted.',
		successfulReferrals: 'The number of referrals who have completed an appointment with us and you received credits for.',
		totalCreditEarned: 'Total credits that you earned from every single successful referral you ever invited and who completed an appointment with us.',
		creditsRemaining: 'Available credits that you have for spending.',
		receivedGiftCards: 'Any gift cards that were sent to you will be shown here.',
		sentGiftCards: 'Any gift cards that were sent by you will be shown here.',
		noGiftCardUpdate: 'This gift card is already in use for a booking or multiple bookings so it cannot be updated.',
		priceAdjustment: 'Price adjustment was made. You can view it inside the booking.',
		address: 'If you have multiple addresses that you need serviced you can add them in here and quickly change between them when making bookings.',
		billing: 'If you have multiple cards, you can add them here and switch between them when making or editing bookings.',
		invoices: 'All your invoices can be found here for any bookings or gift cards purchased.',
		imageTypes: 'Allowed types are jpeg, jpg, png and gif.',
		bookingReminder: 'Reminders about your upcoming appointments.',
		feedbackSurvey: 'Feedback request after every booking to help improve our company.',
		modifiedBookings: 'Modifications to your bookings for example; change in schedule, address change, payment method change.',
		referralAccepted: 'Confirmation letting you know someone booked with your referral code.',
		cancellations: 'Appointment has been cancelled/Confirmation of cancellation.',
		chargedFees: 'Any charged fees to your account.',
		smsNotifications: 'Turn of ALL SMS notifications.',
		cardChargedAfter: "By clicking 'Save Booking' you agree to our terms of service and privacy policy.",
		bkngFormZipcode: 'Please note your form will be reset if you switch to the other option.',
		paymentAdjustmet: 'You can see broken down summary of the payment logs here and final after adjustments',
		billingAddress: 'Authorize.net requires a billing address to securely store credit or debit card information.',
		billingSameAddresss: "Check the box next to this option if the booking address is the same as the credit/debit card's billing address. Otherwise, enter the card's billing address into the field below.",
		bkngLineItem:'The booking is either cancelled, postponed or its id is changed.',
		downloadPdf: 'Download PDF',
		bookingRemainingTtip: 'Booking remaining is a amount that is charged in case of card hold. When firstly card hold for some, after that some changes in booking that impact the price and charged that amount after completion of booking is called booking remaining amount',
		extraRemainingTtip: "Extra remaining is a amount that is charged in case of charged booking's amount increased and that extra amount is called extra remaining amount",
	},
	text: {
		yourLogo: 'YOUR LOGO',
		readMore: 'Read more',
		downloadProviderApp: 'Download the BookingKoala Provider app to check the schedule on the go.',
		checkBookingDetails: 'Check booking details.',
		timeTracking: 'Time tracking on the bookings.',
		manageScheduleSettings: 'Manage schedule and settings.',
		receiveAppReminders: 'Receive app reminders.',
		youArePoweredByBK: 'You Are Powered By BookingKoala',
		anAwardedBookingPlatform: 'An Awarded Booking Platform',
		onlinePricing: 'Online Pricing',
		onlineBooking: 'Online Booking',
		onlineManagement: 'Online Management',
		citiesNotFound: 'Cities not found.',
		availablePositionsNotFound: 'Available positions not found.',
		jobParHour: '$13/hour',
		paidWeekly: 'Paid Weekly',
		lotsOfBenefits: 'Lots Of Benefits',
		applyNow: 'Apply Now',
		published: 'Published',
		comment: 'Comment',
		by: 'By',
		SearchByTitle: 'Search by title',
		noBlogFound: 'No blog found.',
		category: 'Category',
		author: 'Author',
		comments: 'Comments',
		a: 'A',
		reply: 'Reply',
		leaveAComment: 'Leave A Comment',
		firstName: 'First name',
		lastName: 'Last name',
		email: 'Email',
		submit: 'Submit',
		or:'OR',
		signUp: 'Sign Up',
		logIn: 'Log In',
		login: 'Login',
		password: 'Password',
		enter: 'Enter',
		minAmount: 'Minimum amount',
		forgotPwd: 'Forgot Password?',
		next: 'Next',
		to: 'To',
		from: 'From',
		message: 'Message',
		existingCreditCards: 'Existing credit cards',
		newCreditCards: 'New credit cards',
		yourSavedCard: 'Your saved card',
		stripeLoader: 'Please wait while we are building stripe form for you.',
		addNewCard: 'Add new card',
		noPaymentProcessor : `Your payment processor has not yet been connected. Please connect it to allow bookings via credit/debit cards. <a href='${PAYMENT_PROCESSOR_LINK}' target='_blank'>Click here</a> to learn more.`,
		purchase: 'Purchase',
		back: 'Back',
		squareLoader: 'Please wait while we are building square form for you.',
		noRerrals: 'Send more referrals using above form.',
		totalInvites: 'Total Invites',
		successfulReferrals: 'Successful Referrals',
		totalCreditEarned: 'Total Credit Earned',
		balance: 'Balance',
		gaveYou: 'gave you',
		forYourFistBooking: 'for your 1st booking',
		claimYour: 'Claim your',
		referralCouponCode: 'referral coupon code',
		youWillGet: 'You will get',
		referralPlusfriends: 'per referral + your friend will get',
		joinWith:'Join with me and save ',
		withMyLink: 'with my link!',
		imageSize: 'Image size should not be more than 300px by 300px.',
		reward: 'reward',
		get: 'Get',
		friendBookingWithUs:'every time a new friend completes a booking with us',
		yourInfoBilling:'Your Info & Billing',
		dashboard: 'Dashboard',
		myDrive: 'My Drive',
		notifications: 'Notifications',
		deactivateAccount: 'Deactivate Account',
		faqSupport: 'FAQ/Support',
		logout: 'Logout',
		underMaintenance: 'We are currently under maintenance. Please come back later.',
		bookingSummary: 'Booking Summary',
		total:"Total",
		undergoingMaintenance: "This option is currently undergoing maintenance.",
		done: "Done",
		yourLocations: 'Your locations',
		storeLocations: 'Store locations',
		findLocations: 'Find Locations',
		chooseAnotherLocation: 'Choose Another Location',
		archived: '(Archived)',
		notifyMe: 'Notify me if the job requires more time.',
		noPackageAvailable: 'No package available for item',
		hours: 'Hours',
		minutes: 'Minutes',
		selectOption: 'Select Option',
		useExisting: 'Use Existing',
		newAddress: 'New Address',
		viewOtherContact: 'View Other Contact(s)',
		addOtherContact: 'Add other contact',
		acceptTC: 'Accept terms and conditions',
		saveBkng: "By clicking 'Save Booking' you agree to our terms of service and privacy policy.",
		allowReschedule: "Allow Reschedule",
		update: 'Update',
		saveChanges: 'Save Changes',
		resume: 'Resume',
		discardChanges: 'Discard Changes',
		downloadInvoice: 'Download Invoice',
		paymentAdjustment: 'Payment Adjustment',
		totalAmount: 'Total Amount',
		refunded: 'Refunded',
		fullRefund: 'Full refund',
		partialRefund: 'Partial refund',
		amount: 'Amount',
		tax: 'Tax',
		tip: 'Tip',
		additionalCharge: 'Additional Charge',
		bookingInitialChargedAmount: 'Booking Initial Amount',
		bookingRemainingAmount: 'Booking Remaining',
		extraRemainingAmount: 'Extra Remaining',
		cancellingAfter1stAppointment: 'Cancelling After 1st Appointment',
		totalAfterAdjustment: 'Total After Adjustment',
		explainerVideo: 'Explainer video',
		ratings: 'Ratings',
		updateRatings: 'Update Ratings',
		updateReview: 'Update Review',
		yourFeedback: 'Your feedback (optional)',
		shareOnFacebook: 'Share On Facebook',
		congratulations: 'Congratulations!',
		yourCouponIsHere: 'Your Coupon Is Here.',
		youCanNowUseItDuringCheckout: 'You can now use it during checkout.',
		couponCode: 'Coupon Code',
		sms_notification:'Send me reminders about my booking via text message',
		sms_reminder:'Send me reminders via text message',
		noGiftcardInvFound: 'No gift card invoice found.',
		cardAdded: 'Card has been added successfully.'
	},
	msg: {
		duplicate: 'Are you sure you want to duplicate this booking?',
		tip: 'To add tip, please contact merchant.',
		default: 'Are you sure you want to make it default?',
		delete: 'Are you sure you want to delete?',
		disabled: 'Are you sure you want to disable?',
		enabled: 'Are you sure you want to enable?',
		itemDelete: 'Are you sure you want to delete this item?',
		packageDelete: 'Are you sure you want to delete this package?',
		alreadyPackage: 'package is already in your selected list',
		packageNotCombined: 'This package cannot combined with other packages, please remove other packages to select this.',
		previousSelectedPckgNotCombined: 'Previous selected packages are not allowed to combine with this package.',
		itemNotCombined: 'This item cannot combined with other items, please remove other items to select this.',
		previousSelectedItemNotCombined: 'Previous selected items are not allowed to combine with this item.',
		accessAddPayment: 'Oops! It seems the payment method is currently unavailable. Please contact support for assistance with adding your payment details.',
		addPaymentTokenExpired: 'Oops! It seems the add payment token is expired. Please contact support for assistance with adding your payment details.'
	},
	successMsg: {

	},
	errorMsg: {
		empty: 'This field should not be empty.',
		validEmail: 'Please enter a valid email.',
		specialChars: 'Special characters are not allowed.',
		specialCharsExceptSpace: 'Special characters are not allowed except space.',
		validPassword: 'Please enter a valid password.',
		signInEmailExists: 'This email already exists. Please sign into your account or try a different email.',
		passwordLength: 'Password should be at least 6 characters long.',
		passwordNotMatch: 'Passwords do not match!',
		giftCardAmount: 'Please enter a valid amount. Amount should be at least',
		recipientNameValid: 'Please enter a valid recipient name.',
		recipientEmailValid: 'Please enter a valid recipient email address.',
		firstNameValid: 'Please enter a valid first name.',
		lastNameValid: 'Please enter a valid last name.',
		senderEmailValid: 'Please enter a valid sender email address.',
		validPhone: 'Use a valid phone number.',
		noReceivedGiftCard: 'No gift card has been received yet.',
		noSentGiftCard: 'No gift card has been sent yet.',
		emptyRating: 'Rating should not be empty.',
		noDrive: 'You have not uploaded any file yet.',
		emailAlreadyExist: 'This email already exists. Please try with a different email.',
		validPhoneNo: 'Please enter a valid phone number.',
		phoneAlreadyExist: 'Phone number already exists. Please try again with a different phone number.',
		noOtherContacts: 'No profile found.',
		noEmailNotif: 'Email notification not found.',
		noAppNotif: 'App notification not found.',
		noLeadForm: 'We are currently under maintenance. Please come back later.',
		required: 'This field is required.',
		areaSize: 'Please fill the area size.',
		emailExistWithPwd: 'This email already exists. Please enter your password below to continue or try a different email.',
		enterAddr: 'Please enter address.',
		enterZipcode: 'Please enter zipcode/postal code.',
		emptyNote: 'Note is required.',
		validAmount:'Amount should be greater than 0.',
		cvvNum: 'Please enter a valid cvv number.',
		acceptTc: 'Please accept the terms and conditions',
		shortMaxLength: 'Exceeded maximum character limit of 200.',
		longMaxLength: 'Exceeded maximum character limit of 500.',
		extraLongMaxLength: 'Exceeded maximum character limit of 10000.',
		invScheduled:'Your invoice has been added but has not been scheduled for payment. Therefore, you are not yet able to see it.',
		otpLength: 'OTP code should be at least 6 characters long.',
		invVoid:'Your invoice has been voided. Therefore, you are not yet able to see it.',
		maxAmtRange: 'The maximum price should not exceed 1000000.',
		noReceivedInv: 'No invoice has been received yet.',
	},
	toastr:{
		loggedIn: 'Login Successful',
		signUp: 'Successfully Sign Up.',
		enteredWrongPwd: 'You have entered the wrong password, Please try again.',
		enterPwd: 'Please enter the password first.',
		sendGiftCardEmail: 'You can not send the gift card to yourself. Please change one of the emails.',
		paymentGateError: 'There is something wrong, please try again later.',
		giftCardSuccess: 'The gift card has been sent successfully.',
		refEmailError: 'Please enter a valid email: ',
		emptyInput: 'This field should not be empty.',
		postponeDate: 'Please choose a postpone date & time.',
		fileDownload: 'File downloaded successfully.',
		imgUploadError: 'Error while image upload, please try again.',
		duplicateEmails: 'Please remove duplicate emails.',
		duplicatePhones: 'Please remove duplicate phone numbers.',
		leadSuccess:'Thank you. We will be in touch shortly.',
		selectLoc: 'Please select a location.',
		mediaDownload: 'Media downloaded successfully.',
	}

}
