/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation, Self, SimpleChanges, ViewChild, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs';
// External lib
import { ToastrService} from 'ngx-toastr';
// Child component, Custom validator
import { PaymentGatewayComponent } from '../../GlobalDefault';
// Services
import { ApiServ, BkngFrmCmnFunServ, InitServ, LoaderServ, NgOnDestroy, PaymentGatewayPayload, PaymentGatewayServ, UtilServ } from '../../../Services';
// Interface
import { BillingAddress, BillingCard } from 'src/app/Interfaces';

@Component({
	selector: 'bk-payment-info',
	templateUrl: './PaymentInfo.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PaymentInfoComponent implements OnInit, OnChanges {
	// Variables
	@Input() currentUser: any;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() paymentForm!: FormGroup;
	@Input() prefilledData: any;
	@Input() selectedServiceType: any;
	@Input() isMultiStepForm: any;
	@Input() selectedLocation: any;
	@Input() pageSett: any;
	@Input() locationLayout: any;
	@Output() resetIsZipode : EventEmitter<any> = new EventEmitter();

	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined; //Payment gateway component

	cards: any;
	isCreditCard: boolean = false;
	isCash: boolean = false;
	isDisabled: any;
	cashVisibleOnService: boolean = false;
	loaderId: string = 'bkng-payment';
	buildPaymentForm: boolean = false;
	// addresses : any;
	isSameAsBookingAddress : boolean = false;

	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private loader: LoaderServ, private toastr: ToastrService, public initServ: InitServ, private cDRef: ChangeDetectorRef, private paymentServ: PaymentGatewayServ, public bkngCmnFun: BkngFrmCmnFunServ) {
		this.isCreditCard = this.utilServ.visiblePaymentMethod('credit_or_debit_card');
		this.isCash = this.utilServ.visiblePaymentMethod('cash');
	}

	ngOnInit(): void {
		this.buildPayment();
		/** function to call on changes of address every time **/
		this.addressGroup.controls['address'].valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(() => {
			this.isSameAsBookingAddress = false;
		});
		// TODO: Anupam, remove this method after 2months goes to live. Add Bugsnag inside it.
		this.loadDefaultElemJson();
	}
	// convenience getter for easy access to form fields
	get customerGroup(): any{
		return <FormGroup>this.paymentForm.controls['customer'];
	}
	get addressGroup(): any{
		return <FormGroup>this.paymentForm.controls['address'];
	}
	get creditCardGroup(): FormGroup {
		return <FormGroup>this.paymentForm.controls['credit_card'];
	}
	// convenience getter for easy access to form fields
	get billingAddrGroup(): FormGroup{
		return <FormGroup>this.creditCardGroup.controls['billing_address'];
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange && (propName == 'currentUser' || propName == 'selectedLocation')) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildPayment();
						break;
					}
				}
			}
		}
	}
	/**
	 * Build payment block
	 */
	private buildPayment(): void {
		this.buildPaymentForm = false;
		this.loader.show(this.loaderId);
		if(this.bookingType == 'add'){
			if(this.customerGroup.controls['customer_type'].value == 'existing customer' && this.isCreditCard){
				this.userCards();
			} else{
				if(this.isCreditCard){
					this.paymentForm.controls['payment_method'].setValue('new_credit_card');
					this.cardTypeChange('new_credit_card');
				} else if(this.isCash){
					this.paymentForm.controls['payment_method'].setValue('cash');
					this.cardTypeChange('cash');
				}
			}
		} else {
			this.userCards();
		}
		// No used this api on payment info component
		// this.userAddress();
		// Disabled sections
		this.isDisabled = this.disabledParamScope();
		// Cash visibility on service category
		this.cashVisibleOnService = this.cashVisibleForService();
		setTimeout(() => {
			this.buildPaymentForm = true;
			this.cDRef.detectChanges();
		}, 500);
		this.loader.hide(this.loaderId);
	}
	/**
	 * LoggedIn user cards
	 */
	private userCards(): void {
		let uId: any = this.paymentForm.controls['uid'].value;
		let baseLocId: any = this.paymentForm.controls['base_location_id'].value;
		baseLocId = baseLocId ? baseLocId : 0;
		if(this.currentUser && this.currentUser.id){
			this.apiServ.callApiWithPathQueryVars('GET', 'UserCards', [uId], {location_id: baseLocId}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'cards'));
		}
	}

	/**
	 * Check the payment method disabled or not
	 * @returns
	 */
	public disabledParamScope(): string | null {
		if(this.bookingType != 'add'){
			if(this.prefilledData && (this.prefilledData.status == 2 )){
				return "disabled";
			} else if(this.prefilledData && (this.prefilledData.status == 1 || this.prefilledData.status == 4)){
				return null
			} else{
				return null;
			}
		}
		return null;
	}
	/**
	 * Function to check if cash option visible for service.
	 */
	private cashVisibleForService(){
		if(this.bookingType != 'add'){
			if(this.prefilledData && this.prefilledData.status == 4){
				return false;
			}else if((this.selectedServiceType && this.selectedServiceType.can_customer_edit == "no") && (this.prefilledData && this.prefilledData.payment_method == 'existing_credit_card' || this.prefilledData.payment_method == 'new_credit_card')){
				return false;
			} else{
				if((this.selectedServiceType && this.selectedServiceType.display_on == 'admin') && (this.prefilledData && this.prefilledData.payment_method == 'existing_credit_card' || this.prefilledData.payment_method == 'new_credit_card')){
					return false;
				} else if(this.prefilledData && this.prefilledData.status == 1 && this.prefilledData.payment_method != 'cash'){
					return false;
				} else{
					return true;
				}
			}
		}
		return true;
	}
	/**
	 * Card type changes
	 * @param cardType: existing/new
	 */
	public cardTypeChange(cardType: string): void {
		this.isSameAsBookingAddress = false;
		if(cardType === 'existing_credit_card' || cardType === 'cash'){
			// Remove billing address for existing card case
			if(this.creditCardGroup.controls['billing_address']){
				this.creditCardGroup.removeControl('billing_address');
			}
		}
		if(cardType == 'existing_credit_card'){
			let billingCard: BillingCard = this.utilServ.getDefaultCardId(this.cards);
			this.paymentForm.controls['pay_with_cc'].setValue(billingCard?.card_id);
			this.paymentForm.controls['card_last4_digits'].setValue(billingCard?.card_last4_digits);
		} else {
			this.paymentForm.controls['pay_with_cc'].setValue('');
			this.paymentForm.controls['card_last4_digits'].setValue('');
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Update payment
	 */
	public updatePayment(): void {
		this.loader.show(this.loaderId);
		if(this.creditCardGroup.valid){
			if(this.paymentForm.controls['payment_method'].value == 'new_credit_card'){
				this.generateToken();
			} else {
				let data: any = this.createRequiredObj();
				this.updateDeclinePayment(data);
			}
		} else {
			// Adjust behavior of payment gateway child component based on selected card type
			if(this.paymentForm.controls['payment_method'].value == 'new_credit_card' && this.paymentGatewayChild){
				this.paymentGatewayChild.isZipcode = true;
				this.paymentGatewayChild.refresh();
			}
			this.cDRef.detectChanges();
			this.loader.hide(this.loaderId);
		}
	}

	/**
	 * Generate the payment token
	 * @param result
	 */
	public async generateToken(): Promise<void>{
		this.paymentForm.controls['pay_with_cc'].setValue(null);
		let paymentData: {amount: number, location_id: number, base_location_id: number, payment_method: string, billing_address: BillingAddress} = {
			amount: +this.paymentForm?.value?.final_amount,
			location_id: +this.paymentForm?.value?.base_location_id,
			base_location_id: +this.paymentForm?.value?.base_location_id,
			payment_method: this.paymentForm?.value?.payment_method,
			billing_address: this.creditCardGroup.controls['billing_address'] && this.creditCardGroup.controls['billing_address'].value
		}
		// Calls the payment gateway child component's getPaymentToken method to generate the token.
		let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(paymentData, this.paymentGatewayChild, true);
		if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
			if(this.initServ.paymentGateway == 'authorizedotnet'){
				this.creditCardGroup.controls['dataValue'].setValue(paymentGatewayToken.dataValue);
				this.creditCardGroup.controls['dataDescriptor'].setValue(paymentGatewayToken.dataDescriptor);
			} else {
				this.creditCardGroup.controls['token'].setValue(paymentGatewayToken?.token);
			}
			let payload = this.createRequiredObj();
			payload = this.bkngCmnFun.bkngPaymentPayload(payload, paymentGatewayToken);
			this.updateDeclinePayment(payload);
		}else{
			this.toastr.error('Please fill in the valid card details.');
			this.loader.hide()
		}
	}
	/**
	 * Declined required data
	 * @returns
	 */
	private createRequiredObj(): any{
		let data: any = {
			_id : this.prefilledData._id,
			pay_with_cc : this.paymentForm.controls['pay_with_cc'].value,
			card_last4_digits: this.paymentForm.controls['card_last4_digits'].value,
			credit_card : {
				token : this.creditCardGroup.controls['token'].value,
				dataValue : this.creditCardGroup.controls['dataValue'].value,
				dataDescriptor : this.creditCardGroup.controls['dataDescriptor'].value,

			},
			payment_method : this.paymentForm.controls['payment_method'].value,
			uid : this.paymentForm.controls['uid'].value,
			customer : {email_id : this.customerGroup.controls['email_id'].value},
			base_location_id : this.paymentForm.controls['base_location_id'].value,
			location_account_type  : this.creditCardGroup.controls['location_account_type'].value
		}
		if(this.creditCardGroup.controls['billing_address']){
			data['credit_card']['billing_address'] = this.creditCardGroup.controls['billing_address'].value;
		}
		return data;
	}
	/**
	 * Update declined card
	 * @param data
	 */
	private updateDeclinePayment(payload: any): void {
		let bId: any = this.prefilledData._id;
		this.apiServ.callApiWithPathVariables('POST', 'UpdateDeclinedCard', [bId], payload).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'updateCard'));
	}
	/**
	 * On result callback method
	 * @param res API res
	 * @param type API types
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		switch(type){
			case 'cards':
				this.cards = null;
				if(this.apiServ.checkAPIRes(res, false)){
					this.cards = res.data;
					if(this.bookingType == 'add'){
						if(this.cards && (this.cards).length > 0){
							if(this.customerGroup.controls['customer_type'].value == 'existing customer' || (this.cards).length > 0){
								this.paymentForm.controls['payment_method'].setValue('existing_credit_card');
								this.cardTypeChange('existing_credit_card');
							} else{
								this.paymentForm.controls['payment_method'].setValue('new_credit_card');
								this.cardTypeChange('new_credit_card');
							}
						} else{
							this.paymentForm.controls['payment_method'].setValue('new_credit_card');
							this.cardTypeChange('new_credit_card');
						}
					} else {
						if(this.paymentForm.controls['pay_with_cc'].value && this.cards && (this.cards).length > 0){
							this.onCardChange(null, this.paymentForm.controls['pay_with_cc'].value);
						}
					}
				} else {
					if(this.bookingType == 'add'){
						this.paymentForm.controls['payment_method'].setValue('new_credit_card');
						this.cardTypeChange('new_credit_card');
					}
				}

				this.cDRef.detectChanges();
			break;
			case 'updateCard':
				if(this.apiServ.checkAPIRes(res)){
					this.toastr.success(res.message);
					setTimeout(() => {
						location.reload();
					}, 1500);
				} else {
					if(res && res.message){
						this.toastr.error(res.message);
					}
				}
				this.loader.hide(this.loaderId);
			break;
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Handles the change event when a new card is selected.
	 * Updates the payment form with the selected card details.
	 * @param {Event} event - The change event containing the selected card ID.
	 */
	public onCardChange(event: Event|null = null, cardId: string|null = null): void {
		if(!cardId){
			cardId = event && (<HTMLInputElement>event.target).value;
		}
		let billingCard: BillingCard = this.cards.find((card: BillingCard) => card.id === cardId);
		this.paymentForm.controls['pay_with_cc'].setValue(billingCard?.id);
		this.paymentForm.controls['card_last4_digits'].setValue(billingCard?.last4);
	}

	public isSameAddressChange(value: any){
		if(value){
			let addressVal: any = this.addressGroup.value;
			this.billingAddrGroup.patchValue({
				address: addressVal?.address,
				zipcode: addressVal?.zipcode,
				state: addressVal?.state,
				city: addressVal?.city
			})
		}else{
			this.billingAddrGroup.patchValue({
				address : '',
				zipcode : '',
				state : '',
				city : '',
			})
		}
	}

	/**
	 * Method set the default object for `Address` & `Same Address` when section field is null/undefined. This method will only be executed when the account is not migrated with billing address section.
	 */
	private loadDefaultElemJson(): void {
		if(!this.section?.address){
			this.section.address = this.utilServ.loadDefaultElemJson('address');
		}
		if(!this.section?.same_address){
			this.section.same_address = this.utilServ.loadDefaultElemJson('same_address');
		}
	}
}
