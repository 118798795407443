import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { UtilServ } from 'src/app/Services';

@Component({
	selector: 'bk-inv-custom-fields',
	templateUrl: './InvCustomFields.component.html',
	encapsulation: ViewEncapsulation.None
})
export class InvCustomFieldsComponent  {

	@Input() invSec: any;
	@Input() invData: any;
	@Input() bkngAmtData: any;

	constructor(public utilServ: UtilServ) { }
}
