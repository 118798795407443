/* eslint-disable complexity */
/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, Self, SimpleChanges, ChangeDetectorRef,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from "keen-slider";
import { TranslateService } from '@ngx-translate/core';
// Services
import { NgOnDestroy, InitServ, BkngFormServ, UtilServ, LoaderServ, PopupServ, SectionServ, BuildCustomSectionService } from '../../../Services';
import { BkngFormPopupServ } from '../BkngFormPopup.service';

@Component({
	selector: 'bk-items-with-pckgs',
	templateUrl: './ItemsWithPckgs.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ItemsWithPckgsComponent implements OnInit {
	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() prefilledItems: any;
	@Input() itemsForm!: FormGroup;
	@Input() settings: any;
	@Input() availableSett: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() isMultiStepForm: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Output() itemChange: EventEmitter<any> = new EventEmitter();
	@Output() packageChange: EventEmitter<any> = new EventEmitter();
	@Output() itemParamChange : EventEmitter<any> = new EventEmitter();
	@Output() prefilledItemsChange: EventEmitter<any> = new EventEmitter();
	@Output() itemPckgStatus: EventEmitter<boolean> = new EventEmitter();
	@ViewChild("pckgSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	@ViewChild("itemSlideSF") itemSlideRef : ElementRef<HTMLElement> | undefined;
	@ViewChild("pckgSlideSF") pckgSlideRef : ElementRef<HTMLElement> | undefined;
	// Slider configuration
	public slideConfig:any = {
		breakpoints: {
			"(min-width: 1200px)": {
				slides: { perView: 2.15,spacing: 30 },
			},
		},
		slides: { perView: 1 },
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	};
	slider: any = null;
	itemSlider: any = null;
	pckgSlider: any = null;
	extraSlider: any = {};
	items: any;
	isDisabled: any;
	selectedItem: any;
	selectedItemIndex:any;
	loaderId: string = 'bkng-items';
	packages: any;
	pckgLoaderId: string = 'bkng-items-packages';
	pckgAddons: any = {};
	itemsValues: any;
	isChange: boolean = false;
	viewAllStatus: boolean = false;
	enableArrow: boolean = false;
	public shortFormSlideConfig:any = {
		breakpoints: {
			"(min-width: 451px)": {
				slides: { perView: 2 },
			},
			"(min-width: 768px)": {
				slides: { perView: 3 },
			},
		},
		slides: { perView: 1 },
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	}

	// eslint-disable-next-line max-params
	constructor(public initServ: InitServ, @Self() private destroy: NgOnDestroy, public bkngFormServ: BkngFormServ, public utilServ: UtilServ, private frmBldr: FormBuilder, private loader: LoaderServ, private popupServ: PopupServ, public translate: TranslateService, private bkngFormPopupServ: BkngFormPopupServ, private secServ: SectionServ, public cDRef: ChangeDetectorRef, private buildCustSecServ: BuildCustomSectionService) {
		// Set the RTL value
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
	}

	ngOnInit(): void {
		if(this.isMultiStepForm){
			this.slideConfig.breakpoints = {
				"(min-width: 991px)": {
					slides: { perView: 3,spacing:30 },
				},
			}
		}
		if(this.section){
			this.viewAllStatus = this.secServ.checkEleStatus(this.pageSett,this.section.choose_pkg_view_all_id);
		}
		if(!this.prefilledData || this.bookingType != 'add'){
			this.buildItems();
		}
		this.itemsValues = this.itemsForm.value['items'];
		if(this.prefilledData){
			this.checkSelectedItems();
		}
	}
	/**
	 * on changes only with if dependencies are change like location, service, frequency
	 * @param changes : auto detect
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.prefilledLocalVar();
						this.selectedItem = null;
						this.itemsValues = this.itemsForm.value['items'];
						this.buildItems();
						this.pckgAddons = {};
						this.checkSelectedItems();
						break;
					}
				}
			}
		}
	}
	/**
	 * ngOnChanges is not detect the changes, then ngDoCheck works
	 * Param change by list(user selection), build function is not working
	 * Like prefilled items take time etc
	 */
	ngDoCheck(): void {
		if(!this.isChange){
			let cur: any = JSON.stringify(this.itemsForm.value['items']);
			let prev: any = JSON.stringify(this.itemsValues);
			if(cur != prev){
				this.itemsValues = this.itemsForm.value['items'];
				if(this.prefilledData){
					this.buildItems();
				}
				this.checkSelectedItems();
			}
		}
	}
	/**
	 * Checked the selected items
	 */
	private checkSelectedItems(): void {
		if(this.itemsValues && (this.itemsValues).length > 0){
			(this.itemsValues).forEach((item: any, index: any) => {
				if(item.packages && (item.packages).length > 0){
					for(let pckg of item.packages){
						this.buildPckgAddons(item.id, pckg.id, index);
					}
				}
			});
		}
	}
	/**
	 * Build the items
	 */
	private buildItems(): void {
		this.loader.show(this.loaderId);
		this.items = [];
		this.buildCustSecServ.availableElems['items'] = [];
		if(this.settings && this.settings.items && (this.settings.items).length > 0){
			(this.settings.items).forEach((item: any) => {
				let prefilledItemIndex: any = this.getItemIndex(item);
				if(this.bkngFormServ.itemVisible(item, prefilledItemIndex, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledItems, this.availableSett)){
					item['display_name'] = this.bkngFormServ.getElementName(item, 'items');
					this.items.push(item);
					this.buildCustSecServ.availableElems['items'].push(item.id);
				}
			});
		}
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, false, 'items', this.isCustomerAllowedRes);
		// Items and form values are empty, add `no item`
		this.buildNoItem();
		this.emptyItems();
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
	}
	/**
	 * Get the item index in case of reschedule
	 * @param item: item
	 */
	private getItemIndex(item: any): any {
		if(this.bookingType == 'reschedule' && this.prefilledItems && (Object.keys(this.prefilledItems)).length > 0){
			for(let i in this.prefilledItems){
				if(this.prefilledItems[i] && this.prefilledItems[i][item?.id]){
					return i;
				}
			}
		}
	}
	/**
	 * Set the local variable for the selected item
	 * Called when the ngOnChanges event is trigger
	 */
	private prefilledLocalVar(): void{
		let itemsArray = this.itemsForm.controls['items'].value;
		this.prefilledItems = {};
		if(this.utilServ.checkArrLength(itemsArray)){
			(itemsArray).forEach((item: any, index: any)=>{
				this.setPrefilledItems(item, index);
				if(this.utilServ.checkArrLength(item?.packages)){
					for(let pckg of item.packages){
						this.setItemPckgLocalVar(item, index, pckg);
					}
				}
			});
		}
		this.prefilledItemsChange.emit(this.prefilledItems);
	}
	/**
	 * Set the prefilled items
	 * @param item: item
	 * @param index: item index
	 */
	private setPrefilledItems(item: any, index: number): void {
		if(this.prefilledItems && !this.prefilledItems[index]){
			this.prefilledItems[index]={};
		}
		if(this.prefilledItems[index] && !this.prefilledItems[index][item.id]){
			this.prefilledItems[index][item.id]={};
		}
	}
	/**
	 * Set the item, pckg and pckg addon local variable object
	 * @param item: item
	 * @param index: item index
	 * @param pckg: pckg
	 */
	private setItemPckgLocalVar(item: any, index: number, pckg: any): void {
		if(this.prefilledItems[index] && this.prefilledItems[index][item.id] && !this.prefilledItems[index][item.id][pckg.id]){
			this.prefilledItems[index][item.id][pckg.id]={};
		}
		if(this.utilServ.checkArrLength(pckg?.package_addons)){
			for(let pckgAddon of pckg.package_addons){
				this.prefilledItems[index][item.id][pckg.id][pckgAddon.id] = pckgAddon;
			}
		}
	}
	/**
	 * Build items in case of 'no items' in system
	 */
	private buildNoItem(): void {
		// Items and form values are empty, add `no item`
		if(this.items && this.items.length == 0){
			let items = this.itemsForm.controls['items'].value;
			if(items.length < 1){
				setTimeout(() => {
					this.initializeNoItem();
				}, 100);
			} else {
				this.selectedItem = items[0];
				this.selectedItemIndex = 0;
				// No items build the packages
				this.buildPackages();
			}
		} else {
			// Prefilled case packages exist
			if(this.prefilledData && this.utilServ.checkArrLength(this.prefilledData.items)){
				if(this.prefilledData.items[0] && this.prefilledData.items[0]?.packages && this.utilServ.checkArrLength(this.prefilledData.items[0]?.packages)){
					this.itemPckgStatus.emit(true);
				}
			}
		}
	}
	/**
	 * Add item is case of no item
	 */
	private initializeNoItem(): void {
		let obj: any = {
			id: 0,
			name: 'No Item',
			quantity: 1,
			enable_quantity_based: "no",
			quantity_based: 1,
			can_combine: "yes",
			count_multiple_spots: "no",
			packages: []
		};
		this.selectedItem = obj;
		this.selectedItemIndex = 0;
		let itemsArray: any = <FormArray>this.itemsForm.controls['items'];
		if(itemsArray && itemsArray.value && (itemsArray.value).length == 0){
			// Never change the actually variable `obj`
			let formControl: any = JSON.parse(JSON.stringify(obj));
			formControl['packages'] = this.frmBldr.array([]);
			itemsArray.push(this.frmBldr.group(formControl));
		}
		// No items build the packages
		this.buildPackages();
	}
	/**
	 * Select the item and based on this build the packages
	 * @param item
	 */
	public itemSelect(item: any, itemIndex: any): void {
		this.selectedItem = item;
		this.selectedItemIndex = itemIndex;
		// Selected parameter popup
		this.bkngFormServ.formParamMsgPopup(item);
		this.buildPackages();
		this.itemChange.emit(this.selectedItem.id);
	}
	/**
	 * Build the packages
	 */
	private buildPackages(): void {
		this.loader.show(this.pckgLoaderId);
		this.packages = [];
		this.buildCustSecServ.availableElems['packages'] = [];
		if(this.utilServ.checkArrLength(this.settings?.package)){
			let prefilledItem: any = null;
			for(let pckg of this.settings.package){
				prefilledItem = this.getPrefilledData(pckg);
				if(this.bkngFormServ.packageVisible(pckg, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.selectedItem.id, prefilledItem, this.availableSett)){
					pckg['display_name'] = this.bkngFormServ.getElementName(pckg, 'package');
					this.packages.push(pckg);
					this.buildCustSecServ.availableElems['packages'].push(pckg.id);
				}
			}
		}
		let status: boolean = this.packages.length > 0 ? true : false;
		this.itemPckgStatus.emit(status);
		this.cDRef.detectChanges();
		this.loader.hide(this.pckgLoaderId);

		setTimeout(()=>{
			if(this.sliderRef){
				if (this.slider) {this.slider.destroy();}
				if(this.packages.length == 2){
					this.slideConfig.breakpoints = {
						"(min-width: 1200px)": {
							slides: { perView: 2,spacing: 30 },
						},
					}
				}
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig)
			}
			//Short Form Slider
			if(this.itemSlideRef){
				if (this.itemSlider) {this.itemSlider.destroy();}
				this.itemSlider = new KeenSlider(this.itemSlideRef.nativeElement,this.shortFormSlideConfig)
			}
			if(this.pckgSlideRef){
				if (this.itemSlider) {this.itemSlider.destroy();}
				this.pckgSlider = new KeenSlider(this.pckgSlideRef.nativeElement,this.shortFormSlideConfig)
			}

			//Enable Arrow
			if(this.isMultiStepForm){
				if(((window.innerWidth >= 992) && (this.packages.length > 3)) || ((window.innerWidth <= 991) && (this.packages.length > 1))) {
					this.enableArrow = true;
				}
			} else if(((window.innerWidth >= 1201) && (this.packages.length > 2)) || ((window.innerWidth <= 1200) && (this.packages.length > 1))) {
				this.enableArrow = true;
			}
			this.cDRef.detectChanges();
		})
	}
	/**
	 * Get the prefilled local variable data based item and package
	 * @param pckg: package object
	 * @returns object
	 */
	private getPrefilledData(pckg: any): any {
		if(this.prefilledItems && Object.keys(this.prefilledItems).length > 0){
			for(let itemIndex in this.prefilledItems){
				if(this.prefilledItems[itemIndex] && this.prefilledItems[itemIndex][this.selectedItem.id] && this.prefilledItems[itemIndex][this.selectedItem.id][pckg.id]){
					return this.prefilledItems[itemIndex][this.selectedItem.id];
				}
			}
		}
	}
	/**
	 * Select the package
	 * Create and update the item, package and package addons control
	 * @param selectedPackage: selected package
	 * @param type: item based / package based
	 */
	public packageSelect(selectedPackage: any, type: string): void {
		this.isChange = true;
		if(this.bkngFormServ.packagesCanCombine(this.itemsForm.value)){
			if((selectedPackage.combined_for_same_spot != 'no' || (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.bookings.multiple_appointments_per_spot != 'yes')) || (((this.itemsForm.controls['items'].value).length == 0) && type == 'item') || (type != 'item'  && this.bkngFormServ.isPackageAlreadySelected(this.itemsForm.value))){
				// Selected parameter popup
				if(type == 'item'){
					this.buildItemControl(selectedPackage);
				} else {
					this.buildNoItemPckgControl(selectedPackage);
				}
				this.packageChange.emit();
				setTimeout(() => {
					this.bkngFormServ.formParamMsgPopup(selectedPackage);
				},800);
			} else{
				// this.bkngFormServ.msgPopup(this.initServ.appStr.msg.packageNotCombined);
				this.bkngFormServ.newMsgPopup('', true, 'pckg_not_combined');
			}
		} else {
			// this.bkngFormServ.msgPopup(this.initServ.appStr.msg.previousSelectedPckgNotCombined);
			this.bkngFormServ.newMsgPopup('', true, 'previous_selected_pckg_not_combined');
		}
	}
	/**
	 * Check the package is already exist in form control item
	 * @param item: item
	 * @param selectedPackage: selected package
	 * @returns boolean
	 */
	private checkPckgAlreadyExist(item: any, selectedPackage: any ): boolean {
		if(item.packages && (item.packages).length > 0){
			for(let existingPackage of item.packages){
				if(existingPackage.id == selectedPackage.id){
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Build the item control(add/update)
	 */
	private buildItemControl(selectedPackage: any): void {
		let itemsArray = <FormArray>this.itemsForm.controls['items'];
		let isSamePackage: boolean = false;
		if(itemsArray.value && (itemsArray.value).length > 0){
			// Form settings same item as different entity
			let sameItemAsDiffEntity: boolean = true;
			if(this.settings && this.settings.form_data && this.settings.form_data.same_item_as_diff_entity && this.settings.form_data.same_item_as_diff_entity == 'yes'){
				sameItemAsDiffEntity = false;
			}
			(itemsArray.value).forEach((item: any, index: any)=>{
				if(this.selectedItem && this.selectedItem.id == item.id){
					// Check the package is already exist
					let alreadyExist: boolean = this.checkPckgAlreadyExist(item, selectedPackage);
					if(alreadyExist && sameItemAsDiffEntity && !isSamePackage){
						isSamePackage = true;
						// Update package quantity
						this.updateItemPckgControl(itemsArray, index, selectedPackage);
					}
				}
			});
		}
		// Not same package, then add a new control of item and package
		if(!isSamePackage){
			// Build package addons
			this.buildPckgAddons(this.selectedItem.id,selectedPackage.id);
			this.addItemControl(selectedPackage);
		}
		this.selectedItem = null;
		this.itemParamChange.emit();
		// Unchecked the radio buttons
		let inputElms = document.querySelectorAll(`input[name="item"]`);
		if(inputElms && inputElms.length > 0){
			inputElms.forEach((element:any) => element.checked = false);
		}
		// Scroll to specific element
		setTimeout(()=>{
			this.cDRef.detectChanges();
			this.utilServ.scrollToSpecificEle("selected-items-section-scroll");
		},100);
	}
	/**
	 * Update the package quantity
	 * @param itemsArray: item array controls
	 * @param index: selected item index
	 * @param selectedPackage: selected package id
	 */
	private updateItemPckgControl(itemsArray: any, index: any, selectedPackage: any): void {
		let itemPackages: any = <FormArray>(<FormGroup>itemsArray.controls[index]).controls['packages'];
		(itemPackages.value).forEach((packageValue: { id: any; }, pckgIndex: any) => {
			let packageGroup: any = (<FormGroup>itemPackages.controls[pckgIndex]);
			if(packageValue.id == selectedPackage.id){
				// Increment the quantity of package
				let packageEnableQuantity = packageGroup.controls['enable_quantity_based'].value;
				if(packageEnableQuantity == 'yes'){
					let quantity: any = packageGroup.controls['quantity'].value;
					let maxQuantity: any = packageGroup.controls['quantity_based'].value;
					if(quantity < maxQuantity ){
						packageGroup.controls['quantity'].setValue(packageGroup.value['quantity'] + 1);
					}
				}
			}
		});
	}
	/**
	 * Add the item and package control
	 * @param selectedPackage: selected package
	 */
	private addItemControl(selectedPackage: any): void {
		let itemsArray = <FormArray>this.itemsForm.controls['items'];
		let itemObject = {
			id: this.selectedItem && (+this.selectedItem.id),
			name: this.selectedItem && this.selectedItem.name && this.utilServ.replaceUnderscore(this.selectedItem.name),
			quantity: 1,
			enable_quantity_based: 'no',
			quantity_based: 1,
			can_combine: 'yes',
			count_multiple_spots: 'no',
			packages: this.frmBldr.array([])
		};
		itemsArray.push(this.frmBldr.group(itemObject));
		(itemsArray.value).forEach((item: { id: any; }, index: any)=>{
			if(this.selectedItem && this.selectedItem.id == item.id && ((index+1) == (itemsArray.value).length)){
				if(this.prefilledItems && !this.prefilledItems[index]){
					this.prefilledItems[index]={};
				}
				if(this.prefilledItems[index] && !this.prefilledItems[index][item.id]){
					this.prefilledItems[index][item.id]={};
				}
				let packageArray: any = <FormArray>(<FormGroup>itemsArray.controls[index]).controls['packages'];
				this.prefilledItems[index][item.id][selectedPackage.id]={};
				// Create package control
				this.createPckgControl(packageArray, selectedPackage);
			}
		});
		this.cDRef.detectChanges();
	}
	/**
	 * Create package control
	 * @param packageArray: packages form array control
	 * @param selectedPackage: selected package
	 */
	private createPckgControl(packageArray: any, selectedPackage: any): void {
		let packageObject: any = this.frmBldr.group({
			id: selectedPackage.id,
			name: this.utilServ.replaceUnderscore(selectedPackage.name),
			enable_quantity_based: selectedPackage.enable_quantity_based,
			quantity_based: selectedPackage.quantity_based,
			quantity:  1,
			can_combine: selectedPackage.combined_for_same_spot,
			count_multiple_spots: selectedPackage.qty_based_multiple_spots,
			package_addons: this.frmBldr.array([])
		});
		packageArray.push(packageObject);
	}
	/**
	 * Build the package control in case on no item
	 * @param selectedPackage: selected package
	 */
	private buildNoItemPckgControl(selectedPackage: any): void {
		let itemsArray = <FormArray>this.itemsForm.controls['items'];
		(itemsArray.value).forEach((item: any, index: any)=>{
			if(this.selectedItem && this.selectedItem.id == item.id){
				if(this.prefilledItems && !this.prefilledItems[index]){
					this.prefilledItems[index]={};
				}
				if(this.prefilledItems[index] && !this.prefilledItems[index][item.id]){
					this.prefilledItems[index][item.id]={};
				}
				let alreadyExist: boolean = this.checkPckgAlreadyExist(item, selectedPackage);
				// Package already exist, shown popup
				if(alreadyExist){
					let packNames = (selectedPackage.name).toString();
					let msg = this.translate.instant(packNames) + ' '+ this.translate.instant(this.initServ.appStr.msg.alreadyPackage);
					// this.bkngFormServ.msgPopup(msg);
					this.bkngFormServ.newMsgPopup(msg);
				} else {
					// Build package addons
					this.buildPckgAddons(this.selectedItem.id,selectedPackage.id);
					// Create package control
					let packageArray: any = <FormArray>(<FormGroup>itemsArray.controls[index]).controls['packages'];
					this.prefilledItems[index][item.id][selectedPackage.id]={};
					this.createPckgControl(packageArray, selectedPackage);
				}
			}
		});
		this.cDRef.detectChanges();
	}
	/**
	 * Update the package quantity by html
	 * @param itemIndex: item index
	 * @param pckgIndex: package index
	 * @param selectedPackage: selected package
	 * @param type:decrement/increment
	 */
	public pckgCountChange(itemIndex: any, pckgIndex: any, selectedPackage: any, type: string | number): void{
		this.isChange = true;
		let itemsArray = <FormArray>this.itemsForm.controls['items'];
		let packagesArray: any = <FormArray>(<FormGroup>itemsArray.controls[itemIndex]).controls['packages'];
		let packageGroup: any = (<FormGroup>packagesArray.controls[pckgIndex]);
		let pckgControlVal: any = packageGroup.value;
		if(type == 'decrement'){
			if(+(pckgControlVal.quantity) > 1){
				packageGroup.controls['quantity'].setValue(+(pckgControlVal.quantity) - 1);
				this.itemParamChange.emit();
			}
		} else {
			if(+(pckgControlVal.quantity) < selectedPackage.quantity_based){
				packageGroup.controls['quantity'].setValue(+(pckgControlVal.quantity) + 1);
				this.itemParamChange.emit();
			}
		}
	}
	/**
	 * Confirm popup for delete the item and package
	 * @param index: item index
	 * @param type: item/package
	 * @param pckgIndex: package index
	 */
	public confirmPopup(index: any, type: string, pckgIndex: any = null, itemId: any = null,pckgId:any = null): void {
		let extraData: any = { type:type, index:index, itemId: itemId, pckgIndex:pckgIndex, pckgId: pckgId}
		this.popupServ.confirmPopup('delete_pckg').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res,'delete', extraData));
	}
	// Package addons functions
	/**
	 * Build the package addons
	 */
	private buildPckgAddons(itemId: any, pckgId: any, prefilledItemIndex: any = null): void {
		let itemIndex = prefilledItemIndex != null ? prefilledItemIndex : (this.itemsForm.value['items']).length;
		if(this.selectedItem && this.selectedItem.id == 0){
			itemIndex=0;
		}
		if(!this.pckgAddons[itemIndex]){
			this.pckgAddons[itemIndex] = {};
		}
		if(this.pckgAddons[itemIndex] && !this.pckgAddons[itemIndex][itemId]){
			this.pckgAddons[itemIndex][itemId] = {};
		}
		if(!this.pckgAddons[itemIndex][itemId][pckgId]){
			this.pckgAddons[itemIndex][itemId][pckgId] = [];
		}
		if(this.settings && this.settings.package_addons && (this.settings.package_addons).length > 0){
			let prefilledAddons: any = this.prefilledItems && this.prefilledItems[itemIndex] && this.prefilledItems[itemIndex][itemId] && this.prefilledItems[itemIndex][itemId][pckgId];
			this.pckgAddons[itemIndex][itemId][pckgId] = [];
			for(let pckgAddon of this.settings.package_addons){
				if(this.bkngFormServ.packageAddonVisible(pckgAddon, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, pckgId, prefilledAddons, this.availableSett)){
					(this.pckgAddons[itemIndex][itemId][pckgId]).push(pckgAddon);
				}
			}
		}
		this.cDRef.detectChanges();
		setTimeout(() => {
			let addonEls:any = document.querySelectorAll('.addon-slider');
			if(addonEls && addonEls.length > 0){
				addonEls.forEach((addonEl:any,index:number) => {
					if(this.extraSlider && this.extraSlider[index]){
						this.extraSlider[index].destroy();
					}
					this.extraSlider[index] = new KeenSlider(addonEl,this.shortFormSlideConfig)
				})
			}
		})
	}
	/**
	 * Select the package addons
	 * @param event: input event
	 * @param itemIndex: item index
	 * @param itemId: item id
	 * @param pckgIndex: package index
	 * @param pckgId: package id
	 * @param selectedPckgAddon: selected package addon
	 */
	public selectPckgAddon(event: any, itemIndex: any, itemId: any, pckgIndex: any, pckgId: any, selectedPckgAddon: any): void {
		this.isChange = true;
		let packagesArray: any = <FormArray>(<FormGroup>(<FormArray>this.itemsForm.controls['items']).controls[itemIndex]).controls['packages'];
		let packageGroup: any = <FormGroup>packagesArray.controls[pckgIndex];
		let packageAddonArray = <FormArray>(packageGroup).controls['package_addons'];
		if(event.target.checked){
			let addonObj: any = {
				id: selectedPckgAddon.id,
				name: this.utilServ.replaceUnderscore(selectedPckgAddon.name),
				enable_quantity_based: selectedPckgAddon.enable_quantity_based,
				quantity_based: selectedPckgAddon.quantity_based,
				quantity: 1
			};
			packageAddonArray.push(this.frmBldr.group(addonObj));
			this.prefilledItems[itemIndex][itemId][pckgId][selectedPckgAddon.id] = addonObj;
			// Selected parameter popup
			this.bkngFormServ.formParamMsgPopup(selectedPckgAddon);
		} else{
			(packageAddonArray.value).forEach((value: { id: any; }) => {
				if(value.id === selectedPckgAddon.id){
					delete this.prefilledItems[itemIndex][itemId][pckgId][selectedPckgAddon.id];
					packageAddonArray.removeAt(packageAddonArray.value.findIndex((addon: any) => addon.id === selectedPckgAddon.id))
				}
			});
		}
		this.itemParamChange.emit();
	}
	/**
	 * Update the package addon quantity
	 * @param itemIndex: item index
	 * @param itemId: item id
	 * @param pckgIndex: package index
	 * @param pckgId: package id
	 * @param selectedPckgAddon: selected package addon
	 * @param type: decrement/increment
	 */
	public pckgAddonCountChange(itemIndex: any, itemId: any, pckgIndex: any, pckgId: any, selectedPckgAddon: any, type: string | number): void {
		this.isChange = true;
		let itemPackages: any = <FormArray>(<FormGroup>(<FormArray>this.itemsForm.controls['items']).controls[itemIndex]).controls['packages'];
		let packageGroup: any = <FormGroup>itemPackages.controls[pckgIndex];
		let packageAddonArray = <FormArray>(packageGroup).controls['package_addons'];
		let quantity: number = 0;
		(packageAddonArray.value).forEach((addon: any, addonIndex: any) => {
			if(addon.id == selectedPckgAddon.id){
				quantity = +selectedPckgAddon?.quantity_base;
				let pckgAddonGroup: any = (<FormGroup>packageAddonArray.controls[addonIndex]);
				if(type == 'decrement'){
					if(+(addon.quantity) > 0){
						quantity = +(addon.quantity) - 1;
					}
				} else {
					if(+(addon.quantity) < selectedPckgAddon.quantity_based){
						quantity = +(addon.quantity) + 1;
					}
				}
				// quantity is greater than 0 and quantity less than and equal to `quantity_based` field
				// quantity is equal to 0 then uncheck the selected pckgAddon
				if(quantity == 0){
					setTimeout(() => {
						let elemId: string = 'addon-'+itemId+'-'+itemIndex+'-'+pckgId+'-'+selectedPckgAddon?.id;
						let elem: any = document.getElementById(elemId);
						if(elem){
							elem.click();
						}
					}, 50)
				}else if(quantity > 0 && (quantity <= selectedPckgAddon.quantity_based )){
					this.prefilledItems[itemIndex][itemId][pckgId][selectedPckgAddon.id]['quantity'] = quantity;
					pckgAddonGroup.controls['quantity'].setValue(quantity);
					this.itemParamChange.emit();
				}
			}
		});
	}
	/**
	 * Packages popup
	 */
	public packagesPopup(): void {
		document.body.classList.add('tjs-popup-transparent');
		let data: any = {
			bookingType: this.bookingType,
			section: this.section,
			selectedItem: this.selectedItem,
			packages: this.packages
		}
		this.bkngFormPopupServ.packagesPopup(data).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res,'packages'));;
	}

	/**
	 * On popup result callback method
	 * @param res: popup res
	 * @param type: popup type
	 * @param data: extra data
	 * Popup response handler
	 */
	private popupCallback(res:any, type: string, data: any = null): void {
		document.body.classList.remove('tjs-popup-transparent');
		if(res){
			if(type == 'delete'){
				if(data.type == 'item'){
					// Remove the item control from form
					let itemsArray: any = <FormArray>this.itemsForm.controls['items'];
					itemsArray.removeAt(data.index);
					this.itemParamChange.emit();
					// Update the prefilledItems object
					const prefilledKeys = Object.keys(this.prefilledItems);
					if(this.prefilledItems && this.prefilledItems[data.index]){
						prefilledKeys.forEach((key: any) => {
							let objKey = +key;
							if(objKey == data.index || objKey > data.index){
								this.prefilledItems[key]= this.prefilledItems[objKey+1];
							}
						});
					}
					let lastIndex = prefilledKeys[prefilledKeys.length - 1];
					delete this.prefilledItems[lastIndex];
				} else if(data.type == 'package'){
					// Remove the package control from item control
					let packagesArray: any = <FormArray>(<FormGroup>(<FormArray>this.itemsForm.controls['items']).controls[data.index]).controls['packages'];
					packagesArray.removeAt(data.pckgIndex);
					this.itemParamChange.emit();
					// Update the prefilledItems object
					if(this.prefilledItems && this.prefilledItems[data.index] && this.prefilledItems[data.index][data.itemId] && this.prefilledItems[data.index][data.itemId][data.pckgId]){
						delete this.prefilledItems[data.index][data.itemId][data.pckgId];
					}
				}
				this.itemsValues = this.itemsForm.value['items'];
				this.pckgAddons = {};
				this.checkSelectedItems();
			} else {
				if(res.selectedPackage){
					this.packageSelect(res.selectedPackage,res.type)
				}
			}
		}
		this.cDRef.detectChanges();
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
		if (this.itemSlider){this.itemSlider.destroy();}
		if (this.pckgSlider) {this.pckgSlider.destroy();}
		if (this.extraSlider){
			for(let addon in this.extraSlider){
				if(this.extraSlider[addon]){
					this.extraSlider[addon].destroy();
				}
			}
		}
	}
	/**
	 * Uncheck the items
	 */
	private emptyItems(): void {
		// Unchecked the radio buttons
		let inputElms = document.querySelectorAll(`input[name="item"]`);
		if(inputElms && inputElms.length > 0){
			inputElms.forEach((element:any) => element.checked = false);
		}
	}
	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		//Refresh slider
		setTimeout(()=>{
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
			this.buildItems();
			this.cDRef.detectChanges();
		})
	}
}
