import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation, Self, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
// Services
import { NgOnDestroy, BkngFormServ, UtilServ, InitServ, PopupServ, BkngCustomSectionService } from '../../../Services';
// Pipes
import { RmSpclCharAndSpacePipe } from "src/app/Pipes";
@Component({
	selector: 'bk-custom-fields',
	templateUrl: './CustomFields.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CustomFieldsComponent implements OnInit, OnChanges, OnDestroy {
	// Variables receive data from the parent
	@Input() customFieldGroup: any;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() BKFrm!: FormGroup;
	@Input() stepType  : any = 'step_two';
	@Input() buildCustomFields: boolean = false;
	@Input() selectedServiceType: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() isShortForm: boolean = false;
	@Input() isCustomerAllowedRes: any;
	@Output() customFieldChange: EventEmitter<any> = new EventEmitter();
	// Variables
	customFields: any;
	rmSpclCharAndSpacePipe: any; // Form element pipe
	isDisabled: any;
	shortFormData: any;
	secStatus: boolean = false;
	refreshTimepicker: boolean = true;
	currentUser: any = this.utilServ.appLocalStorage();
	embedJsUrl:any;
	videosHeight: any = {};

	width: number = 745;
	private resizeListener: EventListenerOrEventListenerObject | undefined;

	// eslint-disable-next-line max-params
	constructor(public bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, public bkngCustSecServ: BkngCustomSectionService, private cDRef: ChangeDetectorRef, private popupServ: PopupServ, @Self() public destroy: NgOnDestroy, public sanitizer: DomSanitizer) {
		// Remove pipe
		this.rmSpclCharAndSpacePipe = new RmSpclCharAndSpacePipe();
		this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.initServ.appData.primary_custom_domain + '/resources/embed.js');
	}
	ngOnInit(): void {
		// Short form data
		if(this.bookingType == 'add' && !this.isShortForm){
			this.shortFormData = this.bkngFormServ.getShortFormData;
		}
		// TODO: check condition
		// if((!this.prefilledData && (this.isMultiStepForm || this.isShortForm)) || this.bookingType != 'add' || this.shortFormData){
		// 	this.buildSection();
		// }
		// Build the section
		// if((!this.prefilledData) || this.bookingType == 'reschedule'){
			this.buildSection();
		// }

		this.setResizeListener();
	}
	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	ngAfterViewInit() {
		if(this.bookingType == 'reschedule'){
			this.bkngCustSecServ.isRescFirstLoad = false;
		}
	}
	/**
	 * on changes only with if dependencies are change like location, service, frequency
	 * @param changes : auto detect
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange && propName == 'buildCustomFields') {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev && chng.currentValue == true){
						this.buildSection();
						break;
					}
				}
			}
		}
	}
	// convenience getter for easy access to form fields
	get customFieldsGroup() {
		return <FormGroup>this.BKFrm.controls['custom_fields'];
	}
	get cfCheckboxGroup() {
		return <FormGroup>this.BKFrm.controls['custom_fields_checkbox'];
	}
	/**
	 * Builds the section by setting the customFields array and secStatus boolean.
	 */
	private buildSection(): void {
		this.customFields = [this.customFieldGroup];
		// this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, null);
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, null, false, '', this.isCustomerAllowedRes);

		this.secStatus = false;
		if(this.utilServ.checkArrLength(this.customFields)){
			this.setSecStatus();
		}
	}
	/**
	 * Sets the section status based on the custom fields array and the isSecSelStep() and checkCustomFieldStatus() methods.
	 */
	// eslint-disable-next-line complexity
	private setSecStatus(): void {
		if(this.utilServ.checkArrLength(this.customFields)){
			for(let sec of this.customFields){
				if(this.isSecAllowOnSelStep(sec) && this.utilServ.checkArrLength(sec?.custom_fields)){
					for(let field of sec.custom_fields){
						// eslint-disable-next-line max-depth
						if(this.checkCustomFieldStatus(sec, field) && (field.field_type != 'Priceable Input' || (field.field_type == 'Priceable Input' && !field.summary_only))){
							this.secStatus = true;
							break;
						}
					}
				}
			}
		}
	}
	/**
	 * Checks the status of a custom field based on its dependency and visibility.
	 * @param {any} sec - The section object containing the custom field.
	 * @param {any} field - The custom field object to check.
	 * @returns {boolean} - The status of the custom field.
	 */
	private checkCustomFieldStatus(sec: any, field: any): boolean {
		let ctrl: string = sec?._id+'_'+field?._id;
		if(this.customFieldsGroup.contains(ctrl)){
			if(!field.is_dependent || (field.is_dependent && this.checkCtrlVisibleStatus(ctrl))){
				return true;
			}
		}
		return false;
	}
	/**
	 * Checks if a section is allowed to be displayed based on the current step type.
	 * @param {any} sec - The section object to check.
	 * @returns {boolean} - The status of the section.
	 */
	private isSecAllowOnSelStep(sec: any): boolean {
		if(this.stepType == 'all' || sec?.display_on == this.stepType || (!sec.display_on && this.stepType == 'step_two')){
			return true;
		}
		return false;
	}
	/**
	 * Call when value change for priceable custom field
	 * @param {any} name - represents the name parameter
	 * @param {any} value - represents the value parameter
	 * @param {any} field - represents the field parameter
	 */
	public changeValueForPriceable(name: any, value: any, field: any){
		let flag = field?.is_taxable && field?.add_price_in != "after_discounted_total";
		// This is solve the problem if flag is undefined
		if(flag == undefined){
			flag = false;
		}
		// Calls the changeValueForPriceable function of bkngCustSecServ object with the passed parameters.
		if(field?.field_type != 'Checkbox'){
			this.bkngCustSecServ.changeValueForPriceable(name, value, field);
		}
		this.customFieldChange.emit(flag);

	}
	/**
	 * Mark all custom fields as touched.
	 */
	public markAllCustFieldsAsTouched(): void {
		this.customFieldsGroup.markAllAsTouched();
		this.cfCheckboxGroup.markAllAsTouched();
		this.cDRef.detectChanges();
	}
	/**
	 * Mark all custome fields as untouched.
	 */
	public markAllCustFieldsAsUntouched(): void {
		this.customFieldsGroup.markAsUntouched();
		this.cfCheckboxGroup.markAsUntouched();
		this.cDRef.detectChanges();
	}
	/**
	 * Validate and round off the value of the custom field amount.
	 * @param {string} ctrl The name of the custom field control as string.
	 */
	public validateAmount(ctrl: string): void {
		let val: any = this.utilServ.roundToTwo(this.customFieldsGroup.controls[ctrl].value);
		if(val < 0 && !this.isNegativeValAllowed(ctrl)){
			val = 0;
		}
		this.customFieldsGroup.controls[ctrl].setValue(+val);
	}
	/**
	 * Determines if negative values are allowed for a given control.
	 * @param {string} ctrl - The control to check.
	 * @returns {boolean} - True if negative values are allowed, false otherwise.
	 */
	private isNegativeValAllowed(ctrl: string): boolean {
		if(this.utilServ.objHasProp(this.bkngCustSecServ.custFieldsObj, ctrl) && this.bkngCustSecServ.custFieldsObj[ctrl]){
			if(this.bkngCustSecServ.custFieldsObj[ctrl].provider_pay_type && this.bkngCustSecServ.custFieldsObj[ctrl].provider_pay_type == 'none'){
				return true;
			}
			if(+this.bkngCustSecServ.custFieldsObj[ctrl].default_value > 0){
				return false;
			}
		}
		return true;
	}
	/**
	 * Handles the change of a hierarchical checkbox field.
	 * @param {any} event - The event object for the checkbox.
	 * @param {any} field - The hierarchical field object that is being changed.
	 * @param {any} option - The option that is being selected/deselected.
	 */
	public onHierarchicalCheckboxFieldChange(event: any, field: any, option: any): void {
		if(event.target.checked){
			// Calls the onHierarchicalFieldChange function passing an empty string and the option and field parameters.
			this.onHierarchicalFieldChange('', option, field);
		}else{
			let resetCtrlsArr: Array<string> = [];
			if(field.is_hierarchical){
				// Gets option data and checks if there are dependent fields.
				let optData: any = this.getSelOptionData(option, field);
				if(optData.has_dependent_fields && this.utilServ.checkArrLength(optData.dependent_fields)){
					// Loops through the dependent fields and sets the required validator to false, resets the value to an empty string and then resets the hierarchical value.
					for(let opt of optData.dependent_fields){
						let name: string = field.custom_field_section_id+'_'+opt;
						resetCtrlsArr = this.resetCheckboxDependentFields(name, resetCtrlsArr);
					}
				}
			}
			this.updatePriceForResetCtrls(resetCtrlsArr);
		}
	}
	/**
	 * Resets the dependent fields of a checkbox control and adds the control to the resetCtrlsArr array.
	 * @param {string} name - The name of the checkbox control to reset.
	 * @param {array} resetCtrlsArr - The array of controls to reset.
	 * @returns {array} - The updated resetCtrlsArr array.
	 */
	private resetCheckboxDependentFields(name: string, resetCtrlsArr: Array<any>): Array<any> {
		if(!this.checkCtrlVisibleStatus(name)){
			if(!resetCtrlsArr.includes(name)){
				resetCtrlsArr.push(name);
			}
			this.bkngCustSecServ.addRemoveRequiredValidation(false, this.customFieldsGroup, name);
			if(typeof this.customFieldsGroup.controls[name].value == 'object'){
				for(let elem in this.customFieldsGroup.controls[name].value){
					(<FormGroup>this.customFieldsGroup.controls[name]).controls[elem].setValue(false);
				}
			}else{
				this.customFieldsGroup.controls[name].setValue('');
			}
			resetCtrlsArr = this.bkngCustSecServ.resetHierarchicalValOnChange(name, resetCtrlsArr);
		}
		return resetCtrlsArr;
	}
	/**
	 * Determines the visibility status of a given control.
	 * @param {any} ctrl - The control to check.
	 * @returns {boolean} - True if the control is visible, false otherwise.
	 */
	private checkCtrlVisibleStatus(ctrl: any): boolean {
		let secAndFieldIds: Array<any> = ctrl.split('_');
		if(this.utilServ.objHasProp(this.bkngCustSecServ.depFieldDisWhen, ctrl) && this.bkngCustSecServ.depFieldDisWhen[ctrl] && ((this.bkngCustSecServ.depFieldDisWhen[ctrl].isCheckbox && this.checkDependentFieldStatusForCheckbox({_id: secAndFieldIds[0]}, {_id: secAndFieldIds[1]})) || (!this.bkngCustSecServ.depFieldDisWhen[ctrl].isCheckbox && (this.bkngCustSecServ.depFieldDisWhen[ctrl].val).includes(this.customFieldsGroup.controls[this.bkngCustSecServ.depFieldDisWhen[ctrl].ctrl].value)))){
			return true;
		}
		return false;
	}
	/**
	 * Handle changes in hierarchical fields
	 * @param {any} name - The name of the field
	 * @param {any} value - The new value of the field
	 * @param {any} field - The object representing the field
	 */
	public onHierarchicalFieldChange(name: any, value: any, field: any): void {
		let resetCtrlsArr: Array<string> = [];
		// Set the value of the given field
		let val: string = this.bkngCustSecServ.setPriceableFieldValue(field, value);
		// If the field is not a Checkbox, reset the hierarchical value on change
		if(field.field_type != 'Checkbox'){
			resetCtrlsArr = this.bkngCustSecServ.resetHierarchicalValOnChange(name, []);
		}
		// If the field is hierarchical and has dependent fields, set any required fields as required for validation
		if(field.is_hierarchical){
			let optData: any = this.getSelOptionData(val, field);
			if(optData && optData.has_dependent_fields && this.utilServ.checkArrLength(optData.dependent_fields)){
				// Loop through the dependent fields and set them as required if necessary
				for(let depField of optData.dependent_fields){
					this.setDependentFieldValidatonAndDefVal(field, depField);
				}
			}
		}
		this.updatePriceForResetCtrls(resetCtrlsArr);
	}
	/**
	 * Updates the price for the reset controls in the provided array.
	 * @param {array} resetCtrlsArr - The array of controls to reset.
	 */
	private updatePriceForResetCtrls(resetCtrlsArr: Array<string>): void {
		if(this.utilServ.checkArrLength(resetCtrlsArr)){
			for(let ctrl of resetCtrlsArr){
				let ctrlData: any = this.bkngCustSecServ.custFieldsObj[ctrl];
				if(ctrlData.is_priceable){
					let value = this.customFieldsGroup.controls[ctrl].value;
					if(['Dropdown', 'Priceable Input'].includes(ctrlData?.field_type)){
						value = {
							target: {
								value: this.customFieldsGroup.controls[ctrl].value
							}
						}
					}
					if(ctrlData.field_type == 'Checkbox'){
						this.updatePriceForResetCheckboxCtrls(ctrlData, ctrl);
					}else{
						this.changeValueForPriceable(ctrl, value, ctrlData);
					}
				}
			}
		}
	}
	/**
	 * Updates the price for the reset checkbox controls in the provided array.
	 * @param {any} ctrlData - The data associated with the control.
	 * @param {any} ctrl - The control to update.
	 */
	private updatePriceForResetCheckboxCtrls(ctrlData: any, ctrl: any): void {
		let event: any = {target:{checked:false}}
		let value: any = this.cfCheckboxGroup.controls[ctrl].value;
		if(this.utilServ.checkArrLength(value)){
			for(let checkbox of value){
				this.bkngCustSecServ.custCheckBoxChange(event, ctrl, checkbox, ctrlData);
			}
		}
		this.changeValueForPriceable(ctrl, value, ctrlData);
	}
	/**
	 * Sets the validation and default value for a dependent field based on the provided field.
	 * @param {any} field - The field to base the dependent field's validation and default value on.
	 * @param {any} depField - The dependent field to update.
	 */
	private setDependentFieldValidatonAndDefVal(field: any, depField: any): void {
		let ctrl: string = field.custom_field_section_id+'_'+depField;
		let ctrlData: any = this.bkngCustSecServ.custFieldsObj[ctrl];
		if(ctrlData){
			if(ctrlData.is_required){
				this.bkngCustSecServ.addRemoveRequiredValidation(true, this.customFieldsGroup, ctrl);
			}
			if(ctrlData.field_type == 'Priceable Input' && ctrlData.default_value){
				this.customFieldsGroup.controls[ctrl].setValue(+ctrlData.default_value);
			}
			if(ctrlData.is_priceable){
				let value = this.customFieldsGroup.controls[ctrl].value;
				if(['Dropdown', 'Priceable Input'].includes(ctrlData?.field_type)){
					value = {
						target: {
							value: this.customFieldsGroup.controls[ctrl].value
						}
					}
				}
				this.changeValueForPriceable(ctrl, value, ctrlData);
			}
		}
	}
	/**
	 * Returns the data of the selected option from a select dropdown field
	 * @param {any} val: the value of the selected option
	 * @param {any} field: the select dropdown field object that contains an array of options and options_data
	 * @return {any} - the data of the selected option, or null if it doesn't exists in the options array
	 */
	private getSelOptionData(val: any, field: any): any {
		if((field.options).includes(val)){
			//Checks if the select dropdown field options includes the selected value
			let index: any = (field.options).indexOf(val);
			if(index > -1){
				//Returns the value of the corresponding options_data array index
				return field.options_data[index];
			}
		}
		//Returns null if the selected option doesn't exist within the options array of the select dropdown field
		return null;
	}
	/**
	 * Handles the change event triggered by an option in a dropdown list, input checkbox or radio input.
	 * @param {string} ctrl - the control related to the event change.
	 * @param {any} option - the selected option in a dropdown list or checkbox status.
	 * @param {any} event - optional parameter containing the event object.
	 * @param {boolean} isDropdown - true if the dropdown.
	 */
	public onValChange(ctrl: string, option: any, event: any = null, isDropdown: boolean = false): void {
		if(isDropdown && (option.target.value == 'null' || option.target.value == null || option.target.value == '')){
			this.customFieldsGroup.controls[ctrl].setValue('');
		}
		let status: boolean = true;
		// If it is an input checkbox, the event object includes the checkbox status
		if(event && !event.target.checked){
			status = false;
		}
		if(status){
			let val: any = option;
			if(isDropdown){
				val = option.target.value;
			}
			this.displayPopupOnSelection(ctrl, val);
		}
	}
	/**
	 * Displays a popup message based on the selected value of a control.
	 * @param {string} ctrl - The name of the control.
	 * @param {any} val - The selected value of the control.
	 */
	private displayPopupOnSelection(ctrl: string, val: any): void {
		if(this.bkngCustSecServ.optSelDisplayMsg[ctrl] && this.bkngCustSecServ.optSelDisplayMsg[ctrl][val] && this.bkngCustSecServ.optSelDisplayMsg[ctrl][val].status && this.bkngCustSecServ.optSelDisplayMsg[ctrl][val].type == 'popup'){
			this.popupServ.newMsgPopup(this.bkngCustSecServ.optSelDisplayMsg[ctrl][val].msg).pipe(takeUntil(this.destroy));
		}
	}
	/**
	 * Checks the status of a dependent field for a checkbox control.
	 * @param {any} customField - The custom field object.
	 * @param {any} field - The field object.
	 * @returns {boolean} - A boolean value indicating whether the dependent field should be enabled or disabled.
	 */
	public checkDependentFieldStatusForCheckbox(customField: any, field: any): boolean {
		if(this.utilServ.checkArrLength(this.bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].val)){
			for(let val of this.bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].val){
				if(this.customFieldsGroup.controls[this.bkngCustSecServ.depFieldDisWhen[customField?._id+'_'+field?._id].ctrl].value[val]){
					return true;
				}
			}
		}
		return false;
	}
	/**
	 * Updates the selected value of a quantity-based control and triggers related actions.
	 * @param {any} event - The event object.
	 * @param {string} ctrl - The name of the control.
	 * @param {any} field - The field object.
	 */
	public quantityBasedSelect(event: any, ctrl: string, field: any): void {
		if(event.target.checked){
			this.customFieldsGroup.controls[ctrl].setValue((1).toString());
		}else{
			this.customFieldsGroup.controls[ctrl].setValue('');
		}
		this.onHierarchicalFieldChange(ctrl, this.customFieldsGroup.controls[ctrl].value, field);
		this.changeValueForPriceable(ctrl, this.customFieldsGroup.controls[ctrl].value, field);
		this.onValChange(ctrl, this.customFieldsGroup.controls[ctrl].value);
		this.bkngCustSecServ.addRemoveMinValueValidationFromQuantityBased(ctrl, event.target.checked);
	}
	/**
	 * Updates the selected value of a quantity-based control and triggers related actions.
	 * @param {string} ctrl - The name of the control.
	 * @param {any} field - The field object.
	 * @param {string} type - The type of the control.
	 */
	public countChange(ctrl: string, field: any, type: string): void {
		if(!this.isDisabled){
			let quantity = this.incDecQuantity(type, ctrl, field);
			// quantity is greater than 0 and quantity less than and equal to `quantity_based` field
			if(this.checkQuantityValue(quantity, field)){
				this.customFieldsGroup.controls[ctrl].setValue(quantity.toString());
				// this.elementChange.emit();
				this.changeValueForPriceable(ctrl, this.customFieldsGroup.controls[ctrl].value, field);
				let eventObj: any = {
					target: {
						checked: false
					}
				}
				this.onValChange(ctrl, this.customFieldsGroup.controls[ctrl].value, eventObj);
				this.customFieldsGroup.controls[ctrl].markAsTouched();
			}else{
				if(type == 'decrement' && !quantity){
					setTimeout(() => {
						let elem: HTMLElement | null = document.getElementById(ctrl);
						if(elem){
							elem.click();
						}
					}, 50)
				}
			}
		}
	}
	/**
	 * Checks if the quantity value is valid based on the quantity details of a field.
	 * @param {number} quantity - The quantity value to check.
	 * @param {any} field - The field object containing the quantity details.
	 * @returns {boolean} - A boolean value indicating whether the quantity value is valid or not.
	 */
	private checkQuantityValue(quantity: number, field: any): boolean{
		return (quantity > 0 && (quantity <= (field?.quantity_details?.quantity_based))) ? true : false;
	}
	/**
	 * Increments or decrements the quantity value based on the given type and field details.
	 * @param {string} type - The type of the operation (increment or decrement).
	 * @param {string} ctrl - The name of the control.
	 * @param {any} field - The field object containing the quantity details.
	 * @returns {number} - The updated quantity value.
	 */
	private incDecQuantity(type: string, ctrl: string, field: any): number {
		let val: any = +this.customFieldsGroup.controls[ctrl].value;
		let quantity: number = 0;
		if(type == 'decrement'){
			return this.decQuantity(val, quantity);
		} else {
			return this.incQuantity(val, field, quantity);
		}
	}
	/**
	 * Increments the quantity value by 1 if the given value is less than the quantity based on the field details.
	 * @param {any} val - The value to be incremented.
	 * @param {any} field - The field object containing the quantity details.
	 * @param {number} quantity - The current quantity value.
	 * @returns {number} - The updated quantity value.
	 */
	private incQuantity(val: any, field: any, quantity: number): number{
		if(+val < (field?.quantity_details?.quantity_based)){
			quantity = +val + 1;
		}
		return quantity;
	}
	/**
	 * Decrements the quantity value by 1 if the given value is greater than 1.
	 * @param {any} val - The value to be decremented.
	 * @param {number} quantity - The current quantity value.
	 * @returns {number} - The updated quantity value.
	 */
	private decQuantity(val: any, quantity: number): number {
		if(+val > 1){
			quantity = +val - 1;
		}
		return quantity;
	}
	/**
	 * Updates the value of a control in the customFieldsGroup based date selected by the user.
	 * @param {any} val - The selected date value.
	 * @param {string} ctrl - The name of the control to be updated.
	 */
	public onDateChange(val: any, ctrl: string): void {
		if(val){
			this.customFieldsGroup.controls[ctrl].setValue(val.toString());
		}else{
			this.customFieldsGroup.controls[ctrl].setValue('');
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Called when the time value is changed in the timepicker.
	 * @param {any} val - The new time value selected by the user.
	 * @param {string} ctrl - The name of the form control to be updated with the new time value.
	 */
	public onTimeChange(val: any, ctrl: string): void {
		this.refreshTimepicker = false;
		if(val){
			let timeArray = val.split(' ');
			if(this.utilServ.timeFormat == '24' && timeArray[0]){
				let hoursMin = timeArray[0].split(':');
				timeArray[1]='AM';
				if(+hoursMin[0] >= 12){
					timeArray[1] = 'PM';
				}
			}
			let timeString = timeArray[0].replace(':','');
			let hours = timeString / 100;
			let spot = this.utilServ.getTimeIn24HFormat(hours,timeArray[1]);
			this.customFieldsGroup.controls[ctrl].setValue(spot ? spot.toString() : '');
		}else{
			this.customFieldsGroup.controls[ctrl].setValue('');
		}
		setTimeout(() => {
			this.refreshTimepicker = true;
			this.cDRef.detectChanges();
		}, 0);
	}
	/**
	 * Takes in a time parameter and returns the time in a specific format.
	 * @param {any} time - a parameter that represents the time in 24-hour format.
	 * @returns {any} - the time in the format of 'HH:mm meridian' (e.g. 09:30 AM).
	 */
	public getTime(time: any): any {
		if(time){
			return parseInt(this.utilServ.getTimeStringFrom24HFormat(time, null))+':'+this.utilServ.addPrefixZeroBeforeNum(time % 100)+' '+this.utilServ.getTimeStringFrom24HFormat(time, 'meridian');
		}else{
			return null;
		}
	}
	/**
	 * Sets a resize listener on the window object to recalculate the video heights when the window is resized.
	 * It also initializes the video heights on component initialization.
	 */
	private setResizeListener(): void {
		// Initialize the event listener
		this.resizeListener = this.calculateVideoHeights.bind(this);
		window.addEventListener('resize', this.debounce(this.resizeListener, 250));
		// You can also call the function on component initialization if needed
		this.calculateVideoHeights();
	}
	/**
	 * Returns a debounced version of a function that can be called repeatedly without triggering the original function too frequently.
	 * @param {any} func - The function to be debounced.
	 * @param {number} wait - The amount of time to wait before calling the debounced function.
	 * @returns A debounced version of the original function.
	 */
	private debounce(func: any, wait: number) {
		let timeout: any;
		return () => {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			let context: any = this;
			// eslint-disable-next-line prefer-rest-params
			let args: any = arguments;
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				func.apply(context, args);
			}, wait);
		};
	}
	/**
	 * Calculates the height of each video based on the width of the parent container.
	 * It sets the calculated heights in the videosHeight object.
	 */
	public calculateVideoHeights(): void {
		// Initialize the videosHeight object and set the default width.
		this.videosHeight = {};
		this.width = 745;
		// Check if the customFields array and its first element's custom_fields array have length.
		if(this.utilServ.checkArrLength(this.customFields) && this.utilServ.checkArrLength(this.customFields[0]?.custom_fields)){
			// Filter the custom_fields array to get only the video fields.
			let videoFields: Array<any> = this.bkngCustSecServ.filterByFieldType(this.customFields[0]?.custom_fields);
			// Check if the videoFields array has length.
			if(this.utilServ.checkArrLength(videoFields)){
				// Get the parent container element and set the width to its offsetWidth.
				let divElement: any = document.getElementsByClassName('cust-field-wrapper');
				if(this.utilServ.checkArrLength(divElement)){
					this.width = divElement[0]?.offsetWidth;
				}
				// Loop through the videoFields array and calculate the height of each video.
				for(let field of videoFields){
					if(!this.utilServ.objHasProp(this.videosHeight, field._id)){
						this.videosHeight[field._id] = this.bkngCustSecServ.getVideoHeight(field, this.width);
					}
				}
			}
		}
	}
	ngOnDestroy() {
		if(this.resizeListener){
			// Remove the event listener when the component is destroyed
			window.removeEventListener('resize', this.resizeListener);
		}
	}
}
