<div [id]="section?.id" [formGroup]="zipcodeForm" *ngIf="settings && section">
	<div class="tjs-check-btn tjs-btn-multi d-flex flex-wrap mx-n10" *ngIf="settings?.form_data && settings?.form_data?.preferred_service_location == 'both' && (merchantLocations && merchantLocations.length > 0)">
		<!-- Zipcode radio buttons SA & ML -->
		<div class="px-10 text-center mb-30 flex-grow-1">
			<div class="form-check form-check-inline m-0 d-block">
				<input id="customerLoc" class="form-check-input bk-form-check-input" type="radio" name="location_type" value="SA" formControlName="location_type" (change)="locTypeChange('SA')" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null" />
				<label for="customerLoc" class="w-100 form-check-label justify-content-center btn btn-light fw-bold p-25 fs-18 bk-form-check-btn">
					<span [id]="section?.my_loc_label_id" [innerHTML]="section?.my_loc_label | safeHtml"></span>
					<bk-tooltip customClass="tjs-tooltip-icon--sm bk-form-check-tooltip" *ngIf="zipcodeForm.controls['location_type'].value == 'ML' && section?.my_loc_tooltip" [id]="section?.my_loc_tooltip_id" [content]="section?.my_loc_tooltip | translate"></bk-tooltip>
				</label>
			</div>
		</div>
		<div class="px-10 text-center mb-30 flex-grow-1">
			<div class="form-check form-check-inline m-0 d-block">
				<input id="merchantLoc" class="form-check-input bk-form-check-input" type="radio" name="location_type" value="ML" formControlName="location_type" (change)="locTypeChange('ML')" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null"/>
				<label for="merchantLoc" class="w-100 form-check-label justify-content-center btn btn-light p-25 fs-18 bk-form-check-btn">
					<span [id]="section?.merchant_loc_label_id" [innerHTML]="section?.merchant_loc_label  | safeHtml"></span>
					<bk-tooltip customClass="tjs-tooltip-icon--sm bk-form-check-tooltip" *ngIf="zipcodeForm.controls['location_type'].value == 'SA' && section?.merchant_loc_tooltip" [id]="section?.merchant_loc_tooltip_id" [content]="section?.merchant_loc_tooltip | translate"></bk-tooltip>
				</label>
			</div>
		</div>
	</div>
	<!-- SA location -->
	<div *ngIf="settings?.form_data && settings?.form_data?.preferred_service_location == 'customer_location' || zipcodeForm.controls['location_type'].value == 'SA'">
		<label *ngIf="section?.my_loc_zipcode_label" [id]="section?.my_loc_zipcode_label_id" class="form-label bk-form-label" [innerHTML]="section?.my_loc_zipcode_label | safeHtml"></label>
		<div class="row mb-30">
			<div class="col-12 col-md-12 col-xl-6">
				<input bkTrimSpace [ngClass]="{'is-invalid': zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched}" id="zipcode-value" class="form-control form-control-lg bk-input" placeholder="{{section?.my_loc_zipcode_placeholder}}" formControlName="zipcode" (focusin)="zipCodeStatus = false; noZipCodeStatus = false;" (focusout)="checkZipcodeAvail($event)" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null">
				<!-- Error -->
				<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_err_id" *ngIf="zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched">
					<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_err}}
				</div>
				<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_dont_service_err_id" *ngIf="noZipCodeStatus && !zipcodeForm.controls['zipcode'].hasError('required')">
					<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_dont_service_err}}
				</div>
			</div>
		</div>
	</div>
	<!-- ML location -->
	<ng-container *ngIf="mlDesignId == 'bk_ml_loc_v1' && (settings && settings?.form_data && settings?.form_data?.preferred_service_location == 'merchant_location' || zipcodeForm.controls['location_type'].value == 'ML')">
		<label *ngIf="section?.merchant_loc_zipcode_label" [id]="section?.merchant_loc_zipcode_label_id" class="form-label bk-form-label" [innerHTML]="section?.merchant_loc_zipcode_label | safeHtml"></label>
		<div class="row mb-30">
			<div class="col-12 col-md-12 col-xl-6">
				<input [ngClass]="{'is-invalid': zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched}" class="form-control form-control-lg bk-input" placeholder="{{section?.merchant_loc_zipcode_placeholder}}" [(ngModel)]="mlTypeKeyword" [ngModelOptions]="{standalone: true}" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null">
			</div>
			<ng-container *ngIf="bookingType == 'add'">
				<!-- Find location btn -->
				<div class="col-12 col-md-12 col-xl-6 d-grid d-md-block mt-20 mt-xl-0 tjs-animate-faderight">
					<ng-container *ngTemplateOutlet="mlBtn"></ng-container>
				</div>
				<div class="col-12 col-md-12">
					<!-- Error -->
					<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_err_id" *ngIf="zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched">
						<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_err}}
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>

	<div class="position-relative">
		<!-- Merchant address (selected and first 3) -->
		<div class="row mx-n10 tjs-animate-fadeup" *ngIf="mlDesignId != 'bk_ml_loc_v3' && selectedMerchantLoc; else merchantAllLocations">
			<ng-container *ngTemplateOutlet="merchantLocTemp; context:{loc: selectedMerchantLoc, isSelected: true}"></ng-container>
		</div>
		<div class="mb-20 mt-10" *ngIf="selectedMerchantLoc && merchantLocations && merchantLocations.length > 1 && mlDesignId == 'bk_ml_loc_v2'">
			<ng-container *ngTemplateOutlet="mlBtn"></ng-container>
		</div>
		<!-- First 3 address -->
		<ng-template #merchantAllLocations>
			<div *ngIf="bookingType == 'add' && merchantLocations && merchantLocations.length > 0 && zipcodeForm.controls['location_type'].value == 'ML'">
				<div class="row mx-n10 tjs-animate-fadeup">
					<ng-container *ngFor="let loc of merchantLocations; let i = index;trackBy: utilServ.trackByFnIndex">
						<ng-container *ngIf="i < 3 || (mlDesignId == 'bk_ml_loc_v3')">
							<ng-container *ngTemplateOutlet="merchantLocTemp; context:{loc: loc, isSelected: false}"></ng-container>
						</ng-container>
					</ng-container>
				</div>
				<!-- View more btn -->
				<div class="mb-20 mt-10" *ngIf="merchantLocations && merchantLocations.length > 3 && mlDesignId != 'bk_ml_loc_v3'">
					<bk-navigate [secSett]="section?.view_more_loc_btn" [innerHTML]="section?.view_more_loc_btn?.content" customClass="btn btn-primary btn-lg fs-17 border-transparent" essentialClass="bk-btn" (callback)="merchantLocationsPopup()" [btnDisabled]="(bookingType != 'add' || !isQuoteEditable) ? true : false"></bk-navigate>
				</div>
			</div>
		</ng-template>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</div>
<!-- Merchant address template -->
<ng-template #merchantLocTemp let-loc="loc" let-isSelected="isSelected">
	<div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-15 px-10">
		<div class="border h-100 card position-relative tjs-pointer tjs-hover-shadow bk-card-border bg-transparent" [ngClass]="{'border-success-imp': isSelected || (zipcodeForm.controls['location_id'].value && zipcodeForm.controls['location_id'].value == loc?.form_location?.id)}">
			<div class="card-body p-15 pe-30 text-capitalize bk-form-desc" (click)="!isSelected && merchantLocSelect(loc)">
				{{loc?.location_name | translate}}
				<ng-container *ngIf="!loc?.hide_ml_address">
					<span class="bk-form-desc">, {{loc?.merchant_location_address | translate}}</span>
					<div class="bk-form-desc" *ngIf="loc?.apt">{{'Apt' | translate}} : {{loc?.apt}}</div>
				</ng-container>
			</div>
			<div class="position-absolute d-flex flex-column text-center top-5 end-5">
				<button *ngIf="!loc?.hide_ml_image && loc?.picture" type="button" class="btn btn-link text-decoration-none bk-icon p-0 bk-form-desc" (click)="locPicturePopup(loc?.picture)"><i class="tjsicon-camera"></i></button>
				<a *ngIf="!loc?.hide_ml_address" class="text-decoration-none bk-icon bk-form-desc" target="_blank" href="{{mapAddrLink}}{{loc?.merchant_location_address}}">
					<i class="tjsicon-placeholder-1"></i>
				</a>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #mlBtn>
	<ng-container *ngIf="findLocBtnText == 'Find Locations'; else chooseAnotherLoc">
		<bk-navigate [secSett]="section?.find_loc_btn" [innerHTML]="section?.find_loc_btn?.content" customClass="btn btn-success btn-lg fs-17 border-transparent" essentialClass="h-100 bk-btn" (callback)="findMerchantLoc()" [btnDisabled]="(bookingType != 'add' || !isQuoteEditable) ? true : false"></bk-navigate>
	</ng-container>
	<ng-template #chooseAnotherLoc>
		<bk-navigate [secSett]="section?.choose_another_loc_btn" [innerHTML]="section?.choose_another_loc_btn?.content" customClass="btn btn-success btn-lg fs-17 border-transparent" essentialClass="h-100 bk-btn"  (callback)="findMerchantLoc()" [btnDisabled]="(bookingType != 'add' || !isQuoteEditable) ? true : false"></bk-navigate>
	</ng-template>
</ng-template>