<div class="position-relative" [id]="secId">
	<div class="container py-30 py-md-60" *ngIf="invData">
		<ng-container *ngIf="paymentSuccessMsg; else noPayment">
			<div class="alert alert-success text-center text-success" translate>{{paymentSuccessMsg}}</div>
		</ng-container>
		<ng-template #noPayment>
			<form [formGroup]="invPaymentForm">
				<div class="row flex-column-reverse flex-lg-row">
					<div class="col-lg-8 col-12 mb-20">
						<div class="text-sm-center m-sm-auto bk-border" [id]="section?.payment_info?.id">
							<div class="row">
								<div class="col-md-12 col-xl-10 mx-sm-auto col-12">
									<h4 class="mb-15" *ngIf="section?.payment_info?.title" [id]="section?.payment_info?.title_id" [innerHTML]="section?.payment_info?.title | safeHtml"></h4>
									<p *ngIf="section?.payment_info?.desc" class="text-balance mb-25" [id]="section?.payment_info?.desc_id" [innerHTML]="section?.payment_info?.desc"></p>
									<div class="position-relative">
										<!-- Radio Button -->
										<div class="mt-25" *ngIf="cards && cards.length > 0">
											<div class="form-check form-check-inline mb-15 mb-sm-0" [id]="section?.payment_info?.existing_card_id">
												<input type="radio" id="existing-card" class="form-check-input tjs-pointer" value="existing_credit_card" formControlName="payment_method" (change)="cardTypeChange()">
												<label for="existing-card" class="form-check-label bk-form-label bk-label-clr" [innerHTML]="section?.payment_info?.existing_card | safeHtml"></label>
											</div>
											<div class="form-check form-check-inline" [id]="section?.payment_info?.new_card_id">
												<input type="radio" id="new-card" class="form-check-input tjs-pointer"  value="new_credit_card" formControlName="payment_method" (change)="cardTypeChange()">
												<label for="new-card" class="form-check-label bk-form-label bk-label-clr" [innerHTML]="section?.payment_info?.new_card | safeHtml"></label>
											</div>
										</div>
										<div class="my-25 text-start">
											<div class="mb-15 form-group bk-label-clr bk-label-font-weight" *ngIf="invPaymentForm.controls['payment_method'].value == 'existing_credit_card' && cards && cards.length > 0; else newCard;" [id]="section?.payment_info?.saved_card_id">
												<div *ngIf="initServ.paymentGateway == 'stripe'" class="mb-20">
													<ng-container *ngTemplateOutlet="stripeLocTemp"></ng-container>
												</div>
												<label *ngIf="section?.payment_info?.existing_card" [innerHTML]="section?.payment_info?.saved_card | safeHtml" class="mb-15 form-label bk-form-label bk-label-clr bk-label-fw"></label>
												<select class="form-select mb-20" formControlName="pay_with_cc" (change)="onCardChange($event)">
													<ng-container *ngFor="let card of cards;trackBy: section.trackByFnIndex">
														<option [value]="card?.id" *ngIf="card?.is_default; else elseDefault">XXXX XXXX XXXX {{card?.last4}}</option>
														<ng-template #elseDefault>
															<option [value]="card?.id">XXXX XXXX XXXX {{card?.last4}}</option>
														</ng-template>
													</ng-container>
												</select>
												<div>
													<img loading="auto" [src]="initServ.img?.PaymentCard" width="318" height="30" class="img-fluid" alt="SSL Card">
												</div>
											</div>
											<ng-template #newCard>
												<ng-container [ngSwitch]="initServ.paymentGateway">
													<!-- Stripe -->
													<div *ngSwitchCase="'stripe'" class="mb-20">
														<ng-container *ngTemplateOutlet="stripeLocTemp"></ng-container>
														<div *ngIf="buildPaymentForm">
															<bk-payment-gateway [locationId]="stripeLocId" [baseLoc]="true" [isDynamic]="true" [section]="section?.payment_info" [billingAddr]="true" [billingAddrForm]="invPaymentForm"></bk-payment-gateway>
														</div>
													</div>
													<!-- Square/Authorize/Paypal -->
													<ng-container *ngSwitchDefault>
														<div class="mb-20" *ngIf="buildPaymentForm">
															<bk-payment-gateway [isDynamic]="true" [section]="section?.payment_info" [billingAddr]="true" [billingAddrForm]="invPaymentForm"></bk-payment-gateway>
														</div>
													</ng-container>
												</ng-container>
											</ng-template>
										</div>
										<bk-phantom-field [control]="invPaymentForm.controls['inv_payment_timestamp']"></bk-phantom-field>
										<div class="row flex-md-row-reverse gap-md-0 gap-15">
											<bk-navigate [isDebounce]="true" class="col-12 col-md-9" [secId]="section?.payment_info?.button?.id" [designId]="section?.payment_info?.button?.design_id" [innerHTML]="section?.payment_info?.button?.content"  essentialClass="w-100 bk-form-btn" [btnDisabled]="(initServ.paymentGateway === 'stripe' && invPaymentForm.controls['payment_method'].value === 'new_credit_card' && !paymentGatewayChild?.stripeKey)" customClass="btn btn-success" (callback)="submitForm()"></bk-navigate>
											<bk-navigate class="col-12 col-md-3" [secId]="section?.payment_info?.back_btn_id" [innerHTML]="section?.payment_info?.back_btn?.content" essentialClass="w-100" customClass="btn btn-white" (callback)="backToInv()" [designId]="section?.payment_info?.back_btn?.design_id"></bk-navigate>
										</div>
										<bk-app-loader [loaderId]="cardSecLoaderId"></bk-app-loader>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-12 mb-lg-0 mb-20">
						<bk-inv-sidebar [invSidebar]="section?.sidebar" [invData]="invData" [bkngAmtData]="invServ.bkngAmtData" [isForm]="false" [isInvPayment]="true"></bk-inv-sidebar>
					</div>
				</div>
			</form>
		</ng-template>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<ng-template #stripeLocTemp>
	<div *ngIf="invData && locationStatus && stripeIds && stripeIds.length > 1 && locations && (locations).length > 0" [formGroup]="invPaymentForm" [id]="section?.location?.id" [class.mb-20]="!(initServ.allowBillingAddr)">
		<label *ngIf="section?.location?.label" class="form-label bk-form-label bk-label-clr bk-label-fw" [innerHTML]="section?.location?.label | safeHtml"></label>
		<div>
			<select [ngClass]="{'is-invalid' : invPaymentForm.controls['location_id'].touched && invPaymentForm.controls['location_id'].errors}" class="form-select" formControlName="location_id" (change)="changeStripeLoc(true)">{{invPaymentForm.controls['location_id'].value}}
				<option value=null disabled selected hidden>{{section?.location?.placeholder}}</option>
				<ng-container *ngFor="let location of locations; trackBy: utilServ.trackByFnIndex">
					<option [value]="location.location_id">{{location?.location?.location_name}}</option>
				</ng-container>
			</select>
		</div>
		<div class="tjs-error" *ngIf="invPaymentForm.controls['location_id']?.touched && invPaymentForm.controls['location_id']?.errors?.required" translate><i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.empty}}</div>
	</div>
</ng-template>

