import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
// External lib
import { ToastrService} from 'ngx-toastr';
import { sha512Hash } from 'iron-crypto-pkg';
// Services
import { UtilServ, ApiServ, InitServ, LoaderServ } from '../../Services';
// Constants
import { IS_DEV, DEV_HOST } from '../../Constants';

@Injectable()
export class AuthServ {
	// Private variables
	private destroy = new Subject<void>();
	public token: string;
	ipAddress: any = this.initServ.ipAddress ? this.initServ.ipAddress : "107.180.54.183";
	cypherToken: string = 'NPZ8fvABP5pKwU3';
	cypherTokenTwo: string = 'AP6EHEG37zJ2c9j';

	constructor(private utilServ: UtilServ, private apiServ: ApiServ, private toastr: ToastrService, private initServ: InitServ, private router: Router, private loader: LoaderServ) {
		const currentUser = this.utilServ.appLocalStorage();
		this.token = currentUser && currentUser.token;
	}
	/**
	 * Login user and set the current user data
	 * @param user
	 * @param redirectUrl
	 * @param loaderId
	 */
	// eslint-disable-next-line max-params, max-lines-per-function
	public login(user:any, redirectUrl: string='', loaderId:string='', isRedirect: boolean = false, isSession:boolean = false, isBooknow:boolean = false, isSetOldData: boolean = false): Observable<any> {
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		if(loaderId){
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);
		}
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress };
		// eslint-disable-next-line complexity
		return this.apiServ.callApi('POST', 'Login', user, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any)=>{
			// login successful if there's a jwt token in the response
			if(this.apiServ.checkAPIRes(res)){
				let userInfo: any = res.data;
				// Set the user local storage
				if(!isSession || !this.utilServ.inIframe(this.utilServ.embedStatus)){
					await this.setUserLocalStorage(userInfo, isSetOldData);
					// Role customer, called single user information
					if(userInfo.role == 'customer' && (isBooknow || !this.utilServ.inIframe(this.utilServ.embedStatus))){
						await this.initServ.loggedInUser(userInfo.id);
					}
				}
				// Redirect app based on role
				let redirect = ''
				switch(userInfo.role){
					case 'provider':
						redirectUrl = '/provider';
						redirect = '/provider/login/' + userInfo.access_token;
						break;
					case 'merchant':
						if(!userInfo.is_default_setup){
							redirectUrl = '/admin/start/' + userInfo.access_token;
							redirect = '/admin/start/' + userInfo.access_token;
						} else{
							redirectUrl = '/admin/dashboard';
							redirect = '/admin/login/' + userInfo.onetime_access_token;
						}
						break;
					case 'staff':
						redirectUrl = '/admin/dashboard';
						redirect = '/admin/login/' + userInfo.onetime_access_token;
						break;
					default:
						redirectUrl = '/dashboard';
						if(isRedirect){
							// Store the current loggedIn user all information
							redirect = '/login/' + userInfo.access_token;
						}
						break;
				}
				if(isRedirect){
					if(this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme){
						if(top){
							top.window.location.href = redirect;
						}
					} else{
						this.toastr.success(this.initServ.appStr.toastr.loggedIn);
						if(redirectUrl == '/dashboard'){
							this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
						} else {
							window.location.href = redirectUrl;
						}
					}
				} else{
					if (userInfo.role != 'customer') {
						window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/' + redirect;
					}
				}
				loaderId && this.loader.hide(loaderId);
				return true;
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
				this.loader.hide(loaderId);
				return false;
			}
		});
	}
	/**
	 * Login user using access token
	 * @param accessToken
	 * @param loaderId
	 * @returns
	 */
	public async loginAsUser(accessToken: any, loaderId: string ='',isSetOldData: boolean = false ): Promise<Observable<any>> {
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		if(loaderId){
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);
		}
		let token = this.cypherToken + accessToken + this.cypherTokenTwo + this.ipAddress.replace(/\./g, '')
		let encryptKey = await sha512Hash(token);
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress };
		let postdata = { access_token: accessToken };
		return this.apiServ.callApiWithPathVariables('POST', 'LoginAsUser', [encryptKey], postdata, header).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any)=>{
			// login successful if there's a jwt token in the response
			if(this.apiServ.checkAPIRes(res)){
				let userInfo: any = res.data;
				// Set the user local storage
				await this.setUserLocalStorage(userInfo, isSetOldData);
				// Role customer, called single user information
				if(userInfo.role == 'customer'){
					await this.initServ.loggedInUser(userInfo.id);
				}
				loaderId && this.loader.hide(loaderId);
				return true;
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
				this.loader.hide(loaderId);
				return false;
			}
		});
	}
	/**
	 * Login as admin(Login user as admin)
	 * @param currentUser
	 * @returns
	 */
	public async loginAsAdmin(currentUser: any): Promise<Observable<any>> {
		let token = this.cypherToken + currentUser.admin_token + this.cypherTokenTwo + (currentUser.Ip).replace(/\./g, '')
		let encryptKey = await sha512Hash(token);
		let postdata = { onetime_access_token: currentUser.admin_token };
		return this.apiServ.callApiWithPathVariables('POST', 'LoginWithOtToken', [this.utilServ.userId(),encryptKey], postdata).pipe(takeUntil(this.destroy)).toPromise().then(async (res: any)=>{
			// login successful if there's a jwt token in the response
			if(this.apiServ.checkAPIRes(res)){
				let userInfo: any = res.data;
				// Set the user local storage
				await this.setUserLocalStorage(userInfo);
				this.toastr.success(this.initServ.appStr.toastr.loggedIn);
				window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/admin/dashboard';
				return true;
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
				return false;
			}
		});
	}
	/**
	 * Signup new user and set the current user data
	 * @param data
	 * @param authToken
	 * @param loaderId
	 */
	public async signup(data: any, authKey: any, loaderId: string, isSession: boolean = false, isSocial: boolean = false): Promise<void> {
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);
		let authToken = await sha512Hash(authKey);
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress, 'Authtoken': authToken};
		this.apiServ.callApi('POST', 'Signup', data, header).pipe(takeUntil(this.destroy)).subscribe(async (res: any) => {
			// Signup successful
			if(this.apiServ.checkAPIRes(res)){
				let userInfo: any = res.data;
				// Set the user local storage
				if(!isSession || !this.utilServ.inIframe(this.utilServ.embedStatus)){
					await this.setUserLocalStorage(userInfo);
					// Role customer, called single user information
					if(userInfo.role == 'customer' && !this.utilServ.inIframe(this.utilServ.embedStatus)){
						await this.initServ.loggedInUser(userInfo.id);
					}
				}
				// Iframe
				let redirectUrl = '/login/' + userInfo.access_token
				if(this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme){
					if(top){
						top.window.location.href = redirectUrl;
					}
				} else{
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]).then(() => {
						if(isSocial){
							this.toastr.success(res.message);
						} else {
							this.toastr.success(this.initServ.appStr.toastr.signUp);
						}
					});
				}
				this.loader.hide(loaderId);
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
				this.loader.hide(loaderId);
			}
		});
	}
	/**
	 * Set the user local storage
	 * @param userInfo
	 */
	public setUserLocalStorage(userInfo: any, isSetOldData: boolean = false): void {
		let obj: any = {
			Ip: this.ipAddress,
			// username: userInfo.email ? userInfo.email : userInfo.email_id,
			token: userInfo.session_token,
			id: userInfo.id,
			first_name: userInfo.first_name,
			last_name: userInfo.last_name,
			// email: userInfo.email ? userInfo.email : userInfo.email_id,
			photo_url: userInfo.photo_url,
			role: userInfo.role,
			status: userInfo.status,
			access_token: userInfo.access_token,
			is_default_setup: userInfo.is_default_setup,
			onetime_access_token: userInfo.onetime_access_token,
			is_new: userInfo.is_new,
			old_local_storage: null
		}
		// Only for embed get the old local storage
		if(isSetOldData && this.utilServ.inIframe(this.utilServ.embedStatus)){
			let oldLocalStorage: any = this.utilServ.appLocalStorage('currentUser', true);
			if(oldLocalStorage){
				obj['old_local_storage'] = oldLocalStorage;
			}
		}
		// store username and jwt token in local storage to keep user logged in between page refreshes
		try{
			localStorage.setItem('currentUser', JSON.stringify(obj));
		}catch(err){}
	}
	/**
	 * Request reset password, get the link on email id
	 * @param data
	 * @param loaderId
	 */
	public reqResetPassword(data: any, loaderId: string, isRedirect: boolean = true): void {
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);
		this.apiServ.callApi('POST', 'ReqResetPassword', data).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
			// Forgot password successful
			if(this.apiServ.checkAPIRes(res)){
				let url = '/'+this.initServ.appDynamicRoutes['login'];
				if(isRedirect){
					if(this.utilServ.embedStatus){
						this.router.navigate([url], { queryParams: { embed: "true"}}).then(() => {
							this.toastr.success(res.message);
						});
					} else {
						this.router.navigate([url]).then(() => {
							this.toastr.success(res.message);
						});
					}
				} else {
					this.toastr.success(res.message);
				}
				this.loader.hide(loaderId);
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
				this.loader.hide(loaderId);
			}
		});
	}
	/**
	 * Reset password
	 * @param data
	 * @param loaderId
	 */
	public resetPassword(data: any, loaderId: string, isRedirect: boolean = true): Observable<any> {;
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);
		return this.apiServ.callApi('POST', 'ResetPassword', data).pipe(takeUntil(this.destroy)).toPromise().then((res: any)=>{
			// Reset password successful
			if(this.apiServ.checkAPIRes(res)){
				if(isRedirect){
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['login']]).then(() => {
						if(res){
							this.toastr.success(res.message);
						}
					});
					this.loader.hide(loaderId);
				}
				return true;
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
				this.loader.hide(loaderId);
				return false;
			}
		});
	}
	/**
	 * Remove current user local storage
	 */
	public removeCurrentUser(): void {
		try{
			localStorage.removeItem('currentUser');
			localStorage.removeItem('passwordProtected');
			this.initServ._userInfo = null;
			this.initServ.isUserProfile.next(true);
		}catch{}
	}
	/**
	 * Logout user
	 */
	public logout(): void {
		this.apiServ.callApiWithPathVariables('GET', 'Logout', [this.utilServ.userId()]).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
			// successful
			if(this.apiServ.checkAPIRes(res)){
				this.removeCurrentUser();
				let siteData: any = this.initServ.siteData; // App site data
				if(siteData && siteData.theme_settings && siteData.theme_settings.settings && siteData.theme_settings.settings.menu_logout_link){
					window.location.href = this.utilServ.checkHttpExist(siteData.theme_settings.settings.menu_logout_link);
				} else {
					window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/';
				}
			} else{
				// Indicate failed
				if(res && res.message){
					this.toastr.error(res.message, '', {timeOut: 10000});
				}
			}
		});
	}
	/**
	 * Redirect to panel from embed, if old local storage is not exists
	 * Called this function add booking and send gift cards
	 */
	public redirectFromEmbed(){
		if(this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme){
			let currentUser: any = this.utilServ.appLocalStorage('currentUser', true);
			if(currentUser  && currentUser.access_token && (!currentUser.old_local_storage || (currentUser.old_local_storage && currentUser.old_local_storage.role == 'customer'))){
				// Store the current loggedIn user all information
				return '/login/' + currentUser.access_token;
			} else {
				return '';
			}
		} else {
			return '';
		}
	}
}
