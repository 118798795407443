import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Self, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
// Serviecs
import { NgOnDestroy, InitServ, UtilServ, ApiServ, LoaderServ, CacheService } from '../../../../../../Services';
import { UserPopupServ } from '../../../../UserPopup.service';


@Component({
	selector: 'bk-postpone',
	templateUrl: './Postpone.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class PostponeComponent implements OnInit {
	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	booking: any;
	postponeDates: any;
	type: string = 'CanPostpone'; // CanPostpone/Postpone
	//
	selectedMonth: any;
	selectedPostponeDate: any;
	rescheduleTimeType: any;
	isBkngPreCharged: boolean = false;
	postponePrecharge: boolean = false;
	isRefund: boolean = false;
	serviceType: any;
	rescFeeCharge: boolean = false;
	chargeAmount: number = 0;
	accordingMonths: any = {};
	postponeDateMonths: any = [];
	loaderId: string = 'postpone-loader';
	errorMessage: boolean = false;
	// variables for the building the section
	popupData: any;
	secId: any;
	slug: string = 'can_postpone';
	section: any = {};
	/**
	 * Constructor
	 */
	// eslint-disable-next-line max-params, complexity
	constructor(public dialogRef: MatDialogRef<PostponeComponent>, private router: Router, @Self() private destroy: NgOnDestroy, private toastr: ToastrService, public translate: TranslateService, public initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, private userPopupServ: UserPopupServ, private cDRef: ChangeDetectorRef, private cacheServ: CacheService) {
		// Postpone booking store settings
		if (this.admnStngs && this.admnStngs.merchant_settings) {
			if (this.admnStngs.merchant_settings.reschedule && this.admnStngs.merchant_settings.reschedule.reschedule_time_type) {
				this.rescheduleTimeType = this.admnStngs.merchant_settings.reschedule.reschedule_time_type
			}
			if (this.admnStngs.merchant_settings.cancellation) {
				if (this.admnStngs.merchant_settings.cancellation.customer_postpone_pre_charge_service && this.admnStngs.merchant_settings.cancellation.customer_postpone_pre_charge_service == 'yes') {
					this.postponePrecharge = true;
				}
				if (this.admnStngs.merchant_settings.cancellation.postpone_action_on_precharge && this.admnStngs.merchant_settings.cancellation.postpone_action_on_precharge == 'refund_and_postpone') {
					this.isRefund = true;
				}
			}
		}
	}
	/**
	 * On Init
	 */
	ngOnInit(): void {
		if (this.type == 'Postpone') {
			this.postpone();
		} else {
			this.buildDynamicSec();
		}
	}
	/**
	 * This function builds a dynamic section for a postpone or can_postpone popup and sets its style based on the type of
	 * dialog.
	 */
	private buildDynamicSec(): void {
		// fields for postpone & can-postpone sections
		this.section = {};
		let postponeSec: any = { title: null, desc: null, select: null, postpone_btn: null, cancel_btn: null };
		let canPostponeSec: any = { title: null, postpone_btn: null, cancel_btn: null };
		// set slug value
		this.slug = (this.type == 'Postpone') ? 'postpone' : 'can_postpone';
		// set section object.
		this.section = (this.type == 'Postpone') ? { ...postponeSec } : { ...canPostponeSec };
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect the changes
			this.cDRef.detectChanges();
		});
	}

	/**
	 * Cancel booking popups
	 */
	public cancel(): void {
		if([6, 7, 8].includes(this.booking.status) && (this.admnStngs.merchant_settings?.bookings?.show_all_unassigned_bookings && this.admnStngs.merchant_settings?.bookings?.show_all_unassigned_bookings == 'no')){
			this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
		}else{
			this.openCancelPopupBasedOnAdminSett();
			// this.userPopupServ.cancelServicePopup(this.booking).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
		}
	}
	/**
	 * On popup result callback method
	 * @param res: popup res
	 * Popup response handler
	 */
	private popupCallback(res: any): void {
		if (res) {
			this.dialogRef.close(true);
		}
	}
	/**
	 * Postpone service
	 */
	public postpone() {
		this.type = 'Postpone';
		// build dynamic section based on the type.
		this.buildDynamicSec();
		//
		if (this.booking) {
			this.selectedMonth = null;
			this.buildPostponeSection();
			this.isBkngPreCharged = this.utilServ.bkngPreCharged(this.booking); // Booking preCharged
			this.setChargeAmt();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build postpone section values
	 * If postponeDates is null, call 'postponeDates' api
	 */
	private buildPostponeSection(): void {
		if (this.postponeDates && (this.postponeDates).length > 0) {
			for (let data of this.postponeDates) {
				let m = this.monthName(data.arrival_date);
				if (this.accordingMonths[m] === undefined) {
					this.accordingMonths[m] = [];
				}
				this.accordingMonths[m].push(data);
				if (!this.postponeDateMonths.includes(m)) {
					this.postponeDateMonths.push(m)
				}
			}
			this.cDRef.detectChanges();
		} else {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.apiServ.callApiWithPathVariables('GET', 'PostponeDates', [this.booking._id]).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'PostponeDates'));
		}
	}
	/**
	 * Get the month name using timestamp
	 * @param timestamp
	 * @returns
	 */
	private monthName(timestamp: any): string {
		dayjs.extend(utc);
		let monthName = dayjs.utc(timestamp * 1000).format('MMMM');
		let year = +dayjs.utc(timestamp * 1000).format('YYYY');
		return this.translate.instant(monthName) + '-' + year;
	}
	/**
	 * Reschedule fee popup should be opened or not
	 * @returns boolean
	 */
	// eslint-disable-next-line complexity
	private checkIfRescFeeCharge() {
		if (!this.booking.exclude_reschedule_fee && this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.reschedule && this.admnStngs.merchant_settings.reschedule.charge_reschedule_fees && this.admnStngs.merchant_settings.reschedule.charge_reschedule_fees == 'yes' && this.admnStngs.merchant_settings.reschedule.charge_resc_fee_on_postpone && this.booking.status != 3 && this.booking.status != 9 && this.utilServ.isChargeRescheduleFee(this.booking, this.booking, this.serviceType, true)) {
			this.rescFeeCharge = true
			return true;
		}
		this.rescFeeCharge = false
		return false
	}
	/**
	 * Timestamp to date string format
	 * @param timestamp
	 * @returns
	 */
	public convertToDate(timestamp: any) {
		let date: any = dayjs.utc(timestamp * 1000);
		return this.translate.instant(date.format('MMMM')) + " " + date.date() + " - " + this.translate.instant(date.format('dddd'));
	}
	/**
	 * Postponse service api call
	 */
	public postponeService(): void {
		let bookingData: any = {};
		if (this.chargeAmount > 0 && this.booking.payment_method == 'existing_credit_card' && this.rescFeeCharge) {
			bookingData['resch_request'] = {
				booking_id: this.booking._id,
				amount: this.chargeAmount,
				dont_send_notification: false,
				charge_fee: true
			};
		}
		if (this.selectedPostponeDate) {
			this.errorMessage = false;
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			let queryParams = { date: this.selectedPostponeDate, refund: this.refundPreCharge() };
			this.apiServ.callApiWithPathQueryVars('POST', 'PostponeBooking', [this.booking._id], queryParams, bookingData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
		} else {
			this.errorMessage = true;
			this.toastr.error(this.initServ.appStr.toastr.postponeDate);
		}
	}
	/**
	 * Refund precharge
	 * @returns boolean
	 */
	private refundPreCharge(): boolean {
		if (this.isBkngPreCharged && this.postponePrecharge && this.isRefund) {
			return true;
		}
		return false;
	}
	/**
	 * On result callback method
	 * @param res API res
	 * @param type PostponeDates/postpone
	 * API response handler
	 */
	// eslint-disable-next-line complexity
	private onResultCallback(res: any, type: string = ''): void {
		if (type == 'PostponeDates') {
			if(this.apiServ.checkAPIRes(res, false) && res.data && res?.data?.bookings && this.utilServ.checkArrLength(res?.data?.bookings)){
				this.postponeDates = res?.data?.bookings;
				this.buildPostponeSection();
			}
		} else {
			if (this.apiServ.checkAPIRes(res)) {
				this.toastr.success(res.message);
				this.dialogRef.close(true);
				if ((window.location.href).includes("reschedule-booking")) {
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);

				}
			} else {
				// this.dialogRef.close();
				if (res && res.message) {
					this.toastr.error(res.message);
				}
			}
		}
		this.loader.hide(this.loaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}

	/**Function to update selected month */
	public onSelectMonth(e: any) {
		this.errorMessage = false;
		this.selectedMonth = e.target.value;
		this.selectedPostponeDate = null;
	}
	// TODO: Common code in "CancelBookingComponent" & "PostponeComponent" (Lakhvir)
	/**
	 * Opens the cancel service popup based on admin settings and booking options.
	 * Determines the cancellation options available based on admin settings
	 * and the type of booking (single or recurring). It then opens the cancel service popup accordingly.
	 */
	private openCancelPopupBasedOnAdminSett(): void {
		// Get the cancellation options available for the current booking
		let custRecBkngCancelOpt: string[] = this.getCustRecBkngCancelOpt();
		// Check if multiple cancellation options are available
		if(custRecBkngCancelOpt.length > 1){
			// If there are multiple options, handle accordingly
			if(custRecBkngCancelOpt.length == 2 && custRecBkngCancelOpt.includes('upcoming') && custRecBkngCancelOpt.includes('all') && this.booking.is_first){
				// Open the cancel service popup for recurring bookings with 'upcoming' and 'all' options
				this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			}else{
				// Open the cancel service popup for 'this', 'upcoming', and 'all' options
				this.userPopupServ.cancelServicePopup(this.booking).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			}
		}else{
			// If only one cancellation option is available, proceed with that option
			this.oneCancelRecBkngOptAction(custRecBkngCancelOpt[0]);
		}
	}
	/**
	 * Handles the action when there is only one cancellation option available.
	 * Opens the cancel service popup based on the specified option.
	 * @param {string} option - The cancellation option to handle ('single', 'upcoming', or 'all')
	 */
	private oneCancelRecBkngOptAction(option: string): void {
		// Handle the action based on the specified cancellation option
		switch (option) {
			case 'single':
				// Open the cancel service popup for a single booking
				this.userPopupServ.cancelServicePopup(this.booking, 'single').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				break;
			case 'upcoming':
				// Open the cancel service popup for upcoming occurrences of a recurring booking
				this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				break;
			case 'all':
				// If it's the first occurrence of a recurring booking, open the cancel service popup for all occurrences
				if(this.booking.is_first){
					this.userPopupServ.cancelServicePopup(this.booking, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
				}else{
					// If it's not the first occurrence, fetch booking data for the first occurrence from the API
					this.loader.show();
					// Fetch booking data for the first occurrence from the API
					this.apiServ.callApiWithPathVariables('GET', 'RecurringScheduleFirstBkng', [this.booking._id]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.recurringScheduleFirstBkngRes(res));
				}
				break;
		}
	}
	/**
	 * Retrieves the cancellation options available for customer recurring bookings from admin settings.
	 * If the options are not defined in admin settings, default options ('single' and 'upcoming') are returned.
	 * @returns {string[]} - An array of cancellation options available for customer recurring bookings
	 */
	private getCustRecBkngCancelOpt(): string[] {
		// Check if cancellation options are defined in admin settings
		if(this.initServ.appAdmnStngs?.merchant_settings?.cancellation?.customer_recurring_booking_cancellation_options){
			// Return the defined cancellation options
			return this.initServ.appAdmnStngs?.merchant_settings?.cancellation?.customer_recurring_booking_cancellation_options;
		}
		// If cancellation options are not defined, return default options
		return ['single', 'upcoming'];
	}
	/**
	 * Handles the response for fetching booking data for the first occurrence in a recurring schedule.
	 * @param {any} res - The response object from the 'BookingData' API for the first booking
	 */
	private recurringScheduleFirstBkngRes(res: any): void {
		this.loader.hide();
		if(this.apiServ.checkAPIRes(res)){
			// Extract the first booking data from the response
			let firstBkng: any = res.data;
			// Check if payment logs are available for the first booking
			if(this.utilServ.checkArrLength(firstBkng?.payment_log)){
				this.userPopupServ.cancelServicePopup(firstBkng, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
			}else{
				// If payment logs are not available, fetch payment logs for the first booking
				this.loader.show();
				this.apiServ.callApiWithPathVariables('GET', 'PaymentLogs', [firstBkng._id]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.firstBkngPaymentLogApiRes(res, firstBkng));
			}
		}
	}
	/**
	 * Handles the response for fetching payment logs for the first booking in a recurring schedule.
	 * @param {any} res - The response object from the 'PaymentLogs' API for the first booking
	 * @param {any} firstBkng - The first booking object for which payment logs are fetched
	 */
	private firstBkngPaymentLogApiRes(res: any, firstBkng: any): void {
		// Check the API response for validity and the availability of payment logs
		if(this.apiServ.checkAPIRes(res, false) && this.utilServ.checkArrLength(res?.data)){
			// If payment logs are available, update the first booking object with the payment logs
			firstBkng['payment_log'] = res.data;
		}
		this.userPopupServ.cancelServicePopup(firstBkng, 'recurring').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res));
		this.loader.hide();
	}

	/**
	 * Sets the charge amount for a booking.
	 */
	private setChargeAmt(): void {
		// Load service categories and frequencies asynchronously
		this.initServ.loadServCatsAndFreqs().then(() => {
			// Get the selected service type based on the booking's service category
			this.serviceType = this.getSelectedServ(+this.booking.service_category);
			// Check if a reschedule fee needs to be charged and if the payment method is 'existing_credit_card'
			if (this.checkIfRescFeeCharge() && this.booking.payment_method == 'existing_credit_card') {
				// Calculate the reschedule fee and assign it to chargeAmount
				let obj: any = this.utilServ.rescheduleFee(this.booking, this.serviceType);
				this.chargeAmount = obj.amount;
			}
		});
		// Trigger change detection to ensure the view is updated with the new charge amount
		this.cDRef.detectChanges();
	}

	/**
	 * Retrieves the selected service based on the provided service ID.
	 * @param {number} servId - The ID of the service to retrieve.
	 * @returns {any} The service object that matches the provided ID, or `undefined` if not found.
	 */
	private getSelectedServ(servId: number): any {
		// Check if the list of all service categories exists and has at least one item
		if (this.initServ.allServCats && (this.initServ.allServCats).length > 0) {
			// Iterate through the service categories
			for (let serv of this.initServ.allServCats) {
				// Return the service if the ID matches the provided service ID
				if (+serv.id == servId) {
					return serv;
				}
			}
		}
	}
}