import { Component, Input, OnInit, ViewEncapsulation, Self} from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, ApiServ, UtilServ } from '../../../Services';
// Constants
import { EMAIL_REG_EXP } from '../../../Constants';

@Component({
	selector: 'bk-blog-sidebar',
	templateUrl: './BlogSidebar.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BlogSidebarComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() slug: any;
	@Input() blogs: any;
	@Input() settings: any;
	@Input() pageSett: any;
	subscribeForm: FormGroup;
	pageSlug: string = 'blog';

	constructor(private actRoute: ActivatedRoute,private frmBldr: FormBuilder, public initServ: InitServ, private toastr: ToastrService, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, public utilServ: UtilServ) {
		this.pageSlug = this.actRoute.snapshot.params['type'] ? this.actRoute.snapshot.params['type'] : 'blog';
		this.subscribeForm = this.frmBldr.group({
			email: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]]
		})
	}

	ngOnInit(): void {
	}

	/**
	 * Submit the subscription form, after the check the validation
	 */
	public submitForm(): void{
		if(this.subscribeForm.valid){
			this.subscribeForm.controls['email'].setValue((this.subscribeForm.controls['email'].value).toLowerCase());
			let sendData = this.subscribeForm.value;
			sendData['list_id'] = null;
			if(this.pageSett && this.section && this.section.subscribe && this.pageSett[this.section.subscribe.id] && this.pageSett[this.section.subscribe.id].mailchimp_list_id){
				sendData['list_id'] = this.pageSett[this.section.subscribe.id].mailchimp_list_id;
			}
			this.apiServ.callApi('POST', 'SubscribeEmail', sendData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		}else{
			this.subscribeForm.controls['email'].markAsTouched();
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.toastr.success(res.message);
		} else{
			if(res && res.message){
				this.toastr.error(res.message);
			}
		}
		this.subscribeForm.reset();
		this.subscribeForm.controls['email'].markAsUntouched();
	}
}
