/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
import { Component, OnInit, Input, ViewChild, ViewEncapsulation, Self,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from "rxjs/operators";
declare const top: any
declare const gtag: any;
declare const parentToTop: any;
// External library
import dayjs from 'dayjs';
import KeenSlider from "keen-slider";
import { ToastrService} from 'ngx-toastr';
// Child component, Custom validator
import { PaymentGatewayComponent, CustomValidators } from '../../Global/GlobalDefault';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ, ApiServ, LoaderServ, PaymentGatewayServ, LeadsServ, PaymentGatewayPayload } from '../../Services';
import { AuthServ } from '../../Core/_services'; // Auth Service
// Constants
import { TEXT_REG_EXP, EMAIL_REG_EXP, DEV_HOST, IS_DEV, DB_DATE_FORMAT } from '../../Constants';
// Interface
import { BillingAddress, BillingCard } from 'src/app/Interfaces';
type Steps = 'details' | 'payment';

@Component({
	selector: 'bk-send-gift-card',
	templateUrl: './SendGiftCard.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SendGiftCardComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined; //Payment gateway component
	@ViewChild("giftCardSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	// Slider configuration
	public slideConfig = {
		breakpoints: {
			"(min-width: 768px)": {
				slides: { perView: 2 },
			},
			"(min-width: 991px)": {
				slides: { perView: 3 },
			},
		},
		slides: { perView: 1,spacing: 5},
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	};
	sendForm!: FormGroup; // Gift card form
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	public currentStep = <Steps>('details'); // Gift card form steps
	slider: any = null;
	pageSett: any;
	parentPageSett: any;
	// Section fields
	section: any = {
		gallery: null,
		preview_card: null,
		form: null
	}
	galleryLoaderId: string = 'gallery-gc-loader';
	detailsLoaderId: string = 'details-gc-loader';
	paymentLoaderId: string = 'payment-gc-loader';
	editId: string | number = ''; //Gift card id
	pagePerm: boolean = true;
	formKeys: string[] = ['amount', 'message', 'password_field', 'recipient_email', 'recipient_name', 'schedule_date', 'schedule_time', 'sender_email', 'sender_first_name', 'sender_last_name', 'schedule', 'card_label', 'card_type'];
	formBtns: string[] = ['back_btn', 'next_btn', 'purchase_btn'];
	gallery: any;
	selectedImg: string = this.initServ.img.GiftCard;
	currentUser: any;
	isLoggedIn: any = null;
	emailExist: boolean = false;
	times: any = this.utilServ.generateTime(); // Time 00:00 AM to 11:00 PM
	userInfo: any = this.initServ.userInfo; // LoggedIn user info
	cards: any;
	giftCard: any;
	currentLang:string = this.initServ.savedLng;
	// Cookies info: ses_id,campaign_id,contact_id,track_from & sequence_id
	cookiesInfo: any;
	referrerSource: string = '';
	minAmountText: any;
	minDate:any = dayjs().toDate();
	isZipcode : boolean = false;
	// eslint-disable-next-line max-params
	constructor(private router: Router, private actRoute: ActivatedRoute, private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, private toastr: ToastrService, public customValidators: CustomValidators, private authServ: AuthServ, private paymentServ: PaymentGatewayServ, private leadsServ: LeadsServ) {
		if(this.initServ.paymentGateway){
			this.utilServ.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
		this.pagePerm = this.utilServ.appPermission('giftCards'); // Page permission
		// Gift card edit id
		this.editId = this.actRoute.snapshot.params['id'] ? this.actRoute.snapshot.params['id'] : '';
		// Current loggedin user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		this.isUserLoggedIn();
		if(this.pagePerm){
			// Set the RTL value
			this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
				this.slideConfig['rtl']=value;
				if(this.slider && this.sliderRef){
					this.slider.update(this.slideConfig);
				}
			});
			// Get gallery
			this.getGallery();
		} else {
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			// this.utilServ.removeStorage();
			this.utilServ.id = null;
		}
		if(this.pagePerm){
			if(!this.editId){
				// cookies & referrer url
				let cookies: any = this.utilServ.getCookieAndRefUrl();
				this.cookiesInfo = cookies.cookiesInfo; // cookies
				this.referrerSource = cookies.referrerSource; //referrer url
			}
			// Gift card form build
			this.sendForm = this.frmBldr.group({
				details: this.frmBldr.group({
					customer_id: [],
					amount: ['', [CustomValidators.giftCardAmount(this.editId, this.admnStngs)]],
					recipient_name: ['', [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
					recipient_email: ['', [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
					sender_first_name: ['', [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
					sender_last_name: ['', [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
					sender_email: ['', [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
					password : [''],
					message: ['', [Validators.pattern(TEXT_REG_EXP)]],
					image_url: [],
					schedule: ['schedule_now'],
					schedule_on_date: [],
					schedule_on_time: [],
					pay_with_cc: [],
					sent_from_ip: [this.initServ.ipAddress],
					is_marketplace: [false],
					card_last4_digits:['']
				}),
				payment: this.frmBldr.group({
					card_type: ['new']
				})
			});
			// Get the single gift card, based on id
			if(this.editId){
				this.getGiftCard();
			}
			// Build section data
			if(this.secId && this.rcServ.pageData){
				this.pageSett = this.rcServ.pageData.section_settings; // page settings
				this.parentPageSett = this.rcServ.pageData.settings;
				this.secServ.setServData(this.pageSett, this.rcServ.pageData.content, this.formKeys, this.formBtns);
				this.buildSectionFields(this.rcServ.pageData);
			}
			// User logged in get again current user local storage
			this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
				if(value){
					this.userInfo = this.initServ.userInfo;
					this.currentUser = this.utilServ.appLocalStorage();
					// Logged in user exist
					this.loggedInUser();
				}
			});
		}
	}
	// convenience getter for easy access to form fields
	get fDetails() : { [key: string]: AbstractControl }{
		return (<FormGroup>this.sendForm.get('details')).controls;
	}
	// convenience getter for easy access to form fields
	get fPayment() : FormGroup{
		return <FormGroup>this.sendForm.controls['payment'];
	}

	ngOnDestroy():void {
		if (this.slider){ this.slider.destroy()}
	}

	/**
	 * Single gift card, based on gift card id
	 */
	private getGiftCard(): void {
		this.apiServ.setLoaderId(this.detailsLoaderId);
		this.loader.show(this.detailsLoaderId);
		this.apiServ.callApiWithQueryParams('GET', 'GiftCard', {id: this.editId}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'giftCard'));
	}
	/**
	 * Logged in user exist, prefilled the user info
	 * Get the user payment cards
	 */
	// eslint-disable-next-line complexity
	private loggedInUser(): void {
		if(this.currentUser){
			this.isUserLoggedIn();

			let detailsFormGroup = <FormGroup>this.sendForm.get('details');

			if(this.initServ.theme){
				detailsFormGroup.patchValue({
					customer_id: this.currentUser.id,
					sender_first_name: this.currentUser.first_name,
					sender_last_name: this.currentUser.last_name
					// sender_email: (this.currentUser.username ? this.currentUser.username : this.currentUser.email)
				})
				// TODO: Removed using username from local storage
				if(this.initServ?.userInfo?.email_id){
					detailsFormGroup.controls['sender_email'].setValue(this.initServ?.userInfo?.email_id);
					this.detailsFocusOut();
				}
			} else{
				if(this.userInfo){
					// Prefilled user details
					detailsFormGroup.patchValue({
						customer_id: this.userInfo._id,
						sender_first_name: this.userInfo.first_name,
						sender_last_name: this.userInfo.last_name,
						sender_email: this.userInfo.email_id
					});
					// Market place
					let isMarketplace = this.userInfo.is_marketplace ? true : false;
					detailsFormGroup.controls['is_marketplace'].setValue(isMarketplace);
					this.detailsFocusOut();
				}
				// Only for embed user
				if(this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme){
					detailsFormGroup.patchValue({
						customer_id: this.currentUser.id,
						sender_first_name: this.currentUser.first_name,
						sender_last_name: this.currentUser.last_name
						// TODO: Removed using username from local storage
						// sender_email: (this.currentUser.username ? this.currentUser.username : this.currentUser.email)
					})
				}
				// Edit id not exist, get the user payment cards
				if(!this.editId){
					this.userCards();
				}
			}
			// Last name not exist, clear the validation
			if(!detailsFormGroup.value['sender_last_name']){
				detailsFormGroup.controls['sender_last_name'].clearValidators();
				detailsFormGroup.controls['sender_last_name'].updateValueAndValidity();
			}
		}
	}
	/**
	 * Logged in user
	 */
	private isUserLoggedIn(){
		this.isLoggedIn = (this.currentUser && this.currentUser.token) ? true : null;
	}
	/**
	 * Change the gift card form steps
	 * @param currentStep details/payment
	 */
	public changeStep(currentStep: any) {
		if(currentStep == 'details'){
			this.fDetails['sender_email'].setValidators([Validators.required, Validators.pattern(EMAIL_REG_EXP)]);
			this.fDetails['sender_email'].updateValueAndValidity();
		}

		this.currentStep = currentStep;

		this.initServ.reRender = true;
		setTimeout(() => {
			// Call function to scroll to specific element
			this.utilServ.scrollToSpecificEle('payment-sec');
		}, 100);
	}
	/**
	 * Build the gift card sections fields
	 */
	// eslint-disable-next-line complexity
	private buildSectionFields(secData:any): void{
		let secElem : any = null;
		if(secData.sections){
			secElem = secData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "form":
							this.section[key] =  this.secServ.buildForm(secElem[key]);
							break;
						case "gallery":
						case "preview_card":
							this.section[key] =  this.secServ.buildGroup(secElem[key]);
							break;
						default:
							this.section[key] = this.secServ.checkElementStatus(secElem[key]) && secElem[key];
							break;
					}
				}
			}
		}

		// Replace token with relevant value
		if(this.section && this.section.form && this.section.form?.amount?.min_amount){
			this.minAmountText = this.utilServ.generateDynamicStr(this.section.form?.amount?.min_amount, '{{.GiftCardMinAmount}}', this.utilServ.amountWithCurrency(this.admnStngs?.merchant_settings?.admin?.min_gift_card_amount));
		}
		// TODO: ANUPAM, remove this code after 2 months when it goes live.
		if(!this.section?.form?.address){
			this.section.form.address = this.utilServ.loadDefaultElemJson('address');
		}
	}
	/**
	 * Gift card gallery
	 * Gallery handle by admin app
	 */
	private getGallery(): void {
		this.apiServ.setLoaderId(this.galleryLoaderId);
		this.loader.show(this.galleryLoaderId);
		this.apiServ.callApi('GET', 'GiftCardGallery').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'gallery'));
	}
	/**
	 * LoggedIn user cards
	 */
	private userCards(): void {
		this.apiServ.callApiWithPathQueryVars('GET', 'UserCards', [this.currentUser.id], {location_id: 0, is_giftcard:1}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'cards'));
	}
	/**
	 * Card type changes
	 * Card types: existing/new
	 */
	public cardTypeChange(): void {
		if(this.fPayment.controls['card_type'].value == 'existing'){
			let billingCard: BillingCard = this.utilServ.getDefaultCardId(this.cards);
			this.fDetails['pay_with_cc'].setValue(billingCard?.card_id);
			this.fDetails['card_last4_digits'].setValue(billingCard?.card_last4_digits);
			// Remove billing address for existing card case
			if(this.fPayment.controls['billing_address']){
				this.fPayment.removeControl('billing_address');
			}
		} else {
			this.fDetails['pay_with_cc'].setValue('');
			this.fDetails['card_last4_digits'].setValue('');
		}
	}
	/**
	 * Check the user email exist and based on this set the password validation
	 */
	public async userEmailExist(){
		let control = this.fDetails['sender_email'];
		if(control && control.value && (!control.errors?.required && !control.errors?.pattern)){
			let email = null;
			if(!this.isLoggedIn && this.fDetails && this.fDetails['sender_email'].value){
				email = await this.customValidators.emailExist(this.fDetails['sender_email'].value);
			}
			this.emailExist = email ? true : false;
			if(!this.emailExist){
				this.detailsFocusOut();
			}
			this.fDetails['sender_email'].setErrors(email);
			if(!this.fDetails['password']){
				let fDetailsGroup = <FormGroup>this.sendForm.get('details');
				fDetailsGroup.addControl('password', new FormControl('', Validators.required));
			}
			CustomValidators.requiredValidator(this.emailExist, this.fDetails['password']);
		}
	}

	/**
	 * Set/remove the required validation
	 * Edit case prefilled the values
	 * @param specDate true/false
	 */
	public scheduleValidation(specDate: boolean = false): void{
		CustomValidators.requiredValidator(specDate, this.fDetails['schedule_on_date']);
		CustomValidators.requiredValidator(specDate, this.fDetails['schedule_on_time']);
		if(specDate && this.editId){
			this.filledScheduleDate();
		}
	}
	/**
	 * Goto next step:: Payment
	 */
	public async nextStep(){
		await this.userEmailExist();
		// eslint-disable-next-line complexity
		setTimeout(() => {
			// Amount
			this.customValidators.setControlDecimalNo(this.fDetails['amount']);
			let isValidForm: boolean = true;
			for(let i in this.fDetails){
				if(i != 'customer_id' && i != 'sender_email' && this.fDetails[i].invalid){
					isValidForm = false;
					break;
				}
			}
			let senderEmailControl = this.fDetails['sender_email'];
			let senderEmail = false;
			if(senderEmailControl && (senderEmailControl.errors?.required || senderEmailControl.errors?.pattern)){
				isValidForm = false;
				senderEmail = true;
			}
			if(isValidForm && this.fDetails['sender_email'].value != this.fDetails['recipient_email'].value){
				this.apiServ.setLoaderId(this.detailsLoaderId);
				this.loader.show(this.detailsLoaderId);
				if(this.emailExist && !this.currentUser){
					// User email exist, login to app
					this.loginUser();
				} else {
					this.loader.hide(this.detailsLoaderId);
					this.changeStep('payment');
				}
			} else {
				let sameEmail=false;
				// Form is valid but sender and recipient email are same
				if(this.fDetails['sender_email'].value && this.fDetails['recipient_email'].value && this.fDetails['sender_email'].value == this.fDetails['recipient_email'].value){
					sameEmail = true;
					this.toastr.error(this.initServ.appStr.toastr.sendGiftCardEmail);
				} else {
					for(let i in this.fDetails) {
						this.fDetails[i].markAsTouched();
					}
				}
				let errorMsg = "";
				let invalidControlName = null;
				for(const i in this.fDetails){
					if(i != 'customer_id' && i != 'password' && i != 'sender_email' && this.fDetails[i].invalid){
						invalidControlName = i;
						break;
					}
				}
				if(senderEmail && !invalidControlName){
					errorMsg = this.invalidControl('sender_email');
					this.toastr.error(errorMsg);
				}
				if(invalidControlName && !sameEmail){
					errorMsg = this.invalidControl(invalidControlName);
					this.toastr.error(errorMsg);
				}
			}
		}, 200);
	}
	/**
	 * Function to return specific msg according to invalid control.
	 */
	// eslint-disable-next-line complexity
	private invalidControl(name: string): any{
		let msg = '';
		let sectionId =  '';
		switch(name){
			case 'amount':
				msg = 'Please fill in the amount for this gift card.';
				sectionId = this.section?.form?.amount_id;
			break;
			case 'sender_first_name':
				msg = 'Please fill in the sender valid first name.';
				sectionId = this.section?.form?.sender_first_name_id;
			break;
			case 'sender_last_name':
				msg = 'Please fill in the sender valid last name.';
				sectionId = this.section?.form?.sender_last_name_id;
			break;
			case 'sender_email':
				msg = 'Please fill in the sender valid email.';
				sectionId = this.section?.form?.sender_email_id;
			break;
			case 'recipient_name':
				msg = 'Please fill in the recipient valid name.';
				sectionId = this.section?.form?.recipient_name_id;
			break;
			case 'recipient_email':
				msg = 'Please fill in the recipient valid email.';
				sectionId = this.section?.form?.recipient_email_id;
			break;
			case 'schedule_on_date':
				msg = 'Please select the date for this gift card.';
				sectionId = this.section?.form?.schedule_date_id;
			break;
			case 'schedule_on_time':
				msg = 'Please select the time for this gift card.';
				sectionId = this.section?.form?.schedule_time_id;
			break;
			case 'newCreditCardDetail':
				msg = 'Please fill in the valid card details.';
				sectionId = 'payment-details-id';
			break;
		}
		// Call function to scroll to specific element
		this.utilServ.scrollToSpecificEle(sectionId);
		return msg;
	}

	/**
	 * Build the payload data for sending based on the form inputs.
	 * Returns the constructed payload object.
	 */
	private buildPayload(): any {
		let payload: any = this.sendForm.controls['details'].value;
		// Convert schedule_on_time to a number if it exists
		if (payload.schedule_on_time) {
			payload.schedule_on_time = +payload.schedule_on_time;
		}
		// Add creator details if the current user is available
		if (this.currentUser) {
			payload.created_by = this.currentUser.id;
			payload.created_by_role = this.currentUser.role;
		}
		// Convert amount to a number
		payload.amount = +payload.amount;
		// Include selected image URL in the payload
		payload.image_url = this.selectedImg;
		if(this.fPayment.controls['billing_address']){
			payload['billing_address'] = this.fPayment.controls['billing_address'].value;
		}
		return payload;
	}

	/**
	 * Submit the form data
	 */
	public async submitForm(): Promise<void> {
		(<FormGroup>this.sendForm.get('details')).removeControl('password');
		if(this.sendForm.valid){
			this.apiServ.setLoaderId(this.paymentLoaderId);
			this.loader.show(this.paymentLoaderId)
			// Schedule date
			this.updateSpecificDateScheduleControl();
			this.updateEmailControls();
			let payload: any = await this.buildPayload();
			if(this.giftCard){
				this.send(payload);
			} else{
				if((this.initServ.threeDSecure && this.initServ.paymentGateway != 'authorizedotnet') || this.fPayment.controls['card_type'].value == 'new') {
					this.generateToken(payload);
				} else {
					this.send(payload);
				}
			}
		} else {
			this.handleFormErrors();
		}
	}

	/**
	 * Updates the schedule control based on the selected schedule type and date.
	 * If the schedule type is 'schedule_specific_date' and a date is provided,
	 * it formats the date to 'YYYY-MM-DD' format. Otherwise, it sets the date value to null.
	 */
	private updateSpecificDateScheduleControl(): void {
		if (this.fDetails['schedule'].value && this.fDetails['schedule'].value === 'schedule_specific_date' && this.fDetails['schedule_on_date'].value) {
			this.fDetails['schedule_on_date'].setValue(dayjs(this.fDetails['schedule_on_date'].value).format(DB_DATE_FORMAT));
		} else {
			this.fDetails['schedule_on_date'].setValue(null);
		}
	}

	/**
	 * Updates the email controls by converting sender and recipient email addresses to lowercase.
	 */
	private updateEmailControls(): void {
		this.fDetails['sender_email'].setValue((this.fDetails['sender_email'].value).toLowerCase());
		this.fDetails['recipient_email'].setValue((this.fDetails['recipient_email'].value).toLowerCase());
	}

	/**
	 * Generate the payment token
	 * @param result
	 */
	public async generateToken(payload: any): Promise<void> {
		let paymentData: {amount: number, uid: string|number, first_name: string, last_name: string, email_id: string, payment_method: string, pay_with_cc: string, payment: boolean, billing_address: BillingAddress, gift_card_recipient_email: string} = {
			amount: +this.fDetails['amount'].value,
			uid: this.currentUser?.id,
			first_name:this.fDetails['sender_first_name'].value,
			last_name:this.fDetails['sender_last_name'].value,
			email_id: this.fDetails['sender_email'].value,
			payment_method: this.fPayment.controls['card_type'].value == 'new' ? 'new_credit_card' : 'existing_credit_card',
			pay_with_cc: this.fDetails['pay_with_cc'].value,
			payment: true,
			billing_address: this.fPayment.controls['billing_address'] && this.fPayment.controls['billing_address'].value,
			gift_card_recipient_email: this.fDetails['recipient_email'].value
		}
		// Calls the payment gateway child component's getPaymentToken method to generate the token.
		let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(paymentData, this.paymentGatewayChild);
		if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
			// If the token is successfully generated, assigns the token data to tokenData and calls the saveTip method with the tokenData.
			payload = this.paymentServ.assignToken(payload, paymentGatewayToken);
			this.send(payload);
		}else{
			// If the token is not successfully generated, hides the main loader.
			this.loader.hide(this.paymentLoaderId);
		}
	}

	/**
	 * Handles form errors by marking all form fields as touched and displaying an error message.
	 * Additionally, it adjusts the behavior of the payment gateway child component based on the selected card type.
	 */
	private handleFormErrors(): void {
		this.sendForm.markAllAsTouched();
		this.toastr.error('Please fill the required fields marked in red.');
		// Adjust behavior of payment gateway child component based on selected card type
		if (this.paymentGatewayChild) {
			if (this.fPayment.controls['card_type'].value === 'new') {
				this.paymentGatewayChild.isZipcode = true;
			}
			this.paymentGatewayChild.refresh();
		}
	}

	/**
	 * Handles the focus out event for the details section.
	 * This function checks the validation of sender and recipient email controls.
	 * If validation passes, it adds contact information to a lead.
	 * @param {number | null} interval - Optional interval for submitting data (default: -1).
	 */
	public detailsFocusOut(interval: number | null = null): void {
		if(!this.editId){
			let senderEmailControl = this.fDetails['sender_email'];
			let recipientEmailControl = this.fDetails['recipient_email'];
			if(this.checkValidation(senderEmailControl) || this.checkValidation(recipientEmailControl)){
				this.leadsServ.addContactToLead(this.sendForm.controls['details'].value, {type:'giftcard', slug: 'gift-cards'}, interval);
			}
		}
	}
	/**
	 * Checks the validation status of a form control.
	 * @param {any} control - The form control to be checked.
	 * @returns {boolean} - Returns true if the control is valid, otherwise false.
	 */
	checkValidation(control: any){
		if(control && control.value && (!control.errors?.required && !control.errors?.pattern)){
			return true;
		}
		return false;
	}
	/**
	 * Send gift card
	 * Method: POST/Put
	 */
	// eslint-disable-next-line complexity
	private send(payload: any): void {
		if(this.giftCard){
			// Update gift card
			payload['id'] = this.giftCard.id;
			this.apiServ.callApi('PUT', 'PutGiftCards',payload).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'send'));
		} else {
			this.detailsFocusOut(1);
			// Add gift card GiftCards
			let header: any = null
			if(!this.currentUser){
				header = { 'Content-Type': 'application/json', 'Ip': payload.sent_from_ip }
			}
			let queryParams = {
				ses_id: this.cookiesInfo?.ses_id ? this.cookiesInfo?.ses_id : null,
				campaign_id: this.cookiesInfo?.campaign_id ? this.cookiesInfo?.campaign_id : null,
				contact_id: this.cookiesInfo?.contact_id ? this.cookiesInfo?.contact_id : null,
				track_from: this.cookiesInfo?.track_from ? this.cookiesInfo?.track_from : null,
				sequence_id: this.cookiesInfo?.sequence_id ? this.cookiesInfo?.sequence_id : null,
				referrer_source: this.referrerSource ? this.referrerSource : null
			};
			this.apiServ.callApiWithQueryParams('POST', 'GiftCards', queryParams, payload, header).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'send', true));
		}
	}

	/**
	 * Filled the gift card form, in case of edit
	 */
	public filledEditForm(): void {
		if(this.giftCard.image_url){
			this.selectedImg = this.giftCard.image_url;
			this.fDetails['image_url'].setValue(this.selectedImg);
		}
		(<FormGroup>this.sendForm.get('details')).patchValue({
			customer_id: this.giftCard.customer_id,
			amount:this.giftCard.amount,
			sender_first_name: this.giftCard.sender_first_name,
			sender_last_name: this.giftCard.sender_last_name,
			sender_email: this.giftCard.sender_email,
			recipient_email: this.giftCard.recipient_email,
			recipient_name: this.giftCard.recipient_name,
			recipient_id: this.giftCard.recipient_id,
			message : this.giftCard.message
		});
		let schedule:string = (this.giftCard.schedule == 'schedule-date') ? 'schedule_specific_date' : this.giftCard.schedule;
		this.fDetails['schedule'].setValue(schedule);
		let status = schedule == 'schedule_specific_date' ? true : false;
		this.scheduleValidation(status);
		if(this.giftCard.schedule_on_time){
			this.fDetails['schedule_on_time'].setValue(this.giftCard.schedule_on_time);
		}
	}

	/**
	 * Filled schedule date
	 */
	private filledScheduleDate(): void {
		if(this.giftCard && this.giftCard.schedule_on_date){
			let d = dayjs(this.giftCard.schedule_on_date);
			this.fDetails['schedule_on_date'].setValue(d.toDate());
		}
	}
	/**
	 * User exist, then login to app
	 */
	public async loginUser(): Promise<any> {
		let loginData: any = {
			username: (this.fDetails['sender_email'].value).toLowerCase(),
			password: this.fDetails['password'].value
		};
		let status = await this.authServ.login(loginData, '', this.detailsLoaderId, false, false, false, true);
		if(status){
			this.detailsFocusOut();
			// Only for embed code
			if(this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme){
				this.currentUser = this.utilServ.appLocalStorage('currentUser', true);
				this.loggedInUser();
			}
			this.fDetails['sender_email'].setErrors(null);
			this.initServ.reRender = false;
			setTimeout(()=>{
				this.loader.hide(this.detailsLoaderId);
				this.changeStep('payment');
			}, 500);
		} else{
			if(this.utilServ.inIframe(this.utilServ.embedStatus)){
				if(typeof(parentToTop) !== 'undefined'){ parentToTop() }
			}
			this.loader.hide(this.detailsLoaderId);
		}
	}
	/**
	 * Login user using access token
	 * @param gcRes
	 */
	private async loginUsingToken(gcRes:any): Promise<any> {
		// Current logged user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage('currentUser', true);
		let accessToken: any = (gcRes.data.access_token) ? (gcRes.data.access_token) : (this.currentUser && this.currentUser.access_token);
		let status = await this.authServ.loginAsUser(accessToken, 'main', true);
		if(this.utilServ.inIframe(this.utilServ.embedStatus)){
			this.currentUser = this.utilServ.appLocalStorage('currentUser', true);
		}
		if(status){
			// Redirect screen
			this.redirectScreen(gcRes);
		}
	}
	/**
	 * Redirect screen
	 * @param gcRes
	 */
	private redirectScreen(gcRes: any){
		let redirectTo = 'none';
		let redirectUrl;
		if(this.parentPageSett && this.parentPageSett.where_should_redirect && this.parentPageSett.redirect_val){
			redirectTo = this.parentPageSett.where_should_redirect;
			redirectUrl = this.parentPageSett.redirect_val;
		}
		let gotoLinkUrl: any = null;
		switch (redirectTo) {
			case "link":
				let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
				this.redirect(linkUrl);
				break;
			case "page":
				gotoLinkUrl = this.utilServ.getAfterSuccessRedirection(redirectUrl);
				if(gotoLinkUrl){
					this.redirect(this.utilServ.generateLink(gotoLinkUrl));
				}
				break;
			default:
				if(this.utilServ.inIframe(this.utilServ.embedStatus)){
					this.toastr.success(this.initServ.appStr.toastr.giftCardSuccess);
					this.redirectUser();
				} else{
					if(this.currentUser && !this.initServ.theme) {
						this.router.navigate(['/'+this.initServ.appDynamicRoutes['gift-cards']]).then(() =>{ this.toastr.success(gcRes.message); });
					} else if(this.currentUser && this.initServ.theme) {
						this.router.navigate(['/']).then(() =>{ this.toastr.success(gcRes.message); });
					} else {
						if(!this.initServ.theme){
							top.window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + "/dashboard";
						} else {
							top.window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + "/";
						}
						this.toastr.success(this.initServ.appStr.toastr.giftCardSuccess);
						window.location.reload();
					}
				}
				break;
		}
		this.loader.hide(this.paymentLoaderId)
	}
	/**
	* Change the browser url to desired url
	*/
	public redirect(link:any){
		try{
			top.window.location.href = link;
		} catch(err){
			// this.bookingCnfrmPopup(data);
		}
	}

	/**
	 * Function used to redirect user.
	 */
	private redirectUser(){
		let link = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + "/";
		if(this.currentUser){
			link = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + "/dashboard";
			// Redirect to panel from embed, if old local storage is not exists
			let embedUrl: any = this.authServ.redirectFromEmbed()
			if(embedUrl){
				link = embedUrl;
			}
		}
		try{
			top.window.location.href = link;
		} catch(err){
			// this.bookingCnfrmPopup(data)
		}
	}

	/**
	 * Forgot password
	 */
	public forgotPwd(): void {
		let obj = {
			email_id: (this.fDetails['sender_email'].value).toLowerCase()
		}
		this.authServ.reqResetPassword(obj, this.detailsLoaderId, false);
	}
	/**
	 * On result callback method
	 * @param res API res
	 * @param type API types: gallery
	 * API response handler
	 */
	private onResultCallback(res:any, type: string, isSend: boolean = false): void {
		switch(type){
			case 'gallery':
				if(this.apiServ.checkAPIRes(res)){
					this.gallery = res.data;
					if(this.gallery && this.gallery.length > 0 && this.gallery[0] && this.gallery[0].image_url){
						this.selectedImg = this.gallery[0].image_url;
					}
				}
				this.loader.hide(this.galleryLoaderId);
				setTimeout(()=>{
					if(this.sliderRef){
						if (this.slider) {this.slider.destroy();}
						this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig)
					}
				})
			break;
			case 'giftCard':
				if(this.apiServ.checkAPIRes(res)){
					this.giftCard = res.data;
					if(this.giftCard){
						this.filledEditForm();
					}
				}
				this.loader.hide(this.detailsLoaderId);
			break;
			case 'cards':
				if(this.apiServ.checkAPIRes(res)){
					this.cards = res.data;
					if (this.cards && (this.cards).length > 0) {
						this.fPayment.controls['card_type'].setValue('existing');
					} else {
						this.fPayment.controls['card_type'].setValue('new');
					}
				}
				this.cardTypeChange();
			break;
			case 'send':
				if(this.apiServ.checkAPIRes(res)){
					// Remove referrer url after the send the gift card
					if(isSend){
						this.utilServ.removeStorage('referrer_url')
					}
					/* Code for google analytics */
					if(typeof(gtag) !== 'undefined'){
						gtag('event', 'BookingByCustomer');
					}
					// Redirect app
					if(this.currentUser){
						// Redirect screen
						this.redirectScreen(res);
					} else {
						// Login user based on access token
						this.loginUsingToken(res);
					}
				} else {
					this.loader.hide(this.paymentLoaderId);
					if(res && res.message){
						this.toastr.error(res.message);
					}
				}
			break;
		}
	}

	/**
	 * Handles the change event when a new card is selected.
	 * Updates the payment form with the selected card details.
	 * @param {Event} event - The change event containing the selected card ID.
	 */
	public onCardChange(event: Event): void {
		let cardId: string = (<HTMLInputElement>event.target).value;
		let billingCard: BillingCard = this.cards.find((card: BillingCard) => card.id === cardId);
		this.fDetails['pay_with_cc'].setValue(billingCard?.id);
		this.fDetails['card_last4_digits'].setValue(billingCard?.last4);
	}
}
