import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
// Services
import { NgOnDestroy, ApiServ, InitServ, RenderComponentServ, SectionServ,StyleServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-blog-details',
	templateUrl: './BlogDetails.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BlogDetailsComponent implements OnInit {

	// Variables
	slug: string = '';
	blog: any;
	pageSlug: string = 'blog';
	blogs: any;
	categories: any;
	settings: any;
	showComments: boolean = false;
	secId: any;
	pageSett: any;
	// Section fields
	section: any = {
		single_post: null,
		blog_list:null,
		description:null,
		recent_posts:null,
		social_list:null,
		subscribe:null
	}
	popularBlogIds: any = [];

	constructor(private actRoute: ActivatedRoute, private apiServ: ApiServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, public secServ: SectionServ, private rcServ: RenderComponentServ,private styleServ : StyleServ, public utilServ: UtilServ, private router: Router, private metaService: Meta) {
		// Should reuse route
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		// Page slug
		this.slug = this.actRoute.snapshot.params['slug'] ? this.actRoute.snapshot.params['slug'] : '';
		this.pageSlug = this.actRoute.snapshot.params['type'] ? this.actRoute.snapshot.params['type'] : 'blog';
		this.pageData();
		this.blogSettings();
		this.getBlog();
	}

	ngOnInit(): void {}
	/**
	 * Get the blog settings, which is get by content management app
	 */
	private blogSettings(): void{
		this.apiServ.callApi('GET', 'BlogsSett').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'sett'));
	}
	/**
	 * Get the blog based on blog slug
	 */
	private getBlog(): void{
		let queryParams: any = {slug: this.slug, language:this.initServ.savedLng};
		this.apiServ.callApiWithQueryParams('GET', 'Blog', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'blog'));
	}
	/**
	 * Get the blog page data
	 */
	private pageData(): void {
		// Query params
		let queryParams: any = {slug:this.pageSlug,language:this.initServ.savedLng, mode: 'live', theme_slug: this.initServ.theme};
		if(this.initServ.theme){
			queryParams['mode']='preview';
		}
		if(this.initServ.firstPageData && this.initServ.firstPageSlug && this.pageSlug == this.initServ.firstPageSlug && this.apiServ.checkAPIRes(this.initServ.firstPageData) && this.initServ.firstPageData.data){
			setTimeout(() => {
				this.onResultCallback(JSON.parse(JSON.stringify(this.initServ.firstPageData)), 'page');
			}, 0)
		}else{
			this.apiServ.callApiWithPathQueryVars('GET', 'PageData',[0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'page'));
		}
		this.apiServ.callApiWithPathQueryVars('GET', 'PageStyle',[0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.styleServ.applyPageStyle(res));
	}
	/**
	 * Build the blog sections fields
	 */
	private buildSectionFields(pageData: any, secId: any): void{
		let secElem : any = null;
		if(pageData && pageData.sections){
			secElem = pageData.sections[secId];
		}
		if(secElem && this.pageSett && this.pageSett[secId] && this.pageSett[secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "description":
						case "popular_blogs":
						case "recent_posts":
						case "social_list":
						case "subscribe":
							this.section[key] =  this.secServ.buildGroup(secElem[key]);
							break;
						default:
							this.section[key] = this.secServ.checkElementStatus(secElem[key]);
							break;
					}
				}
			}
		}
		// Get the recent blogs, depend on section settings
		if(this.section.recent_posts){
			this.getBlogs();
		}
	}
	/**
	 * Get the blogs from content management app
	 */
	private getBlogs(): void{
		let queryParams: any = {limit: 4, content: 'no',status:'publish', language:this.initServ.savedLng };
		this.apiServ.callApiWithQueryParams('GET', 'Blogs', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'blogs'));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			switch(type){
				case 'blog':
					this.blog = res.data.blog_data;
					this.categories = res.data.categories;
					this.showComments = true;
					this.setBlogPageTitle();
					this.setOgTags();
				break;
				case 'sett':
					this.settings = res.data;
				break;
				case 'page':
					let pageData : any = res.data;
					if(pageData.column_sections && (pageData.column_sections).length > 0){
						pageData.added_sections = (pageData.added_sections).filter( (el: any) => !(pageData.column_sections).includes( el ) );
					}
					if(pageData.added_sections && (pageData.added_sections).length > 0){
						for(let secId of pageData.added_sections){
							let secData: any = pageData.section_settings[secId];
							switch(secData.slug){
								case 'blog':
									if(pageData){
										this.pageSett = pageData.section_settings;
										this.secServ.setServData(this.pageSett, pageData.content);
										this.buildSectionFields(pageData, secId);
									}
								break;
								case 'blog_section':
									if(!this.popularBlogIds.includes(secId)){
										this.popularBlogIds.push(secId);
									}
								break;
							}
						}
						if(pageData && pageData.settings){
							let pageSett: any = res.data.settings;
							if(pageSett.redirect_status && pageSett.redirect_status == 'yes' && (pageSett.redirect_link_to == 'page' || pageSett.redirect_link_to == 'web') && pageSett.redirect_link_url){
								this.utilServ.redirectPageURL(pageSett); // Redirect page url
							} else {
								this.rcServ.setPageData = JSON.parse(JSON.stringify(pageData));
								// Theme popups
								// this.rcServ.loadThemePopups(pageSett);
								// // Page title
								// this.rcServ.setPageTitle(pageSett);
								// Add tracking codes added in page settings
								this.rcServ.addTrackingCodes(pageSett);
								// Page setting
								this.utilServ.setPageSett(res.data._id, pageSett);
							}
						}
						// Reset the page api hit count;
						this.rcServ.setPageApiHitCount = 0;
					} else {
						this.utilServ.redirectBrokenUrl();
					}
					this.initServ.firstPageData = null;
					this.initServ.firstPageSlug = null;
				break;
				case 'blogs':
					this.blogs = res.data;
				break;
			}
		} else {
			if(type == 'blog'){
				this.router.navigate(['/'+this.initServ.appDynamicRoutes['blog']]);
			}
		}
	}
	/**
	 * Set page title
	 * If SEO title is available then show seo.title otherwise title of blog
	 */
	private setBlogPageTitle(): void {
		let seoObj: any = {};
		if(this.blog && this.blog.seo && this.blog.seo.title){
			seoObj['seo_title'] = this.blog.seo.title;
		}else{
			seoObj['seo_title'] = this.blog.title;
		}
		this.rcServ.setPageTitle(seoObj);
	}
	private setOgTags(): void {
		let tagsArr: any = [{ name: 'og:type', content: 'website' }];

		let title: string = this.blog?.seo?.title ?? this.blog.title;
		let img = this.blog?.seo?.fb_image_url ?? '';
		let desc = this.blog?.seo?.description ?? this.blog?.description;
		let url = this.initServ.appData.primary_custom_domain + '/blog/' + this.blog?.seo?.url;

		if(title){
			tagsArr.push({ name: 'og:title', content: title })
		}
		if(img){
			tagsArr.push({ name: 'og:image', content: this.initServ.imgBase+img })
		}
		if(desc){
			tagsArr.push({ name: 'og:description', content: desc })
		}
		if(url){
			tagsArr.push({ name: 'og:url', content: url })
		}
		this.metaService.addTags(tagsArr);
	}
}
