import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
// Services
import { ApiServ, BkngFormServ, BuildCustomSectionService, InitServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';
import { InvServ } from '../../Invoices';
@Component({
	selector: 'bk-inv-from-bkng-table',
	templateUrl: './InvFromBkngTable.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvFromBkngTableComponent implements OnInit {

	@Input() invSec : any;
	@Input() invData: any;
	@Input() invForm!: FormGroup;
	@Output() bkngsTipAmt: EventEmitter<number> = new EventEmitter<number>();
	bkngs: any[] = [];
	elementName: any;
	formParamsName: any = {
		booking_id:'Booking ID',
		service_date: 'Service date',
		industry: 'Industry',
		frequency: 'Frequency',
		service: 'Service category',
		pricing_parameter: 'Pricing parameter',
		excludes:'Excludes',
		items:'Items',
		packages:'Packages',
		extras: 'Extras',
		custom_section: 'Custom section',
		total: 'Total'
	}
	offset: number = 0;
	limit: number = 10;
	bkngIdsBatches:any;
	loaderId: string = 'bkng-ids-loader';
	customFieldsBids: number[] = [];
	pricingParams: any;
	isPlanPermission!: boolean;
	formParams: any;


	// convenience getter for easy access to form fields
	get bkngTipGroup(): FormGroup {
		return <FormGroup>this.invForm.controls['bookings_tip'];
	}
	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, public bkngFormServ: BkngFormServ, public initServ: InitServ, private loader: LoaderServ,private apiServ: ApiServ, private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, private frmBldr: FormBuilder,private invServ: InvServ, public bkngCustSecServ: BuildCustomSectionService, private el: ElementRef) {
		this.isPlanPermission = this.initServ.appPlansPermission('Invoice Charges Settings');
	}

	ngOnInit(): void {
		this.invForm.addControl('bookings_tip', this.frmBldr.group({}));
		this.buildTable();
		this.fetchPricingParams();
		this.getFormParamsApi();
	}

	/**
	 * Get the form element name
	 * @returns object
	 */
	public formElementName(data: any): any {
		let obj: any = {'service_category':{}, 'form_frequencies':{}, 'extras':{}, 'excludes':{}, 'package':{}, 'items':{}, 'package_addons':{}, 'addons':{}};
		let types = ['service_category', 'form_frequencies', 'extras', 'excludes', 'package', 'items', 'package_addons', 'addons'];
		for(let type of types){
			// TODO: Remove appload data usage, check again (Lakhvir)
			if(this.utilServ.checkArrLength(data?.[type])) {
				for(let val of data[type]) {
					obj[type][val?.id] = this.utilServ.getFormParamName(val);
				}
			}
		}
		return obj;
	}

	private buildTable(): void {
		if(this.invData){
			// Get the bookings based on ids
			if(this.utilServ.checkArrLength(this.invData?.booking_ids)){
				// Build the bookings ids chunk
				this.bkngIdsBatches = this.buildBkngsIdsChunk();
				if(this.utilServ.checkArrLength(this.bkngIdsBatches)){
					this.fetchBkngsByIds();
					this.getCustomSec();
				}
			}
		}
	}

	/**
	 * Get the booking by ids
	 */
	private fetchBkngsByIds(): void {
		this.loader.show(this.loaderId);
		let postData: any = {
			bids : this.bkngIdsBatches[this.offset]
		}
		this.fetchBkngsCustomFields(postData?.bids);
		this.apiServ.callApi('POST','BkngByIds',postData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.handleBkngRes(res));
	}
	/**
	 * Booking api res handler
	 * @param res API Response
	 */
	private handleBkngRes(res: any): void {
		if(this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)){
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, this.bkngs);
			this.bkngs = loaMoreData.items;
			this.addTipControl();
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}

	/**
	 * Get the booking ids array or array with the limit of 10
	 * Get the booking IDs from invData
	 * Array to store resulting chunks
	 * Check if booking IDs exist and exceed the limit
	 * @returns Return the resulting chunks
	 */
	private buildBkngsIdsChunk():any {
		let bkngIds: any = this.invData?.booking_ids;
		let result: number[][] = [];
		if(bkngIds.length > this.limit){
			//Calculate the total number of chunks needed
			let totalPages: number = Math.ceil(bkngIds.length / this.limit);
			//Iterate through each chunk
			for (let i = 0; i < totalPages; i++) {
				let startIndex: number = i * this.limit;
				let endIndex: number = Math.min(startIndex + this.limit, bkngIds.length);
				// Slice the booking IDs to create a chunk and add it to the result array
				let chunk: number[] = bkngIds.slice(startIndex, endIndex);
				result.push(chunk);
			}
		} else {
			result.push(bkngIds);
		}
		return result;
	}

	/**
	 * Load more
	 */
	public loadMore(): void {
		this.offset = this.offset+1;
		this.fetchBkngsByIds();
	}

	private addTipControl(): void {
		if(this.invData?.settings?.tip_settings?.pay_type == 'specific'){
			for(let bkng of this.bkngIdsBatches[this.offset]){
				this.bkngTipGroup.addControl(bkng, this.frmBldr.group({
					pay: [null],
					unit: ['amount']
				}))
			}
		}
	}

	/**
	 * Calculate total tip amount for bookings.
	 * Emits the total tip amount.
	 */
	public calBkngTip(): void {
		this.invServ.bkngsTipAmt = 0;
		for(let bid in this.bkngTipGroup.value){
			if(this.bkngTipGroup.value[bid]?.pay){
				this.invServ.bkngsTipAmt += +(this.bkngTipGroup.value[bid]?.pay);
			}
		}
		this.bkngsTipAmt.emit(this.invServ.bkngsTipAmt);
	}

	/**
	 * Fetch the bookings custum fields based on booking ids
	 * @param bids: Booking ids
	 */
	private fetchBkngsCustomFields(bids: number[]): void {
		if(this.utilServ.checkArrLength(bids) && this.isCustomSec()){
			this.customFieldsBids = Array.from(new Set([...this.customFieldsBids, ...bids]));
			this.bkngCustSecServ.fetchBkngsCustomFields(this.customFieldsBids, this.cDRef);
		}
	}

	private isCustomSec(): boolean {
		return (this.utilServ.checkArrLength(this.invData?.form_params) && this.invData?.form_params.includes('custom_section'));
	}

	/**
	 * Fetch the pricing params
	 */
	private fetchPricingParams(): void {
		this.apiServ.callApi('GET', 'PricingParams').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.handlePricingParamsApiRes(res));
	}

	/**
	 * Handles the response from the pricing parameters API and updates the global pricing parameters accordingly.
	 * @param res The response from the pricing parameters API.
	 */
	private handlePricingParamsApiRes(res: any): void {
		if(this.apiServ.checkAPIRes(res) && res?.data){
			this.pricingParams = this.createGlobalPricingParam(res.data);
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Fetch the form params
	 */
	private getFormParamsApi(): void {
		this.apiServ.callApi('GET', 'FormParams').pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.formParamsApiRes(resp));
	}

	/**
	 * Handles the response from the form parameters API.
	 * @param res The response from the form parameters API.
	 */
	private formParamsApiRes(resp: any): void {
		if(this.apiServ.checkAPIRes(resp)){
			this.formParams = resp.data;
			this.elementName = this.formElementName(this.formParams);
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Create global object for pricing parameters.
	 * @param industryId : industry id
	 * @param formId : Form id
	 * @param settings : Industry form settings
	 * @returns settings object
	 */
	public createGlobalPricingParam(pricingParams: any): any {
		let settingsObj: any = {}
		if(this.utilServ.checkArrLength(pricingParams)){
			for(let pricingParam of pricingParams){
				let obj : any = {}
				obj = this.setObjForParam(pricingParam, obj);
				if(+pricingParam?.form_id == 1){
					settingsObj[pricingParam.id] = obj;
				}else{
					settingsObj[0] = obj;
				}
			}
		}
		return settingsObj
	}
	/**
	 * Set the pricing param object
	 * @param pricingParam
	 * @param obj
	 * @param industryId
	 * @param formId
	 * @returns
	 */
	private setObjForParam(pricingParam: any, obj: any){
		if(this.utilServ.checkArrLength(pricingParam?.values)){
			obj['id'] = pricingParam.id;
			obj['cat_name'] = pricingParam.name,
			obj['value'] = {};
			for(let section of pricingParam.values){
				obj.value[section.id] = section;
			}
		}
		return obj;
	}

	//Select custom section td to apply class conditionally
	private getCustomSec(): void{
		setTimeout(() => {
			let element =  this.el.nativeElement.querySelector('#custom_section');
			if(element){
				element.classList.add('w-250');
			}
		},500)
	}
}
