import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
// External lib
import dayjs from 'dayjs';
// Services
import { UtilServ, InitServ } from '../../../Services';
@Component({
	selector: 'bk-date-picker',
	template: `
		<div id="date-field-{{secId}}" class="tjs-date__picker position-relative">
			<input type="text" [placeholder]="(initServ.appAdmnStngs?.merchant_settings?.store?.date_format) ? initServ.appAdmnStngs?.merchant_settings?.store?.date_format : 'MM/DD/YYYY'" aria-label="Date" aria-describedby="basic-addon1" class="form-control tjs-pointer bk-input pe-70" (click)="dateActive = !dateActive" [value]="selectedDate" readonly [attr.disabled]="isDisabled">
			<div class="tjs-group-icon h-100 position-absolute">
				<button *ngIf="selectedDate && !isDisabled" type="button" id="basic-addon1" class="input-group-text bg-transparent border-0 position-absolute end-0 p-0 fs-14 me-30 p-5-imp text-danger lh-1 pe-auto" (click)="emptyDate()"><i class="tjsicon-x-mark"></i></button>
				<button type="button" id="basic-addon1" class="input-group-text bg-transparent border-0 position-absolute end-0 p-0 pe-none fs-14"><i class="tjsicon-calendar-1"></i></button>
			</div>
		</div>
		<!-- Calendar -->
		<div id="date-filter-{{secId}}" class="tjs-date-range__container tjs-date-range__calendar tjs-single-date__calendar" [class.active]="dateActive">
			<div class="bk-calender card shadow">
				<div class="tjs-card-body card-body p-0">
					<div class="tjs-date-picker">
						<mat-calendar [selected]="selectedCalDate" [minDate]="calOpt?.minDate" [maxDate]="calOpt?.maxDate" (selectedChange)="onChangeDate($event, true)"></mat-calendar>
					</div>
				</div>
			</div>
		</div>
	`,
	encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnInit, OnDestroy {
	@Input() secId: string = ''; // Pass the secId when date picker show multipe time same component
	@Input() isDisableFutureDates: boolean = false;
	@Input() isDisabledPastDates: boolean = false;
	@Input() prefillDate: any;
	@Input() isDisabled: boolean = false;
	@Output() dateChange: EventEmitter<any> = new EventEmitter();
	selectedDate: any = null;
	selectedCalDate: any = null;
	dateActive: boolean = false;
	calOpt: any = {};
	constructor( public utilServ: UtilServ, public cDRef: ChangeDetectorRef, public initServ: InitServ) {}
	ngOnInit() {
		this.disabledDates()
		this.setPrefillDate();
		window.addEventListener("click", this.dpEventLis, false);
	}
	ngOnChanges() {
		this.disabledDates();
		this.setPrefillDate();
	}
	private dpEventLis = (e: any) => {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self: any = this;
		let elem: any = document.getElementById('date-filter-'+self.secId);
		let fieldElem: any = document.getElementById('date-field-'+self.secId);
		if(!(elem && (elem.contains(e.target) || fieldElem.contains(e.target) || e.target.classList.contains('mat-calendar-body-cell-content')))){
			self.dateActive = false;
			self.cDRef.detectChanges();
		}
	}
	private disabledDates(): void {
		if(this.isDisableFutureDates){
			this.disableFutureDates();
		} else if(this.isDisabledPastDates){
			this.disablePastDates();
		}
		this.cDRef.detectChanges();
	}
	private disableFutureDates(): void {
		let date: any = dayjs().toDate()
		this.calOpt['maxDate'] = date;
	}
	private disablePastDates(): void {
		let date: any = dayjs().toDate()
		this.calOpt['minDate'] = date;
	}
	private setPrefillDate(): void {
		if(this.prefillDate){
			// let sd: any = new Date(this.prefillDate * 1000);
			let sd: any = dayjs(this.prefillDate).toDate();
			this.onChangeDate(sd);
		}else{
			this.prefillDate = null;
		}
	}
	public onChangeDate(date: any, isChange: boolean = false): void {
		let format: string = (this.initServ.appAdmnStngs?.merchant_settings?.store?.date_format) ? this.initServ.appAdmnStngs?.merchant_settings?.store?.date_format : 'MM/DD/YYYY'
		let sd = dayjs(date).format(format);
		this.selectedDate = sd;
		this.selectedCalDate = date;
		if(isChange){
			// this.dateChange.emit(this.utilServ.convertToTM(date));
			this.dateChange.emit(dayjs(date).format("YYYY-MM-DD"));
		}
		this.dateActive = false;
		this.cDRef.detectChanges();
	}
	public emptyDate(): void {
		this.selectedDate = null;
		this.selectedCalDate = null;
		this.dateActive = false;
		this.dateChange.emit(null);
		this.cDRef.detectChanges();
	}
	ngOnDestroy() {
		window.removeEventListener("click", this.dpEventLis, false);
	}
}