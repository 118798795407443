<div class="tjs-popup tjs-popup--xl tjs-package-selection tjs-package-selection__popup bk-package-popup {{utilServ.embedStatus ? 'tjs-embed-package':''}}" *ngIf="data && data?.packages && (data?.packages).length > 0">
	<bk-popup-header></bk-popup-header>
	<div class="tjs-popup__content bg-transparent tjs-popup__content--height tjs-maxheight-initial px-20 pb-5 pt-0">
		<div class="packages-select slider">
			<div class="{{utilServ.embedStatus ? 'tjs-embed-arrow--wrap':''}}">
				<div class="d-flex justify-content-start justify-content-md-end align-items-center" *ngIf="enableArrow">
					<span (click)="slider.prev()" class="fs-18 tjs-pointer slider-arrow me-10"><i class="tjsicon-left-arrow"></i></span>
					<span (click)="slider.next()" class="fs-18 tjs-pointer slider-arrow"><i class="tjsicon-right-arrow"></i></span>
				</div>
				<bk-popup-header *ngIf="utilServ.embedStatus"></bk-popup-header>
			</div>
			<!-- Packages slider -->
			<div class="keen-slider packages-slide-wrapper bk-slider {{(data?.packages.length <= 3) && !initServ?.selectedLang?.rtl ? 'justify-content-xl-center' : ''}}" [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'"  #packagesSlide>
				<div class="keen-slider__slide packages-slide" *ngFor="let pckg of data?.packages;trackBy: utilServ.trackByFnIndex">
					<div class="package-hover card border fw-normal bk-form-package bk-form-package-border m-15">
						<div class="p-20 border-bottom bk-form-package-border">
							<div class="d-flex justify-content-center">
								<h5 class="text-capitalize fw-bold mb-0 text-center bk-form-package-label">
									<span>{{pckg?.display_name | translate}}</span>
									<span *ngIf="data?.bookingType != 'add' && pckg?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
								</h5>
								<!-- Tooltip -->
								<bk-tooltip *ngIf="pckg?.show_tooltip_icon && pckg?.tooltip_text" customClass="bk-tooltip" [content]="pckg?.tooltip_text | translate"></bk-tooltip>
							</div>
						</div>
						<div class="card-body d-flex flex-column justify-content-between">
							<div class="tjs-scrollbar tjs-package-scroll">
								<!-- Image -->
								<div class="text-center mb-20">
									<img loading="auto" width="" height="" [src]="utilServ.getImgUrl(pckg?.icon_url)" alt="package" class="img-fluid m-auto bk-img" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
								</div>
								<div *ngIf="pckg?.description" class="tjs-html-content fs-14 bk-form-package-label" [innerHTML]="pckg?.description | translate | safeHtml"></div>
								<!-- Add button -->
							</div>
							<div *ngIf="data?.section && data?.section?.add_pkg_btn">
								<bk-navigate [secId]="data?.section?.add_pkg_btn?.id" [designId]="data?.section?.add_pkg_btn?.design_id" [innerHTML]="data?.section?.add_pkg_btn?.content" essentialClass="bk-form-package-btn w-100" customClass="btn btn-outline-primary btn-lg" (callback)="packageSelect(pckg)"></bk-navigate>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>