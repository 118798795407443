<div [id]="section?.id" [formGroup]="serviceForm" *ngIf="settings && section && (services && services.length > 0)" [ngClass]="{'py-30 px-md-30 border-bottom bk-card-border':((services && services.length > 1) || isHourly)}">
	<!-- Title and description -->
	<bk-section-heading *ngIf="services.length > 1 || isHourly" [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<!-- Label -->
	<div class="mb-15" *ngIf="services.length > 1 && (section?.serv_label || (descStatus && section?.serv_desc))">
		<label *ngIf="section?.serv_label" [id]="section?.serv_label_id" class="mb-0 form-label bk-form-label" [innerHTML]="section?.serv_label | safeHtml"></label>
		<small  *ngIf="descStatus && section?.serv_desc" [id]="section?.serv_desc_id" class="d-block mt-10 bk-form-desc" [innerHTML]="section?.serv_desc | safeHtml"></small>
	</div>
	<div class="row">
		<div class="col-12 col-md-12 col-lg-8">
			<!-- Service selection -->
			<div class="d-flex align-items-center" *ngIf="services.length > 1">
				<select class="form-select bk-select" [(ngModel)]="selectedServiceType" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectService($event)" [attr.disabled]="isDisabled">
					<ng-container *ngFor="let service of services;trackBy: utilServ.trackByFnIndex">
						<option class="bk-select-option" [ngValue]="service">{{utilServ.getFormParamName(service) | translate}} <span *ngIf="bookingType != 'add' && service?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span></option>
					</ng-container>
				</select>
				<!-- Tooltip -->
				<bk-tooltip *ngIf="selectedServiceType && selectedServiceType?.show_tooltip_icon" customClass="bk-tooltip" [content]="selectedServiceType?.tooltip_text | translate"></bk-tooltip>
			</div>
			<!-- Hourly service -->
			<bk-hourly
				[isQuoteEditable]="isQuoteEditable"
				[services]="services"
				[bookingType]="bookingType"
				[prefilledData]="prefilledData"
				[section]="section"
				[serviceForm]="serviceForm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				[isCustomerAllowedRes]="isCustomerAllowedRes"
				[pageSett]="pageSett"
				(hourlyServiceChange)="hourlyServiceChange.emit()"
				(isHourlyService)="getHourlVal($event)"
			></bk-hourly>
		</div>
	</div>
</div>