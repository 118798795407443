import { Component, Input, OnInit, ViewEncapsulation, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, Self } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
// External lib
import { TranslateService } from '@ngx-translate/core';
// Services
import { BkngFormServ, InitServ, LoaderServ, NgOnDestroy, SectionServ, UtilServ, RenderComponentServ, BkngCustomSectionService } from '../../../../Services';
import { PriceableFieldCalServ } from '../../../../Services/BkngFormPriceCal';
import { AddBkngServ } from '../../../../AddBookings/index';
import { IS_TAB } from '../../../../Constants';
@Component({
	selector: 'bk-bkng-summary',
	templateUrl: './BkngSummary.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BkngSummaryComponent implements OnInit {
	// Variables

	@Input() bookingType: string = '';
	@Input() isStepOneValid: any;
	@Input() prefilledData: any;
	@Input() smryScrlStatus: any;
	@Input() smryMobileScrlStatus: any;
	@Input() summaryType: any = 'full';
	@Input() section: any;
	@Input() parentSection: any;
	@Input() pageSett: any;
	@Input() couponData: any;
	@Input() isMobile: any;
	@Input() settings: any;
	@Input() BKFrm!: FormGroup;
	@Input() formValues: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() isMultiStepHeader: boolean = false;
	@Input() formMetaData: any;
	@Input() settingsObj: any;
	@Input() formLayout: any;

	@Input() isStepOneAvail: any;
	@Input() isStepAvailFormFive : any;
	@Input() activeStep: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() selectedLocation: any;
	@Input() selectedPricingParams: any;
	@Input() selectedItems: any;
	@Input() selectedAreaParams: any;
	@Input() selectedExtras: any;
	@Input() selectedExcludes: any;
	@Input() selectedAddr: any;
	@Input() displayTotal: number = 0;
	@Input() displayGiftCardAmount: number = 0;
	@Input() displayReferralDiscount: number = 0;
	@Input() displayFinalAmount: number = 0;
	@Input() adjustedTimeHours : any = 0;
	@Input() adjustedTimeMin : any = 0;
	@Input() bookingTimeHours : any = 0;
	@Input() bookingTimeMin : any = 0;
	@Input() displayServicePrice : number = 0;
	@Input() displayFrequencyDiscount : number = 0;
	@Input() displaySpotDiscount : number = 0;
	@Input() displayCouponDiscount : number = 0;
	@Input() displayServiceFee : number = 0;
	@Input() displayDiscountedAmount : number = 0;
	@Input() bookingTaxRate : any;
	@Input() bookingTaxType : any;
	@Input() displaySaleTax : number = 0;
	@Input() displayTipsAmount : number = 0;
	@Input() excludeExpeditedAmountValue : boolean = false;
	@Input() excludeServiceFeeValue : boolean = false;
	@Input() isServiceFeeTaxable : string = 'no';
	@Input() exemptSalesTaxValue : boolean = false;
	@Input() firstRecAptFinalAmount : number = 0;
	@Input() afterNthAptFinalAmount : number = 0;
	@Input() subId : string = '';

	@Input() includeInFreqFieldsPrice: any;
	@Input() exemptFromFreqFieldsPrice: any;
	@Input() nonTaxableFieldsPrice: any;
	@Input() afterDiscTotalFieldsPrice: any;
	@Input() beforePriceableFieldsPrice: any;

	@Output() moveToStep: EventEmitter<any> = new EventEmitter();

	siteData: any = this.initServ.siteData; // App site settings
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	bodyOrder: any = {
		industry: 1,
		service: 2,
		frequency: 3,
		parameters: 4,
		extras: 5,
		excludes: 6,
		professionals: 7,
		length: 8,
		location: 9,
		apt: 10
	}
	paymentOrder: any = {
		total_before_tax: 1,
		total: 2,
		first_apt_total: 3,
		next_n_apt_total: 4,
		rec_total_after_n_apt: 5,
		learn_more: 6,
	}
	multiPaymentOrder: any = {
		total_before_tax: 1,
		total: 2,
		first_apt_total: 3,
		next_n_apt_total: 4,
		rec_total_after_n_apt: 5,
		learn_more: 6,
	}
	totalWithoutTax: number = 0;

	loaderId: string = 'bkng-summary';
	formId: any;
	industryName: string = '';
	serviceName: string = '';
	frequencyName: string = '';
	settingsParams: any = {};
	pricingParams: any = [];
	items: any;
	hideItems: boolean = false;
	areaParams: any;
	isExtraWithPrice: boolean = false;
	extras: any;
	excludes: any;
	isExcludeWithPrice: boolean = false;
	isJobLength: boolean = false;
	nextNAptTotal: string = '';
	recTotalAfterNApt : string = '';
	multiNextNAptTotal: string = '';
	multiRecTotalAfterNApt: string = '';

	isPaySummVisible : boolean = true;
	taxLabel : string = 'Sales Tax';
	subTotalLabel : string = '';
	firstAptTotalLabel : string = '';
	isMultiInd : any;

	// isMob: boolean = window.innerWidth <= MOBILE_WIDTH ? true : false;
	isMob: boolean = IS_TAB;

	totalDisplayStatus: boolean = false;
	headerSummTotalDisplayStatus: boolean = false;

	totalLable: string = '';
	headerSummTotalLable: string = '';
	totalLabelForShortForm: string = 'Total';

	priceableCustomFields: any = null;
	priceableCustomFieldsPriceType : any;
	priceableFieldNamePriceObj : any = {};
	updatedDisplayServicePrice: any = 0;

	custFieldGroupStatus: any = {};
	get customFieldsGroup() {
		return <FormGroup>this.BKFrm.controls['custom_fields'];
	}
	get cfCheckboxGroup() {
		return <FormGroup>this.BKFrm.controls['custom_fields_checkbox'];
	}
	// eslint-disable-next-line max-params
	constructor(public initServ: InitServ, public utilServ: UtilServ, public secServ: SectionServ, public bkngFormServ: BkngFormServ, private loader: LoaderServ, public translate: TranslateService, public addBkngServ: AddBkngServ, private cDRef: ChangeDetectorRef, private rcServ: RenderComponentServ, private priceableFieldCal: PriceableFieldCalServ, public bkngCustSecServ: BkngCustomSectionService, @Self() public destroy: NgOnDestroy) {}
	/**
	 * Based on design id set the isExtraWithPrice and isExcludeWithPrice value
	 */
	private setExtraAndExcludeDesign(): void {
		if(this.section?.extras_design_id && this.section?.extras_design_id == 'bk_extra_v2'){
			this.isExtraWithPrice = true;
		}
		if(this.section?.excludes_design_id && this.section?.excludes_design_id == 'bk_exclude_v2'){
			this.isExcludeWithPrice = true;
		}
	}

	ngOnInit(): void {
		if(this.isMultiStepForm){
			this.loaderId = this.loaderId + ' multi'
		}
		if(this.subId){
			this.loaderId = this.loaderId + this.subId;
		}
		this.setExtraAndExcludeDesign();
		this.isMultiInd = this.utilServ.multiIndustriesStatus()
		this.formParamsSettingsConvertIntoObj();
		this.buildSummary();
		this.setCustFieldDisplayStatus();
		this.bkngFormServ.onPriceableFieldChange.pipe(takeUntil(this.destroy)).subscribe((status: boolean) => {
			if(status){
				this.setCustFieldDisplayStatus();
			}
		});
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self: any = this;
		window.addEventListener('resize', () => {
			// self.isMob = window.innerWidth <= MOBILE_WIDTH ? true : false;
			self.isMob = IS_TAB;
			self.shortSummColInit();
			self.cDRef.detectChanges();
		});
		this.shortSummColInit();
		this.setHeaderLabels();
		this.getTotalLableForShortForm();
	}
	getTotalLableForShortForm(): any {
		if(this.formValues.industry_id && this.formValues.form_id){
			let id: any = this.formValues.industry_id+'_'+this.formValues.form_id;
			if(this.initServ.bkngSummData && this.initServ.bkngSummData[id]){
				let summData: any = this.initServ.bkngSummData[id];
				let summSecId: any = Object.keys(this.initServ.bkngSummData[id].sections)[0];
				this.totalLabelForShortForm = summData.content[summData.sections[summSecId].total]
			}
		}
	}
	shortSummColInit(): void {
		const myCollapsible : any = document.getElementById('booking-summary');
		if(myCollapsible){
			myCollapsible.addEventListener('show.bs.collapse', () => {
				const accordionEl:any = document.getElementById('booking-summary-box');
				if(accordionEl){
					if(accordionEl.classList.contains('collapsed-sum')){
						accordionEl.classList.remove('collapsed-sum');
					}
				}
			})
			myCollapsible.addEventListener('hide.bs.collapse', () => {
				const accordionEl:any = document.getElementById('booking-summary-box');
				if(accordionEl){
					if(!accordionEl.classList.contains('collapsed-sum')){
						accordionEl.classList.add('collapsed-sum');
					}
				}
			})
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildSummary(true);
						break;
					}
				}
			}
		}
	}

	/**
	 * Form parameters settings convert into object
	 */
	private formParamsSettingsConvertIntoObj(): void {
		if(this.settings){
			switch(this.BKFrm.value.form_id){
				case 1:
					// Pricing parameters array convert into object
					this.convrtParamToObj();
				break;
				case 4:
					// Area parameters array convert into object
					this.convrtAreaParamToObj();
				break;
			}
		}
		this.settingsParams = {...this.settingsParams, ...this.settingsObj}
	}
	convrtParamToObj(){
		if(this.settings && this.settings.pricing_parameters && (this.settings.pricing_parameters).length > 0){
			this.settingsParams['pricing_parameters'] = {};
			for(let param of this.settings.pricing_parameters){
				if(param.value && (param.value).length > 0){
					this.settingsParams['pricing_parameters'][param.name] = {};
					for(let value of param.value){
						this.settingsParams['pricing_parameters'][param.name][value.id] = value;
					}
				}
			}
		}
	}
	convrtAreaParamToObj(){
		if(this.settings && this.settings.pricing_parameters && (this.settings.pricing_parameters).length > 0){
			this.settingsParams['area_parameters'] = {};
			for(let param of this.settings.pricing_parameters){
				if(param.value && (param.value).length > 0){
					for(let value of param.value){
						this.settingsParams['area_parameters'][value.id] = value;
					}
				}
			}
		}
	}
	/**
	 * Build summary
	 */
	// eslint-disable-next-line max-lines-per-function, complexity
	private buildSummary(flag: boolean = false): void {
		if(!flag){
			this.loader.show(this.loaderId);
		}
		this.formValues = this.BKFrm.value;
		this.formId = this.formValues.form_id;
		this.totalWithoutTax = this.getTotalWithoutTax();
		this.updateServPriceAccPriceableFields();

		// Industry name
		this.industryName = this.bkngFormServ.industryName(this.formValues.industry_id);
		// Service name
		this.serviceName = '';
		if(this.selectedServiceType){
			this.serviceName = this.utilServ.getFormParamName(this.selectedServiceType);
		}
		// Frequency name
		this.frequencyName = '';
		if(this.selectedFrequency && this.selectedFrequency.form_frequency_data){
			this.frequencyName = this.utilServ.getFrequencyName(this.selectedFrequency);
		}
		// Form parameters
		this.buildFormParams();
		// Job length
		this.isJobLength = (this.bkngFormServ.checkVisibilityOfLength(this.selectedServiceType)) ? true : false;
		// Replace referral token with relevant value
		let bknCntStr = (this.BKFrm.value.coupon.apply_on_bookings - 1).toString();
		if(this.section && this.section.next_n_apt_total){
			this.nextNAptTotal = this.utilServ.generateDynamicStr(this.section.next_n_apt_total, '{{.BookingCount}}', bknCntStr);
		}
		if(this.parentSection && this.isMultiStepForm && this.parentSection.header_summary && this.parentSection.header_summary.next_n_apt_total){
			this.multiNextNAptTotal = this.utilServ.generateDynamicStr(this.parentSection.header_summary.next_n_apt_total, '{{.BookingCount}}', bknCntStr);
		}
		if(this.section && this.section.rec_total_after_n_apt){
			this.recTotalAfterNApt = this.utilServ.generateDynamicStr(this.section.rec_total_after_n_apt, '{{.BookingCount}}', bknCntStr);
		}
		if(this.parentSection && this.isMultiStepForm && this.parentSection.header_summary && this.parentSection.header_summary.rec_total_after_n_apt){
			this.multiRecTotalAfterNApt = this.utilServ.generateDynamicStr(this.parentSection.header_summary.rec_total_after_n_apt, '{{.BookingCount}}', bknCntStr);
		}
		// Check if payment summary visible
		this.isPaySummVisible = this.bkngFormServ.isPaySumryVisible();
		// set label for tax
		this.taxLabel = '';
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.custom_tax_label){
			this.taxLabel = this.admnStngs.merchant_settings.store.custom_tax_label;
		} else {
			this.taxLabel = 'Sales Tax';
		}
		this.taxLabel = this.translate.instant(this.taxLabel);
		if(this.bookingTaxType == 'percentage'){
			this.taxLabel = this.taxLabel+'('+this.bookingTaxRate+'%)';
		}
		// set label for sub total
		this.subTotalLabel = this.isMultiStepHeader ? this.parentSection?.header_summary?.total : this.section?.total;
		this.firstAptTotalLabel = this.isMultiStepHeader ? this.parentSection?.header_summary?.total : this.section?.total;
		if(this.selectedFrequency && this.selectedFrequency.form_frequency_data.occurence_time && this.selectedFrequency.form_frequency_data.occurence_time == 'recurring' && this.selectedFrequency.form_frequency_data.frequency_discount_on_bookings == 'exclude-first-booking' && this.bookingType == 'add'){
			if(this.displayGiftCardAmount || this.displayReferralDiscount){
				this.subTotalLabel = this.translate.instant('1st Appt Sub Total');
				this.firstAptTotalLabel = this.translate.instant('1st Appt Total');
			}
		} else{
			if(this.displayGiftCardAmount || this.displayReferralDiscount){
				this.subTotalLabel = this.translate.instant('Sub Total');
			}
		}
		this.totalDisplayStatus = this.setTotalDisplayStatus(this.section?.total, this.section?.total_before_tax_id, this.section?.total_before_tax);
		if(this.parentSection?.header_summary){
			this.headerSummTotalDisplayStatus = this.setTotalDisplayStatus(this.parentSection?.header_summary?.total, this.parentSection?.header_summary?.total_before_tax_id, this.parentSection?.header_summary?.total_before_tax);
		}
		// Summary Sequence
		this.setSummarySeq();
		this.loader.hide(this.loaderId);
		//This section only used in multistep (Total summaries)
		if(this.isMultiStepForm && this.settings){
			let count: number = 0;
			if(this.parentSection?.header_summary?.total_before_tax){
				count++
			}
			if(this.headerSummTotalDisplayStatus){
				count++
			}
			if(this.selectedFrequency?.form_frequency_data?.occurence_time && this.selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && this.BKFrm?.value?.coupon?.apply_on_bookings && this.BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && this.BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring'){
				if(this.parentSection?.header_summary?.next_n_apt_total){
					count++
				}
				if(this.parentSection?.header_summary?.rec_total_after_n_apt){
					count++
				}
			}
			if(!(this.selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && this.BKFrm?.value?.coupon?.apply_on_bookings && this.BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && this.BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring') && (this.selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && (this.selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (this.BKFrm?.value?.coupon?.apply_on_bookings && this.BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking') || (this.selectedFrequency?.form_frequency_data?.shorter_job_length && this.selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && this.selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) ))){
				if(this.parentSection?.header_summary?.first_apt_total){
					count++
				}
			}
			if(count > 2){
				let totalWrapEl:any;
				try{
					totalWrapEl = document.querySelector('.multistep__total-wrapper');
				}catch(err){}
				if(totalWrapEl){
					totalWrapEl.classList.add('both-total-enabled');
				}
			}
		}
		// Add id in case of summary scrolling
		if(this.smryScrlStatus){
			let bkngSumEl:any;
			try{
				bkngSumEl = document.querySelector('.booking-summary-main-cls');
			}catch(err){ /* empty */ }
			if(bkngSumEl){
				bkngSumEl.setAttribute('id','booking-summary-main');
			}
		}
		this.cDRef.detectChanges();
	}
	setHeaderLabels(): void {
		if(this.section?.total_id){
			this.totalLable = this.rcServ.pageData.content?.[this.section.total_id];
		}
		if(this.parentSection?.header_summary && this.parentSection.header_summary?.total_id){
			this.headerSummTotalLable = this.rcServ.pageData.content?.[this.parentSection.header_summary.total_id];
		}
	}

	setTotalDisplayStatus(elemStatus: boolean, id: string, tbtStatus: boolean): boolean {
		let tbtSett: any = this.pageSett[id];
		if(!elemStatus){
			if(!this.totalWithoutTax && tbtStatus){
				if(tbtSett && tbtSett.total_before_tax_type && tbtSett.total_before_tax_type == 'both'){
					return true;
				}
			}
		}else{
			return true;
		}
		return false;
	}
	/**
	 * Build form parameters based on form id
	 */
	private buildFormParams(): void {
		switch(this.formId){
			case 1:
				// Pricing params name
				this.buildPriceParam();
				// Excludes
				this.buildExcludes();
			break;
			case 2:
				// Items with packages and packages addons
				this.buildItemPckgs();
			break;
			case 3:
				//  Items with addons
				this.buildItemAddons();
			break;
			case 4:
				//  Area params
				this.buildAreaParam();
			break;
		}

		// Extras
		this.buildExtras();
		this.cDRef.detectChanges();
	}
	/**
	 * Build pricing params
	 */
	buildPriceParam(){
		this.pricingParams = [];
		if(this.selectedPricingParams && this.selectedPricingParams.length > 0){
			for(let pricingParam of this.selectedPricingParams){
				if(pricingParam?.quantity){
					let obj: any = {
						id: pricingParam.id,
						cat_name: this.bkngFormServ.getPricingParamCatName(pricingParam.name, this.settings),
						quantity: pricingParam.quantity,
						name: this.utilServ.getPricingParamName(pricingParam?.name, pricingParam?.quantity, this.settingsParams?.pricing_parameters)
					}
					this.pricingParams.push(obj);
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build excludes
	 */
	buildExcludes(){
		this.excludes = [];
		if(this.selectedExcludes && (this.selectedExcludes).length > 0 && this.settingsParams && this.settingsParams.excludes){
			for(let exclude of this.selectedExcludes){
				let obj: any = {
					name: this.utilServ.getFormParamName(this.settingsParams.excludes[exclude?.id]),
					quantity: exclude.quantity,
					price: this.isExcludeWithPrice ? this.bkngFormServ.calExcludesPrice(exclude, this.formValues, this.settingsParams.excludes) : 0
				}
				this.excludes.push(obj);
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build the item packages
	 */
	buildItemPckgs(){
		this.items = null;
		this.hideItems = false;
		if(this.selectedItems && this.selectedItems.length > 0){
			if(this.bkngFormServ.checkItemsAvailability(this.selectedItems)){
				this.items = this.selectedItems;
			} else if(this.bkngFormServ.isPackageAlreadySelected(this.selectedItems)){
				this.hideItems = true;
				this.items = this.selectedItems;
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build the item addons
	 */
	buildItemAddons(){
		this.items = [];
		if(this.selectedItems && this.selectedItems.length > 0 && this.settingsParams && this.settingsParams.addons){
			for(let item of this.selectedItems){
				let selectedAddons = [];
				if(item.addons && (item.addons).length > 0){
					for(let addon of item.addons){
						let param: any = this.settingsParams.addons[addon.id];
						selectedAddons.push(this.translate.instant(this.utilServ.getFormParamName(param))+" ");
					}
					let obj: any = {
						name : this.bkngFormServ.getElementName(item, 'items'),
						quantity: item.quantity,
						addons: selectedAddons.toString()
					}
					this.items.push(obj);
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build the area parameters
	 */
	buildAreaParam(){
		this.areaParams = null;
		if(this.selectedAreaParams && this.selectedAreaParams.quantity && this.selectedAreaParams.id && this.settingsParams && this.settingsParams.area_parameters){
			let param: any = this.settingsParams.area_parameters[this.selectedAreaParams.id];
			let obj: any = {
				id:this.selectedAreaParams.id,
				name: param ? (this.utilServ.getFormParamName(param)) : this.selectedAreaParams.name,
				quantity: this.selectedAreaParams.quantity
			}
			this.areaParams = obj;
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build the extras
	 */
	buildExtras(){
		this.extras = [];
		if(this.selectedExtras && this.selectedExtras.length > 0 && this.settingsParams && this.settingsParams.extras){
			for(let extra of this.selectedExtras){
				let obj: any = {
					name: this.utilServ.getFormParamName(this.settingsParams.extras[extra?.id]),
					quantity: extra.quantity,
					price: this.isExtraWithPrice ? this.bkngFormServ.calExtraPrice(extra, this.formValues, this.settingsParams.extras) : 0
				}
				this.extras.push(obj);
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Set the summary sequence
	 */
	private setSummarySeq(): void {
		if(this.section && this.section.id && this.formMetaData){
			// Set body sequence
			this.setBodySeq();
			// Set Footer sequence
			this.setFooterSeq();
			// Multi step header payment order
			this.setMultiStepHeaderSeq();
		}
		this.cDRef.detectChanges();
	}
	setBodySeq(){
		if(this.formMetaData.sum_body_sequence && (this.formMetaData.sum_body_sequence).length > 0){
			let boydSeq: any = this.formMetaData.sum_body_sequence;
			// Handle form section order
			if(boydSeq && boydSeq.length > 0){
				for(let i = 0; i < boydSeq.length;i++){
					this.bodyOrder[boydSeq[i]] = i + 1;
				}
			}
		}
	}
	setFooterSeq(){
		if(this.formMetaData.sum_footer_sequence && (this.formMetaData.sum_footer_sequence).length > 0){
			let footerSeq: any = this.formMetaData.sum_footer_sequence;
			// Handle form section order
			if(footerSeq && footerSeq.length > 0){
				for(let i = 0; i < footerSeq.length;i++){
					this.paymentOrder[footerSeq[i]] = i + 1;
				}
			}
		}
	}
	setMultiStepHeaderSeq(){
		// Multi step header payment order
		if(this.formMetaData.multi_header_sum_sequence && (this.formMetaData.multi_header_sum_sequence).length > 0){
			let multiHeaderSeq: any = this.formMetaData.multi_header_sum_sequence;
			// Handle form section order
			if(multiHeaderSeq && multiHeaderSeq.length > 0){
				for(let i = 0; i < multiHeaderSeq.length;i++){
					this.multiPaymentOrder[multiHeaderSeq[i]] = i + 1;
				}
			}
		}
	}
	/**
	 * Get the total without tax
	 * @returns
	 */
	public getTotalWithoutTax(): any{
		let tWTax = 0;
		let total = 0;
		let salesTax = 0;
		let giftCardAmount = 0;
		let referralAmount = 0;
		if(this.bookingType == 'add' || (this.prefilledData && ![1, 2, 4].includes(this.prefilledData?.status))){
			total = +this.displayTotal;
			salesTax = +this.displaySaleTax;
			giftCardAmount = +this.displayGiftCardAmount;
			referralAmount = +this.displayReferralDiscount;
		} else if(!this.prefilledData?.is_voided){
			total = this.prefilledData.total ? this.prefilledData.total : 0;
			salesTax = this.prefilledData.booking_tax ? this.prefilledData.booking_tax : 0;
			giftCardAmount = this.prefilledData.gift_card.discount ? this.prefilledData.gift_card.discount : 0;
			referralAmount = this.prefilledData.referral_amount ? this.prefilledData.referral_amount : 0;
		}
		tWTax = this.utilServ.roundToTwo(total - salesTax);
		tWTax = this.utilServ.roundToTwo(tWTax - giftCardAmount);
		tWTax = this.utilServ.roundToTwo(tWTax - referralAmount);

		tWTax = Math.max(this.utilServ.roundToTwo(tWTax), 0);
		return tWTax;
	}

	/**
	 * Update service price according to priceable custom fields
	 */
	private updateServPriceAccPriceableFields(): void {
		this.priceableCustomFieldsPriceType = this.BKFrm.controls['location_type'].value === 'ML' ? 'prices_ml' : 'prices_sa';
		this.priceableCustomFields = this.BKFrm.controls['priceable_custom_fields'] ? this.BKFrm.controls['priceable_custom_fields'].value : undefined;
		this.updatedDisplayServicePrice = 0;
		if(this.displayServicePrice > 0){
			this.updatedDisplayServicePrice = this.displayServicePrice;
			let includeInFreqFieldsPrice = +(this.BKFrm.value['include_in_freq_fields_price'] || 0);
			this.updatedDisplayServicePrice -= includeInFreqFieldsPrice;
		}else if(this.BKFrm.value['before_priceable_fields_price']){
			this.updatedDisplayServicePrice = this.BKFrm.value['before_priceable_fields_price'];
		}
		if(this.updatedDisplayServicePrice < 0){
			this.updatedDisplayServicePrice = 0;
		}
		this.displaySetForFieldNamePrice();
	}
	/**
	 * Display priceable custom fields in payment summary according to position
	 */
	private displaySetForFieldNamePrice(){
		if(this.priceableCustomFields){
			let typeArray = ['include_in_freq_disc', 'exempt_from_freq_disc', 'non_taxable', 'after_discounted_total'];
			let locType = this.BKFrm.value['location_type'];
			for (let fieldType of typeArray){
				this.priceableFieldNamePriceObj[fieldType] = [];
				if(this.priceableCustomFields[fieldType] && (this.priceableCustomFields[fieldType]).length > 0){
					this.setPriceToName(fieldType, locType);
				}
			}
		}
	}
	/**
	 * Set name and price object for display purpose
	 * @param fieldType
	 */
	private setPriceToName(fieldType: any, locType: any){
		for(let field of this.priceableCustomFields[fieldType]){
			if(this.priceableFieldCal.isFieldValue(field, fieldType, this.selectedServiceType)){
				let fieldObj = this.priceableFieldCal.setFieldObj(field, locType);
				(this.priceableFieldNamePriceObj[fieldType]).push(fieldObj);
			}
		}
	}
	// eslint-disable-next-line complexity
	private setCustFieldDisplayStatus(): void {
		this.custFieldGroupStatus = {};
		if(this.utilServ.checkArrLength(this.bkngCustSecServ.priceableCustFields)){
			for(let group of this.bkngCustSecServ.priceableCustFields){
				this.custFieldGroupStatus[group.id] = false;
				if(this.utilServ.checkArrLength(group.custFields)){
					for(let field of group.custFields){
						// eslint-disable-next-line max-depth
						if(this.hasCustFieldVal(group, field)){
							this.custFieldGroupStatus[group.id] = true;
							break;
						}
					}
				}
			}
		}
		this.cDRef.detectChanges();
	}
	// eslint-disable-next-line complexity
	private hasCustFieldVal(group: any, field: any): boolean {
		if(this.customFieldsGroup && this.customFieldsGroup.contains(field.ctrl)){
			if((!field.isCheckbox && this.customFieldsGroup.controls[field.ctrl].value && this.customFieldsGroup.controls[field.ctrl].value != '' && this.customFieldsGroup.controls[field.ctrl].value != null && this.customFieldsGroup.controls[field.ctrl].value != 'null') || (field.isCheckbox && this.cfCheckboxGroup.controls[field.ctrl].value && (this.cfCheckboxGroup.controls[field.ctrl].value).length > 0)){
				if(this.checkCustomFieldStatus(group, field)){
					return true;
				}
			}
		}
		return false;
	}
	checkCustomFieldStatus(group: any, field: any): boolean {
		if(!group.isIndividual && this.bkngFormServ?.formMetaData?.sum_custom_fields_status?.sec?.[group.id]?.['status']){
			if(this.bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields[field.id] && this.bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields[field.id]['status']){
				return true;
			}
			return false;
		}else{
			return true;
		}
	}
}
