import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
// Service
import { InitServ, SchedulingServ, DateServ} from '../../../../Services';

@Component({
  selector: 'bk-slot',
  templateUrl: './SlotLayout.component.html',
})
export class SlotLayoutComponent implements OnInit {

	@Input() BKFrm!  : FormGroup;
	@Input() section : any;
	@Input() selectedDate : any;
	@Input() perDateAvailSpots : any;
	@Input() availableDates : any;
	@Input() notAvailableDates : any = [];
	@Input() availableProviders: any;
	@Input() dateDiscountsArray : any;
	@Input() dayDiscountsArray : any;
	@Input() dayDiscountBookings : any;
	@Input() prefilledData : any;
	@Input() isSpotAvailable : any;
	@Output() callScheduleCalendarPopup : EventEmitter<any> = new EventEmitter();
	@Output() onSlotChange : EventEmitter<any> = new EventEmitter();

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	allSpots: any = this.initServ.appBookingSpots; // get all spots
	bookingSpots: any;
	// selectedSpot: any;

	constructor(public keyvalue: KeyValuePipe,  public initServ: InitServ, public scheduleServ: SchedulingServ, public dateServ : DateServ){
	}

	ngOnInit(): void {
		// console.log('selectedDate', this.selectedDate, this.perDateAvailSpots, this.availableDates)
		let location_id = this.BKFrm.controls['base_location_id'].value
		if(this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.providers.provider_availability_based_on && this.admnStngs.merchant_settings.providers.provider_availability_based_on == 'all_location'){
			location_id = 0
		}
		this.bookingSpots = this.allSpots[location_id]
		if(!this.notAvailableDates || typeof(this.notAvailableDates) == 'undefined'){
			this.notAvailableDates = []
		}
		// this.selectedSpot = this.selectedDate+','+this.BKFrm.controls['arrival_time'].value
		// console.log('perDateAvailSpots', this.perDateAvailSpots, this.bookingSpots)
	}

	/**
	 * Function to call on provider slot change.
	 * @param slot
	 * @param slotDate
	 */
	// SlotChange(spot: any): void {
	// 	this.selectedSpot = spot.value
	// 	let dateAndSpot = (spot.value).split(",")
	// 	console.log('slot',spot.value)
	// 	console.log(dateAndSpot)
	// 	let obj = {
	// 		slot : dateAndSpot[1],
	// 		slotDate : dateAndSpot[0],
	// 		// slotLayoutType : this.slotLayoutType
	// 	}
	// 	this.onSlotChange.emit(obj);
	// }
	SlotChangeFunc(slot: string, slotDate: any): void {
		let obj = {
			slot : slot,
			slotDate : slotDate,
			// slotLayoutType : this.slotLayoutType
		}
		this.onSlotChange.emit(obj);
	}

	callBeforeSlotSelect(){
		if(this.selectedDate && !this.isSpotAvailable){
			this.callScheduleCalendarPopup.emit();
		}
		setTimeout(()=>{
			let spotId = 'li-'+this.BKFrm.controls['arrival_time'].value+"-"+this.selectedDate
			let myElement : any = document.getElementById(spotId);
			if(myElement){
				let topPos:any = myElement.offsetTop;
				let p:any = document.getElementById('spot-dropdown')
				p.scrollTop = topPos -50;
			}
		},200);
	}
}