import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { NgOnDestroy, UtilServ, InitServ, BkngFormServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-frequency',
	templateUrl: './Frequency.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class FrequencyComponent implements OnInit {
	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() availableSett: any;
	@Input() frequencyForm!: FormGroup;
	@Input() settings: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() isMultiStepForm: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Output() frequencyChange: EventEmitter<any> = new EventEmitter();
	frequencies: any;
	isDisabled: any;
	descStatus: boolean = false;

	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy, Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param _index
	 * @param freq
	 * @returns frequency id
	 */
	public trackById(_index:any, freq: any) {
		return freq.form_frequency_data.id;
	}

	constructor(public bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, private secServ: SectionServ,private cDRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		if(this.section){
			this.descStatus = this.secServ.checkEleStatus(this.pageSett,this.section.freq_desc_id);
		}
		// Build frequencies
		this.buildfrequencies();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange && propName !== 'selectedFrequency') {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildfrequencies();
						break;
					}
				}
			}
		}
	}
	/**
	 * Build the frequencies
	 */
	private buildfrequencies(): void {
		this.frequencies = [];
		if(this.settings && this.settings.frequencies && (this.settings.frequencies).length > 0){
			for(let freq of this.settings.frequencies){
				if(this.bkngFormServ.frequencyVisible(freq, this.settings, this.selectedLocation, this.selectedServiceType, this.bookingType, this.prefilledData, this.availableSett)){
					this.frequencies.push(freq);
				}
			}
		}
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, true, '', this.isCustomerAllowedRes);
		this.cDRef.detectChanges();
	}
	/**
	 * Get the frequency
	 * @param frequency : form frequency
	 */
	public getFrequency(freq: any) : void {
		this.selectedFrequency = freq;
		this.bkngFormServ.formParamMsgPopup(freq.form_frequency_data);
		this.frequencyChange.emit(freq);
	}
}