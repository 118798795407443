import { Injectable } from '@angular/core';

import { CalCmnFuncServ } from './CalCmnFunc.service';
import { DiscountCalServ } from './DiscountCal.service';
import { PricingParamCalServ } from './PricingParamCal.service';
import { ExtraAndExcludeCalServ } from './ExtraAndExcludeCal.service';
import { PriceableFieldCalServ } from './PriceableFieldCal.service';

@Injectable({
	providedIn: 'root'
})
export class BkngFormPriceCalServ {

	// eslint-disable-next-line max-params
	constructor(private cmnFunc: CalCmnFuncServ, private DscntCal: DiscountCalServ, private priceableFieldCal: PriceableFieldCalServ, private paramCalServ: PricingParamCalServ, private extraExcludeCalServ : ExtraAndExcludeCalServ) {

	}
	/**
	 * Get the common local variables for price calculations
	 */
	public get getCmnPriceVar(): any {
		return {
			displayServicePrice : 0,
			displayServiceTotal : 0,
			displayFrequencyDiscount : 0,
			displayServiceFee : 0,
			displayCouponDiscount : 0,
			displayTipsAmount : 0,
			displayDiscountedAmount : 0,
			displaySpotDiscount : 0,
			giftCardTotalAmount : 0,
			displayGiftCardAmount : 0,
			displayReferralDiscount : 0,
			displaySaleTax : 0,
			displayFinalAmount : 0,
			displayProviderAmount : 0,
			displayTotal : 0,
			displayBookingTime : 0,
			bookingTotalTime: 0,
			bookingParamsTime : 0,
			bookingTimeHours : 0,
			bookingTimeMin : 0,
			adjustedTimeHours : 0,
			adjustedTimeMin : 0,
			excludeDayDiscountValue : false,
			excludeExpeditedAmountValue : false,
			exemptSalesTaxValue : false,
			excludeServiceFeeValue : false,
			bookingTaxRate : 0,
			bookingTaxType : '',
			serviceTotalForProvider : 0,
			firstRecAptDiscountedAmount : 0,
			firstRecAptFinalAmount : 0,
			afterNthAptDiscountedAmount: 0,
			afterNthAptFinalAmount : 0,
			isServiceFeeTaxable : '',
			baseAmountForRecDiscount: 0
		}
	}
	/**
	 * Get the variables for provider payment details.
	 */
	public get getCmnPrvdrPayVar(): any {
		return {
			serviceTotal : 0,
			couponDiscount : 0,
			reimbursements : [],
			tipsAmount : 0,
			parkingAmount : 0,
			bonusAmount : 0,
			serviceFeeAmount : 0,
			providerBasicAmount : 0,
			override_provider_pay : false,
			provider_pay_value : 0,
			priceableFieldsPrice: 0
		}
	}
	/**
	 * This calculates the total price and time for a booking. It takes several parameters including the local price variable, provider pay variable, the booking form values, and input object.
		Here are the steps performed in the function:
		1.Calculate the price and time of pricing parameters based on the form values.
		2.Update displayServicePrice and bookingParamsTime with the calculated values.
		3.Calculate the price and time of selected extras based on the form values.
		4.Add the extra price and time to displayServicePrice and bookingParamsTime respectively.
		5.Calculate the price and time of priceable custom fields included in the frequency discount.
		6.Add the custom field price to displayServicePrice.
		7.Calculate the time of priceable custom fields.
		8.Add the custom field time to bookingParamsTime.
		9.Reset the exclude extra time and other related fields.
		10.If the selected service is not overridden in pricing and not override service total, calculate the price of include_in_freq_disc custom fields.
		11.Add the custom field price to displayServicePrice.
		12.Calculate the total booking time.
		13.Update the form values with the calculated booking total time.
		14.Calculate the booking total time and extras price.
		15.Calculate the service hourly value or the overridden service price.
		16.Update the form values with the calculated service values.
		17.Calculate the service total for the provider by subtracting the price of custom fields that go directly to the provider.
		18.Calculate the frequency discount if applicable.
		19.Update the form values with the calculated frequency discount values.
		20.Check if the service is hourly based and price based on pricing param time.
		21.If so, calculate the extras value exempted from the frequency discount.
		22.Add the exempt extras price to displayServiceTotal and serviceTotalForProvider.
		23.Calculate the price and time of priceable custom fields exempted from the frequency discount.
		24.Add the exempt fields price to displayServiceTotal and serviceTotalForProvider.
		25.Subtract the price of exempt fields from serviceTotalForProvider.
		26.Calculate the spot, coupon discount, and taxable service fee if applicable.
		27.Update the form values with the calculated discount values.
		28.Convert the booking time into hours and minutes.
		29.Calculate the final amount after all adjustments and discounts.
		30.Calculate the price of priceable custom fields after the discounted total.
		31.Add the after-disc fields price to the displayFinalAmount and after_discounted_total.
		32.Calculate the tax, tips, parking, and service fee.
		33.Update the form values with the calculated tax and fee values.
		34.Return the updated priceLocalVar and BKFrmValue objects.
	 * @return object
	 */
	// eslint-disable-next-line max-lines-per-function
	public calcTotalPrice(priceLocalVar : any, prvdrPayVar: any, BKFrmValue: any, inptObj: any): any {
		priceLocalVar.displayServicePrice = 0;
		priceLocalVar.bookingParamsTime = 0;
		// calculate price and time of pricing parameters according to forms.
		let paramPriceTimeObj = this.calcPriceOfParamFormBased(BKFrmValue, inptObj.selectedService, inptObj.settingsObj);
		priceLocalVar.displayServicePrice = paramPriceTimeObj.displayServicePrice;
		priceLocalVar.bookingParamsTime = paramPriceTimeObj.bookingTotalTime;
		//calculate price and time of selected extras
		let extrasValueObj = this.extraExcludeCalServ.calcValueExtras(BKFrmValue, inptObj.settingsObj.extras, '', 'all');
		// Add extra price to service price
		priceLocalVar.displayServicePrice = this.cmnFunc.sumFunc(priceLocalVar.displayServicePrice, extrasValueObj.price);
		// Add extra time to booking params time
		priceLocalVar.bookingParamsTime = this.cmnFunc.sumFunc(priceLocalVar.bookingParamsTime, extrasValueObj.time);

		//calculate time of priceable custom fields
		let fieldInpObjfrTime = {type: '', applyTo: '', seprateFieldValue: '', outType: 'time'};
		let custmFieldTimeValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObjfrTime);

		// Add custom field time to booking params time
		priceLocalVar.bookingParamsTime = this.cmnFunc.sumFunc(priceLocalVar.bookingParamsTime, custmFieldTimeValObj.time);

		priceLocalVar.bookingParamsTime = priceLocalVar.bookingParamsTime > 0 ? priceLocalVar.bookingParamsTime : 0;
		// Reset exclude extra time
		BKFrmValue.exclude_extra_time = 0;
		BKFrmValue.exclude_fields_time = 0;
		BKFrmValue.add_fields_time = 0;
		if(this.cmnFunc.isHourlyAndPriceBasedOnCustom(inptObj.selectedService)){
			let seprateCustomFields = this.priceableFieldCal.calcPriceableCustomFieldsExcTime(BKFrmValue, '', 'add_as_separate_charge', 'add_time');
			BKFrmValue.add_fields_time = BKFrmValue?.add_fields_time + seprateCustomFields?.time;
		}
		// calculate total booking time.
		let bookingTotalTimeObj = this.calcBookingTotalTime(BKFrmValue, priceLocalVar, inptObj);
		// Assign
		BKFrmValue.original_length = bookingTotalTimeObj.originalLength;
		BKFrmValue.reduced_length = bookingTotalTimeObj.reducedLength;
		BKFrmValue.length = bookingTotalTimeObj.length;
		BKFrmValue.provider_length = bookingTotalTimeObj.providerLength;
		let bookingTotalTime = bookingTotalTimeObj.totalTime;
		let totalTimeAndExtrasPric : any = {
			bookingTotalTime: bookingTotalTime,
			extrasPrice : extrasValueObj.price
		};
		// Calc if service hourly or override pricing
		let servCalObj = this.calcHourlyServOrOverrideServ(BKFrmValue, inptObj, priceLocalVar, totalTimeAndExtrasPric);
		BKFrmValue.service_price_overridden = servCalObj.servicePriceOverridden;
		BKFrmValue.service_price_overridden_value = servCalObj.servicePriceOverriddenValue;
		BKFrmValue.service_hourly_value = servCalObj.serviceHourlyValue;
		BKFrmValue.exclude_extra_time = servCalObj.excludeExtraTime;
		BKFrmValue.exclude_fields_time = servCalObj.excludeFieldsTime;
		priceLocalVar.displayServicePrice = servCalObj.displayServicePrice;
		//calculate price and time of priceable custom fields include in freq discount
		BKFrmValue.before_priceable_fields_price = priceLocalVar.displayServicePrice;
		BKFrmValue.include_in_freq_fields_price = 0;
		BKFrmValue.inc_in_freq_exc_frm_prvdr = 0;

		let fieldInpObj = {type: 'include_in_freq_disc', applyTo: '', seprateFieldValue: '', outType: ''};
		let incInFreqExcFrmServPrvdTotal = 0;
		if(this.cmnFunc.isHourlyService(inptObj.selectedService)){
			//Function to add some priceable custom fields(whose add to charge as seprate option enable) in price seprately.
			fieldInpObj = {type: 'include_in_freq_disc', applyTo: '', seprateFieldValue: 'add_as_separate_charge', outType: ''};
		}
		let seprateCustomFields = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);
		BKFrmValue.include_in_freq_fields_price = this.cmnFunc.roundToTwo(+seprateCustomFields?.price);
		incInFreqExcFrmServPrvdTotal = this.cmnFunc.roundToTwo(+seprateCustomFields?.priceExcFromServPrvTotal);
		BKFrmValue.inc_in_freq_exc_frm_prvdr = +incInFreqExcFrmServPrvdTotal;
		priceLocalVar.displayServicePrice = priceLocalVar.displayServicePrice + (+seprateCustomFields?.price);
		priceLocalVar.displayServicePrice = this.convertMinusValueToZero(priceLocalVar.displayServicePrice);

		priceLocalVar.displayServiceTotal = this.cmnFunc.roundToTwo(priceLocalVar.displayServicePrice);
		// Assign
		BKFrmValue.service_price = priceLocalVar.displayServiceTotal;
		BKFrmValue.service_total = priceLocalVar.displayServiceTotal;
		// Calculate expedited amount price
		priceLocalVar.displayServiceTotal = this.calcExpeditedAmount(BKFrmValue, priceLocalVar);
		// Calculate referral discount price
		priceLocalVar.displayServiceTotal = this.DscntCal.calcReferralAmount(BKFrmValue, priceLocalVar);
		// Set service total for provider
		priceLocalVar.serviceTotalForProvider = this.cmnFunc.roundToTwo(priceLocalVar.displayServiceTotal);
		// Subtract the value of priceable custom fields from service total for provider whose part directly goes to provider
		priceLocalVar.serviceTotalForProvider = priceLocalVar.serviceTotalForProvider - BKFrmValue.inc_in_freq_exc_frm_prvdr;
		priceLocalVar.serviceTotalForProvider = this.convertMinusValueToZero(priceLocalVar.serviceTotalForProvider);
		priceLocalVar.serviceTotalForProvider = this.validateValue(priceLocalVar.serviceTotalForProvider, priceLocalVar.serviceTotalForProvider, 0);

			// Calc freq discount
		let freqDiscObj = this.calcFreqDisc(BKFrmValue, inptObj, priceLocalVar);
		priceLocalVar = freqDiscObj.priceLocalVar,
		BKFrmValue.frequency_discount_amount = freqDiscObj.frequencyDiscountAmount,
		BKFrmValue.frequency_discount_value = freqDiscObj.frequencyDiscountValue,
		BKFrmValue.frequency_discount_type = freqDiscObj.frequencyDiscountType
		// check if service hourly based and price based on pricing param time
		BKFrmValue.exempt_extras_price = 0;
		if(this.cmnFunc.checkIfServHoulyBasedOnParam(inptObj.selectedService)){
			//code to calculate the extras value whose value exempted from freq discount.
			let exemptextrasObj : any = this.extraExcludeCalServ.calcValueExtras(BKFrmValue, inptObj.settingsObj.extras, 'exempt', 'all');
			let exemptExtrasValue = exemptextrasObj.price;
			// Assign
			BKFrmValue.exempt_extras_price = +exemptExtrasValue;
			priceLocalVar.displayServiceTotal = priceLocalVar.displayServiceTotal + (+exemptExtrasValue);
			priceLocalVar.displayServiceTotal = this.convertMinusValueToZero(priceLocalVar.displayServiceTotal);
			priceLocalVar.serviceTotalForProvider = this.cmnFunc.roundToTwo(priceLocalVar.serviceTotalForProvider + (+exemptExtrasValue));
			priceLocalVar.serviceTotalForProvider = this.convertMinusValueToZero(priceLocalVar.serviceTotalForProvider);
		}

		//calculate price and time of priceable custom fields whose value exempted from freq discount
		let exemptFieldInpObj = {type: 'exempt_from_freq_disc', applyTo: '', seprateFieldValue: '', outType: '', selectedService: ''};
		if(this.cmnFunc.isHourlyService(inptObj.selectedService)){
			exemptFieldInpObj = {type: 'exempt_from_freq_disc', applyTo: '', seprateFieldValue: 'add_as_separate_charge', outType: '', selectedService: ''};
		}
		let exemptFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, exemptFieldInpObj);
		let exemptFieldsValue = exemptFieldValObj.price;
		let exemptFrmFreqExcFrmServPrvdTotal = this.cmnFunc.roundToTwo(+exemptFieldValObj?.priceExcFromServPrvTotal);
		BKFrmValue.exempt_frm_freq_exc_frm_prvdr = +exemptFrmFreqExcFrmServPrvdTotal;
		// Assign
		BKFrmValue.exempt_from_freq_fields_price = +exemptFieldsValue;
		priceLocalVar.displayServiceTotal = priceLocalVar.displayServiceTotal + (+exemptFieldsValue);
		priceLocalVar.displayServiceTotal = this.convertMinusValueToZero(priceLocalVar.displayServiceTotal);
		// add this line to solve a issue in emails, like if service price value is zero but service total has some value, but because of this code in old mobile app and old email code there is a difference in price, so we comment this value
		// BKFrmValue.service_total = priceLocalVar.displayServiceTotal;
		priceLocalVar.serviceTotalForProvider = this.cmnFunc.roundToTwo(priceLocalVar.serviceTotalForProvider + (+exemptFieldsValue));
		// Subtract the value of priceable custom fields from service total for provider whose part directly goes to provider
		priceLocalVar.serviceTotalForProvider = this.cmnFunc.roundToTwo(priceLocalVar.serviceTotalForProvider - exemptFrmFreqExcFrmServPrvdTotal);
		priceLocalVar.serviceTotalForProvider = this.convertMinusValueToZero(priceLocalVar.serviceTotalForProvider);

		// Calc spot, coupon discount and taxable service fee
		let discObj = this.calcSpotCouponDiscAndTaxableServFee(BKFrmValue, priceLocalVar, prvdrPayVar);
		priceLocalVar = discObj.priceLocalVar;
		prvdrPayVar =  discObj.prvdrPayVar;
		BKFrmValue.total_before_coupon_discount = discObj.totalBeforeCouponDiscount;
		BKFrmValue.provider_discounted_total = discObj.providerDiscountedTotal;
		// Assign
		BKFrmValue.discounted_total = +priceLocalVar.displayDiscountedAmount;
		BKFrmValue.after_discounted_total = +priceLocalVar.displayDiscountedAmount
		priceLocalVar.displayBookingTime = bookingTotalTime > 0 ? bookingTotalTime : 0;
		priceLocalVar.bookingTotalTime = bookingTotalTime > 0 ? bookingTotalTime : 0;

		// Convert time into hours and minutes
		let convertTimeObj = this.cmnFunc.convertBookingTimeToHours(BKFrmValue, priceLocalVar.displayBookingTime);
		priceLocalVar.displayBookingTime = convertTimeObj.displayBookingTime;
		priceLocalVar.bookingTimeHours = convertTimeObj.bookingTimeHours;
		priceLocalVar.bookingTimeMin = convertTimeObj.bookingTimeMin;
		priceLocalVar.displayFinalAmount = this.cmnFunc.roundToTwo(+priceLocalVar.displayDiscountedAmount);
		// if adjusted price
		priceLocalVar.displayFinalAmount = this.validateValue(BKFrmValue.adjust_price, this.cmnFunc.roundToTwo(+(BKFrmValue.adjusted_price)), priceLocalVar.displayFinalAmount);
		//calculate price of priceable custom fields after discounted total
		let afterDiscfieldInpObj = {type: 'after_discounted_total', applyTo: '', seprateFieldValue: '', outType: ''};
		let afterDiscFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, afterDiscfieldInpObj);
		BKFrmValue.after_disc_total_fields_price = +afterDiscFieldValObj?.price;
		priceLocalVar.displayFinalAmount = priceLocalVar.displayFinalAmount + (this.cmnFunc.roundToTwo(+afterDiscFieldValObj?.price));
		priceLocalVar.displayFinalAmount = this.convertMinusValueToZero(priceLocalVar.displayFinalAmount);
		BKFrmValue.after_discounted_total = this.cmnFunc.roundToTwo(BKFrmValue.after_discounted_total + (this.cmnFunc.roundToTwo(+afterDiscFieldValObj?.price)));
		BKFrmValue.after_discounted_total = this.convertMinusValueToZero(BKFrmValue.after_discounted_total);

		// calculate tax, tips, parking and service fee
		let taxTipParkFeeObj = this.calcTaxTipsParkingServiceFee(BKFrmValue, priceLocalVar);
		priceLocalVar.displaySaleTax = taxTipParkFeeObj.displaySaleTax;
		priceLocalVar.displayFinalAmount = taxTipParkFeeObj.displayFinalAmount;
		priceLocalVar.displayTipsAmount = taxTipParkFeeObj.displayTipsAmount;
		priceLocalVar.displayServiceFee = taxTipParkFeeObj.displayServiceFee;
		priceLocalVar.displayTotal = taxTipParkFeeObj.displayTotal;
		BKFrmValue.non_taxable_fields_price = taxTipParkFeeObj?.nonTaxableFieldsPrice;
		// Assign
		BKFrmValue.booking_tax = +priceLocalVar.displaySaleTax;
		return {
			priceLocalVar,
			BKFrmValue,
		}
	}
	/**
	 * Calculates the display service price based on the inputs provided. It checks if the selected service is hourly or if it has an override pricing.
	 * If it is hourly, it calculates the service price and assigns values to certain variables.
	 * If it has override pricing, it sets the display service price to the override pricing value.
	 * It also validates and overrides the service total from the booking form.
	 * In the end, it returns an object containing various values related to the service price.
	 * @param BKFrmValue
	 * @param inptObj
	 * @param priceLocalVar
	 * @param totalTimeAndExtrasPric
	 */
	private calcHourlyServOrOverrideServ(BKFrmValue: any, inptObj: any, priceLocalVar: any, totalTimeAndExtrasPric: any){
		BKFrmValue.service_price_overridden = false;
		BKFrmValue.service_price_overridden_value = null;
		// If service category is hourly
		if(this.cmnFunc.isHourlyService(inptObj.selectedService)){
			// calculate in case of hourly service
			let hourlyServFunObj = this.calcIfHourlyService(BKFrmValue, priceLocalVar, inptObj, totalTimeAndExtrasPric);
			priceLocalVar.displayServicePrice = hourlyServFunObj.displayServicePrice;
			BKFrmValue.before_priceable_fields_price = +priceLocalVar.displayServicePrice;
			// Assign
			BKFrmValue.service_hourly_value = hourlyServFunObj.serviceHourlyValue;
			BKFrmValue.exclude_extra_time = hourlyServFunObj.excludeExtraTime;
			BKFrmValue.exclude_fields_time = hourlyServFunObj.excludeFieldsTime;

			BKFrmValue.service_price_overridden = false;
			BKFrmValue.service_price_overridden_value = null;
		}else if(this.cmnFunc.isOverridePricing(inptObj.selectedService)){
			// If service override price true
			priceLocalVar.displayServicePrice = this.cmnFunc.roundToTwo(+inptObj.selectedService.override_pricing);
			BKFrmValue.service_price_overridden = true;
			BKFrmValue.service_price_overridden_value = priceLocalVar.displayServicePrice;
		}
		// To override service total from booking form
		priceLocalVar.displayServicePrice = this.validateValue(BKFrmValue.override_service_total, this.cmnFunc.roundToTwo(+BKFrmValue.overridden_service_total), priceLocalVar.displayServicePrice);
		return {
			servicePriceOverridden: BKFrmValue.service_price_overridden,
			servicePriceOverriddenValue: BKFrmValue.service_price_overridden_value,
			serviceHourlyValue: BKFrmValue.service_hourly_value,
			excludeExtraTime: BKFrmValue.exclude_extra_time,
			excludeFieldsTime : BKFrmValue.exclude_fields_time,
			displayServicePrice : priceLocalVar.displayServicePrice
		}
	}
	/**
	 * Function calculates the frequency discount for a booking form. It resets variables related to frequency discount and assigns initial values.
	 * It then checks if the frequency discount is applicable based on certain conditions.
	 * If applicable, it calculates the discount and sets the corresponding variables. If not applicable,
	 * it checks if it is a reschedule form and applies the discount accordingly.
	 * Finally, it patches the values for frequency discount amount, value, and type, and returns the result.
	 * @param BKFrmValue
	 * @param inptObj
	 * @param priceLocalVar
	 * @returns
	 */
	private calcFreqDisc(BKFrmValue: any, inptObj: any, priceLocalVar:any){
		// Reset variables of frequency discount.
		// Assign
		priceLocalVar.displayFrequencyDiscount = 0;
		BKFrmValue.recurring_total = 0;
		BKFrmValue.frequency_discount_amount = 0;
		BKFrmValue.frequency_discount_value = 0;
		BKFrmValue.frequency_discount_type = null;
		let flag = 0;
		// if add/draft booking form OR duplicate booking addition OR frequency not equal to prefilled frequency
		if(this.cmnFunc.isFreqDiscAppliOnFirstAndNth(BKFrmValue, inptObj)){
			// Calculate frequency discount
			let freqDiscountObj= this.calcFreqDiscount(BKFrmValue, priceLocalVar, inptObj);
			// Set variables accordingly
			// Assign
			let typeOneTwo =  this.cmnFunc.isTypeOneTwoThree(freqDiscountObj.type, 'one-two');
			let typeOneThree = this.cmnFunc.isTypeOneTwoThree(freqDiscountObj.type, 'one-three');
			BKFrmValue.recurring_total = this.validateValue(typeOneTwo, freqDiscountObj.recurringTotal);
			priceLocalVar.displayFrequencyDiscount = this.validateValue(typeOneTwo, freqDiscountObj.displayFrequencyDiscount);
			priceLocalVar.firstRecAptFinalAmount = this.validateValue(typeOneTwo, freqDiscountObj.firstRecAptFinalAmount);
			priceLocalVar.firstRecAptDiscountedAmount = this.validateValue(typeOneTwo, freqDiscountObj.firstRecAptDiscountedAmount);
			priceLocalVar.afterNthAptFinalAmount = this.validateValue(typeOneTwo, freqDiscountObj.afterNthAptFinalAmount);
			priceLocalVar.afterNthAptDiscountedAmount = this.validateValue(typeOneTwo, freqDiscountObj.afterNthAptDiscountedAmount);
			priceLocalVar.baseAmountForRecDiscount = freqDiscountObj.baseAmountForRecDiscount;
			if(typeOneThree){
				// Set variables accordingly
				priceLocalVar.displayFrequencyDiscount = freqDiscountObj.freqDiscOnFirstBookingObj.displayFrequencyDiscount;
				priceLocalVar.displayServiceTotal = freqDiscountObj.freqDiscOnFirstBookingObj.displayServiceTotal;
				priceLocalVar.serviceTotalForProvider = freqDiscountObj.freqDiscOnFirstBookingObj.serviceTotalForProvider;
				flag = 1;
			}
		}else if(this.cmnFunc.checkFreqDiscForResc(inptObj)){
			priceLocalVar.displayFrequencyDiscount = 0;
			// if reschedule form
			let applyFreqDiscOnBookObj = this.DscntCal.applyFreqDiscOnBooking(BKFrmValue, inptObj.selectedFrequency, priceLocalVar);
			// Set variables
			priceLocalVar.displayFrequencyDiscount = this.validateValue(applyFreqDiscOnBookObj?.displayFrequencyDiscount, applyFreqDiscOnBookObj?.displayFrequencyDiscount, priceLocalVar?.displayFrequencyDiscount);
			priceLocalVar.displayServiceTotal = this.validateValue(applyFreqDiscOnBookObj.displayServiceTotal, applyFreqDiscOnBookObj.displayServiceTotal,  priceLocalVar.displayServiceTotal);
			priceLocalVar.serviceTotalForProvider = this.validateValue(applyFreqDiscOnBookObj.serviceTotalForProvider, applyFreqDiscOnBookObj.serviceTotalForProvider, priceLocalVar.serviceTotalForProvider);
			flag = 1;
		}
		if(flag){
			// Patch values
			// Assign
			BKFrmValue.frequency_discount_amount = this.cmnFunc.roundToTwo(+priceLocalVar.displayFrequencyDiscount);
			BKFrmValue.frequency_discount_value = this.cmnFunc.roundToTwo(inptObj.selectedFrequency.form_frequency_data.frequency_discount);
			BKFrmValue.frequency_discount_type = inptObj.selectedFrequency.form_frequency_data.frequency_discount_type;
		}
		return {
			priceLocalVar: priceLocalVar,
			frequencyDiscountAmount : BKFrmValue.frequency_discount_amount,
			frequencyDiscountValue : BKFrmValue.frequency_discount_value,
			frequencyDiscountType: BKFrmValue.frequency_discount_type

		}
	}
	/**
	 * Function calculates the spot coupon discount and taxable service fee for a booking.
	 * It first gets the spot discount using the provided values. Then it sets the necessary local variables and assigns values to the input object.
	 * Next, it gets the coupon discount for the provider and assigns the values.
	 * After that, it gets the coupon discount for the customer and assigns the values.
	 * Finally, it adds the taxable service fee if applicable and returns the updated variables and the total before coupon discount and provider discounted total.
	 * @param BKFrmValue
	 * @param priceLocalVar
	 * @param prvdrPayVar
	 * @returns
	 */
	private calcSpotCouponDiscAndTaxableServFee(BKFrmValue: any, priceLocalVar: any, prvdrPayVar: any){
		// Get the spot discount
		let spotDiscObj = this.DscntCal.getSpotDiscount(BKFrmValue, priceLocalVar.displayServiceTotal, priceLocalVar.serviceTotalForProvider, priceLocalVar.excludeDayDiscountValue);
		priceLocalVar.displaySpotDiscount = spotDiscObj.displaySpotDiscount;
		priceLocalVar.displayServiceTotal = spotDiscObj.displayServiceTotal;
		priceLocalVar.serviceTotalForProvider = spotDiscObj.serviceTotalForProvider;
		// Set local variables
		priceLocalVar.displayDiscountedAmount = this.cmnFunc.roundToTwo(priceLocalVar.displayServiceTotal);
		prvdrPayVar.serviceTotal = this.cmnFunc.roundToTwo(priceLocalVar.serviceTotalForProvider);
		// Assign
		BKFrmValue.total_before_coupon_discount = +priceLocalVar.displayDiscountedAmount;
		// Get the coupon discount for provider
		let provDiscObj = this.DscntCal.getCoupnDiscProvider(BKFrmValue, priceLocalVar.serviceTotalForProvider);
		prvdrPayVar.couponDiscount = provDiscObj.couponDiscount;
		priceLocalVar.serviceTotalForProvider = provDiscObj.serviceTotalForProvider;
		// Assign
		BKFrmValue.provider_discounted_total = +priceLocalVar.serviceTotalForProvider;
		// Get the coupon discount for customer
		let couponDisObj = this.DscntCal.getCouponDiscount(BKFrmValue, priceLocalVar.displayServiceTotal, priceLocalVar.displayDiscountedAmount);
		priceLocalVar.displayCouponDiscount = couponDisObj.displayCouponDiscount;
		priceLocalVar.displayDiscountedAmount = couponDisObj.displayDiscountedAmount;
		// Add service fee here if service fee is taxable.
		let servFeeObj = this.cmnFunc.calcTaxableServFee(BKFrmValue, priceLocalVar);
		priceLocalVar.displayServiceFee = servFeeObj.displayServiceFee;
		priceLocalVar.displayDiscountedAmount = servFeeObj.displayDiscountedAmount;
		return {
			priceLocalVar,
			prvdrPayVar,
			totalBeforeCouponDiscount: BKFrmValue.total_before_coupon_discount,
			providerDiscountedTotal: BKFrmValue.provider_discounted_total
		}
	}
	/**
	 * ValidateValue function checks a given condition.
	 * If the condition is true, it returns the value provided.
	 * If the condition is false, it returns the elseValue, which is set to 0 by default.
	 * @param condition
	 * @param value
	 * @param elseValue
	 * @returns
	 */
	public validateValue(condition:any, value: any, elseValue:any = 0){
		if(condition){
			return value;
		}
		return elseValue
	}
	public convertMinusValueToZero(value: any){
		return value > 0 ? value : 0;
	}
	/**
	 * Calculates the price and total time based on the parameters of a selected service and the values of a form.
	 * The function takes three arguments: BKFrmValue (the values of the form), selectedService (the selected service), and settingsObj (the settings object).
	 * Inside the function, the displayServicePrice and bookingTotalTime variables are initialized to 0.
	 * Then, the function checks if the selectedService has a service category price and sets the displayServicePrice variable accordingly.
	 * Next, it checks if the selectedService has a service category time and sets the bookingTotalTime variable accordingly.
	 * After that, based on the value of the form_id from BKFrmValue, the function executes different calculations.
	 * For form_id 1, it calls the calcFormOne function and updates the displayServicePrice and bookingTotalTime variables.
	 * For form_id 2, it calls the calcValueItems function, adds the returned price and time to the variables.
	 * For form_id 3, it calls calcValueItems with an additional argument 'three', adds the returned price and time to the variables.
	 * For form_id 4, it calls calcValueAreaPricing function and adds the returned price and time to the variables.

	 * Finally, the function returns an object with the displayServicePrice and bookingTotalTime values.
	 * @param BKFrmValue : form control values
	 * @param selectedService : service
	 * @param settingsObj : settings
	 * @return object
	 */
	public calcPriceOfParamFormBased(BKFrmValue: any, selectedService: any, settingsObj: any): any{
		let displayServicePrice: number = 0;
		let bookingTotalTime : number = 0;
		// Set the base price of service category if any.
		if(this.cmnFunc.isServCatPrice(selectedService)){
			displayServicePrice = +selectedService.service_category_price;
		}
		// Set the service category time
		if(this.cmnFunc.isServCatTime(selectedService)){
			bookingTotalTime = +selectedService.service_category_time;
		}
		// based on the formid, get the value of pricing parameters.
		switch(+BKFrmValue['form_id']){
			case(1):
				// eslint-disable-next-line no-case-declarations
				let frmOneObj = this.calcFormOne(BKFrmValue, settingsObj, displayServicePrice, bookingTotalTime);
				displayServicePrice = frmOneObj.displayServicePrice;
				bookingTotalTime = frmOneObj.bookingTotalTime;
				break;
			case(2):
				// For form 2 get the price of items and packages.
				// eslint-disable-next-line no-case-declarations
				let itemsPackageObj = this.paramCalServ.calcValueItems(BKFrmValue, settingsObj);
				//price addition
				displayServicePrice = this.cmnFunc.sumFunc(displayServicePrice, itemsPackageObj.price);
				//time addition
				bookingTotalTime = this.cmnFunc.sumFunc(bookingTotalTime, itemsPackageObj.time);
				break;
			case(3):
				// For form 3 get the price of items and addons.
				// eslint-disable-next-line no-case-declarations
				let itemsAddonsObj = this.paramCalServ.calcValueItems(BKFrmValue, settingsObj.addons, 'three');
				//price addition
				displayServicePrice = this.cmnFunc.sumFunc(displayServicePrice, itemsAddonsObj.price);
				//time addition
				bookingTotalTime = this.cmnFunc.sumFunc(bookingTotalTime, itemsAddonsObj.time);
				break;
			case(4):
				// For form 4 get the price of area parameters.
				// eslint-disable-next-line no-case-declarations
				let areaPricingObj = this.paramCalServ.calcValueAreaPricing(BKFrmValue, settingsObj.area_param);
				//price addition
				displayServicePrice = this.cmnFunc.sumFunc(displayServicePrice, areaPricingObj.price);
				//time addition
				bookingTotalTime = this.cmnFunc.sumFunc(bookingTotalTime, areaPricingObj.time);
				break;
		}
		return {
			displayServicePrice,
			bookingTotalTime,
		};
	}
	/**
	 * Calculates the price and total time for form 1. It takes in four parameters: BKFrmValue (the value of the booking form), settingsObj (an object containing pricing parameters), displayServicePrice (the current display service price), and bookingTotalTime (the current booking total time).
	 * First, it calls calcValuePricingParameter and calcValueExcludes to get the pricingParamObj and excludeParamObj.
	 * Then, it adds the price of the pricing parameters to the displayServicePrice using a sumFunc helper function.
	 * Next, it subtracts the price of the excludes from the displayServicePrice using a subFunc helper function.
	 * After that, it checks if the displayServicePrice is greater than zero and sets it to zero if it is not.
	 * Finally, it calculates the bookingTotalTime by adding the time of the pricing parameters and subtracting the time of the excludes.
	 * The function returns an object containing displayServicePrice and bookingTotalTime.
	 * @param BKFrmValue
	 * @param settingsObj
	 * @param displayServicePrice
	 * @param bookingTotalTime
	 * @returns
	 */
	private calcFormOne(BKFrmValue: any, settingsObj: any, displayServicePrice: any, bookingTotalTime: any){
		// For form 1 get the price of pricing parameters and exclude parameters.
		let pricingParamObj = this.paramCalServ.calcValuePricingParameter(BKFrmValue, settingsObj.pricing_param);
		let excludeParamObj = this.extraExcludeCalServ.calcValueExcludes(BKFrmValue, settingsObj.excludes);
		// price addition
		displayServicePrice = this.cmnFunc.sumFunc(displayServicePrice, pricingParamObj.price);
		// Exclude price of excludes
		displayServicePrice = this.cmnFunc.subFunc(displayServicePrice, excludeParamObj.price);
		// check if price greater than zero
		displayServicePrice = (displayServicePrice > 0) ? displayServicePrice : 0;
		//time addition
		bookingTotalTime = (+bookingTotalTime) + (+pricingParamObj.time) - (+excludeParamObj.time);
		return {
			displayServicePrice,
			bookingTotalTime,
		}
	}


	/*****************Calculate total booking time functions*************************************/

	/**
	 * Function calculates the booking total time based on several input values.
	 * It takes the values BKFrmValue, priceLocalVar, and inptObj as parameters.
	 * The function initializes a variable totalTimeValue to 0.
	 * It then assigns the value of priceLocalVar.bookingParamsTime to totalTimeValue.
	 * The variable originalLength is set to the rounded value of totalTimeValue.
	 * The function calls another function called CBTTPartTwo from the cmnFunc object, passing in the selectedFrequency and totalTimeValue as arguments, and assigns the result to newTotalTime.
	 * It also calls CBTTPartTwo again, passing in selectedFrequency and BKFrmValue.add_fields_time as arguments, and assigns the result to newAddTime.
	 * The variable reducedLength is set to the rounded value of newTotalTime.
	 * If certain conditions are met, totalTimeValue and addFieldsTime are updated with newTotalTime and newAddTime respectively.
	 * Another condition updates the values of originalLength, reducedLength, and totalTimeValue if certain conditions are met.
	 * The function then calls a function called isHrlyAndAdjustTimeHrly, passing in BKFrmValue and totalTimeValue as arguments, and assigns the result back to totalTimeValue.
	 * The function then sets totalTimeValue to 0 if it is null.
	 * The variable newLengthValue is set to the rounded value of totalTimeValue. The variable providerFinalLength is initialized to 0.
	 * If BKFrmValue.adjust_time is true, providerFinalLength is assigned the value of BKFrmValue.adjusted_time.
	 * Otherwise, it is calculated using the calcPrvdrAmountIfNotAdjust function from the cmnFunc object, passing in BKFrmValue, inptObj, totalTimeValue, and providerFinalLength as arguments.
	 * The function then returns an object with the values of originalLength, reducedLength, newLengthValue, providerFinalLength, and totalTimeValue.
	 * @param BKFrm : form control
	 * @param priceLocalVar : local variables object
	 * @param selectedService : service
	 * @param selectedFrequency : frequency
	 * @return object
	 */
	public calcBookingTotalTime(BKFrmValue: any, priceLocalVar: any, inptObj: any) {
		let totalTimeValue = 0;
		// BKFrm.controls['length'].setValue(null);
		totalTimeValue = priceLocalVar.bookingParamsTime;
		let originalLength = +(Number(totalTimeValue).toFixed(0));
		/**call function from comnFunc**/
		let newTotalTime = this.cmnFunc.CBTTPartTwo(inptObj.selectedFrequency, totalTimeValue);
		let addFieldsTime = +BKFrmValue.add_fields_time;
		let reducedAddFieldsTime = addFieldsTime;
		let newAddTime = this.cmnFunc.CBTTPartTwo(inptObj.selectedFrequency, BKFrmValue.add_fields_time);
		let reducedLength = +(Number(newTotalTime).toFixed(0));
		// if first apt excluded from shorter job length
		if(this.cmnFunc.isShorterLengthApplicable(BKFrmValue, inptObj)){
			if(!this.cmnFunc.isExcFirstFromShorterLength(inptObj.selectedFrequency)){
				totalTimeValue = +newTotalTime;
				reducedAddFieldsTime = +newAddTime;
			}
		}else if(this.cmnFunc.isReschedule(inptObj.bookingType) && !this.cmnFunc.isRecIdEqualBkngId(inptObj.recFirstBkngId, inptObj.bookingId)){
			totalTimeValue = +newTotalTime;
			reducedAddFieldsTime = +newAddTime;
		}else{
			totalTimeValue = +newTotalTime;
			addFieldsTime = +newAddTime;
		}
		if(BKFrmValue.is_service_hourly == 'yes' && (this.cmnFunc.ifPriceBaseOfHSCustom(inptObj.selectedService) && !BKFrmValue.adjust_time_hourly)){
			originalLength = +(Number(BKFrmValue.service_hourly_value + addFieldsTime).toFixed(0));
			reducedLength = +(Number(BKFrmValue.service_hourly_value + reducedAddFieldsTime).toFixed(0));
			totalTimeValue = reducedLength;
		}

		totalTimeValue = this.isHrlyAndAdjustTimeHrly(BKFrmValue, totalTimeValue)

		totalTimeValue = this.convertMinusValueToZero(totalTimeValue);
		let newLengthValue = +(Number(totalTimeValue).toFixed(0));
		// BKFrm.controls['length'].setValue(+newLengthValue);
		let providerFinalLength = 0;
		// if adjust time
		if(BKFrmValue.adjust_time){
			let adjustedTime = +BKFrmValue.adjusted_time;
			providerFinalLength = adjustedTime;
			// BKFrm.controls['provider_length'].setValue(adjustedTime);
		}else{
			providerFinalLength = this.cmnFunc.calcPrvdrAmountIfNotAdjust(BKFrmValue, inptObj, totalTimeValue, providerFinalLength);
		}
		return {
			originalLength : originalLength,
			reducedLength : +reducedLength,
			length : +newLengthValue,
			providerLength : providerFinalLength,
			totalTime : +(Number(totalTimeValue).toFixed(0))
		}
	}
	/**
	 * If service hourly and adjust time hourly then set the value
	 * @param BKFrmValue
	 * @param totalTimeValue
	 * @returns
	 */
	public isHrlyAndAdjustTimeHrly(BKFrmValue: any, totalTimeValue: any){
		if(BKFrmValue.is_service_hourly == 'yes' && BKFrmValue.adjust_time_hourly){
			totalTimeValue = +BKFrmValue.service_hourly_value;
		}
		return totalTimeValue;
	}

	/*****************Calculate price if service hourly functions*************************************/

	/**
	 * Function calculates the price for a selected service based on various parameters.
	 * It takes in input objects and values, such as the booking form value, the price local variable, the input object, and the total time and extras price.
	 * The function first assigns variables for the booking total time, extras price, display service price, and hourly service time.
	 * Next, it checks if the hourly service price is based on parameters time and updates the hourly service time and exclude extra time and fields based on the result.
	 * If the selected service has a price based on custom hourly service, it adds the additional fields time to the hourly service time.
	 * It then calculates the service hours and the decimal service hours, and calculates the display service price based on the pricing per hour and the decimal service hours.
	 * If the selected service has a service category price, it adds it to the display service price.
	 * If the selected service includes the extra price in the hourly price, it adds the extras price to the display service price.
	 * It then calculates the separate extras price based on the booking form value, the extras settings, and the selected service.
	 * Finally, it returns an object with the display service price, the service hourly value, the exclude extra time, and the exclude fields time.
	 * @returns object
	 */
	public calcIfHourlyService(BKFrmValue: any, priceLocalVar: any, inptObj: any, totalTimeAndExtrasPric: any): any {
		let bookingTotalTime : any = totalTimeAndExtrasPric.bookingTotalTime;
		let extrasPrice : any = totalTimeAndExtrasPric.extrasPrice;
		let displayServicePrice = priceLocalVar.displayServicePrice;
		let hourlyServiceTime = BKFrmValue.service_hourly_value;
		// If hourly servie price based on parameters time
		let hourlyObj = this.isHourlyServBasedOnParam(BKFrmValue, inptObj, bookingTotalTime, hourlyServiceTime);
		BKFrmValue.exclude_extra_time = hourlyObj.BKFrmValue.exclude_extra_time;
		BKFrmValue.exclude_fields_time = hourlyObj.BKFrmValue.exclude_fields_time;
		BKFrmValue.service_hourly_value = hourlyObj.BKFrmValue.service_hourly_value;
		hourlyServiceTime = hourlyObj.hourlyServiceTime;
		// Extra time includes in hourly price.
		if(this.cmnFunc.ifPriceBaseOfHSCustom(inptObj.selectedService) && !BKFrmValue.adjust_time_hourly){
			// check if shorter length for extra time
			let addFieldsTime = this.cmnFunc.addFieldsTimeForhourlyCustomServ(BKFrmValue, inptObj);
			hourlyServiceTime = hourlyServiceTime + addFieldsTime;
		}
		hourlyServiceTime = (hourlyServiceTime && hourlyServiceTime > 0) ? hourlyServiceTime : 0;
		let serviceHours = hourlyServiceTime / 60;
		let DecimalserviceHours = Number(serviceHours).toFixed(2);
		displayServicePrice = this.cmnFunc.roundToTwo((+inptObj.selectedService.pricing_per_hour)*(+DecimalserviceHours));
		// Set the base price of service category if any.
		if(this.cmnFunc.isServCatPrice(inptObj.selectedService)){
			displayServicePrice = this.cmnFunc.roundToTwo(this.cmnFunc.sumFunc(displayServicePrice, inptObj.selectedService.service_category_price));
		}
		// Extra price includes in hourly price.
		if(this.cmnFunc.isIncludeExtraInPrice(inptObj.selectedService) && this.cmnFunc.ifPriceBaseOfHSCustom(inptObj.selectedService)){
			displayServicePrice = this.cmnFunc.roundToTwo(this.cmnFunc.sumFunc(displayServicePrice, extrasPrice));
		}
		//Function to add some extras(whose add to charge as seprate option enable) in price seprately.
		let seprateExtrasPrice = this.extraExcludeCalServ.calcExtrasAsSeprateForPriceBaseOnParam(BKFrmValue, inptObj.settingsObj.extras, inptObj.selectedService);
		if(seprateExtrasPrice){
			displayServicePrice = this.cmnFunc.roundToTwo(this.cmnFunc.sumFunc(displayServicePrice, seprateExtrasPrice));
		}
		return {
			displayServicePrice,
			serviceHourlyValue : BKFrmValue?.service_hourly_value,
			excludeExtraTime : BKFrmValue?.exclude_extra_time,
			excludeFieldsTime : BKFrmValue?.exclude_fields_time,
		}
	}
	/**
	 * Function takes four parameters: BKFrmValue, inptObj, bookingTotalTime, and hourlyServiceTime.
	 * Inside the function, two variables are declared: excludeExtraTime and excludeCustomFieldTime.
	 * The function checks if the selected service is priced based on an hourly service.
	 * If it is, the function performs various calculations and assignments to update the BKFrmValue object and calculate the excludeExtraTime and excludeCustomFieldTime values.
	 * Next, the function calculates the excludeExtraAndFieldTime value by adding excludeExtraTime and excludeCustomFieldTime together.
	 * If the hourlyServiceTime is greater than excludeExtraAndFieldTime, the function subtracts excludeExtraAndFieldTime from hourlyServiceTime
	 * Otherwise, it sets hourlyServiceTime to 0.
	 * Finally, the function returns an object containing the updated hourlyServiceTime and BKFrmValue values.
	 * @param BKFrmValue
	 * @param inptObj
	 * @param bookingTotalTime
	 * @param hourlyServiceTime
	 * @returns
	 */
	private isHourlyServBasedOnParam(BKFrmValue: any, inptObj: any, bookingTotalTime: any, hourlyServiceTime: any){
		let excludeExtraTime = 0;
		let excludeCustomFieldTime = 0;
		if(this.cmnFunc.isHourlyServPriceBasedOnParam(inptObj.selectedService)){
			hourlyServiceTime = +(Number(bookingTotalTime).toFixed(0));
			// Assign
			BKFrmValue.service_hourly_value = hourlyServiceTime;
			// calculate seprate extra's excluded time
			excludeExtraTime = this.extraExcludeCalServ.calcExtrasSeprateEnable(BKFrmValue, inptObj.settingsObj.extras, 'all', 'time');
			// check if shorter length for extra time
			excludeExtraTime = this.cmnFunc.isReschedule(inptObj.bookingType) ? this.cmnFunc.checkForShorterLengthForExtraTimeRes(excludeExtraTime, BKFrmValue.frequency, inptObj) : this.cmnFunc.checkForShorterLengthForExtraTime(inptObj.selectedFrequency, excludeExtraTime);
			excludeExtraTime = +(Number(excludeExtraTime).toFixed(0));
			// Assign
			BKFrmValue.exclude_extra_time = excludeExtraTime;

			// calculate seprate custome fields exclude time
			let seprateCustomFields = this.priceableFieldCal.calcPriceableCustomFieldsExcTime(BKFrmValue, '', 'add_as_separate_charge', 'exclude_time');
			excludeCustomFieldTime =  seprateCustomFields?.time;
			// check if shorter length for extra time
			excludeCustomFieldTime = this.cmnFunc.isReschedule(inptObj.bookingType) ? this.cmnFunc.checkForShorterLengthForExtraTimeRes(excludeCustomFieldTime, BKFrmValue.frequency, inptObj) : this.cmnFunc.checkForShorterLengthForExtraTime(inptObj.selectedFrequency, excludeCustomFieldTime);
			excludeCustomFieldTime = +(Number(excludeCustomFieldTime).toFixed(0));
			// Assign
			BKFrmValue.exclude_fields_time = excludeCustomFieldTime
			let excludeExtraAndFieldTime = excludeExtraTime + excludeCustomFieldTime;
			if(hourlyServiceTime > excludeExtraAndFieldTime){
				hourlyServiceTime = hourlyServiceTime - excludeExtraAndFieldTime;
				hourlyServiceTime = +(Number(hourlyServiceTime).toFixed(0));
			} else {
				hourlyServiceTime = 0;
			}
		}
		return {
			hourlyServiceTime,
			BKFrmValue,
		}
	}
	/**
	 * Calculates the expedited amount and adds it to the total.
	 * If the expedited amount is available, it is added to the display service total and then rounded to two decimal places.
	 * If the expedited amount is not available, the display service total is returned as is.
	 * @param BKFrm : form control
	 * @param priceLocalVar : local variables
	 * @returns number
	 */
	public calcExpeditedAmount(BKFrmValue: any, priceLocalVar: any): number{
		// Add the expedited amount to total
		if(this.cmnFunc.isExpeditedAmount(BKFrmValue, priceLocalVar.excludeExpeditedAmountValue)){
			return (this.cmnFunc.roundToTwo((+priceLocalVar.displayServiceTotal) + (+BKFrmValue?.expedited_amount)));
		}else{
			return +priceLocalVar?.displayServiceTotal;
		}
	}

	/**
	 * Function calculates the price of a recurring appointment.
	 * It takes several parameters including the amount without frequency discount, BKFrmValue (an object containing various values), priceLocalVar (an object to store price related values), and inptObj (an object containing additional inputs).
	 * The function first removes any expedited amount from the amount without frequency discount by calling the calcExpeditedForRecApt function.
	 * It then applies a referral discount if it exists.
	 * Next, it calculates the amount without frequency discount for recurring appointments by calling the getAmoutForRecApp function.
	 * After that, it checks if frequency discount is applicable with a coupon or if the coupon should not apply on the first appointment.
	 * If either of these conditions is met, it gets the frequency discount object and updates the amountWithFreqDiscFirstRecApt value.
	 * Similarly, it checks if the coupon should not apply on the first appointment and should apply on recurring appointments.
	 * If this condition is met, it gets the frequency discount object and updates the amountWithFreqDiscNthApt value.
	 * If the selected service is hourly based, it calculates the extras value that is exempted from the frequency discount for recurring appointments by calling the calcValueExtras function.
	 * Next, it calculates the price and time for priceable custom fields that are exempted from the frequency discount for recurring appointments by calling the calcPriceableCustomFields function.
	 * Afterwards, it applies the day discount by calling the getSpotDiscRec function and updates the firstRecAptspotDiscount and afterNthspotDiscount values.
	 * If the day discount applies to all appointments, it calculates the final amount with frequency discount for the first appointment and the final amount with frequency discount for the after nth appointment by calling the finalFirstAndNthspotDiscount function.
	 * Then, it calculates the final amount for the first appointment by calling the getPORAPart function with the first appointment flag set to true. It updates the firstRecAptFinalAmount value.
	 * After that, it calculates the final amount for the after nth appointment by calling the getPORAPart function with the first appointment flag set to false. It updates the afterNthAptFinalAmount value.
	 * Finally, it returns an object containing the recurring total, display frequency discount, final amount for the first appointment, and final amount for the after nth appointment.
	 * @param amountWithoutFrequencyDiscount
	 *
	 */
	public getPriceOfRecApt(amountWithoutFrequencyDiscount: number, BKFrmValue: any, priceLocalVar: any, inptObj: any): any{
		let firstRecAptspotDiscount = 0;
		let afterNthspotDiscount = 0;
		// Remove expedited amount
		amountWithoutFrequencyDiscount = this.cmnFunc.calcExpeditedForRecApt(BKFrmValue, priceLocalVar.excludeExpeditedAmountValue, amountWithoutFrequencyDiscount);
		// Apply referral discount
		if(this.cmnFunc.isValExist(BKFrmValue.referral_discount)){
			amountWithoutFrequencyDiscount = (+amountWithoutFrequencyDiscount) + (+BKFrmValue.referral_discount);
		}
		// Get the without frequency discount for recurring appointment.
		// This block only execute if override service total is not set.
		amountWithoutFrequencyDiscount = this.getAmoutForRecApp(amountWithoutFrequencyDiscount, BKFrmValue, inptObj);
		let amountWithFreqDiscFirstRecApt = +amountWithoutFrequencyDiscount;
		let amountWithFreqDiscNthApt = +amountWithoutFrequencyDiscount;
		// To check if frequency discount applicable with coupon.
		if(this.cmnFunc.isFrequencyDisWithCoupon(BKFrmValue) || this.cmnFunc.isCouponNotApplyOnFirst(BKFrmValue)){
			let freqDiscObj = this.DscntCal.getFrequencyDiscount(inptObj.selectedFrequency, amountWithoutFrequencyDiscount);
			priceLocalVar.displayFrequencyDiscount = freqDiscObj.displayFrequencyDiscount;
			amountWithFreqDiscFirstRecApt = freqDiscObj.amountBasedFrequencyDiscount;
		}
		// Apply frequency discount on after nth booking.
		if(!this.cmnFunc.isCouponNotApplyOnFirst(BKFrmValue) && this.cmnFunc.isCouponNotApplyOnRec(BKFrmValue)){
			let freqDiscObj = this.DscntCal.getFrequencyDiscount(inptObj.selectedFrequency, amountWithoutFrequencyDiscount);
			priceLocalVar.displayFrequencyDiscount = freqDiscObj.displayFrequencyDiscount;
			amountWithFreqDiscNthApt = freqDiscObj.amountBasedFrequencyDiscount;
		}
		if(this.cmnFunc.checkIfServHoulyBasedOnParam(inptObj.selectedService)){
			// code to calculate the extras value whose value exempted from freq discount for recurring appointments.
			let exemptExtraObj = this.extraExcludeCalServ.calcValueExtras(BKFrmValue, inptObj.settingsObj.extras, 'exempt', 'without-first');
			amountWithFreqDiscFirstRecApt = amountWithFreqDiscFirstRecApt + (+exemptExtraObj.price);
			amountWithFreqDiscNthApt   = amountWithFreqDiscNthApt + (+exemptExtraObj.price);
		}

		//calculate price and time of priceable custom fields whose value exempted from freq discount for recurring appointments.
		let fieldInpObj = {type: 'exempt_from_freq_disc', applyTo: 'all', seprateFieldValue: '', outType: ''};
		if(this.cmnFunc.isHourlyService(inptObj.selectedService)){
			fieldInpObj = {type: 'exempt_from_freq_disc', applyTo: 'all', seprateFieldValue: 'add_as_separate_charge', outType: ''}
		}
		let custmFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);
		// Assign
		amountWithFreqDiscFirstRecApt = amountWithFreqDiscFirstRecApt + (+custmFieldValObj.price);
		amountWithFreqDiscNthApt   = amountWithFreqDiscNthApt + (+custmFieldValObj.price);
		amountWithFreqDiscFirstRecApt = this.convertMinusValueToZero(amountWithFreqDiscFirstRecApt);
		amountWithFreqDiscNthApt = this.convertMinusValueToZero(amountWithFreqDiscNthApt);
		priceLocalVar.baseAmountForRecDiscount = amountWithFreqDiscFirstRecApt;

		// Apply day discount
		let recDayDisObj = this.DscntCal.getSpotDiscRec(BKFrmValue, priceLocalVar.excludeDayDiscountValue, amountWithFreqDiscFirstRecApt, amountWithFreqDiscNthApt);
		firstRecAptspotDiscount = recDayDisObj.firstRecAptspotDiscount;
		afterNthspotDiscount = recDayDisObj.afterNthspotDiscount;
		// If apply to all true
		if(BKFrmValue.day_discount.apply_to_all == 'true'){
			let spotDscntObj = this.cmnFunc.finalFirstAndNthspotDiscount(amountWithFreqDiscFirstRecApt, amountWithFreqDiscNthApt, firstRecAptspotDiscount, afterNthspotDiscount);
			amountWithFreqDiscFirstRecApt = spotDscntObj.amountWithFreqDiscFirstRecApt;
			amountWithFreqDiscNthApt = spotDscntObj.amountWithFreqDiscNthApt;
		}
		/**
		 * Pass the extras paramteres isServiceFeeTaxable and excludeServiceFee,
		 * To check if service fee is taxable.
		 * and calculate accordingly.
		 */
		let firstRecAptFinalAmount = this.getPORAPart(amountWithFreqDiscFirstRecApt, BKFrmValue, priceLocalVar, true);
		priceLocalVar.firstRecAptDiscountedAmount = firstRecAptFinalAmount;
		priceLocalVar.firstRecAptFinalAmount = this.getRecurringTotalValue(firstRecAptFinalAmount, BKFrmValue, priceLocalVar);
		priceLocalVar.firstRecAptFinalAmount = this.convertMinusValueToZero(priceLocalVar.firstRecAptFinalAmount);
		// BKFrm.controls['recurring_total'].setValue(+priceLocalVar.firstRecAptFinalAmount);

		/**
		 * This code is for, if coupon have setting to apply discount on number of bookings like 5,
		 * then after that other recurring booking's total shown by this variable.
		 */
		let afterNthAptFinalAmount = this.getPORAPart(amountWithFreqDiscNthApt, BKFrmValue, priceLocalVar, false);
		priceLocalVar.afterNthAptDiscountedAmount = afterNthAptFinalAmount;
		priceLocalVar.afterNthAptFinalAmount = this.getRecurringTotalValue(afterNthAptFinalAmount, BKFrmValue, priceLocalVar);
		priceLocalVar.afterNthAptFinalAmount = this.convertMinusValueToZero(priceLocalVar.afterNthAptFinalAmount);

		return {
			recurringTotal : +priceLocalVar.firstRecAptFinalAmount,
			displayFrequencyDiscount : priceLocalVar.displayFrequencyDiscount,
			firstRecAptFinalAmount : priceLocalVar.firstRecAptFinalAmount,
			firstRecAptDiscountedAmount : priceLocalVar.firstRecAptDiscountedAmount,
			afterNthAptFinalAmount : priceLocalVar.afterNthAptFinalAmount,
			afterNthAptDiscountedAmount : priceLocalVar.afterNthAptDiscountedAmount,
			baseAmountForRecDiscount : priceLocalVar.baseAmountForRecDiscount
		}
	}
	/**
	 * Function calculates the total amount for a recurring appointment.
	 * It takes in the amount without any frequency discount, the input object containing the booking form values, and the input object containing the settings.
	 * The function first checks if the service total is overridden.
	 * If not, it calculates the price and time for first booking extras, first booking excludes, and first booking priceable custom fields.
	 * It then subtracts the price of extras and custom fields and adds the price of excludes from the amount without frequency discount.
	 * If the service price is not overridden and the selected service is not hourly, it removes the value of extras, custom fields, and excludes that only apply to first bookings.
	 * If the price is based on hourly service and extras are included in the price, it removes the value of extras that only apply to first bookings.
	 * It calculates the separate custom field price and time for first bookings and deducts it from the amount without frequency discount.
	 * If the selected service is hourly and the price is based on a parameter and the time is not adjusted hourly, it calls the "getRecAmntIfHourlyAndParamBased" function to calculate and update the amount without frequency discount.
	 * Finally, the function returns the calculated amount as a number.
	 * @param amountWithoutFrequencyDiscount
	 * @param BKFrmValue
	 * @param inptObj
	 * @returns
	 */
	public getAmoutForRecApp(amountWithoutFrequencyDiscount: number, BKFrmValue: any, inptObj: any): number{
		// Get the price and time of extras whose apply only on first booking.
		let extrasFirstObj = this.extraExcludeCalServ.calcValueExtras(BKFrmValue, inptObj.settingsObj.extras, '', 'first');
		let extrasFirstOnlyPrice = extrasFirstObj.price;
		let extrasFirstOnlyTime = extrasFirstObj.time;
		// Get the price and time of exclude whose apply only on first booking.
		let excludesFirstObj = this.extraExcludeCalServ.calcValueExcludes(BKFrmValue, inptObj.settingsObj.excludes, 'first-only');
		let excludesFirstOnlyPrice = excludesFirstObj.price;
		let excludesFirstOnlyTime = excludesFirstObj.time;
		// Get the price and time of priceable custom fields whose value only apply to first booking.
		let fieldInpObj = {type: 'include_in_freq_disc', applyTo: 'first-only', seprateFieldValue: '', outType: ''};
		if(this.cmnFunc.isHourlyAndPriceBasedOnCustom(inptObj.selectedService)){
			fieldInpObj = {type: 'include_in_freq_disc', applyTo: 'first-only', seprateFieldValue: 'add_as_separate_charge', outType: ''};
		}
		let custmFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);
		let customFieldFirstOnlyPrice = custmFieldValObj.price;
		if(!BKFrmValue.service_price_overridden && !this.cmnFunc.isHourlyService(inptObj.selectedService)){
			// Remove extras and excludes value whose apply only on first.
			amountWithoutFrequencyDiscount = amountWithoutFrequencyDiscount - (+customFieldFirstOnlyPrice);
			// To override service total from booking form
			if(!BKFrmValue?.override_service_total){
				amountWithoutFrequencyDiscount = amountWithoutFrequencyDiscount - (+extrasFirstOnlyPrice) - (+excludesFirstOnlyPrice);
			}
		}else if(BKFrmValue.service_price_overridden){
			// Remove extras and excludes value whose apply only on first.
			amountWithoutFrequencyDiscount = amountWithoutFrequencyDiscount - (+customFieldFirstOnlyPrice);
		}else if(this.cmnFunc.isHourlyAndPriceBasedOnCustom(inptObj?.selectedService)){
			amountWithoutFrequencyDiscount = this.getRecAmtIfHourlyAndCustomBased(BKFrmValue, inptObj, amountWithoutFrequencyDiscount);
		}else if(this.cmnFunc.isHourlyAndPriceBasedOnParam(inptObj.selectedService) && !BKFrmValue?.adjust_time_hourly){
			let fieldObj = {type: '', applyTo: 'first-only', seprateFieldValue: '', outType: ''};
			let custmFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldObj);
			let customFieldFirstOnlyTime = custmFieldValObj.time;
			let seprateCustomFields = this.priceableFieldCal.calcPriceableCustomFieldsExcTime(BKFrmValue, 'all', 'add_as_separate_charge', 'exclude_time');
			let firstOnlyTimeObj: any = {
				extrasFirstOnlyTime : extrasFirstOnlyTime,
				excludesFirstOnlyTime : excludesFirstOnlyTime,
				customFieldFirstOnlyTime : seprateCustomFields?.time + customFieldFirstOnlyTime
			}

			amountWithoutFrequencyDiscount = this.getRecAmntIfHourlyAndParamBased(BKFrmValue, inptObj, amountWithoutFrequencyDiscount, firstOnlyTimeObj);
		}
		amountWithoutFrequencyDiscount = this.convertMinusValueToZero(+amountWithoutFrequencyDiscount);
		return +amountWithoutFrequencyDiscount;
	}
	/**
	 * Calculate the amount without frequency discount for rec app based on hourly and custom parameters.
	 * @param BKFrmValue - Object containing booking form values.
	 * @param inptObj - Input object with additional parameters.
	 * @param amountWithoutFrequencyDiscount - Initial amount without frequency discount.
	 * @returns Calculated amount without frequency discount.
	 */
	private getRecAmtIfHourlyAndCustomBased(BKFrmValue: any, inptObj: any, amountWithoutFrequencyDiscount: any) {
		// Retrieve the hourly value from the booking form
		let recBookingLength = BKFrmValue?.service_hourly_value;

		// Calculate separate custom fields time excluding time
		let seprateCustomFields = this.priceableFieldCal.calcPriceableCustomFieldsExcTime(BKFrmValue, 'all', 'add_as_separate_charge', 'add_time');
		let seprateCustomFieldsTime = +seprateCustomFields?.time;

		// Calculate a cutoff value based on shorter job length, if applicable
		let cutOffValueFromRecFieldsTime = 0;
		if (inptObj?.selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && this.cmnFunc.isShortJobLengthVal(inptObj?.selectedFrequency)) {
			let cutOffPercentage = (+inptObj?.selectedFrequency?.form_frequency_data?.shorter_job_length_value);
			cutOffValueFromRecFieldsTime = +this.cmnFunc.roundToTwo(((seprateCustomFieldsTime / 100) * cutOffPercentage));
		}
		let newValueTime = seprateCustomFieldsTime - cutOffValueFromRecFieldsTime;

		// Update the booking length with the adjusted custom fields time
		recBookingLength = recBookingLength + newValueTime;

		// Calculate service hours and round to two decimal places
		let serviceHours = recBookingLength / 60;
		let DecimalserviceHours = Number(serviceHours).toFixed(2);

		// Update the amount without frequency discount based on hourly pricing
		amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo((+inptObj.selectedService.pricing_per_hour) * (+DecimalserviceHours));
		// Calculate the price of extras and update the amount
		let extrasAllObj = this.extraExcludeCalServ.calcValueExtras(BKFrmValue, inptObj.settingsObj.extras, '', 'all');
		let extrasAllPrice = +extrasAllObj.price;

		let extrasFirstObj = this.extraExcludeCalServ.calcValueExtras(BKFrmValue, inptObj.settingsObj.extras, '', 'first');
		let extrasFirstPrice = +extrasFirstObj.price;

		let extraPrice = this.cmnFunc.roundToTwo(extrasAllPrice - extrasFirstPrice);

		if(this.cmnFunc.isIncludeExtraInPrice(inptObj.selectedService)){
			amountWithoutFrequencyDiscount = amountWithoutFrequencyDiscount + extraPrice;
		}

		if(this.cmnFunc.isServCatPrice(inptObj.selectedService)){
			amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo(amountWithoutFrequencyDiscount + (+inptObj.selectedService.service_category_price));
		}

		//Function to add some extras(whose add to charge as seprate option enable) in price seprately.
		let seprateExtrasPrice = this.extraExcludeCalServ.calcExtrasAsSeprateForPriceBaseOnParam(BKFrmValue, inptObj.settingsObj.extras, inptObj.selectedService, 'without-first-only');
		if(seprateExtrasPrice){
			amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo(this.cmnFunc.sumFunc(amountWithoutFrequencyDiscount, seprateExtrasPrice));
		}

		// To override service total from booking form
		amountWithoutFrequencyDiscount = this.validateValue(BKFrmValue.override_service_total, this.cmnFunc.roundToTwo(+BKFrmValue.overridden_service_total), amountWithoutFrequencyDiscount);

		// Define an object for calculating separate custom fields and update the amount
		let fieldInpObj = { type: 'include_in_freq_disc', applyTo: 'all', seprateFieldValue: 'add_as_separate_charge', outType: '' };
		let seprateCustomFieldss = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);
		amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo(amountWithoutFrequencyDiscount + seprateCustomFieldss?.price);

		// Return the calculated amount without frequency discount
		return +amountWithoutFrequencyDiscount;
	}

	/**
	 * Function calculates the amount without the frequency discount for an hourly and parameter-based booking.
	 * It takes several parameters including the booking value, input object, amount without frequency discount, and first only time object.
	 * The function starts by getting the booking length from the BKFrmValue parameter. It then calls a function to calculate any separate custom fields that should be included in the price and time separately.
	 * Next, it calculates the separate extra time to exclude from the booking length.
	 * It subtracts the extra time without the first only option, the time from the extras first only time object, the time from the custom field first only time object, and adds the time from the excludes first only time object.
	 * It then calls a function from the comnFunc module to calculate a new total time based on the selected frequency and the adjusted booking length.
	 *  The booking length is rounded to the nearest whole number.
	 * The service hours are calculated by dividing the booking length by 60 and rounding to two decimal places.
	 * The amount without frequency discount is then calculated by multiplying the pricing per hour of the selected service by the service hours.
	 * If the selected service has a service category price, it is added to the amount without frequency discount.
	 * Next, it calls a function to calculate any extras that should be added to the price separately.
	 * If there are any separate extras price, it is added to the amount without frequency discount.
	 * Finally, if there are any separate custom fields price, it is also added to the amount without frequency discount.
	 * The function returns the final amount without frequency discount.
	 * @param BKFrmValue
	 * @param inptObj
	 * @param amountWithoutFrequencyDiscount
	 * @param firstOnlyTimeObj
	 * @returns
	 */
	private getRecAmntIfHourlyAndParamBased(BKFrmValue: any, inptObj: any, amountWithoutFrequencyDiscount: any, firstOnlyTimeObj: any ){
		let bookingLength = BKFrmValue?.original_length;
		// Call function to add some custom fields(whose add to cherge as seprate option enable) in price/time seprately.
		let fieldInpObj = {type: 'include_in_freq_disc', applyTo: 'all', seprateFieldValue: 'add_as_separate_charge', outType: ''};
		let seprateCustomFields = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);

		// calculate seprate extra's exclude time(without first only)
		let excludeExtraTime = this.extraExcludeCalServ.calcExtrasSeprateEnable(BKFrmValue, inptObj.settingsObj.extras, 'without-first-only', 'time');
		bookingLength = bookingLength - excludeExtraTime;
		bookingLength = bookingLength - (+firstOnlyTimeObj.extrasFirstOnlyTime);
		bookingLength = bookingLength - (+firstOnlyTimeObj.customFieldFirstOnlyTime);
		bookingLength = bookingLength + (+firstOnlyTimeObj.excludesFirstOnlyTime);
		//call function from comnFunc
		let newTotalTime = this.cmnFunc.CBTTPartTwo(inptObj.selectedFrequency, bookingLength);
		bookingLength = +(Number(newTotalTime).toFixed(0));
		let serviceHours = bookingLength / 60;
		let DecimalserviceHours = Number(serviceHours).toFixed(2);
		amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo((+inptObj.selectedService.pricing_per_hour) * (+DecimalserviceHours));
		if(this.cmnFunc.isServCatPrice(inptObj.selectedService)){
			amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo(amountWithoutFrequencyDiscount + (+inptObj.selectedService.service_category_price));
		}

		// Call function to add some extras(whose add to charge as seprate option enable) in price seprately.
		let seprateExtrasPrice = this.extraExcludeCalServ.calcExtrasAsSeprateForPriceBaseOnParam(BKFrmValue, inptObj.settingsObj.extras, inptObj.selectedService, 'without-first-only');
		if(seprateExtrasPrice){
			amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo((+amountWithoutFrequencyDiscount) + (+seprateExtrasPrice));
		}

		// To override service total from booking form
		amountWithoutFrequencyDiscount = this.validateValue(BKFrmValue.override_service_total, this.cmnFunc.roundToTwo(+BKFrmValue.overridden_service_total), amountWithoutFrequencyDiscount);

		if(seprateCustomFields?.price){
			amountWithoutFrequencyDiscount = this.cmnFunc.roundToTwo((+amountWithoutFrequencyDiscount) + (+seprateCustomFields?.price));
		}
		return amountWithoutFrequencyDiscount;
	}
	/**
	 * Function takes in the "amountWithoutFrequencyDiscount", "BKFrmValue", "priceLocalVar", and "withCoupon" values and returns a number.
	 * Inside the function, it performs various calculations and assigns values to different variables.
	 * It checks if a coupon applies to bookings and if the coupon is for the first booking. If so, it calculates coupon discount for first appointment.
	 * Then, it subtracts the coupon discount from the "firstAptDiscountedAmount" and assigns the result back to the same variable.
	 * It also assigns "firstAptDiscountedAmount" to "firstAptFinalAmount".
	 * Next, it checks if the service fee is taxable and applicable based on certain conditions.
	 * If so, it adds the service fee to the "firstAptFinalAmount".
	 * Then, it checks if an "adjust_price" property exists in "BKFrmValue".
	 * If it does exist, it assigns the value of "BKFrmValue.adjusted_price" to "firstAptFinalAmount".
	 * Finally, it returns the value of "firstAptFinalAmount" as a number.
	 * @param amountWithoutFrequencyDiscount
	 * @param BKFrmValue
	 * @param priceLocalVar
	 * @param withCoupon
	 * @returns
	 */
	public getPORAPart(amountWithoutFrequencyDiscount: number, BKFrmValue: any, priceLocalVar: any, withCoupon : boolean = true): number{
		let firstAptDiscountedAmount = +amountWithoutFrequencyDiscount;
		let firstAptCouponDiscount = 0;
		if(BKFrmValue.coupon.apply_on_bookings != null && BKFrmValue.coupon.apply_on_bookings != 'first_booking' && withCoupon){
			// Calc coupon discount for first apt
			firstAptCouponDiscount = this.cmnFunc.calcCouponDisc(BKFrmValue, firstAptDiscountedAmount);
		}
		firstAptDiscountedAmount = (firstAptDiscountedAmount <= firstAptCouponDiscount) ? 0 : this.cmnFunc.roundToTwo((+firstAptDiscountedAmount) - (+firstAptCouponDiscount));
		let firstAptFinalAmount = firstAptDiscountedAmount;
		// Add service fee here if service fee is taxable.
		if(this.cmnFunc.isServiceFeeTaxable(priceLocalVar.isServiceFeeTaxable) && this.cmnFunc.isServiceFeeApplicable(BKFrmValue, priceLocalVar.excludeServiceFeeValue)){
			firstAptFinalAmount = this.cmnFunc.roundToTwo((+firstAptFinalAmount) + (+BKFrmValue.service_fee));
		}
		// if adjust price
		if(BKFrmValue?.adjust_price){
			firstAptFinalAmount = this.cmnFunc.roundToTwo(+(BKFrmValue.adjusted_price));
		}
		return (+firstAptFinalAmount);
	}
	/**
	 * Function getRecurringTotalValue calculates the total value for a recurring appointment.
	 * It takes three parameters: amountValue, BKFrmValue, and priceLocalVar.
	 * The function performs the following steps:
	 * 1. Calculates the price and time of the priceable after discounted total.
	 * 2. Calculates the tax for the recurring appointment.
	 * 3. Calculates the tip amount for the recurring appointment.
	 * 4. Calculates the parking amount for the recurring appointment.
	 * 5. Calculates the service fee for the recurring appointment.
	 * 6. Calculates the custom field price for the recurring appointment.
	 * Finally, it returns the calculated amountValue.
	 * @param amountValue
	 * @param BKFrmValue
	 * @param priceLocalVar
	 * @returns
	 */
	public getRecurringTotalValue(amountValue: any, BKFrmValue: any, priceLocalVar: any): number{
		//calculate price and time of priceable after discounted total
		let afterDiscfieldInpObj = {type: 'after_discounted_total', applyTo: 'all', seprateFieldValue: '', outType: ''};
		let afterDiscFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, afterDiscfieldInpObj);
		amountValue = amountValue + (this.cmnFunc.roundToTwo(+afterDiscFieldValObj?.price));

		let tipCalculationBase = +amountValue;
		let firstAptSaleTax = 0;

		// Calc tax for recurring apt
		let taxObj = this.cmnFunc.calcTax(priceLocalVar, firstAptSaleTax, amountValue);
		firstAptSaleTax = taxObj.displaySaleTax;
		amountValue = taxObj.displayFinalAmount;

		// Calc tip amount for recurring apt
		let firstAptTipAmount = 0;
		let tipObj  = this.cmnFunc.calcTip(BKFrmValue, tipCalculationBase, firstAptTipAmount, amountValue);
		firstAptTipAmount = tipObj.displayTipsAmount;
		amountValue = tipObj.displayFinalAmount;

		// Calc parking amount for recurring apt
		amountValue = this.cmnFunc.calcParking(BKFrmValue, amountValue);

		// Cal service fee for recurring apt
		let servFeeObj = this.cmnFunc.calcServiceFee(BKFrmValue, priceLocalVar, +BKFrmValue?.service_fee, amountValue);
		amountValue = servFeeObj.displayFinalAmount;

		// Cal priceable custom field price for recurring apt
		let fieldInpObj = {type: 'non_taxable', applyTo: 'all', seprateFieldValue: '', outType: ''};
		let custmFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);
		amountValue = amountValue + (+custmFieldValObj.price)

		return amountValue;
	}
	/**
	 * Function calculates the frequency discount for a booking based on the selected frequency and other input parameters.
	 * If the selected frequency is recurring and the frequency discount is applicable, it calls the getPriceOfRecApt function to calculate the price of the recurring appointment.
	 * It then sets the displayFrequencyDiscount, firstRecAptFinalAmount, and afterNthAptFinalAmount variables based on the calculated price.
	 * If the first booking is not excluded from the frequency discount, it calls the applyFreqDiscOnBooking function to apply the frequency discount to the first booking and returns an object with the discount details.
	 * If the first booking is excluded, it returns an object without the discount details.
	 * If the selected frequency is not recurring, it directly calls the applyFreqDiscOnBooking function to apply the frequency discount to the first booking and returns an object with the discount details.
	 * The returned objects differ based on the type of discount calculation performed.
	 * @return object
	 */
	public calcFreqDiscount(BKFrmValue: any, priceLocalVar: any, inptObj: any): any{
		if(this.cmnFunc.isRecurring(inptObj.selectedFrequency) && this.cmnFunc.checkFreqDiscApplyonRec(BKFrmValue, inptObj.selectedFrequency)){
			// price of recurring apt
			let priceOfRecAptObj = this.getPriceOfRecApt(priceLocalVar.displayServiceTotal, BKFrmValue, priceLocalVar, inptObj);
			// Set variables
			// BKFrm.controls['recurring_total'].setValue(priceOfRecAptObj?.recurringTotal);
			priceLocalVar.displayFrequencyDiscount = priceOfRecAptObj.displayFrequencyDiscount;
			priceLocalVar.firstRecAptFinalAmount = priceOfRecAptObj.firstRecAptFinalAmount;
			priceLocalVar.firstRecAptDiscountedAmount = priceOfRecAptObj.firstRecAptDiscountedAmount;
			priceLocalVar.afterNthAptFinalAmount = priceOfRecAptObj.afterNthAptFinalAmount;
			priceLocalVar.afterNthAptDiscountedAmount = priceOfRecAptObj.afterNthAptDiscountedAmount;
			priceLocalVar.baseAmountForRecDiscount = priceOfRecAptObj.baseAmountForRecDiscount;
			if(!this.cmnFunc.isExcludeFirstBooking(inptObj?.selectedFrequency)){
				// Call function to apply frequency discount to first booking.
				let freqDiscOnFirstBookingObj = this.DscntCal.applyFreqDiscOnBooking(BKFrmValue, inptObj.selectedFrequency, priceLocalVar);
				return {
					type : 1,
					recurringTotal : priceOfRecAptObj.recurringTotal,
					displayFrequencyDiscount : priceLocalVar.displayFrequencyDiscount,
					firstRecAptFinalAmount : priceLocalVar.firstRecAptFinalAmount,
					firstRecAptDiscountedAmount : priceLocalVar.firstRecAptDiscountedAmount,
					afterNthAptFinalAmount : priceLocalVar.afterNthAptFinalAmount,
					afterNthAptDiscountedAmount : priceLocalVar.afterNthAptDiscountedAmount,
					freqDiscOnFirstBookingObj : freqDiscOnFirstBookingObj,
					baseAmountForRecDiscount : priceLocalVar.baseAmountForRecDiscount

				}
			}
			return {
				type : 2,
				recurringTotal : priceOfRecAptObj.recurringTotal,
				displayFrequencyDiscount : priceLocalVar.displayFrequencyDiscount,
				firstRecAptFinalAmount : priceLocalVar.firstRecAptFinalAmount,
				firstRecAptDiscountedAmount : priceLocalVar.firstRecAptDiscountedAmount,
				afterNthAptFinalAmount : priceLocalVar.afterNthAptFinalAmount,
				afterNthAptDiscountedAmount : priceLocalVar.afterNthAptDiscountedAmount,
				baseAmountForRecDiscount : priceLocalVar.baseAmountForRecDiscount
			}
		}else{
			// Call function to apply frequency discount to first booking.
			let freqDiscOnFirstBookingObj = this.DscntCal.applyFreqDiscOnBooking(BKFrmValue, inptObj.selectedFrequency, priceLocalVar);
			return {
				type : 3,
				freqDiscOnFirstBookingObj : freqDiscOnFirstBookingObj,
				baseAmountForRecDiscount: priceLocalVar.baseAmountForRecDiscount
			}
		}
	}

	/*****************Calculate tax, tips, parking, bonus, giftcard and referral functions*************************************/

	/**
	 * Function named "calcTaxTipsParkingServiceFee" that takes two parameters: "BKFrmValue" and "priceLocalVar".
	 * Inside the function, the code performs several calculations to calculate the tax, tip, parking fee, service fee, and the total amount.
	 * It uses helper functions like "calcTax", "calcTip", "calcParking", "calcServiceFee", and "calcPriceableCustomFields" to perform these calculations.
	 * At the end, the function returns an object that contains the calculated values for displaySaleTax, displayFinalAmount, displayTipsAmount, displayServiceFee, displayTotal, and nonTaxableFieldsPrice.
	 * Overall, this function calculates and returns various values related to taxes, tips, parking, service fees, and total amount based on the provided input parameters.
	 * @param BKFrmValue : form control
	 * @param priceLocalVar : local variables
	 * @returns object
	 */
	public calcTaxTipsParkingServiceFee(BKFrmValue: any, priceLocalVar:any): any{
		let tipCalculationBase = +priceLocalVar?.displayFinalAmount;
		// Calc tax amount
		let taxObj = this.cmnFunc.calcTax(priceLocalVar, priceLocalVar.displaySaleTax, priceLocalVar.displayFinalAmount);
		priceLocalVar.displaySaleTax = taxObj.displaySaleTax;
		priceLocalVar.displayFinalAmount = taxObj.displayFinalAmount;
		// BKFrm.controls['booking_tax'].setValue(+priceLocalVar.displaySaleTax);
		// Calc tip amount
		let tipObj  = this.cmnFunc.calcTip(BKFrmValue, tipCalculationBase, priceLocalVar.displayTipsAmount, priceLocalVar.displayFinalAmount);
		priceLocalVar.displayTipsAmount = tipObj.displayTipsAmount;
		priceLocalVar.displayFinalAmount = tipObj.displayFinalAmount;
		// Calc parking amount
		priceLocalVar.displayFinalAmount = this.cmnFunc.calcParking(BKFrmValue, priceLocalVar.displayFinalAmount);
		// Add service fee here if service fee is not taxable.
		let servFeeObj = this.cmnFunc.calcServiceFee(BKFrmValue, priceLocalVar, priceLocalVar.displayServiceFee, priceLocalVar.displayFinalAmount);
		priceLocalVar.displayServiceFee = servFeeObj.displayServiceFee;
		priceLocalVar.displayFinalAmount = servFeeObj.displayFinalAmount;

		//calculate price and time of priceable non taxable custom fields
		let fieldInpObj = {type: 'non_taxable', applyTo: '', seprateFieldValue: '', outType: ''};
		let custmFieldValObj = this.priceableFieldCal.calcPriceableCustomFields(BKFrmValue, fieldInpObj);
		priceLocalVar.displayFinalAmount = priceLocalVar.displayFinalAmount + custmFieldValObj?.price;
		priceLocalVar.displayFinalAmount = this.convertMinusValueToZero(priceLocalVar.displayFinalAmount);

		priceLocalVar.displayTotal = this.cmnFunc.roundToTwo(+priceLocalVar.displayFinalAmount);
		return {
			displaySaleTax : priceLocalVar.displaySaleTax,
			displayFinalAmount : priceLocalVar.displayFinalAmount,
			displayTipsAmount : priceLocalVar.displayTipsAmount,
			displayServiceFee : priceLocalVar.displayServiceFee,
			displayTotal : priceLocalVar.displayTotal,
			nonTaxableFieldsPrice : custmFieldValObj?.price
		}
	}

	/**
	 * Patch the variables that are calculated from calcTotalPrice function.
	 * @param outputCalcTotalPrice
	 * @returns object
	 */
	public patchTotalPriceVar(outputCalcTotalPrice: any):any {
		return {
			original_length : outputCalcTotalPrice.BKFrmValue.original_length,
			reduced_length : outputCalcTotalPrice.BKFrmValue.reduced_length,
			length : outputCalcTotalPrice.BKFrmValue.length,
			provider_length : outputCalcTotalPrice.BKFrmValue.provider_length,
			exempt_extras_price : outputCalcTotalPrice.BKFrmValue.exempt_extras_price,
			include_in_freq_fields_price : outputCalcTotalPrice.BKFrmValue.include_in_freq_fields_price,
			exempt_from_freq_fields_price : outputCalcTotalPrice.BKFrmValue.exempt_from_freq_fields_price,
			after_disc_total_fields_price : outputCalcTotalPrice.BKFrmValue.after_disc_total_fields_price,
			non_taxable_fields_price : outputCalcTotalPrice.BKFrmValue.non_taxable_fields_price,
			exclude_fields_time : outputCalcTotalPrice.BKFrmValue.exclude_fields_time,
			add_fields_time : outputCalcTotalPrice.BKFrmValue.add_fields_time,
			inc_in_freq_exc_frm_prvdr : outputCalcTotalPrice.BKFrmValue.inc_in_freq_exc_frm_prvdr,
			add_sep_exc_frm_prvdr : outputCalcTotalPrice.BKFrmValue.add_sep_exc_frm_prvdr,
			exempt_frm_freq_exc_frm_prvdr : outputCalcTotalPrice.BKFrmValue.exempt_frm_freq_exc_frm_prvdr,
			exclude_extra_time : outputCalcTotalPrice.BKFrmValue.exclude_extra_time,
			service_hourly_value : outputCalcTotalPrice.BKFrmValue.service_hourly_value,
			service_price_overridden : outputCalcTotalPrice.BKFrmValue.service_price_overridden,
			service_price_overridden_value : outputCalcTotalPrice.BKFrmValue.service_price_overridden_value,
			service_total : outputCalcTotalPrice.BKFrmValue.service_total,
			service_price : outputCalcTotalPrice.BKFrmValue.service_price,
			before_priceable_fields_price : outputCalcTotalPrice.BKFrmValue.before_priceable_fields_price,
			recurring_total : outputCalcTotalPrice.BKFrmValue.recurring_total,
			frequency_discount_amount : outputCalcTotalPrice.BKFrmValue.frequency_discount_amount,
			frequency_discount_value : outputCalcTotalPrice.BKFrmValue.frequency_discount_value,
			frequency_discount_type : outputCalcTotalPrice.BKFrmValue.frequency_discount_type,
			total_before_coupon_discount : outputCalcTotalPrice.BKFrmValue.total_before_coupon_discount,
			provider_discounted_total : outputCalcTotalPrice.BKFrmValue.provider_discounted_total,
			discounted_total : outputCalcTotalPrice.BKFrmValue.discounted_total,
			after_discounted_total : outputCalcTotalPrice.BKFrmValue.after_discounted_total,
			booking_tax : outputCalcTotalPrice.BKFrmValue.booking_tax,
		}
	}
	/**
	 * Function checks if the conditions for returning true are met and false otherwise.
	 * The conditions include:
	 * 1. firstTimeStatus is true
	 * 2. isStepOneValid is false
	 * 3. priceLocalVar.displayTotal is not equal to prefilledData.total
	 * 4. priceLocalVar.bookingTaxRate is not undefined
	 * If all these conditions are met, the function returns true. Otherwise, it returns false.
	 * @returns
	 */
	public isTotalDiffOrFirstTimeStatus(firstTimeStatus: any, isStepOneValid: any, priceLocalVar: any, prefilledData: any){
		if(firstTimeStatus &&
			!isStepOneValid &&
			priceLocalVar.displayTotal != prefilledData.total &&
			priceLocalVar.bookingTaxRate != undefined){
			return true;
		}
		return false;
	}
	/**
	 * Function checks if the total value in the prefilledData object is different from the displayTotal value in the priceLocalVar object.
	 * If they are different, it returns true; otherwise, it returns false.
	 * @returns
	 */
	public isTotalDiff(prefilledData: any, priceLocalVar: any){
		if(prefilledData.total && prefilledData.total != priceLocalVar.displayTotal){
			return true;
		}
		return false;
	}
}
