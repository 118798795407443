import { Component, OnInit, Input, ViewEncapsulation, Self} from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl,Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
declare let window: any;
// External lib
import { ToastrService} from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, UtilServ, InitServ, ApiServ, LoaderServ, SocialServ, LeadsServ } from '../../Services';
// Constants
import { IS_DEV, DEV_HOST, GMAIL_INVITE_LINK, YAHOO_INVITE_LINK } from '../../Constants';
// Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';
@Component({
	selector: 'bk-referral-banner',
	templateUrl: './ReferralBanner.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ReferralBannerComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	referralForm!: FormGroup; // Referral form
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	userInfo: any = this.initServ.userInfo; // LoggedIn user info
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		invite_using: null,
		invite_by: null,
		share_by: null,
		media: null,
		cookie_content:null
	}
	pagePerm: boolean = true;
	sendLoaderId: string = 'send-ref-loader';
	creditsForReferrar: any;
	defReferralAmt: any;
	phoneError: number = 0;
	referralUrl: string = '';
	overrideDesc: string = '';
	fbAppId: string = '';
	googleClientId: string = '';
	yahooClientId: string = '';
	siteUrl: string = '';
	// eslint-disable-next-line max-params
	constructor(private frmBldr: FormBuilder, @Self() private destroy: NgOnDestroy, public rcServ: RenderComponentServ, public secServ: SectionServ, public utilServ: UtilServ, public initServ: InitServ, private apiServ: ApiServ, private toastr: ToastrService, private loader: LoaderServ, public customValidators: CustomValidators, public socialServ: SocialServ, private leadsServ: LeadsServ) {
		this.pagePerm = this.utilServ.appPermission('referrals'); // Page permission
		if (this.pagePerm && this.admnStngs && this.admnStngs.merchant_settings) {
			// Credits for referrar
			this.setCreditsForReferrar();
			this.setDefRefAmt();
			// Override description
			this.setOverrideDesc();
			// App keys
			this.appSocialKeys();
			// Site url
			this.siteUrl = window.location.protocol + "//" + window.location.host;
		}
	}
	ngOnInit(): void {
		if(this.pagePerm){
			// Build form
			this.referralForm = this.frmBldr.group({
				customer_id: [this.utilServ.userId()],
				type: ['email'],
				referred_email: [null,[Validators.required]],
				referred_phone: [null,[Validators.required]],
				referral_amount: [],
				sent_from_ip: [this.initServ.ipAddress]
			});
			// Referral amount
			if(this.defReferralAmt){
				this.f['referral_amount'].setValue(this.defReferralAmt);
			}
			// Build section data
			if(this.secId && this.rcServ.pageData){
				this.pageSett = this.rcServ.pageData.section_settings;
				this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
				this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
			}
			// Section email is hide, set the phone type
			if(this.section && this.section.invite_by && this.section.invite_by?.select && !this.section.invite_by?.select.email){
				this.f['type'].setValue('phone');
			}
			// User logged in get the user info
			this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
				if(value){
					this.userInfo = this.initServ.userInfo;
					// Referral url
					if(this.userInfo && this.userInfo.customer && this.userInfo.customer.refferal_code){
						this.referralUrl = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/referral-signup/' + this.userInfo.customer.refferal_code;
					}
				}
			});
		}
	}
	/**
	 * Sets the referral credits for the referrer based on admin settings.
	 */
	private setCreditsForReferrar(): void {
		if(this.admnStngs.merchant_settings.admin && this.admnStngs.merchant_settings.admin.referral_credits_for_referrar){
			this.creditsForReferrar = this.admnStngs.merchant_settings.admin.referral_credits_for_referrar;
		} else if(this.admnStngs.merchant_settings.admin && this.admnStngs.merchant_settings.admin.def_referral_amount){
			this.creditsForReferrar = this.admnStngs.merchant_settings.admin.def_referral_amount;
		}
	}
	/**
	 * Sets the default referral amount based on admin settings.
	 */
	private setDefRefAmt(): void {
		if(this.admnStngs.merchant_settings.admin.def_referral_amount){
			this.defReferralAmt = this.admnStngs.merchant_settings.admin.def_referral_amount;
		}
	}
	/**
	 * Sets the override description based on admin settings.
	 */
	private setOverrideDesc(): void {
		if(this.admnStngs.merchant_settings.bookings && this.admnStngs.merchant_settings.bookings.facebook_coupon_share_popup && this.admnStngs.merchant_settings.bookings.referral_share_desc){
			this.overrideDesc = this.admnStngs.merchant_settings.bookings.referral_share_desc
		}
	}
	/**
	 * Convert token into valid/readable string
	 * @param content actual content
	 * @returns Dynamic text string
	 */
	public tokenReplace(content: string): any {
		if(content){
			let str = this.utilServ.generateDynamicStr(content, '{{.ReferralAmountForReferrar}}', this.utilServ.amountWithCurrency(this.creditsForReferrar));
			return this.utilServ.generateDynamicStr(content, '{{.ReferralAmount}}', this.utilServ.amountWithCurrency(this.defReferralAmt), str);
		}
		return '';
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.referralForm.controls;
	}
	/**
	 * App social key, like Google, FB & Yahoo
	 */
	private appSocialKeys(): void {
		this.apiServ.callApi('GET', 'Addons').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.addonsRes(res));
	}
	/**
	 * Handles the response from addon API calls.
	 * @param res The response from the API call.
	 */
	private addonsRes(res: any): void {
		// Check if the API response is valid
		if (this.apiServ.checkAPIRes(res)) {
			// Extract addon keys from the response data
			let appKeys = res.data;
			// If addon keys exist and there are addons available
			if (appKeys && appKeys.length > 0) {
				// Iterate through each addon key
				for (let keys of appKeys) {
					// Check if the addon key is valid and active
					if (keys && keys.status && keys.status == 1) {
						// Set the addon key
						this.setKeys(keys);
					}
				}
			}
			// If Facebook App ID is available, initialize Facebook SDK
			if (this.fbAppId) {
				this.socialServ.fbInit(this.fbAppId);
			}
		}
	}
	/**
	 * Sets keys for various services based on the addon details.
	 * @param keys The keys object containing addon details.
	 */
	private setKeys(keys: any): void {
		// Check if addon details exist
		if (keys.details) {
			// Determine the addon type and set corresponding keys
			switch (keys.addon_type) {
				case 'facebook':
					// If the addon type is Facebook, set the Facebook App ID if available
					if (keys.details.app_id) {
						this.fbAppId = keys.details.app_id;
					}
					break;
				case 'google':
					// If the addon type is Google, set the Google Client ID if available
					if (keys.details.client_id) {
						this.googleClientId = keys.details.client_id;
					}
					break;
				case 'yahoo':
					// If the addon type is Yahoo, set the Yahoo Client ID if available
					if (keys.details.client_id) {
						this.yahooClientId = keys.details.client_id;
					}
					break;
			}
		}
	}
	/**
	 * Social invitation
	 * @param type gmail/yahoo
	 */
	public socialInvite(type:string): void {
		let url: string = '';
		if(type == 'gmail'){
			url = GMAIL_INVITE_LINK + this.googleClientId + "&redirect_uri=" + this.siteUrl + "/google-referral.php&scope=https://www.googleapis.com/auth/contacts.readonly&response_type=code";
		} else {
			url = YAHOO_INVITE_LINK + this.yahooClientId + "&redirect_uri=" + this.siteUrl + "/yahoo-referral.php&response_type=code&language=en-us";
		}
		window.open(url, '_blank', 'toolbar=0,location=0,menubar=0,width=600,height=300,top=200,left=400');
	}
	/**
	 * Type change email to phone visa versa
	 * Empty the input fields
	 */
	public emptyInput(): void {
		this.f['referred_phone'].setValue(null);
		this.f['referred_email'].setValue(null);
		this.f['referred_email'].markAsUntouched();
		this.f['referred_phone'].markAsUntouched();
	}
	/**
	 * Phone number validator
	 */
	public phoneValidate(): any {
		let value = this.customValidators.phoneValidate((this.f['referred_phone'].value).toString());
		if(value){
			this.phoneError = value.phoneError;
			if (this.phoneError == 0) {
				this.referralForm.controls['referred_phone'].setValue(value.numbers);
			}
		}
	}
	/**
	 * Handles the focus out event for the details section.
	 * This function performs validation on referred email and phone, and sends a referral if the validation passes.
	 */
	public detailsFoucusOut(): void {
		// Check if referred email or referred phone is provided
		if (this.f['referred_email'].value != null || this.f['referred_phone'].value != null) {
			this.addLeadsAndSubmit();
		}
	}

	/**
	 * Validates and formats the referred phone number(s) if provided.
	 * @param {any} formData - The form data containing the referred phone number(s).
	 * @returns {any} - Returns the updated form data with formatted phone number(s).
	 */
	private phoneValidation(formData: any): any {
		// Check if referred phone number is provided and there are no phone errors
		if (formData.referred_phone && this.phoneError == 0) {
			let phoneNumbers = [];
			// Check if referred_phone is an array of phone numbers
			if (typeof formData.referred_phone === 'object' && formData.referred_phone && formData.referred_phone.length > 0) {
				// Iterate through each phone number, unmask it, and add to phoneNumbers array
				for (let phone of formData.referred_phone) {
					phoneNumbers.push(this.utilServ.phoneUnmask(phone));
				}
			} else {
				// If referred_phone is a single phone number, unmask it and add to phoneNumbers array
				phoneNumbers = formData.referred_phone;
			}
			// Convert phoneNumbers array to a comma-separated string
			formData.referred_phone = phoneNumbers.toString();
		}
		// Return the updated form data
		return formData;
	}
	/**
	 * Submit the form data
	 * Email and phone number valid format
	 */
	public submit(): void {
		if (this.f['referred_email'].value != null || this.f['referred_phone'].value != null) {
			this.addLeadsAndSubmit(true);
		} else {
			for(let i in this.f) {
				this.f[i].markAsTouched();
			}
			this.toastr.error(this.initServ.appStr.toastr.emptyInput);
		}
	}
	/**
	 * Adds leads and optionally submits data.
	 * @param {boolean} isSubmit - Indicates whether to submit the data. Default value is `false`.
	 */
	private addLeadsAndSubmit(isSubmit: boolean = false): void {
		// Get form data from the referral form
		let formData: any = this.referralForm.value;
		let status: boolean = false;
		// Check if referred email exists
		if (formData.referred_email) {
			// Validate referred email
			status = this.customValidators.emailValid(this.f['referred_email']);
		}else{
			// If referred email does not exist, perform phone validation
			formData = this.phoneValidation(formData);
			if(this.f['referred_phone'].value){
				// Validate referred phone number
				status = !this.customValidators.phoneValidate((this.f['referred_phone'].value).toString()).phoneError;
			}
		}
		// Extract user information
		let { first_name, last_name, email_id, phone_number } = this.userInfo;
		// Assign user information to formData
		formData.customer = { first_name, last_name, email_id, middle_name: '', phone_number };
		// If validation passes
		if(status != false){
			// Add contact to lead
			this.leadsServ.addContactToLead(formData, { type: 'referral', slug: 'earn-credits' }, isSubmit ? 1 : null);
			// If isSubmit is true, submit the data
			if(isSubmit){
				this.send(formData);
			}
		}
	}
	/**
	 * Send the invitation using email & phone
	 * @param formData
	 */
	private send(formData: any): void {
		this.apiServ.setLoaderId(this.sendLoaderId);
		this.loader.show(this.sendLoaderId);
		let sendData: any = {
			customer_id: formData.customer_id,
			referred_email: formData.referred_email,
			referred_phone: formData.referred_phone,
			referral_amount: formData.referral_amount,
			sent_from_ip: formData.sent_from_ip,
		}
		this.apiServ.callApiWithPathVariables('POST', 'SendReferral', [formData.customer_id], sendData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.sendReferralRes(res));
	}
	/**
	 * Handles the response from sending a referral.
	 * @param res The response from the API call.
	 */
	private sendReferralRes(res: any): void {
		// Check if the API response is valid
		if (this.apiServ.checkAPIRes(res)) {
			// If the API response is successful, display success message and refresh referral list
			this.toastr.success(res.message);
			this.utilServ.refListRefresh.next(true);
		} else {
			// If the API response is not successful, display error message if available
			if (res && res.message) {
				this.toastr.error(res.message);
			}
		}
		// Hide loader and clear input fields
		this.loader.hide(this.sendLoaderId);
		this.emptyInput();
	}
}