<div class="py-30 px-md-30 border-bottom bk-card-border" [id]="section?.id" [formGroup]="zipcodeForm" *ngIf="settings && section">
	<!-- Title and description -->
	<bk-section-heading [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<!-- Zipcode radio buttons SA & ML -->
	<div class="tjs-check-btn mb-10" *ngIf="settings?.form_data && settings?.form_data?.preferred_service_location == 'both' && (merchantLocations && merchantLocations.length > 0)">
		<div class="form-check form-check-inline mb-10">
			<input id="customerLoc" class="form-check-input bk-form-check-input" type="radio" name="location_type" value="SA" formControlName="location_type" (change)="locTypeChange('SA')" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null" />
			<label for="customerLoc" class="form-check-label btn btn-light fw-normal fs-14 bk-form-check-btn">
				<span [id]="section?.my_loc_label_id" [innerHTML]="section?.my_loc_label | safeHtml"></span>
				<bk-tooltip customClass="bk-form-check-tooltip tjs-tooltip-icon--sm" *ngIf="zipcodeForm.controls['location_type'].value == 'ML'" [id]="section?.my_loc_tooltip_id" [content]="section?.my_loc_tooltip | translate"></bk-tooltip>
			</label>
		</div>
		<div class="form-check form-check-inline mb-10">
			<input id="merchantLoc" class="form-check-input bk-form-check-input" type="radio" name="location_type" value="ML" formControlName="location_type" (change)="locTypeChange('ML')" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null"/>
			<label for="merchantLoc" class="form-check-label btn btn-light fw-normal fs-14 bk-form-check-btn">
				<span [id]="section?.merchant_loc_label_id" [innerHTML]="section?.merchant_loc_label | safeHtml"></span>
				<bk-tooltip customClass="bk-form-check-tooltip tjs-tooltip-icon--sm" *ngIf="zipcodeForm.controls['location_type'].value == 'SA'" [id]="section?.merchant_loc_tooltip_id" [content]="section?.merchant_loc_tooltip | translate"></bk-tooltip>
			</label>
		</div>
	</div>
	<!-- SA location -->
	<div *ngIf="(settings?.form_data && settings?.form_data?.preferred_service_location == 'customer_location' || zipcodeForm.controls['location_type'].value == 'SA') && (bookingType == 'add' || (bookingType != 'add' && prefilledData && prefilledData?.location_type == 'SA'))">
		<label *ngIf="section?.my_loc_zipcode_label" [id]="section?.my_loc_zipcode_label_id" class="form-label bk-form-label" [innerHTML]="section?.my_loc_zipcode_label | safeHtml"></label>
		<div class="row">
			<div class="col-12 col-md-12 col-xl-6 mb-30">
				<input bkTrimSpace [ngClass]="{'is-invalid': zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched}" id="zipcode-value" class="form-control bk-input" placeholder="{{section?.my_loc_zipcode_placeholder}}" formControlName="zipcode" (focusin)="zipCodeStatus = false; noZipCodeStatus = false;" (focusout)="checkZipcodeAvail($event)" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null">
				<!-- Error -->
				<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_err_id" *ngIf="zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched">
					<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_err}}
				</div>
				<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_dont_service_err_id" *ngIf="noZipCodeStatus && !zipcodeForm.controls['zipcode'].hasError('required')">
					<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_dont_service_err}}
				</div>
			</div>
		</div>
	</div>
	<!-- ML location -->
	<ng-container *ngIf="mlDesignId == 'bk_ml_loc_v1' && (settings && settings?.form_data && settings?.form_data?.preferred_service_location == 'merchant_location' || zipcodeForm.controls['location_type'].value == 'ML') && (bookingType == 'add' || (bookingType != 'add' && prefilledData && prefilledData?.location_type == 'ML'))">
		<label *ngIf="section?.merchant_loc_zipcode_label" [id]="section?.merchant_loc_zipcode_label_id" class="form-label bk-form-label" [innerHTML]="section?.merchant_loc_zipcode_label | safeHtml"></label>
		<div class="row mb-25">
			<div class="col-12 col-md-12 col-xl-6">
				<input [ngClass]="{'is-invalid': zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched}" class="form-control bk-input" placeholder="{{section?.merchant_loc_zipcode_placeholder}}" [(ngModel)]="mlTypeKeyword" [ngModelOptions]="{standalone: true}" [attr.disabled]="(bookingType != 'add' || !isQuoteEditable) ? 'disabled' : null" [attr.readonly]="(bookingType != 'add') ? true : null">
			</div>
			<ng-container *ngIf="bookingType == 'add'">
				<!-- Find/Choose location btn -->
				<div class="col-12 col-md-12 col-xl-6 mt-20 mt-xl-0">
					<ng-container *ngTemplateOutlet="mlBtn"></ng-container>
				</div>
				<div class="col-12 col-md-12">
					<!-- Error -->
					<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_err_id" *ngIf="zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched">
						<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_err}}
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<div class="position-relative">
		<!-- Merchant address (selected and first 3) -->
		<div class="row mx-n10 my-15" *ngIf="(mlDesignId != 'bk_ml_loc_v3' || bookingType != 'add') && selectedMerchantLoc; else merchantAllLocations">
			<ng-container *ngTemplateOutlet="merchantLocTemp; context:{loc: selectedMerchantLoc, isSelected: true}"></ng-container>
		</div>
		<div class="mb-20" *ngIf="bookingType == 'add' && selectedMerchantLoc && merchantLocations && merchantLocations.length > 1 && mlDesignId == 'bk_ml_loc_v2'">
			<ng-container *ngTemplateOutlet="mlBtn"></ng-container>
		</div>
		<!-- First 3 address -->
		<ng-template #merchantAllLocations>
			<div *ngIf="bookingType == 'add' && merchantLocations && merchantLocations.length > 0 && zipcodeForm.controls['location_type'].value == 'ML'">
				<div class="row mx-n10 my-15">
					<ng-container *ngFor="let loc of merchantLocations; let i = index;trackBy: utilServ.trackByFnIndex">
						<ng-container *ngIf="(i < 3 || (mlDesignId == 'bk_ml_loc_v3'))">
							<ng-container *ngTemplateOutlet="merchantLocTemp; context:{loc: loc, isSelected: false}"></ng-container>
						</ng-container>
					</ng-container>
				</div>
				<!-- View more btn -->
				<div class="mb-20" *ngIf="merchantLocations && merchantLocations.length > 3 && mlDesignId != 'bk_ml_loc_v3'">
					<bk-navigate [secSett]="section?.view_more_loc_btn" [innerHTML]="section?.view_more_loc_btn?.content" customClass="btn btn-primary btn-sm border-transparent" essentialClass="bk-btn" (callback)="merchantLocationsPopup()" [btnDisabled]="(bookingType != 'add' || !isQuoteEditable) ? true : false"></bk-navigate>
				</div>
			</div>
		</ng-template>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
	<!-- Description -->
	<div *ngIf="zipcodeDescStatus && section?.zipcode_desc">
		<small class="bk-form-desc" [id]="section?.zipcode_desc_id" [innerHTML]="section?.zipcode_desc | safeHtml"></small>
	</div>
</div>
<!-- Merchant address template -->
<ng-template #merchantLocTemp let-loc="loc" let-isSelected="isSelected">
	<div class="col-12 col-md-6 col-lg-4 my-10 px-10">
		<div class=" border card h-100 bg-light shadow-sm position-relative tjs-pointer tjs-hover-shadow bg-transparent" [ngClass]="{'border-success-imp': isSelected || (zipcodeForm.controls['location_id'].value && zipcodeForm.controls['location_id'].value == loc?.form_location?.id)}">
			<div class="card-body p-15 pe-30 bk-card-body bk-form-desc" (click)="!isSelected && merchantLocSelect(loc)">
				{{loc?.location_name | translate}}
				<ng-container *ngIf="!loc?.hide_ml_address">
					<span class="fs-14 bk-form-desc">, {{loc?.merchant_location_address | translate}}</span>
					<div class="fs-14 bk-form-desc" *ngIf="loc?.apt">{{'Apt' | translate}} : {{loc?.apt}}</div>
				</ng-container>
			</div>
			<div class="position-absolute d-flex flex-column end-5 top-5">
				<button *ngIf="!loc?.hide_ml_image && loc?.picture" type="button" class="btn btn-link text-decoration-none bk-icon p-0" (click)="locPicturePopup(loc?.picture)"><i class="tjsicon-camera bk-form-desc"></i></button>
				<a *ngIf="!loc?.hide_ml_address" class="text-decoration-none bk-icon" target="_blank" href="{{mapAddrLink}}{{loc?.merchant_location_address}}">
					<i class="tjsicon-placeholder-1 bk-form-desc"></i>
				</a>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #mlBtn>
	<ng-container *ngIf="findLocBtnText == 'Find Locations'; else chooseAnotherLoc">
		<bk-navigate [secSett]="section?.find_loc_btn" [innerHTML]="section?.find_loc_btn?.content" essentialClass="h-100 bk-btn" customClass="btn btn-success border-transparent" (callback)="findMerchantLoc()" [btnDisabled]="(bookingType != 'add' || !isQuoteEditable) ? true : false"></bk-navigate>
	</ng-container>
	<ng-template #chooseAnotherLoc>
		<bk-navigate [secSett]="section?.choose_another_loc_btn" [innerHTML]="section?.choose_another_loc_btn?.content" essentialClass="h-100 bk-btn" customClass="btn btn-success border-transparent" (callback)="findMerchantLoc()" [btnDisabled]="(bookingType != 'add' || !isQuoteEditable) ? true : false"></bk-navigate>
	</ng-template>
</ng-template>