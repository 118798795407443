import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, SimpleChanges, Self, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
// Services
import { NgOnDestroy, InitServ, UtilServ, ApiServ, PopupServ, SectionServ } from '../../../../Services';
import { AuthServ } from '../../../../Core/_services'; // Auth Service
// Constant
import { EMAIL_REG_EXP } from '../../../../Constants';
//Custom validator
import { CustomValidators } from '../../../../Global/GlobalDefault';
// Address component
import { AddressComponent } from '../../Address/Address.component';

@Component({
	selector: 'bk-customer-details',
	templateUrl: './CustomerDetails.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerDetailsComponent implements OnInit {

	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	@Input() currentUser: any;
	@Input() isQuote : any;
	@Input() selectedFrequency: any;
	@Input() selectedServiceType: any;
	@Input() settings: any;
	@Input() bookingType: string = '';
	@Input() customerForm!: FormGroup
	@Input() prefilledData: any;
	@Input() locationLayout: any;
	@Input() section: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() locationType: any;
	@Input() selectedLocation: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Input() zipcode: any;
	@Output() loginExistingUser : EventEmitter<any> = new EventEmitter();
	@Output() zipCodeChange: EventEmitter<any> = new EventEmitter();
	@Output() custmrChng : EventEmitter<any> = new EventEmitter();
	@Output() custDetailsChng : EventEmitter<any> = new EventEmitter();
	// Address component child
	@ViewChild('addressComp', {static: false}) addressComp: AddressComponent | undefined;

	loaderId: string = 'bkng-cust-details';
	disableSecEmailAndPhone: string = 'no';
	primaryNoReq: string = 'no';
	splitDetails: boolean = false;
	disabledFirstLastName: boolean = true;
	isDisabled: any;
	primaryEmailExist: boolean = false;
	userInfo: any;
	contacts: any;
	secondaryUserInfo: any;
	secCustDetails: any;
	secAddress: any;
	cookieStatus: boolean = false;
	buildBlockBasedOnPropNames: any = ['currentUser', 'selectedLocation', 'zipcode', 'locationType'];
	emailExistVar: boolean = false;
	sendSmsNotification: boolean = true;
	phoneNotifStatus: boolean = false;

	constructor(public initServ: InitServ, private frmBldr: FormBuilder, private customValidators: CustomValidators, public utilServ: UtilServ, private cDRef: ChangeDetectorRef,private authServ: AuthServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private popupServ: PopupServ, private secServ: SectionServ) {
		// Disabled secondary email and phone number
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.bookings && this.admnStngs.merchant_settings.bookings.disable_secondary_email_and_phone){
			this.disableSecEmailAndPhone = this.initServ.appAdmnStngs.merchant_settings.bookings.disable_secondary_email_and_phone;
		}
		// Primary number required
		if(this.initServ.appAdmnStngs && this.initServ.appAdmnStngs.merchant_settings && this.initServ.appAdmnStngs.merchant_settings.bookings && this.initServ.appAdmnStngs.merchant_settings.bookings.primary_number_required){
			this.primaryNoReq = this.initServ.appAdmnStngs.merchant_settings.bookings.primary_number_required;
		}
	}

	// convenience getter for easy access to form fields
	get customerGroup(): any{
		return <FormGroup>this.customerForm.controls['customer'];
	}
	get emails(): FormArray {
		return <FormArray>this.customerGroup.controls['emails'];
	}
	get phoneNumbers(): FormArray {
		return <FormArray>this.customerGroup.controls['phone_numbers'];
	}

	ngOnInit(): void {
		this.buildUserBlock();

	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange && this.buildBlockBasedOnPropNames.includes(propName)) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildUserBlock();
						break;
					}
				}
			}
		}
	}
	/**
	 * Build the user block
	 */
	// eslint-disable-next-line complexity
	private buildUserBlock(): void {
		if(this.section){
			if(this.locationType == 'SA'){
				this.secCustDetails = this.section.customer_details;
				this.secAddress = this.section.address_details;
			} else {
				this.secCustDetails = this.section.personal_details;
				this.customerGroup.controls['customer_zipcode'].clearValidators();
				this.customerGroup.controls['customer_zipcode'].updateValueAndValidity();
			}
			if(this.isMultiStepForm && this.secCustDetails && this.secCustDetails?.cookie_content && this.secServ.checkEleStatus(this.pageSett,this.secCustDetails?.cookie_content_id)){
				this.cookieStatus = true;
			}
			if(this.secCustDetails && this.secCustDetails.phone_notification_id){
				this.phoneNotifStatus = this.secServ.checkEleStatus(this.pageSett, this.secCustDetails.phone_notification_id);
			}
		}
		if(this.customerGroup.controls['send_sms_notification'] && this.customerGroup.controls['send_sms_notification'].value){
			this.sendSmsNotification = (this.customerGroup.controls['send_sms_notification'].value == 'yes') ? true : false;
		}
		if(this.section.customer_details.design_id == 'bk_customer_v3' || this.section.customer_details.design_id == 'bk_customer_v4'){
			this.splitDetails = true;
		}
		// App/update phone number validation
		this.addUpdatePhoneNoValidation();
		// Update customer group
		this.updateCustomerGroup();
		// Disabled fields status
		this.checkSmsNotification();
		this.isDisabled = this.disabledParamScope();
		this.cDRef.detectChanges();
	}
	/**
	 * Check the sms notification for existing customer and if disabled phone notification from builder
	 */
	private checkSmsNotification():void {
		let userInfo: any = this.initServ.userInfo;
		if(userInfo){
			if(!userInfo.hasOwnProperty('send_sms_notification')){
				if(!this.phoneNotifStatus){
					this.customerGroup.controls['send_sms_notification'].setValue('');
				} else if(userInfo.sms_notification == false){
					this.customerGroup.controls['send_sms_notification'].setValue("no");
					this.sendSmsNotification = (this.customerGroup.value['send_sms_notification'] == 'yes') ? true : false;
				}
			}
		}
	}
	/**
	 * Add/update phone number validation
	 */
	private addUpdatePhoneNoValidation(): void {
		if(this.primaryNoReq && this.primaryNoReq == 'yes'){
			this.customerGroup.controls['phone_number'].markAsUntouched();
			this.customerGroup.controls['phone_number'].setValidators([Validators.required]);
		} else{
			this.customerGroup.controls['phone_number'].markAsUntouched();
			this.customerGroup.controls['phone_number'].clearValidators();
		}
		this.customerGroup.controls['phone_number'].updateValueAndValidity();
	}
	/**
	 * Updated the customer group based on add/edit booking form
	 */
	// eslint-disable-next-line complexity
	private updateCustomerGroup(): void {
		if(this.bookingType != 'add'){
			this.otherContacts();
		} else {
			this.customerGroup.markAsUntouched();
			// this.customerGroup.controls['is_customer_new'].setValue(false);
			// Disabled first and last name
			if(this.customerForm.controls['uid'].value !== null && this.customerGroup.controls['first_name'].value){
				this.disabledFirstLastName = true;
			} else{
				this.disabledFirstLastName = false;
			}
			// Get the user other contacts
			if(this.customerForm.controls['uid'].value){
				this.otherContacts();
			} else {
				const customerSecValues: any = this.customerGroup.value;
				// Emails
				let secEmail: any = null;
				if(customerSecValues.emails && (customerSecValues.emails).length > 0){
					secEmail = customerSecValues.emails[0];
				}
				this.createEmailsControl(secEmail);
				// Phone numbers
				let secPhone: any = null;
				if(customerSecValues.phone_numbers && (customerSecValues.phone_numbers).length > 0){
					secPhone = customerSecValues.phone_numbers[0];
				}
				this.createPhoneNumbersControl(secPhone);
			}
			// Existing customer
			if(this.customerGroup.controls['customer_type'].value == 'existing customer'){
				this.disabledFirstLastName = true;
				this.userInfo = this.initServ.userInfo;
				if(this.userInfo){
					this.customerGroup.patchValue({
						first_name : this.userInfo.first_name,
						last_name : this.userInfo.last_name,
						phone_number : this.userInfo.phone_number,
						is_customer_new: this.userInfo.is_new ? true : false
					});
					if(this.userInfo.send_sms_notification){
						this.customerGroup.controls['send_sms_notification'].setValue(this.userInfo.send_sms_notification);
						this.sendSmsNotification = (this.userInfo.send_sms_notification == 'yes') ? true : false;
					}
					if(this.userInfo.is_marketplace){
						this.customerForm.controls['is_marketplace'].setValue(true);
					} else{
						this.customerForm.controls['is_marketplace'].setValue(false);
					}
				}
			} else {
				// if(this.customerGroup.controls['email_id'].value && !this.customerForm.controls['uid'].value && !this.isMultiStepForm){
				// Comment above line because we also need to run this code in case of multistep
				if(this.customerGroup.controls['email_id'].value && !this.customerForm.controls['uid'].value){
					this.emailExistControl(this.customerGroup.controls['email_id']);
					this.customerGroup.controls['email_id'].markAsTouched();
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Get the user other contacts
	 */
	private otherContacts(): void {
		let userId: any = this.customerForm.controls['uid'].value;
		if(this.currentUser && this.currentUser.id && userId){
			this.apiServ.callApiWithPathVariables('Get', 'OtherContacts',[userId]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		}
	}
	/**
	 * Create emails control
	 * @param secEmail: sec email object user for prefilled data
	 */
	private createEmailsControl(secEmail: any = null): void {
		let emails: any = <FormArray>this.customerGroup.controls['emails'];
		emails.clear();
		if(this.customerGroup.controls['emails'].value && (this.customerGroup.controls['emails'].value).length == 0){
			if(this.customerGroup.controls['emails'].value && (this.customerGroup.controls['emails'].value).length == 0){
				emails.push(this.frmBldr.group({
					value: [((secEmail && secEmail.value) ? (secEmail.value) : ''), [Validators.pattern(EMAIL_REG_EXP)]],
					note: [((secEmail && secEmail.note) ? (secEmail.note) : '')],
					send_notifications: [(secEmail ? (secEmail.send_notifications) : true)]
				}));
			}
		}
	}
	/**
	 * Create phone numbers control
	 * @param secPhone: sec phone number object user for prefilled data
	 */
	private createPhoneNumbersControl(secPhone: any = null): void {
		let phoneNumbers: any = <FormArray>this.customerGroup.controls['phone_numbers'];
		phoneNumbers.clear();
		if(this.customerGroup.controls['phone_numbers'].value && (this.customerGroup.controls['phone_numbers'].value).length == 0){
			phoneNumbers.push(this.frmBldr.group({
				value: [((secPhone && secPhone.value) ? (secPhone.value) : '')],
				note: [((secPhone && secPhone.note) ? (secPhone.note) : '')],
				send_notifications: [(secPhone ? (secPhone.send_notifications) : true)]
			}));
		}
	}
	/**
	 * Disabled the parameter scope
	 * @returns any
	 */
	public disabledParamScope(): string | null {
		if(this.bookingType != 'add'){
			if(this.prefilledData && (this.prefilledData.status == 1 || this.prefilledData.status == 2 || this.prefilledData.status == 4)){
				return "disabled";
			} else{
				return null;
			}
		} else {
			if(this.customerGroup.controls['customer_type'].value == 'existing customer'){
				return 'disabled';
			}
			return null;
		}
	}
	/**
	 * Check the user phone exist
	 */
	public async phoneExistControl(control: any, type: string='single') {
		if(control.value && (!control.errors?.required && !control.errors?.pattern)){
			let phone: any;
			if(type == 'all' && this.secondaryUserInfo && this.secondaryUserInfo.phone_numbers && (this.secondaryUserInfo.phone_numbers).length > 0 && control.value == this.secondaryUserInfo.phone_numbers[0].value){
				control.setErrors(null);
			} else {
				phone = await this.customValidators.phoneExist(this.utilServ.phoneUnmask(control.value), this.utilServ.userId());
				control.setErrors(phone);
			}
			if(!this.currentUser){
				this.custDetailsChng.emit();
			}
			this.cDRef.detectChanges();
		}
	}
	/**
	 * On keyup add the password control if not exist
	 */
	public addPwdControl(): void {
		if(!this.customerGroup.controls['password']){
			this.customerGroup.addControl('password', new FormControl('', Validators.required));
			this.customerGroup.controls['password'].markAsUntouched();
		}
	}
	/**
	 * Check the user email exist
	 */
	// eslint-disable-next-line complexity
	public async emailExistControl(control: any, type: string='single', isChange: boolean = false, emptyPwd:boolean=true) {
		this.emailExistVar = false;
		if(control.value && (!control.errors?.required && !control.errors?.pattern)){
			let email: any;
			if(type == 'all' && this.secondaryUserInfo && this.secondaryUserInfo.emails && (this.secondaryUserInfo.emails).length > 0 && control.value == this.secondaryUserInfo.emails[0].value){
				control.setErrors(null);
			} else {
				email = await this.customValidators.emailExist(control.value, this.utilServ.userId());
				control.setErrors(email);
			}
			if(type == 'single'){
				if(email){
					this.emailExistVar = true;
					if(this.customerGroup.controls['password']){
						// eslint-disable-next-line max-depth
						if(emptyPwd){
							this.customerGroup.controls['password'].setValue('');
							this.customerGroup.controls['password'].markAsUntouched();
						}
					} else {
						this.addPwdControl();
					}
				}
				if(isChange && !email){
					this.custmrChng.emit();
				}
			}
			setTimeout(() => {
				this.cDRef.detectChanges();
			}, 0);
		}
	}
	/**
	 * Login to existing user
	 */
	public loginUser(): void {
		if(this.customerGroup?.controls['password'].valid){
			this.loginExistingUser.emit();
		} else {
			this.customerGroup?.controls['password'].markAsTouched();
		}
	}
	/**
	 * Forgot password
	 */
	public forgotPwd(): void {
		let obj = { email_id: (this.customerGroup.controls['email_id'].value).toLowerCase()};
		this.authServ.reqResetPassword(obj, this.loaderId, false);
	}
	/**
	 * On result callback method
	 * @param res API res
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data) {
			this.contacts = res.data.profiles;
			this.secondaryUserInfo = res.data;
			if(this.secondaryUserInfo){
				// Emails
				let secEmail: any = null;
				if(this.secondaryUserInfo.emails && (this.secondaryUserInfo.emails).length > 0){
					secEmail = this.secondaryUserInfo.emails[0];
				}
				this.createEmailsControl(secEmail);
				// Phone numbers
				let secPhone: any = null;
				if(this.secondaryUserInfo.phone_numbers && (this.secondaryUserInfo.phone_numbers).length > 0){
					secPhone = this.secondaryUserInfo.phone_numbers[0];
				}
				this.createPhoneNumbersControl(secPhone);
			}
		}
		this.cDRef.detectChanges();

	}
	/**
	 * See other contact popup
	 */
	public otherContactsPopup(): void {
		let userId = this.customerForm.controls['uid'].value;
		this.popupServ.otherContactsPopup(userId, true);
	}
	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		this.cDRef.detectChanges();
	}
	/**
	 * Set the yes/no send sms notification field value
	 * @param event: HTML event
	 */
	public sendSmsNotifChange(event: Event): void {
		const isChecked = (<HTMLInputElement>event.target).checked;
		if(isChecked){
			this.customerGroup.controls['send_sms_notification'].setValue("yes");
		} else {
			this.customerGroup.controls['send_sms_notification'].setValue("no");
		}
	}
}