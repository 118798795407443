import { Component, OnInit, ViewEncapsulation, Self, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
// External library
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { TranslateService } from '@ngx-translate/core';
// Services
import { NgOnDestroy, InitServ, ApiServ, LoaderServ, UtilServ, PopupServ, RenderComponentServ, SectionServ } from '../../Services';
// Constants
import { TEXT_REG_EXP, EMAIL_REG_EXP, IS_DEV, MIME_TYPE } from '../../Constants';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';

@Component({
	selector: 'bk-edit-profile',
	templateUrl: './EditProfile.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class EditProfileComponent implements OnInit {
	// Variables
	editForm: FormGroup;
	userInfo: any = this.initServ.userInfo; // LoggedIn user info
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	loaderId: string = 'edit-profile-loader';
	disableSecEmailPhone: string = 'no';
	// Upload image variables
	uploadImgName: string = '';
	allowedMimeType: string[] = MIME_TYPE;
	public imgPath!: SafeUrl;
	@ViewChild('uploadEl', { static: true }) uploadElRef!: ElementRef;
	// File uploader
	public uploader:FileUploader = this.utilServ.fileUploadOptions(['image'], 5 * 1024 * 1024);
	sendSmsNotification: boolean = true;
	// customer section build
	secId: any;
	section: any = { form: null, title: null };
	formKeys: string[] = ['company_name', 'choose_file', 'gender'];
	formBtns: string[] = ['view_btn'];

	constructor(private cDRef: ChangeDetectorRef, private router: Router, private toastr: ToastrService, public initServ: InitServ, private frmBldr: FormBuilder, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, private customValidators: CustomValidators, private sanitizer: DomSanitizer, public translate: TranslateService, private popupServ: PopupServ, public rcServ: RenderComponentServ, public secServ: SectionServ) {
		// Secondary Email and phone hide
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.bookings && this.admnStngs.merchant_settings.bookings.disable_secondary_email_and_phone) {
			this.disableSecEmailPhone = this.admnStngs.merchant_settings.bookings.disable_secondary_email_and_phone;
		}
		// Form build
		this.editForm = this.frmBldr.group({
			photo_url: [],
			first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			email_id: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
			gender: ['unspecified'],
			phone_number: [null, [Validators.required]],
			send_sms_notification: ["yes"],
			emails: this.frmBldr.array([]),
			phone_numbers: this.frmBldr.array([]),
			company_name: [null, [Validators.pattern(TEXT_REG_EXP)]]
		});
	}

	ngOnInit(): void {
		// User profile changes
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if (value) {
				this.userInfo = this.initServ.userInfo; // LoggedIn user info
				this.filledForm(); // Filled edit form values
			}
		});
		// Uploader events
		this.uploader.onAfterAddingFile = (f) => {
			this.imgPath = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(f._file)));
			this.editForm.controls['photo_url'].setValue(this.imgPath);
			this.uploadImgName = f._file.name;
			f.withCredentials = false;
			if(this.uploadElRef){
				this.uploadElRef.nativeElement.value = '';
			}
			if (this.uploader.queue.length > 1) {
				this.uploader.removeFromQueue(this.uploader.queue[0]);
			}
		};
		this.uploader.onWhenAddingFileFailed = (item, filter) => this.onWhenAddingFileFailed(item, filter);
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			let pageSett: any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content, this.formKeys, this.formBtns);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Convenience getter for easy access to form fields
	 */
	get f(): { [key: string]: AbstractControl } {
		return this.editForm.controls;
	}
	/**
	 * Get the form array controls
	 * @param controlName
	 * @returns
	 */
	public getControls(controlName: string): any {
		return (this.editForm.get(controlName) as FormArray).controls;
	}
	/**
	 * onWhenAddingFileFailed function is used to when adding file failed, display the warning message on toaster.
	 */
	private onWhenAddingFileFailed(item: FileLikeObject, filter: any) {
		if (IS_DEV) { console.log(item, 'item') }
		let uploadErrorMsg: string = '';
		switch (filter.name) {
			case 'fileSize':
				uploadErrorMsg = this.translate.instant('Maximum upload size 5Mb allowed.');
				break;
			case 'mimeType':
				const allowedTypes = this.allowedMimeType.join();
				const txt = this.translate.instant('Allowed types are');
				uploadErrorMsg = `"${txt}" "${allowedTypes}".`;
				break;
			default:
				const defTxt = this.translate.instant('Unknown error');
				uploadErrorMsg = `"${defTxt}" (filter is ${filter.name}).`;
		}
		this.toastr.warning(uploadErrorMsg)
	}
	/**
	 * Filled the profile form
	 */
	private filledForm(): void {
		if (this.userInfo) {
			this.editForm.addControl('id', new FormControl());
			this.editForm.patchValue({
				id: this.userInfo._id,
				photo_url: this.userInfo.photo_url,
				first_name: this.userInfo.first_name,
				last_name: this.userInfo.last_name,
				email_id: this.userInfo.email_id,
				gender: this.userInfo.gender ? this.userInfo.gender : 'unspecified',
				phone_number: this.userInfo.phone_number,
				company_name: this.userInfo.company_name
			});
			if (this.userInfo.send_sms_notification) {
				this.editForm.controls['send_sms_notification'].setValue(this.userInfo.send_sms_notification);
			} else {
				if (this.userInfo.sms_notification == false) {
					this.editForm.controls['send_sms_notification'].setValue("no");
				}
			}
			this.sendSmsNotification = (this.editForm.value['send_sms_notification'] == 'yes') ? true : false;
			// Multi emails
			if (this.userInfo.emails && (this.userInfo.emails).length > 0) {
				for (let email of this.userInfo.emails) {
					if (email.value) {
						this.addMultiControl('emails', email);
					}
				}
			}
			// Multi phone
			if (this.userInfo.phone_numbers && (this.userInfo.phone_numbers).length > 0) {
				for (let phone of this.userInfo.phone_numbers) {
					if (phone.value) {
						this.addMultiControl('phone_numbers', phone);
					}
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Delete image
	 */
	public deleteImage(): void {
		this.editForm.controls['photo_url'].setValue(null);
		this.uploadImgName = '';
		if (this.uploader.queue.length > 0) {
			this.uploader.removeFromQueue(this.uploader.queue[0])
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Add multi control "emails/phone_numbers"
	 * @param controlName emails/phone_numbers
	 * @param value  used for prefilled multi controls
	 */
	public addMultiControl(controlName: string, value: any = null): void {
		let formArray = <FormArray>this.f[controlName];
		let validators: any = controlName == 'emails' ? [Validators.required, Validators.pattern(EMAIL_REG_EXP)] : [Validators.required];
		let formGroup: any = this.frmBldr.group({
			value: [(value ? value.value : null), validators],
			note: [(value ? value.note : null)],
			send_notifications: [(value ? value.send_notifications : null)]
		});
		formArray.push(formGroup);
	}
	/**
	 * Delete multi controls "emails/phone_numbers"
	 * @param controlName "emails/phone_numbers"
	 * @param index control index
	 */
	public deleteMultiControl(controlName: string, index: any) {
		let formArray: any = <FormArray>this.f[controlName];
		formArray.removeAt(index);
	}
	/**
	 * Check the user email exist
	 */
	public async emailExistControl(control: any) {
		if (control.value && (!control.errors?.required && !control.errors?.pattern)) {
			let email = await this.customValidators.emailExist(control.value, this.utilServ.userId());
			control.setErrors(email);
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Check the user phone number exist
	 */
	public async phoneExistControl(control: any) {
		if (control.value && (!control.errors?.required)) {
			let phone = await this.customValidators.phoneExist(this.utilServ.phoneUnmask(control.value), this.utilServ.userId());
			control.setErrors(phone);
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Check the multi emails/phone_numbers values
	 * @param controlName emails/phone_numbers
	 * @returns
	 */
	private checkMultiControls(controlName: string): boolean {
		let formArray = [];
		if (this.editForm.controls[controlName].value && (this.editForm.controls[controlName].value).length > 0) {
			if (controlName == 'emails') {
				formArray.push(this.editForm.controls['email_id'].value);
			} else {
				formArray.push(this.utilServ.phoneUnmask(this.editForm.controls['phone_number'].value));
			}
			let controls: any = this.getControls(controlName);
			if (controls && controls.length > 0) {
				for (let control of controls) {
					control.markAsTouched();
					if (control.value && control.value.value) {
						let value = control.value.value;
						if (controlName == 'phone_numbers') {
							value = this.utilServ.phoneUnmask(value);
						}
						formArray.push(value);
					}
				}
			}
		}
		let exist: boolean = false;
		if (formArray.length > 0) {
			for (var i = 0; i <= formArray.length; i++) {
				for (var j = i; j <= formArray.length; j++) {
					if (i != j && formArray[i] == formArray[j]) {
						exist = true;
					}
				}
			}
		}
		return exist;
	}
	/**
	 * Add error on emails/phone_numbers in case of value is not valid
	 * @param controlName emails/phone_numbers
	 */
	private multiControlsMarkAsTouched(controlName: string): void {
		if (this.editForm.controls[controlName].value && (this.editForm.controls[controlName].value).length > 0) {
			let formArray = <FormArray>this.f[controlName];
			(formArray.controls).forEach((control: any) => {
				control.controls['value'].markAsTouched();
			});
		}
	}
	/**
	 * Submit form
	 */
	public async submitForm() {
		this.loader.show(this.loaderId);
		let multiEmailExist = await this.checkMultiControls('emails');
		let multiPhoneExist = await this.checkMultiControls('phone_numbers');
		setTimeout(() => {
			if (this.editForm.valid && !multiEmailExist && !multiPhoneExist) {
				this.apiServ.setLoaderId(this.loaderId);
				this.loader.show(this.loaderId);
				this.editForm.controls['email_id'].setValue((this.editForm.controls['email_id'].value).toLowerCase());
				if (this.uploader.queue[0]) {
					/** upload a image for customer **/
					this.utilServ.fileUploaderApi(this.uploader);
					// Uploader success handler
					this.uploader.onCompleteItem = (item: any, res: any) => {
						if (res) {
							if (IS_DEV) { console.log(item) }
							var result = JSON.parse(res);
							if (result.response) {
								if (result.response.api_status == 1) {
									let formValues = this.editForm.value;
									if (result.response.data.upload_url) {
										formValues.photo_url = result.response.data.upload_url;
									}
									this.updateProfile(formValues); // Update profile
								} else {
									this.loader.hide(this.loaderId);
									this.toastr.error(result.response.message);
								}
							}
						}
					};
					// Upload error handler
					this.uploader.onErrorItem = (item: any, res: any) => {
						this.loader.hide(this.loaderId);
						if (IS_DEV) { console.log(item) }
						if (res) {
							let error = JSON.parse(res); //error server response
							this.toastr.error(error);
						} else {
							this.toastr.error(this.initServ.appStr.toastr.imgUploadError);
						}
					}
				} else {
					this.updateProfile(this.editForm.value); // Update profile
				}
			} else {
				if (this.editForm.valid && (multiEmailExist || multiPhoneExist)) {
					let msg: string = multiEmailExist ? this.initServ.appStr.toastr.duplicateEmails : this.initServ.appStr.toastr.duplicatePhones;
					this.toastr.error(msg);
				} else {
					for (let i in this.editForm.controls) {
						this.editForm.controls[i].markAsTouched();
					}
					this.multiControlsMarkAsTouched('emails');
					this.multiControlsMarkAsTouched('phone_numbers');
					this.toastr.error('Please fill the required fields marked in red.');
				}
				this.loader.hide(this.loaderId);
			}
			this.cDRef.detectChanges();
		}, 1000);
	}
	/**
	 * Update the user information
	 * @param sendData
	 */
	private updateProfile(sendData: any): void {
		sendData['phone_number'] = this.utilServ.phoneUnmask(this.editForm.value['phone_number']);
		if (sendData.emails && (sendData.emails).length > 0) {
			for (let control of sendData.emails) {
				control.value = (control.value).toLowerCase();
			}
		}
		if (sendData.phone_numbers && (sendData.phone_numbers).length > 0) {
			for (let control of sendData.phone_numbers) {
				control.value = this.utilServ.phoneUnmask(control.value);
			}
		}
		this.apiServ.callApi('PUT', 'UpdateCustomer', sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res API res
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			if (res.data) {
				let customerInfo = res.data;
				// Set the user local storage
				let currentUser = this.utilServ.appLocalStorage();
				if (currentUser) {
					currentUser['first_name'] = customerInfo?.first_name ? customerInfo?.first_name : '';
					currentUser['last_name'] = customerInfo?.last_name ? customerInfo?.last_name : '';
					currentUser['photo_url'] = customerInfo?.photo_url ? customerInfo?.photo_url : '';
					try {
						localStorage.setItem('currentUser', JSON.stringify(currentUser));
					} catch (err) { }
					this.initServ.isUserProfile.next(true);
				}
				this.initServ.loggedInUser(res.data.id);
			}
			this.loader.hide(this.loaderId);
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]).then(() => { this.toastr.success(res.message); });
		} else {
			this.loader.hide(this.loaderId);
		}
	}
	/**
	 * Other contacts popups
	 */
	public otherContacts(): void {
		this.popupServ.otherContactsPopup(this.utilServ.userId());
	}
	/**
	 * Add new contact popup
	 */
	public addNewContact(): void {
		this.popupServ.addOtherContactPopup(this.utilServ.userId());
	}
	/**
	 * Set the yes/no send sms notification field value
	 * @param event: HTML event
	 */
	public sendSmsNotifChange(event: Event): void {
		const isChecked = (<HTMLInputElement>event.target).checked;
		if (isChecked) {
			this.editForm.controls['send_sms_notification'].setValue("yes");
		} else {
			this.editForm.controls['send_sms_notification'].setValue("no");
		}
	}
}
