import { Component, Input, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import{ TranslateService} from '@ngx-translate/core';
declare var FB: any;
declare var google: any;
// Services
import { NgOnDestroy, InitServ, UtilServ, PopupServ, SocialServ } from '../../../Services';
import { AuthServ } from '../../../Core/_services';

@Component({
	selector: 'bk-session-social',
	templateUrl: './SessionSocial.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SessionSocialComponent implements OnInit {
	// Variables
	@Input() data: any;
	@Input() openFrom: string = 'login';
	btnText: string = 'signin_with';
	socialKeys: any = this.initServ.appSocialKeys; // App social keys
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	isFB: boolean = false;
	isGoogle: boolean = false;
	fbUser: any;
	googleUser: any;
	loaderId: string = 'social-session-loader';

	constructor(@Self() private destroy: NgOnDestroy, public initServ: InitServ, private utilServ: UtilServ, private authServ: AuthServ, private popupServ: PopupServ, private socialServ: SocialServ, public translate: TranslateService) {
		if(this.utilServ){
			this.utilServ.loadGooglePlatformScript();
		}
	}

	ngOnInit() {
		if(this.openFrom && this.openFrom == 'signup'){
			this.btnText = 'signup_with';
		}
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			// this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		setTimeout(() => {
			if(this.socialKeys){
				this.initSocialScript();
			}
		}, 1000);
	}
	/**
	 * Initial social script(Facebook & Google)
	 */
	private initSocialScript() : void {
		if(this.socialKeys.Facebook || this.socialKeys.Google){
			// Build FB script
			if(this.socialKeys.Facebook){
				this.socialServ.fbInit(this.socialKeys.Facebook);
				this.isFB = true;
			}
			// Build Google script
			if(this.socialKeys.Google){
				// Insert google script
				var script = document.createElement('script');
				script.src = 'https://accounts.google.com/gsi/client';
				document.head.appendChild(script);
				this.isGoogle = true;
				let clientId:any = this.socialKeys.Google;
				setTimeout(() =>{
					try{
						google.accounts.id.initialize({
							client_id: clientId,
							callback: this.handleGoogleCredentialRes
						});
						google.accounts.id.renderButton(
							document.getElementById("googleBtn"),
							{
								theme: "outline",
								size: "large",
								width: "300px",
								type: "standard",
								shape: "rectangular",
								text: this.btnText,
								logo_alignment: "center",
								locale: this.translate.currentLang
							}  // customization attributes
						);
						google.accounts.id.prompt();
					}catch(err){console.log(err)}
				}, 1000);
			}
		}
	}
	handleGoogleCredentialRes(res: any): void {
		var base64Url = (res.credential).split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
			 return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
		const responsePayload = JSON.parse(jsonPayload);
		let user : any = {
			id : responsePayload.sub,
			name : responsePayload.name,
			email : responsePayload.email,
			photoUrl : responsePayload.picture,
			firstName : responsePayload.given_name,
			lastName : responsePayload.family_name,
			provider : 'GOOGLE'
		}
		let userStr: any = JSON.stringify(user);
		let input = document.getElementById('userData');
		if(input){
			input.setAttribute('value',userStr);
			let btnElem: any = document.getElementById("createUserBtn");
			if(btnElem){
				btnElem.click();
			}
		}
	}
	googleLoginAsUser(): any {
		let input = document.getElementById('userData');
		if(input){
			let user: any = input.getAttribute('value');
			if(user){
				this.getUserData(JSON.parse(user));
			}
		}
	}
	/**
	 * Get the Facebook user data
	 * @returns
	 */
	private FBStatus(): any {
		return new Promise((resolve) => {
			FB.login(function (res: any){
				if(res && res.status === 'connected'){
					FB.api('/me?fields=name,email,picture,first_name,last_name', (fbUser: any) => {
						let user: any = {
							id : fbUser.id,
							name : fbUser.name,
							email : fbUser.email,
							photoUrl : 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal',
							firstName : fbUser.first_name,
							lastName : fbUser.last_name,
							provider : 'FACEBOOK'
						}
						resolve(user);
					});
				}
			},{scope: 'public_profile,email'});
		});
	}
	/**
	 * Session form
	 * User email called api function
	 * Else  add email popup
	 * @param user
	 */
	private getUserData(user: any): void {
		if(user){
			if(user.email){
				this.sessionForm(user.email, user);
			} else {
				this.popupServ.socialEmailPopup(user).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res, user));
			}
		}
	}
	/**
	 * On popup result callback method
	 * @param res popup res
	 * @param user user information
	 * Popup response handler
	 */
	public popupCallback(res: any, user: any): void {
		if(res){
			this.sessionForm(res, user);
		}
	}
	/**
	 * Facebook signup/signin
	 */
	public signInWithFB(): void {
		this.fbUser = null;
		this.fbUser = this.FBStatus();
		this.fbUser.then((user: any) => this.getUserData(user));
	}
	/**
	 * Session form, called api to login/signup user
	 * @param email user email
	 * @param user user information
	 */
	private sessionForm(email: any, user: any): void {
		let sendObj: any = {
			first_name : user.firstName || user.name.split(" ")[0],
			last_name : user.lastName || user.name.split(" ")[1],
			email_id : user.email || email,
			social_login : user.provider,
			ip : this.initServ.ipAddress,
			user_agent : navigator.userAgent
		}
		// Auth key domain name + email id
		let authKey: string = '';
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.store && this.admnStngs.merchant_settings.store.domain_name){
			authKey = this.admnStngs.merchant_settings.store.domain_name + sendObj.email_id;
		}
		this.authServ.signup(sendObj,authKey, this.loaderId, true, true);
	}
}