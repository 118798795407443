<div class="tjs-d-border card mt-20 mb-10 bg-transparent bk-card-border" *ngIf="provider.provider_type == 'team'; else individualProv">
	<div class="card-header p-15 bg-transparent" [ngClass]="{'d-md-flex':(existingBookingDate && existingArrivalTime), 'border-bottom': isMember}">
		<ng-container *ngTemplateOutlet="provTemp; context:{team:true}"></ng-container>
		<ng-container *ngTemplateOutlet="scheduletDate"></ng-container>
	</div>
	<div class="card-body px-15 pt-15 pb-0" *ngIf="isMember && provider.members && (provider.members).length > 0">
		<div class="row">
			<div class="col-12 col-md-6 mb-15" *ngFor="let teamMember of provider.members;trackBy: utilServ.trackByFnIndex">
				<div class="tjs-media d-flex flex-column flex-sm-row align-items-center text-center text-sm-start">
					<div class="flex-shrink-0 mb-15 mb-md-0" *ngIf="utilServ.userNameSett('photo')">
						<bk-user-img [imgSrc]="teamMember?.photo_url" customClass="rounded img-fluid-disabled" alt="Provider Avatar" width="80" height="80" [isParentMedia]="true"></bk-user-img>
					</div>
					<div class="flex-grow-1 ms-sm-15">
						<h6 class="mb-0 fw-bold text-capitalize">
							<span class="text-capitalize">
								<ng-container *ngIf="utilServ.userNameSett('firstname')">{{teamMember?.first_name}} </ng-container>
								<ng-container *ngIf="utilServ.userNameSett('lastname')">{{teamMember?.last_name}} </ng-container>
								<ng-container *ngIf="!utilServ.userNameSett('lastname') && utilServ.userNameSett('last_initial')">{{utilServ.nameLastInitial(teamMember?.last_name)}}</ng-container>
							</span>
						</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #individualProv>
	<div class="border rounded-2 mt-20 bg-transparent mb-10 bk-card-border">
		<div class="card-body" [ngClass]="{'d-md-flex':(existingBookingDate && existingArrivalTime)}">
			<ng-container *ngTemplateOutlet="provTemp"></ng-container>
			<ng-container *ngTemplateOutlet="scheduletDate"></ng-container>
		</div>
	</div>
</ng-template>
<!-- Provider info template -->
<ng-template #provTemp let-team="team">
	<div class="tjs-media d-flex flex-column flex-sm-row align-items-center align-items-md-start text-center text-sm-start" *ngIf="provider && showSelectedProvider" [ngClass]="{'col-md-6':(existingBookingDate && existingArrivalTime)}">
		<div class="flex-shrink-0 mb-15 mb-md-0" *ngIf="utilServ.userNameSett('photo')">
			<bk-user-img [imgSrc]="provider?.photo_url" customClass="rounded img-fluid-disabled" alt="Provider Avatar" width="80" height="80" [isParentMedia]="true"></bk-user-img>
		</div>
		<div class="flex-grow-1 ms-sm-15">
			<h6 class="fw-bold mb-5 text-capitalize {{isMultiStepForm ? 'bk-label-fs bk-desc-clr':'bk-form-label'}}" *ngIf="utilServ.userNameSett('firstname') || utilServ.userNameSett('lastname') || utilServ.userNameSett('last_initial')">
				<ng-container *ngIf="utilServ.userNameSett('firstname')">{{provider?.first_name}} </ng-container>
				<ng-container *ngIf="utilServ.userNameSett('lastname')">{{provider?.last_name}} </ng-container>
				<ng-container *ngIf="!utilServ.userNameSett('lastname') && utilServ.userNameSett('last_initial')">{{utilServ.nameLastInitial(provider?.last_name)}}</ng-container>
			</h6>
			<div class="mb-5" *ngIf="team">
				<span class="badge rounded-2 bg-primary p-5">{{'Team of' | translate}} {{(provider.members).length}}</span>
			</div>
			<ng-container *ngIf="ratingPerm">
				<ng-container *ngIf="utilServ.userNameSett('ratings')">
					<ng-container *ngIf="admnStngs?.merchant_settings?.customers?.show_ratings != 'all'; else elseRatingBlock">
						<div *ngIf="provider?.average_rating && (provider?.average_rating >= admnStngs?.merchant_settings?.customers?.show_ratings)">
							<bk-rating-display class="tjs-rating-start" [rating]="provider?.average_rating" [readOnly]="true"></bk-rating-display>
							<span class="fs-14 d-block {{isMultiStepForm ? 'bk-label-fs bk-desc-clr':'bk-form-label-clr'}}">({{provider?.average_rating | number : '1.2-2'}} {{'out of' | translate}} 5)</span>
						</div>
					</ng-container>
					<ng-template #elseRatingBlock>
						<div *ngIf="provider?.average_rating">
							<bk-rating-display class="tjs-rating-start" [rating]="provider?.average_rating" [readOnly]="true"></bk-rating-display>
							<span class="fs-14 d-block {{isMultiStepForm ? 'bk-label-fs bk-desc-clr':'bk-form-label-clr'}}">({{provider?.average_rating | number : '1.2-2'}} {{'out of' | translate}} 5)</span>
						</div>
					</ng-template>
				</ng-container>
				<div *ngIf="utilServ.userNameSett('jobs')">
					<span class="fs-14 {{isMultiStepForm ? 'bk-label-fs bk-desc-clr':'bk-form-label-clr'}}">{{provider?.total_jobs_done}} {{'Jobs Completed' | translate}}</span>
				</div>
				<!-- Provider reviews -->
				<bk-view-provider-reviews [selectedProv]="provider" ></bk-view-provider-reviews>
			</ng-container>
			<!-- <div>
				<span class="label text-primary" *ngIf="ServiceProviderControl.controls['non_available_provider'].value" translate>NA</span>
			</div> -->
		</div>
	</div>
</ng-template>
<!-- Schedule template -->
<ng-template #scheduletDate>
	<div class="mt-20 mt-md-0 text-center text-sm-start" *ngIf="existingBookingDate && existingArrivalTime">
		<h6 class="fw-bold bk-form-label-clr" translate>Scheduled on</h6>
		<!-- <span class="d-block camel-case">{{'Date' | translate}}: {{bookingScheduledDay | translate}} - {{showExistingDate(existingBookingDate)}}</span> -->
		<span class="d-block fs-14 bk-desc-clr">{{'Date' | translate}}: {{bookingScheduledDay | translate}} - {{dateServ.formattedDateFromString(existingBookingDate, true, admnStngs?.merchant_settings?.store.date_format)}}</span>
		<span class="d-block fs-14 bk-desc-clr">{{'Arrival time' | translate}}:
			<span class="dir-ltr">{{utilServ.slotToString(prefilledData.base_location_id, prefilledData.day, prefilledData.arrival_time,prefilledData.spot_type,prefilledData.arrival_window)}}</span>
		</span>
	</div>
</ng-template>