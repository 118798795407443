import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { BkngFormServ, LoaderServ, NgOnDestroy, SectionServ, BuildCustomSectionService } from '../../../Services';

@Component({
	selector: 'bk-extras',
	templateUrl: './Extras.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ExtrasComponent implements OnInit {
	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() prefilledExtras: any;
	@Input() availableSett: any;
	@Input() extrasForm!: FormGroup;
	@Input() settings: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() isMultiStepForm: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Input() isShortForm: boolean = false;
	@Output() extraChange: EventEmitter<any> = new EventEmitter();
	@Output() isExtraVisible: EventEmitter<any> = new EventEmitter();
	extras: any;
	isDisabled: any;
	loaderId: string = 'bkng-extras';
	paramsValue: any;
	extraFormValue: any;
	controlName:string = '';
	formId: any;
	isChangeByEvent: boolean = false;
	extraStatus: boolean = false;

	constructor(private bkngFormServ: BkngFormServ, private loader: LoaderServ, private secServ: SectionServ, private cDRef: ChangeDetectorRef, private buildCustSecServ: BuildCustomSectionService) {}

	ngOnInit(): void {
		if(this.section){
			this.extraStatus = this.secServ.checkEleStatus(this.pageSett, this.section.id);
		}
		this.formId = +this.extrasForm.value.form_id;
		switch(this.formId) {
			case 1:
				this.controlName = 'pricing_parameter';
			break;
			case 3:
				this.controlName = 'items';
			break;
			case 4:
				this.controlName = 'area_parameter';
			break;
		}

		if(!this.prefilledData || this.bookingType != 'add'){
			this.buildExtras();
		}
		this.extraFormValue = this.extrasForm.value['extras'];

	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.extraFormValue = this.extrasForm.value['extras'];
						this.buildExtras();
						break;
					}
				}
			}
		}
	}
	/**
	 * ngOnChanges is not detect the changes, then ngDoCheck works
	 */
	ngDoCheck(): void {
		let cur: any = null;
		let prev: any = null;
		let isParamsChange: boolean = false;
		// Extra depend on form params
		if(this.formId == 1 || this.formId == 3 || this.formId == 4){
			cur = JSON.stringify(this.extrasForm.value[this.controlName]);
			prev = JSON.stringify(this.paramsValue);
			isParamsChange = (cur != prev) ? true : false;
		}
		// On extra change, its only work prefilled the extras
		if(!this.isChangeByEvent && !isParamsChange){
			cur = JSON.stringify(this.extrasForm.value['extras']);
			prev = JSON.stringify(this.extraFormValue);
		}
		if(cur != prev){
			this.extraFormValue = this.extrasForm.value['extras'];
			this.buildExtras();
		}
	}
	/**
	 * Build the extras array
	 */
	private buildExtras(): void {
		this.loader.show(this.loaderId);
		this.paramsValue = this.extrasForm.value[this.controlName];
		this.extras = [];
		this.buildCustSecServ.availableElems['extras'] = [];
		let formParamsValues: any = this.bkngFormServ.getFormParamsValue(this.extrasForm.value);
		if(this.settings && this.settings.extras && (this.settings.extras).length > 0){
			for(let extra of this.settings.extras){
				if(this.bkngFormServ.extraVisible(extra, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, formParamsValues, this.prefilledExtras, this.availableSett)){
					this.extras.push(extra);
					this.buildCustSecServ.availableElems['extras'].push(extra.id);
				}
			}
		}
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, false, '', this.isCustomerAllowedRes);
		if(this.isShortForm){
			this.isExtraVisible.emit((this.extras && this.extras.length > 0) ? true : false);
		}
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
	}
	/**
	 * extraChangeEvent
	 */
	public extraChangeEvent(): void {
		this.isChangeByEvent = true;
		this.extraChange.emit()
	}
}