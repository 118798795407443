import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, Self, AfterViewInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
// Services
import { InitServ, NgOnDestroy, UtilServ, SectionServ, RenderComponentServ } from '../../../Services';
import { BASE_URL } from '../../../Constants';
@Component({
	selector: 'bk-common-buttons',
	templateUrl: './CommonButtons.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CommonButtonsComponent implements OnInit, AfterViewInit {
	// Variables
	@Input() bookingType: string = '';
	@Input() settings : any;
	@Input() BKFrm!: FormGroup;
	@Input() section: any;
	@Input() isCustomerAllowedRes: boolean = false;
	@Input() prefilledData: any;
	@Input() isStepOneValid: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() pageSett: any;
	@Input() acceptTcStatus: boolean = false;
	@Input() isAcceptTcValid: boolean = true;
	@Output() submitBKFrm: EventEmitter<any> = new EventEmitter();
	@Output() rescheduleOnlyInfo : EventEmitter<any> = new EventEmitter();
	@Output() acceptTcChange: EventEmitter<any> = new EventEmitter();

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	invoiceStatus: boolean = true;
	isTermsValid: boolean = true;
	cookieStatus: boolean = false;
	cardChargedAfterStatus: boolean = false;
	acceptSaveBookingTcStatus: boolean = false;
	isBlockedStatus : boolean = false;
	userInfo = this.initServ.userInfo;
	isBkngStarted: boolean = false;

	constructor(public initServ: InitServ, public utilServ: UtilServ, public router: Router, private secServ: SectionServ, private rcServ: RenderComponentServ, @Self() private destroy: NgOnDestroy) {
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.userInfo = this.initServ.userInfo;
				this.isCustomerBlocked();
			}
		});
		this.isCustomerBlocked();
	}

	ngOnInit(): void {
		if(this.section){
			if(this.section?.cookie_content){
				this.cookieStatus = this.secServ.checkEleStatus(this.pageSett,this.section?.cookie_content?.id);
			}
			if(this.section?.card_charged_after){
				this.cardChargedAfterStatus = this.secServ.checkEleStatus(this.pageSett, this.section?.card_charged_after?.title_id)
			}
			if(this.section?.accept_save_booking_tc){
				this.acceptSaveBookingTcStatus = this.secServ.checkEleStatus(this.pageSett, this.section?.accept_save_booking_tc?.id)
			}
		}
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings.customers && this.admnStngs.merchant_settings.customers.offer_invoice){
			if((this.admnStngs.merchant_settings.customers.offer_invoice).length > 0){
				if(!(this.admnStngs.merchant_settings.customers.offer_invoice).includes('booking')){
					this.invoiceStatus = false;
				}
			} else {
				this.invoiceStatus = false;
			}
		}
		if(this.prefilledData && this.prefilledData?.status != 9){
			this.isBkngStarted = this.utilServ.checkIsBkngStarted(this.prefilledData.booking_date, this.prefilledData.arrival_time);
		}
	}
	ngAfterViewInit(): void {
		let elems: any = document.getElementsByClassName('bk-cust-link');
		if(elems && elems.length > 0){
			let self: any = this;
			for(let elem of elems){
				elem.addEventListener('click', function(e: any){
					self.openCustLink(e);
				})
			}
		}
	}
	/**
	 * Submit booking form
	 */
	public submit(): void {
		this.submitBKFrm.emit();
	}
	/**
	 * Reschedule only with info change
	 */
	public rescheduleInfo(){
		this.rescheduleOnlyInfo.emit();
	}
	/**
	 * Reload page
	 */
	public reload(): void{
		location.reload();
	}
	/**
	 * Reload the booking
	 * @param value
	 */
	public refreshBkng(value:any): void {
		if(value){
			setTimeout(() => {
				this.reload()
			}, 500);
		}
	}
	/**
	 * Check if customer blocked or not.
	 */
	public isCustomerBlocked(){
		if(this.userInfo && this.userInfo.is_blocked){
			this.isBlockedStatus = true;
		}else{
			this.isBlockedStatus = false;
		}
	}
	/**
	 * Open custom popup on terms and conditions link
	 * @param e Event data
	 */
	// public openCustPopup(e: any): void {
	// 	let type: string = e.target.getAttribute("data-link_type");
	// 	if(type && type == 'popup'){
	// 		e.preventDefault();
	// 		let popupid: any = e.target.getAttribute("data-link");
	// 		if(popupid){
	// 			this.rcServ.buildPopup(popupid);
	// 		}
	// 	}
	// }
	public openCustLink(e: any): void {
		e.preventDefault();
		let url: string = BASE_URL;
		let type: string = e.target.getAttribute("data-link_type");
		if(type){
			switch (type) {
				case 'page':
					// eslint-disable-next-line no-case-declarations
					let href: any = e.target.getAttribute("data-link");
					if(href){
						url += '/'+href;
						window.open(url, '_blank');
					}
					break;
				case 'popup':
					// eslint-disable-next-line no-case-declarations
					let popupid: any = e.target.getAttribute("data-link");
					if(popupid){
						this.rcServ.buildPopup(popupid);
					}
					break;
			}
		}
	}
}
