<div class="position-relative">
	<div class="min-height--150" [ngClass]="{'pt-30 pb-5 px-md-30 border-bottom bk-card-border': !isMultiStepForm}" [id]="secCustDetails?.id" [formGroup]="customerGroup" *ngIf="settings && secCustDetails">
		<!-- Title and description -->
		<bk-section-heading [section]="secCustDetails" [pageSett]="pageSett"></bk-section-heading>
		<!-- Existing customer cards -->
		<div class="mb-20" *ngIf="locationType == 'SA' && customerGroup.value['customer_type']=='existing customer' && addressComp && (addressComp?.addresses) && (addressComp?.addresses).length > 0">
			<div class="form-check form-check-inline me-25 mb-10 input--checked">
				<input id="existing" class="form-check-input" #existingAddress type="radio" value="existing_address" [(ngModel)]="addressComp.addressType" [ngModelOptions]="{standalone: true}" (change)="addressComp.addressTypeChange('existing')" [disabled]="addressComp.isRadioDisabled">
				<label for="existing" class="form-check-label text-capitalize bk-form-check-label" [id]="secAddress?.existing_address_id">{{secAddress?.existing_address ? secAddress?.existing_address : initServ?.appStr?.text?.useExisting}}</label>
			</div>
			<div class="form-check form-check-inline me-25 mb-10 input--checked">
				<input id="new" class="form-check-input" #newAddress type="radio" value="new_address" [(ngModel)]="addressComp.addressType" [ngModelOptions]="{standalone: true}" (change)="addressComp.addressTypeChange('new')" [disabled]="addressComp.isRadioDisabled">
				<label for="new" class="form-check-label text-capitalize bk-form-check-label" [id]="secAddress?.new_address_id">{{secAddress?.new_address ? secAddress?.new_address : initServ?.appStr?.text?.newAddress}}</label>
			</div>
		</div>
		<div class="row">
			<!-- First name -->
			<div id="customer-first-name-id" class="col-sm-6 col-md-6 mb-30">
				<div>
					<ng-container *ngTemplateOutlet="labelTemp; context:{field:'first_name', section:secCustDetails}"></ng-container>
					<input class="form-control bk-input" placeholder="{{secCustDetails?.first_name_placeholder}}" formControlName="first_name" [attr.disabled]="disabledFirstLastName ? 'disabled' : null" [ngClass]="{'is-invalid': customerGroup.controls['first_name']?.touched && customerGroup.controls['first_name']?.errors, 'form-control-lg': isMultiStepForm}" (focusout)="custDetailsChng.emit()">
					<!-- Error -->
					<ng-container *ngIf="customerGroup.controls['first_name']?.touched">
						<div class="tjs-error bk-error" *ngIf="customerGroup.controls['first_name']?.errors?.required"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}</div>
						<div class="tjs-error bk-error" *ngIf="customerGroup.controls['first_name']?.errors?.pattern"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.firstNameValid | translate}}</div>
						<div class="tjs-error bk-error" *ngIf="customerGroup.controls['first_name']?.errors?.shortTextValidationCheck"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.shortMaxLength | translate}}</div>
					</ng-container>
				</div>
			</div>
			<!-- Last name -->
			<div id="customer-last-name-id" class="col-sm-6 col-md-6 mb-30">
				<div>
					<ng-container *ngTemplateOutlet="labelTemp; context:{field:'last_name', section:secCustDetails}"></ng-container>
					<input class="form-control bk-input" placeholder="{{secCustDetails?.last_name_placeholder}}" formControlName="last_name" [attr.disabled]="disabledFirstLastName ? 'disabled' : null" [ngClass]="{'is-invalid': customerGroup.controls['last_name']?.touched && customerGroup.controls['last_name']?.errors, 'form-control-lg': isMultiStepForm}" (focusout)="custDetailsChng.emit()">
					<!-- Error -->
					<ng-container *ngIf="customerGroup.controls['last_name']?.touched">
						<div class="tjs-error bk-error" *ngIf="customerGroup.controls['last_name']?.errors?.required"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}</div>
						<div class="tjs-error bk-error" *ngIf="customerGroup.controls['last_name']?.errors?.pattern"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.lastNameValid | translate}}</div>
						<div class="tjs-error bk-error" *ngIf="customerGroup.controls['last_name']?.errors?.shortTextValidationCheck"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.shortMaxLength | translate}}</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="row">
			<!-- Primary email -->
			<div id="customer-section-id" class="col-sm-6 col-md-6 mb-30">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'email', section:secCustDetails}"></ng-container>
				<input class="form-control bk-input {{secCustDetails?.email_errors?.existing_email_id}}" id="userEmail" placeholder="{{secCustDetails?.email_placeholder}}" formControlName="email_id" [value]="customerGroup.controls['email_id']?.value" (focusout)="emailExistControl(customerGroup.controls['email_id'], 'single', true)" [attr.disabled]="isDisabled" [ngClass]="{'is-invalid': customerGroup.controls['email_id']?.touched && (customerGroup.controls['email_id']?.errors?.required || customerGroup.controls['email_id']?.errors?.pattern || customerGroup.controls['email_id']?.errors?.shortTextValidationCheck || customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar), 'form-control-lg': isMultiStepForm}" [readonly]="bookingType != 'add'" (keyup)="addPwdControl()" [class.existing-email]="customerGroup.controls['email_id']?.touched && secCustDetails?.email_errors && (customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar)">
				<!-- Error -->
				<ng-container *ngIf="customerGroup.controls['email_id']?.touched">
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['email_id']?.errors?.required"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['email_id']?.errors?.pattern"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.validEmail | translate}}</div>
					<div class="tjs-error bk-error" *ngIf="customerGroup.controls['email_id']?.errors?.shortTextValidationCheck"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.shortMaxLength | translate}}</div>
					<!-- <div class="tjs-error bk-error" *ngIf="customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.emailExistWithPwd | translate}}</div> -->
					<div [id]="secCustDetails?.email_errors?.existing_email_id" class="tjs-error" *ngIf="secCustDetails?.email_errors && (customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar)">
						<i class="tjsicon-attention"></i>
						{{secCustDetails?.email_errors?.existing_email}}
					</div>

				</ng-container>
			</div>
			<!-- Secondary emails -->
			<div id="sec-email-section-id" formArrayName="emails" class="col-sm-6 col-md-6 mb-30" *ngIf="customerGroup.controls['emails'].value && (customerGroup.controls['emails'].value).length > 0" [class.secondary_none]="disableSecEmailAndPhone!='no'">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'sec_email', section:secCustDetails, isHide:(disableSecEmailAndPhone!='no' ? true : false)}"></ng-container>
				<ng-container *ngIf="emails.controls && (emails.controls).length > 0">
					<ng-container *ngFor="let email of emails.controls; let i = index" [formGroupName]="i">
						<ng-container *ngIf="i == 0">
							<input [class.secondary_none]="disableSecEmailAndPhone!='no'" class="form-control bk-input" placeholder="{{secCustDetails?.sec_email_placeholder}}" formControlName="value" [ngClass]="{'is-invalid': email.get('value')?.touched && email.get('value')?.errors, 'form-control-lg': isMultiStepForm}" (focusout)="emailExistControl(email.get('value'), 'all', true)">
							<!-- Error -->
							<div class="tjs-error bk-error" [class.secondary_none]="disableSecEmailAndPhone!='no'" *ngIf="email.get('value')?.touched && (email.get('value')?.errors?.required || email.get('value')?.errors?.pattern || email.get('value')?.errors?.emailExists)">
								<i class="tjsicon-attention"></i>
								<ng-container *ngIf="email.get('value')?.errors?.required || email.get('value')?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
								<ng-container *ngIf="email.get('value')?.errors?.emailExists"> {{initServ?.appStr?.errorMsg?.emailAlreadyExist | translate}} </ng-container>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<!-- Password field if email exist -->
		<div class="row" *ngIf="customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar">
			<div id="password-section-id" class="col-sm-6 col-md-6 mb-30 t-gap-right">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'password', section:secCustDetails}"></ng-container>
				<div class="d-flex align-items-center">
					<input type="password" class="form-control bk-input" formControlName="password" placeholder="{{secCustDetails?.password_placeholder}}" [ngClass]="{'is-invalid': customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors, 'form-control-lg': isMultiStepForm}">
					<bk-tooltip *ngIf="secCustDetails?.password_tooltip" customClass="bk-tooltip" [id]="secCustDetails?.password_tooltip_id" [content]="secCustDetails?.password_tooltip | translate"></bk-tooltip>
				</div>
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors">
					<i class="tjsicon-attention"></i>
					{{initServ?.appStr?.errorMsg?.empty | translate}}
				</div>
			</div>
			<!-- Login and forgot button -->
			<div class="col-sm-6 col-md-6 mb-30">
				<label class="form-label d-none d-md-block">&nbsp;</label>
				<bk-navigate innerHTML="<i class='tjsicon-pin-code me-10'></i>{{secCustDetails?.login_btn?.content | translate}}" customClass="btn border-transparent {{isMultiStepForm ? 'btn-primary' : 'btn-success'}}" essentialClass="w-100 m-5 text-capitalize d-block bk-btn" (callback)="loginUser()" [secSett]="secCustDetails?.login_btn" ></bk-navigate>
				<div class="button-wrap d-flex align-items-center justify-content-between mt-5 forgot-pass-link">
					<a href="javascript:void(0)" class="{{isMultiStepForm ? 'fs-16' : 'fs-12'}}" [id]="secCustDetails?.forgot_password_id" [innerHTML]="secCustDetails?.forgot_password | safeHtml" (click)="forgotPwd()"></a>
				</div>
			</div>
		</div>
		<!-- Phone number -->
		<div class="row">
			<div id="phone-section-id" class="col-sm-6 col-md-6 mb-30">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'phone', section:secCustDetails}"></ng-container>
				<div class="input-group input-group-ltr" [ngClass]="{'input-group-lg': isMultiStepForm}">
					<div class="input-group-text" *ngIf="initServ?.callingCode"><span>{{initServ?.callingCode}}</span></div>
					<input class="form-control bk-input" type="text" [textMask]="{mask: phoneMask}" placeholder="{{secCustDetails?.phone_placeholder}}" formControlName="phone_number" (focusout)="phoneExistControl(customerGroup.controls['phone_number'])" [ngClass]="{'is-invalid': customerGroup.controls['phone_number']?.touched && customerGroup.controls['phone_number']?.errors, 'form-control-lg': isMultiStepForm}">
				</div>
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="customerGroup.controls['phone_number']?.touched && (customerGroup.controls['phone_number']?.errors?.required || customerGroup.controls['phone_number']?.errors?.phoneExists)">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="customerGroup.controls['phone_number']?.errors?.required"> {{initServ?.appStr?.errorMsg?.validPhoneNo | translate}} </ng-container>
					<ng-container *ngIf="customerGroup.controls['phone_number']?.errors?.phoneExists"> {{initServ?.appStr?.errorMsg?.phoneAlreadyExist | translate}} </ng-container>
				</div>
				<!-- Send notification checkbox -->
				<div class="form-check form-check-inline mt-20" *ngIf="phoneNotifStatus">
					<input id="send_sms_notification" class="form-check-input " type="checkbox" [(ngModel)]="sendSmsNotification" [ngModelOptions]="{standalone: true}" (change)="sendSmsNotifChange($event)">
					<label for="send_sms_notification" class="form-check-label bk-form-check-label" [id]="secCustDetails?.phone_notification_id" [innerHTML]="((secCustDetails?.phone_notification) ? secCustDetails?.phone_notification : initServ?.appStr?.text?.sms_notification) | translate | safeHtml" ></label>
				</div>
			</div>
			<!-- Secondary phone numbers -->
			<div id="sec-email-section-id" formArrayName="phone_numbers" class="col-sm-6 col-md-6 mb-30" *ngIf="customerGroup.controls['phone_numbers'].value && (customerGroup.controls['phone_numbers'].value).length > 0" [class.secondary_none]="disableSecEmailAndPhone!='no'">
				<ng-container *ngTemplateOutlet="labelTemp; context:{field:'sec_phone', section:secCustDetails, isHide:(disableSecEmailAndPhone!='no' ? true : false)}"></ng-container>
				<ng-container *ngIf="phoneNumbers.controls && (phoneNumbers.controls).length > 0">
					<ng-container *ngFor="let phone of phoneNumbers.controls; let i = index" [formGroupName]="i">
						<ng-container *ngIf="i == 0">
							<div class="input-group input-group-ltr" [ngClass]="{'input-group-lg': isMultiStepForm, 'secondary_none':disableSecEmailAndPhone!='no'}">
								<div class="input-group-text" *ngIf="initServ?.callingCode"><span>{{initServ?.callingCode}}</span></div>
								<input [class.secondary_none]="disableSecEmailAndPhone!='no'" [textMask]="{mask: phoneMask}" class="form-control bk-input" placeholder="{{secCustDetails?.sec_phone_placeholder}}" formControlName="value" [ngClass]="{'is-invalid': phone.get('value')?.touched && phone.get('value')?.errors, 'form-control-lg': isMultiStepForm}" (focusout)="phoneExistControl(phone.get('value'), 'all')">
							</div>
							<!-- Error -->
							<div class="tjs-error bk-error" [class.secondary_none]="disableSecEmailAndPhone!='no'" *ngIf="phone.get('value')?.touched && (phone.get('value')?.errors?.required || phone.get('value')?.errors?.phoneExists)">
								<i class="tjsicon-attention"></i>
								<ng-container *ngIf="phone.get('value')?.errors?.required"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
								<ng-container *ngIf="phone.get('value')?.errors?.phoneExists"> {{initServ?.appStr?.errorMsg?.phoneAlreadyExist | translate}} </ng-container>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<!-- Address component -->
		<ng-container *ngIf="locationType == 'SA' && !splitDetails">
			<bk-address #addressComp
			[isMultiStepForm]="isMultiStepForm"
			[isQuote]="isQuote"
			[currentUser]="currentUser"
			[isCustomerDetails]="true"
			[settings]="settings"
			[pageSett]="pageSett"
			[bookingType]="bookingType"
			[section]="section?.address_details"
			[custSection]="section.customer_details"
			[addressForm]="customerForm"
			[prefilledData]="prefilledData"
			[locationLayout]="locationLayout"
			[locationType]="locationType"
			[zipcode]="zipcode"
			[selectedLocation]="selectedLocation"
			[isCustomerAllowedRes]="isCustomerAllowedRes"
			(zipCodeChange)="zipCodeChange.emit($event)">
			</bk-address>
		</ng-container>
		<div class="mb-20" *ngIf="contacts && contacts.length > 0">
			<bk-navigate innerHTML="<i class='tjsicon-visibility me-10'></i>{{((secCustDetails?.other_cont_btn?.content) ? (secCustDetails?.other_cont_btn?.content) : (initServ?.appStr?.text?.viewOtherContact))}}" customClass="btn btn-primary border-transparent" essentialClass="d-inline-flex align-items-center bk-btn" [secSett]="secCustDetails?.other_cont_btn" (callback)="otherContactsPopup()" ></bk-navigate>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	<!-- Cookie content -->
	<div class="mt-10 mb-20" *ngIf="cookieStatus && secCustDetails && secCustDetails?.cookie_content && isMultiStepForm">
		<span class="bk-cookie fs-14" [id]="secCustDetails?.cookie_content_id" [innerHTML]="secCustDetails?.cookie_content | safeHtml"></span>
	</div>
</div>
<!-- Label template -->
<ng-template #labelTemp let-field="field" let-section="section" let-isHide="isHide">
	<label class="form-label bk-form-label" [class.secondary_none]="isHide" *ngIf="section[field]" [id]="section[field+'_id']" [innerHTML]="section[field] | safeHtml"></label>
</ng-template>
