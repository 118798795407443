<div class="py-30 py-md-60" [id]="secId">
	<div class="container">
		<div class="card position-relative" [id]="section?.form?.id" *ngIf="section.form">
			<div class="card-body">
				<!-- Section title -->
				<div class="tjs-card-title bk-border" *ngIf="section?.title">
					<h4 class="mb-0 card-title fw-bold" [id]="section?.title?.id" [innerHTML]="section?.title?.content | safeHtml"></h4>
				</div>
				<div class="">
					<form bkDebounceEvent [isSubmit]="true" [formGroup]="editForm" (debounceSubmit)="submitForm()">
						<!-- User image -->
						<div class="row">
							<div class="col-lg-12 col-xl-9">
								<div class="mb-20 d-lg-flex profile-file-upload">
									<div class="upload-btn p-15 mb-15 mb-lg-0 rounded-3 position-relative flex-shrink-0">
										<!-- add -->
										<div class="flex-shrink-0 mb-30 mb-md-0">
											<div class="img-upload-wrap d-flex align-items-center justify-content-center overflow-hidden rounded-3">
												<bk-user-img customClass="w-100 img-fluid-disabled" class="d-block w-100" [imgSrc]="editForm.value['photo_url']" height="180" width="180" [prepandImgUrl]="imgPath ? false : true"></bk-user-img>
											</div>
										</div>
										<i class="tjsicon-x-mark delete-image bg-danger tjs-pointer text-white d-flex align-items-center justify-content-center position-absolute rounded-circle" *ngIf="uploader.queue[0] || editForm.value['photo_url']" (click)="deleteImage()"></i>
									</div>
									<!-- choose file section -->
									<div class="ms-lg-20 profile-file-label profile-file-upload" [id]="section?.form?.choose_file_id">
										<div *ngIf="section?.form?.choose_file?.label">
											<label class="form-label bk-form-label" [innerHTML]="section?.form?.choose_file?.label | safeHtml" [id]="section?.form?.choose_file?.label_id"></label>
											<bk-tooltip class="bk-tooltip" [content]="section?.form?.choose_file?.tooltip" [id]="section?.form?.choose_file?.tooltip_id"></bk-tooltip>
										</div>
										<div class="tjs-custom-file w-100 d-block position-relative rounded">
											<input type="file" class="d-none form-control m-0 position-relative w-100" #uploadEl id="choose-file-2" type="file" ng2FileSelect [uploader]="uploader" accept="image/*" />
											<label class="upload-file__label tjs-pointer tjs-custom-file__label form-check-label form-control overflow-hidden text-truncate d-flex align-items-center" for="choose-file-2" [attr.data-browse-label]="section?.form?.choose_file?.upload_btn" [id]="section?.form?.choose_file?.placeholder_id">
												<ng-container *ngIf="uploadImgName; else staticTxt">{{uploadImgName | translate}}</ng-container>
												<ng-template #staticTxt>{{section?.form?.choose_file?.placeholder}}</ng-template>
											</label>
										</div>
										<small class="mt-5 d-block bk-desc" [id]="section?.form?.choose_file?.desc_id" [innerHTML]="section?.form?.choose_file?.desc | safeHtml" *ngIf="section?.form?.choose_file?.desc"></small>
									</div>
								</div>
								<!-- Input fields -->
								<div class="row">
									<!-- First name -->
									<div class="col-lg-6 mb-25">
										<label class="d-block form-label bk-form-label" [id]="section?.form?.first_name?.label_id" [innerHTML]="section?.form?.first_name?.label | safeHtml" *ngIf="section?.form?.first_name?.label"></label>
										<input class="form-control bk-input" [ngClass]="{'is-invalid': f['first_name']?.touched && f['first_name']?.errors}" [attr.placeholder]="section?.form?.first_name?.placeholder" formControlName="first_name">
										<!-- Error -->
										<div class="tjs-error" *ngIf="f['first_name']?.touched && (f['first_name']?.errors?.required || f['last_name']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											<ng-container *ngIf="f['first_name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
											<ng-container *ngIf="f['first_name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialChars | translate}} </ng-container>
										</div>
									</div>
									<!-- Last name -->
									<div class="col-lg-6 mb-25">
										<label class="form-label bk-form-label d-block" [id]="section?.form?.last_name?.label_id" [innerHTML]="section?.form?.last_name?.label | safeHtml" *ngIf="section?.form?.last_name?.label"></label>
										<input class="form-control bk-input" [ngClass]="{'is-invalid': f['last_name']?.touched && f['last_name']?.errors}" [attr.placeholder]="section?.form?.last_name?.placeholder" formControlName="last_name">
										<!-- Error -->
										<div class="tjs-error" *ngIf="f['last_name']?.touched && (f['last_name']?.errors?.required || f['last_name']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											<ng-container *ngIf="f['last_name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
											<ng-container *ngIf="f['last_name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialChars | translate}} </ng-container>
										</div>
									</div>
								</div>
								<div class="row">
									<!-- Company name -->
									<div class="col-lg-6 mb-25">
										<label class="form-label bk-form-label d-block" [innerHTML]="section?.form?.company_name?.label | safeHtml" [id]="section?.form?.company_name?.label_id" *ngIf="section?.form?.company_name?.label"></label>
										<input [ngClass]="{'is-invalid': f['company_name']?.touched && f['company_name']?.errors}" class="form-control bk-input"  [attr.placeholder]="'Ex: '+admnStngs.merchant_settings.store.store_name | translate"  formControlName="company_name">
										<div class="tjs-error" *ngIf="f['company_name']?.touched && f['company_name']?.errors?.pattern">
											<ng-container>{{initServ?.appStr?.errorMsg?.specialChars | translate}}</ng-container>
										</div>
									</div>
									<!-- Gender -->
									<div class="col-lg-6 mb-25">
										<label class="form-label bk-form-label d-block" [innerHTML]="section?.form?.gender?.label | safeHtml" [id]="section?.form?.gender?.id" *ngIf="section?.form?.gender?.label"></label>
										<div>
											<div class="form-check form-check-inline tjs-pointer mb-15 mb-sm-0">
												<input type="radio" id="male" class="form-check-input tjs-pointer bk-input" value="male" formControlName="gender">
												<label class="form-check-label bk-form-label" for="male" [id]="section?.form?.gender?.male_id" [innerHTML]="section?.form?.gender?.male | safeHtml"></label>
											</div>
											<div class="form-check form-check-inline tjs-pointer mb-15 mb-sm-0">
												<input type="radio" id="female" class="form-check-input tjs-pointer bk-input" value="female" formControlName="gender">
												<label class="form-check-label bk-form-label" for="female" [id]="section?.form?.gender?.female_id" [innerHTML]="section?.form?.gender?.female | safeHtml"></label>
											</div>
											<div class="form-check  form-check-inline">
												<input type="radio" id="unspecified" class="form-check-input tjs-pointer bk-input" value="unspecified" formControlName="gender">
												<label class="form-check-label bk-form-label" for="unspecified" [id]="section?.form?.gender?.unspecified_id" [innerHTML]="section?.form?.gender?.unspecified | safeHtml"></label>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<!-- Email -->
									<div class="col-lg-6">
										<div class="mb-25">
											<label class="form-label bk-form-label d-block" [id]="section?.form?.email?.label_id" [innerHTML]="section?.form?.email?.label | safeHtml" *ngIf="section?.form?.email?.label"></label>
											<div class="d-flex align-items-center justify-content-between">
												<input class="form-control bk-input" [ngClass]="{'is-invalid': f['email_id']?.touched && (f['email_id']?.errors)}" [attr.placeholder]="section?.form?.email?.placeholder" formControlName="email_id" (focusout)="emailExistControl(f['email_id'])">
												<!-- Multi button -->
												<button *ngIf="disableSecEmailPhone != 'yes'" type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__primary fs-20 bk-add" (click)="addMultiControl('emails')"><i class="tjsicon-add"></i></button>
											</div>
											<!-- Error -->
											<div class="tjs-error" *ngIf="f['email_id']?.touched && (f['email_id']?.errors?.required || f['email_id']?.errors?.pattern || f['email_id']?.errors?.emailExists)">
												<i class="tjsicon-attention"></i>
												<ng-container *ngIf="f['email_id']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
												<ng-container *ngIf="f['email_id']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
												<ng-container *ngIf="f['email_id']?.errors?.emailExists"> {{initServ?.appStr?.errorMsg?.emailAlreadyExist | translate}} </ng-container>
											</div>
										</div>
										<!-- Emails -->
										<ng-container *ngIf="f['emails'].value && (f['emails'].value).length > 0">
											<ng-container formArrayName="emails" *ngFor="let email of getControls('emails'); let i = index;trackBy: utilServ.trackByFnIndex ">
												<div class="mb-25" [formGroupName]="i">
													<div class="d-flex align-items-center justify-content-between">
														<input [class.secondary_none]="disableSecEmailPhone != 'no'" [ngClass]="{'is-invalid': email.controls['value']?.touched && email.controls['value']?.errors}" class="form-control bk-input" [attr.placeholder]="section?.form?.email?.placeholder" formControlName="value" (focusout)="emailExistControl(email.controls['value'])">
														<!-- Delete button -->
														<button [class.secondary_none]="disableSecEmailPhone != 'no'" type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__danger fs-20 bk-del" (click)="deleteMultiControl('emails', i)"><i class="tjsicon-delete "></i></button>
													</div>
													<!-- Error -->
													<div class="tjs-error" [class.secondary_none]="disableSecEmailPhone != 'no'" *ngIf="email.controls['value']?.touched && (email.controls['value']?.errors?.required || email.controls['value']?.errors?.pattern || email.controls['value']?.errors?.emailExists)">
														<i class="tjsicon-attention"></i>
														<ng-container *ngIf="email.controls['value']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
														<ng-container *ngIf="email.controls['value']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
														<ng-container *ngIf="email.controls['value']?.errors?.emailExists"> {{initServ?.appStr?.errorMsg?.emailAlreadyExist | translate}} </ng-container>
													</div>
													<div [class.secondary_none]="disableSecEmailPhone != 'no'" class="form-check mt-15 tjs-pointer">
														<input type="checkbox" class="form-check-input tjs-pointer bk-input" id="email_notification_{{i}}" formControlName="send_notifications">
														<label class="form-check-label bk-form-label" for="email_notification_{{i}}" [innerHTML]="section?.form?.email?.send_notification_label | safeHtml"></label>
													</div>
												</div>
											</ng-container>
										</ng-container>
									</div>
									<!-- Phone -->
									<div class="col-lg-6">
										<div class="mb-25">
											<label class="form-label bk-form-label d-block" [id]="section?.form?.phone?.label_id" [innerHTML]="section?.form?.phone?.label | safeHtml" *ngIf="section?.form?.phone?.label"></label>
											<div class="d-flex align-items-center justify-content-between">
												<div class="input-group input-group-ltr">
													<div class="input-group-text" *ngIf="initServ?.callingCode"><span>{{initServ?.callingCode}}</span></div>
													<input [ngClass]="{'is-invalid': f['phone_number']?.touched && f['phone_number']?.errors}" class="form-control bk-input" type="text" [textMask]="{mask: phoneMask}" [attr.placeholder]="section?.form?.phone?.placeholder" formControlName="phone_number" (focusout)="phoneExistControl(f['phone_number'])">
												</div>
												<!-- Multi button -->
												<button *ngIf="disableSecEmailPhone != 'yes'" type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__primary fs-20 bk-add" (click)="addMultiControl('phone_numbers')"><i class="tjsicon-add"></i></button>
											</div>
											<!-- Error -->
											<div class="tjs-error" *ngIf="f['phone_number']?.touched && (f['phone_number']?.errors?.required || f['phone_number']?.errors?.phoneExists)">
												<i class="tjsicon-attention"></i>
												<ng-container *ngIf="f['phone_number']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
												<ng-container *ngIf="f['phone_number']?.errors?.phoneExists"> {{initServ?.appStr?.errorMsg?.phoneAlreadyExist | translate}} </ng-container>
											</div>
											<!-- Send notification checkbox -->
											<div class="form-check form-check-inline mt-20">
												<input id="send_sms_notification" class="form-check-input " type="checkbox" [(ngModel)]="sendSmsNotification" [ngModelOptions]="{standalone: true}" (change)="sendSmsNotifChange($event)">
												<label for="send_sms_notification" class="form-check-label bk-form-label bk-form-check-label" [innerHTML]="section?.form?.phone?.send_sms_remainder_label | safeHtml" ></label>
											</div>
										</div>
										<!-- Phone numbers -->
										<ng-container *ngIf="f['phone_numbers'].value && (f['phone_numbers'].value).length > 0">
											<ng-container formArrayName="phone_numbers" *ngFor=" let phone of getControls('phone_numbers'); let i = index;trackBy: utilServ.trackByFnIndex ">
												<div class="mb-25" [formGroupName]="i">
													<div class="d-flex align-items-center justify-content-between">
														<div class="input-group input-group-ltr" [class.secondary_none]="disableSecEmailPhone != 'no'">
															<div class="input-group-text" *ngIf="initServ?.callingCode"><span>{{initServ?.callingCode}}</span></div>
															<input [class.secondary_none]="disableSecEmailPhone != 'no'" [ngClass]="{'is-invalid': phone.controls['value']?.touched && phone.controls['value']?.errors}" class="form-control bk-input" type="text" [textMask]="{mask: phoneMask}" [attr.placeholder]="section?.form?.phone?.placeholder" formControlName="value" (focusout)="phoneExistControl(phone.controls['value'])">
														</div>
														<!-- Delete button -->
														<button [class.secondary_none]="disableSecEmailPhone != 'no'" type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__danger fs-20 bk-del" (click)="deleteMultiControl('phone_numbers', i)"><i class="tjsicon-delete"></i></button>
													</div>
													<!-- Error -->
													<div class="tjs-error" [class.secondary_none]="disableSecEmailPhone != 'no'" *ngIf="phone.controls['value']?.touched && (phone.controls['value']?.errors?.required || phone.controls['value']?.errors?.emailExists)">
														<i class="tjsicon-attention"></i>
														<ng-container *ngIf="phone.controls['value']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
														<ng-container *ngIf="phone.controls['value']?.errors?.phoneExists"> {{initServ?.appStr?.errorMsg?.phoneAlreadyExist | translate}} </ng-container>
													</div>
													<div [class.secondary_none]="disableSecEmailPhone != 'no'" class="form-check mt-15 tjs-pointer">
														<input type="checkbox" class="form-check-input tjs-pointer bk-input" id="phone_notification_{{i}}" formControlName="send_notifications">
														<label class="form-check-label bk-form-label" for="phone_notification_{{i}}" [innerHTML]="section?.form?.phone?.send_notification_label | safeHtml"></label>
													</div>
												</div>
											</ng-container>
										</ng-container>
									</div>
								</div>
								<!-- Buttons -->
								<div class="d-md-flex d-grid align-items-center mt-5">
									<bk-navigate [secId]="section?.form?.add_btn?.id" innerHTML="<i class='tjsicon-visibility me-5'></i>{{(section?.form?.add_btn?.content)}}" [designId]="section?.form?.add_btn?.design_id" class="me-md-20 mb-15 mb-md-0" customClass="btn btn-lg btn-opacity-primary" (callback)="addNewContact()" essentialClass="w-100"></bk-navigate>
									<bk-navigate [secId]="section?.form?.view_btn?.id" innerHTML="<i class='tjsicon-visibility me-5'></i>{{(section?.form?.view_btn?.content)}}" [designId]="section?.form?.view_btn?.design_id" customClass="btn btn-lg btn-success" (callback)="otherContacts()" essentialClass="w-100"></bk-navigate>
								</div>
							</div>
						</div>
						<!-- Submit -->
						<div class="d-block text-md-end pt-20 mt-20 border-top bk-border">
							<div class="d-md-inline-block">
								<bk-navigate [secId]="section?.form?.update_btn?.id" [isSubmit]="true" [innerHTML]="section?.form?.update_btn?.content" [designId]="section?.form?.update_btn?.design_id" customClass="btn btn-lg btn-primary w-100"></bk-navigate>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- Loader -->
			<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
		</div>
	</div>
</div>
